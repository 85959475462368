import React, { Suspense } from 'react'

const SettingsMainAnalytics = React.lazy(() => import('src/modules/projects/containers/SettingsMainAnalytics'))

const SettingsMainDeliveryPage = () => {
  return (
    // TODO: add loading shimmer
    <Suspense fallback={''}>
      <SettingsMainAnalytics />
    </Suspense>
  )
}

export default SettingsMainDeliveryPage