import { useState, useEffect, useCallback } from 'react'
import { getLocalStorage, setLocalStorage } from '../localStorage'
import { omit } from 'lodash-es'
import { stringify, parse } from '../stringifyer'
import { CartBookingItem } from '../types/goodType'

export const localStorageCart = 'cart'
export const localStorageBookingCart = 'cartBooking'

type CartContextItem = Record<number | string, number | string>

export const useCart = (items?: CartContextItem) => {

  const [cartItems, setCartItems] = useState(items || {})
  /// Для бронирования
  const [cartBookingItems, setCartBookingItems] = useState<Array<CartBookingItem>>([])

  useEffect(() => {
    if (!items || Object.keys(items).length === 0) {
      let _cartItems = getLocalStorage(localStorageCart)
      if (_cartItems) {
        setCartItems(parse(_cartItems))
      }
    }
    /// Для бронирования
    let _cartBookingItems = getLocalStorage(localStorageBookingCart)
    // alert(_cartBookingItems)
    if (_cartBookingItems) {
      setCartBookingItems(JSON.parse(_cartBookingItems))
    }

  }, [])

  useEffect(() => {
    setLocalStorage(localStorageCart, stringify(cartItems))
    /// Для бронирования
    setLocalStorage(localStorageBookingCart, JSON.stringify(cartBookingItems))
  }, [cartItems, cartBookingItems])



  const deleteCartItem = useCallback((id: number) => {
    setCartItems((prevCartItems) => {
      const _cartItems = { ...prevCartItems }
      delete _cartItems[id]
      return _cartItems
    })
  }, [setCartItems])

  const toggleCartItem = useCallback((id: number) => {
    setCartItems((prevCartItems) => {
      const _item = prevCartItems[id]
      if (_item) {
        return omit(prevCartItems, id)
      } else {
        return { ...prevCartItems, [id]: 1 }
      }

    })
  }, [setCartItems])

  const setCartItem = useCallback((id: number, amount: number) => {
    if (!id) {
      return
    }
    if (amount > 0) {
      setCartItems((prevCartItems) => ({ ...prevCartItems, [id]: amount }))
    } else {
      deleteCartItem(id)
    }
  }, [setCartItems, deleteCartItem])

  const clearCartItems = useCallback(() => {
    setCartItems({})
    setCartBookingItems([])
  }, [setCartItems])

  const addAllCartItems = useCallback((items: number[] | string[]) => {
    setCartItems((prevCartItems) => {
      const newCartItems: CartContextItem = {}
      items.forEach((item: string | number) => {
        if (!prevCartItems[item]) {
          newCartItems[item] = 1
        }
      })
      return { ...prevCartItems, ...newCartItems }
    })
  }, [setCartItems])

  //Для бронирования

  const deleteCartBookingItem = useCallback((id: number) => {
    setCartBookingItems((prevCartBookingItems) => {
      const _cartBookingItems = [...prevCartBookingItems]
      return _cartBookingItems.filter(e => e.bookingOptionId !== id)
    })
  }, [setCartBookingItems])

  const toggleCartBookingItem = useCallback((obj: CartBookingItem) => {
    if (cartBookingItems) {
      const _cartBookingItems = [...cartBookingItems]
      _cartBookingItems.push(obj)
      setCartBookingItems(_cartBookingItems);
    } else {
      setCartBookingItems([obj]);
    }
  }, [setCartBookingItems, cartBookingItems])

  const clearCartBookingItems = useCallback(() => {
    setCartBookingItems([])
  }, [setCartBookingItems])


  return {
    cartItems,
    toggleCartItem,
    setCartItem,
    deleteCartItem,
    clearCartItems,
    addAllCartItems,
    setCartItems,

    //Для бронирования
    cartBookingItems,
    deleteCartBookingItem,
    clearCartBookingItems,
    setCartBookingItems,
    toggleCartBookingItem
  }
}