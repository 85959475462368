import React from 'react'

import DesignModeWrapper from 'src/modules/design/containers/DesignModeWrapper'

const DefaultHeader = React.lazy(() => import('src/templates/default/Header'))

const DefaultCover = React.lazy(() => import('src/templates/default/Cover'))

export const getTemplateHeader = (template, props) => {
  switch (template) {
    default:
      return <DesignModeWrapper type="Header" zIndex={2}>
        <DefaultHeader {...props} />
      </DesignModeWrapper>
  }
}

export const getTemplateCover = (template, props) => {
  switch (template) {
    default:
      return <DesignModeWrapper type="Cover" zIndex={2}>
        <DefaultCover {...props} />
      </DesignModeWrapper>
  }
}

const DefaultFooter = React.lazy(() => import('src/templates/default/layout/Footer'))

export const getTemplateFooter = (template, props) => {
  switch (template) {
    default:
      return <DesignModeWrapper type="Footer">
        <DefaultFooter {...props} />
      </DesignModeWrapper>
  }
}

const DefaultLayout = React.lazy(() => import('src/templates/default/layout/MainLayout'))

export const getTemplateLayout = (template, props) => {
  switch (template) {
    default:
      return DefaultLayout
  }
}

const DefaultListTemplate = React.lazy(() =>
  import("src/templates/default/ItemList")
);

export const getTemplateItemList = (template, props) => {
  switch (template) {
    default:
      return <DesignModeWrapper type="Goods">
        <DefaultListTemplate {...props} />
      </DesignModeWrapper>
  }
}