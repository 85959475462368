import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { showNotification } from 'src/store/notification'

import NotificationSearch from '../components/NotificationSearch'

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  showNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(
    NotificationSearch
  )
)
