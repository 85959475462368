import React, {
  memo,
  useRef,
  useMemo,
  Fragment,
  Suspense,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  P,
  P2,
  H2,
  Divider,
  IconButton,
  fullHeight,
  HeaderText,
  ProfileIcon,
  SettingsIcon,
} from "hopshopui";
import {
  selectActiveProject,
  getProjectDetails,
  handleImportLoading,
} from "src/store/projects";
import {
  triggerGetServices,
  triggerGetSubscriptions,
  selectPackageAdvancedEnabled,
} from "src/store/subscriptions";
import { node } from "prop-types";
import { useT } from "src/hooks/useT";
import { size } from "src/utils/styles";
import { AdminHeader } from "src/elements";
import { useSticky } from "src/hooks/sticky";
import { Grid, Row, Col } from "react-flexbox-grid";
import { useSelector, useDispatch } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import Menu from "src/modules/profile/containers/ProfileMenu";
import { history, locationShape, match } from "src/utils/propTypes";

const PopupImportLoading = React.lazy(() =>
  import("src/elements/popups/PopupImportLoading")
);

const ProjectMenu = React.lazy(() => import('src/pages/project/menu/ProjectMenuContainer'));

const SubscriptionMain = React.lazy(() =>
  import("src/modules/subscriptions/containers/SubscriptionMainContainer")
);

const LayoutGlobalStyles = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.colors.mildBackground};
  }
`;

const ProfileLayout = ({ children, location, history, match, ...props }) => {
  const { t } = useT();

  const isProfile = useMemo(() => {
    return location.pathname.indexOf("/profile") >= 0;
  }, [location.pathname]);
  const isProject = useMemo(() => {
    return location.pathname.indexOf("/project") >= 0;
  }, [location.pathname]);
  const [isTopProfile, setTopProfile] = useState(false);

  const [localePath, setLocalePath] = useState("");
  useEffect(() => {
    setLocalePath(location.pathname.replace(/\/\d+/, ""));
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.indexOf("/profile") >= 0) {
      if (
        location.pathname.length <
        location.pathname.indexOf("/profile") + 9
      ) {
        setTopProfile(true);
      } else {
        setTopProfile(false);
      }
    } else if (location.pathname.indexOf("/project") >= 0) {
      setTopProfile(false);
    }
  }, [location.pathname]);

  const { id } = match.params;
  const handleBack = useCallback(() => {
    if (isProfile) {
      if (isTopProfile) {
        history.push("/project");
      } else {
        history.push("/profile");
      }
    } else if (isProject) {
      if ( 
        location.pathname.length <
        location.pathname.indexOf(`/project/${id}/main`) +
          `/project/${id}/main`.length +
          1
      ) {
        history.push(`/project/${id}`);
      } else if (history.location.pathname.includes("main/promocodes/form")) {
          history.goBack()
      } else {
        history.push(`/project/${id}/main`);
      } 
    }
  }, [isProfile, isProject, isTopProfile, history, id, location.pathname]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getProjectDetails(id));
      dispatch(handleImportLoading({ projectId: id }));
      dispatch(triggerGetServices(id));
      dispatch(triggerGetSubscriptions(id));
    }
  }, [match.params, dispatch, id]);
  const project = useSelector((state) => selectActiveProject(state, id));
  const packageAdvancedEnabled = useSelector((state) =>
    selectPackageAdvancedEnabled(state, id)
  );

  const containerRef = useRef();

  const [fixed] = useSticky({
    ref: containerRef,
    deps: [],
  });

  const packagesRedirect = useCallback((e) => {
    e.preventDefault();
    history.push(`/project/${id}/main/packages`);
  }, []);

  const showMobilePackage = useMemo(() => {
    return localePath === "/project/main" || localePath === "/project/main/";
  }, [localePath]);

  return (
    <Background>
      <LayoutGlobalStyles />
      <AdminHeaderContainer>
        <AdminHeader
          id={project.id}
          location={location}
          isProfile={isProfile}
          isProject={isProject}
          domain={project && project.display_domain}
        />
      </AdminHeaderContainer>
      <MobileHeaderContainer ref={containerRef}>
        <HeaderRowMobile fixed={fixed}>
          <Arrow
            square
            transparent
            mobileCornered
            iconType="arrow"
            onClick={handleBack}
          />
          <HeaderText>{t(`router.${localePath}`)}</HeaderText>
        </HeaderRowMobile>
      </MobileHeaderContainer>
      {showMobilePackage && (
        <MobilePackageBlock onClick={packagesRedirect}>
          <PackagesTitle>{t("packages.linkTitle")}</PackagesTitle>
          <PackagesSelect>
            {packageAdvancedEnabled
              ? t("packages.advanced.header")
              : t("packages.basic.header")}
            {" ↓"}
          </PackagesSelect>
        </MobilePackageBlock>
      )}
      <Container>
        <HeaderContainer>
          {isProfile && (
            <Fragment>
              <Profile />
              <H2>{t("profile.myProfile")}</H2>
            </Fragment>
          )}
          {isProject && (
            <Fragment>
              <SettingsIcon />
              <StyledH2>{project && project.display_domain}</StyledH2>
            </Fragment>
          )}
          {isProject && (
            <PackagesBlock onClick={packagesRedirect}>
              <PackagesTitle>{t("packages.linkTitle")}</PackagesTitle>
              <PackagesSelect>
                {packageAdvancedEnabled
                  ? t("packages.advanced.header")
                  : t("packages.basic.header")}
                {" ↓"}
              </PackagesSelect>
            </PackagesBlock>
          )}
        </HeaderContainer>
        {isProject && !showMobilePackage && <Divider />}
        {isProfile && <Divider />}
        <ChildContainer>
          <Col md={3} className="hidden-sm">
            {isProfile && <MenuStyled match={match} {...props} />}
            {isProject && (
              <ProjectMenu />
            )}
          </Col>
          <RightCol md={9} xs={12}>
            {children}
          </RightCol>
        </ChildContainer>
      </Container>
      <Suspense fallback={<div></div>}>
        <SubscriptionMain />
      </Suspense>
      <Suspense fallback={<div></div>}>
        <PopupImportLoading />
      </Suspense>
    </Background>
  );
};
ProfileLayout.propTypes = {
  children: node,
  match: match.isRequired,
  history: history.isRequired,
  location: locationShape.isRequired,
};

const PackagesBlock = styled("div")`
  margin-left: auto;
  margin-right: 10px;
  background: ${(props) => props.theme.colors.light};
  border-radius: 10px;
  max-height: 60px;
  width: 220px;
  text-align: center;
  cursor: pointer;
  padding: 10px 15px;
  text-align: left;
`;

const MobilePackageBlock = styled("div")`
  display: none;
  margin: 10px 10px 20px 10px;
  background: ${(props) => props.theme.colors.light};
  border-radius: 10px;
  max-height: 60px;
  width: calc(100% - 20px);
  text-align: center;
  cursor: pointer;
  padding: 10px 15px;
  text-align: left;
  @media screen and (max-width: ${size.mobile}) {
    display: block;
  }
`;

const PackagesTitle = styled(P2)``;

const PackagesSelect = styled(P)``;

const AdminHeaderContainer = styled.div`
  @media screen and (max-width: ${size.mobile}) {
    display: none;
  }
`;

const Arrow = styled(IconButton)`
  top: -80px;
  &:hover{
    background: none;
  }
`;

const Background = styled.div`
  background-color: ${(props) => props.theme.colors.mildBackground};
  && {
    ${fullHeight}
  }
`;

const RightCol = styled(Col)`
  @media screen and (max-width: ${size.mobile}) {
    && {
      padding: 0;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  @media screen and (max-width: ${size.mobile}) {
    && {
      padding-top: 0;
      display: none;
    }
  }
`;

const Profile = styled(ProfileIcon)`
  margin-right: 30px;
`;


const Container = styled(Grid)`
  @media screen and (max-width: ${size.mobile}) {
    && {
      width: 100%;
    }
  }
`;

const ChildContainer = styled(Row)`
  padding-top: 20px;
  @media screen and (max-width: ${size.mobile}) {
    && {
      padding-top: 0;
    }
  }
`;

const HeaderRowMobile = styled(Row)`
  && {
    display: none;
  }
  @media screen and (max-width: ${size.mobile}) {
    background: #000000;
    justify-content: center;
    position: ${(props) => (props.fixed ? "fixed" : "static")};
    left: 0;
    right: 0;
    top: 0;
    line-height: 10px;
    height: 70px;
    align-items: center;
    z-index: 7;
    && {
      display: flex;
    }
  }
`;

const MobileHeaderContainer = styled.div`
  position: relative;
  height: 70px;
  width: 100%;
  overflow: hidden;
  display: none;
  @media screen and (max-width: ${size.mobile}) {
    display: block;
  }
`;

const MenuStyled = styled(Menu)`
  @media screen and (max-width: ${size.mobile}) {
    && {
      display: none;
    }
  }
`;

const StyledH2 = styled(H2)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export default memo(ProfileLayout);
