import React, { Fragment, Suspense } from 'react'
import { string, func } from 'prop-types'
import { Notification } from 'hopshopui'

const NotificationComonent = ({ text, delNotification }) => {
  return <Fragment>
    <Suspense fallback={<div></div>}>
      {text && <Notification onClick={delNotification}>{text}</Notification>}
    </Suspense>
  </Fragment>
}

NotificationComonent.propTypes = {
  text: string,
  delNotification: func
}

export default NotificationComonent