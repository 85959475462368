function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min
}

function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str
  return str.substr(0, index) + chr + str.substr(index + 1)
}

function isLetter(str) {
  return str.length === 1 && str.match(/[a-z]/i)
}

export const KEYLISTSPEC = "@_$!#%*?.,’\"~`&”-"

export function generatePass(plength) {
  let keylistalpha = "abcdefghijklmnopqrstuvwxyz"
  let keylistint = "123456789"

  let temp = ''
  let len = plength / 2
  len = len - 1
  let lenspec = plength - len - len

  for (let i = 0; i < len; i++) {
    temp += keylistalpha.charAt(Math.floor(Math.random() * keylistalpha.length))
  }

  for (let i = 0; i < lenspec; i++) {
    temp += KEYLISTSPEC.charAt(Math.floor(Math.random() * KEYLISTSPEC.length))
  }

  for (let i = 0; i < len; i++) {
    temp += keylistint.charAt(Math.floor(Math.random() * keylistint.length))
  }

  temp = temp.split('').sort(function () { return 0.5 - Math.random() }).join('')

  let didUppercase = false

  while (!didUppercase) {
    const charNumber = Math.floor(getRandomArbitrary(0, plength - 1))
    const char = temp[charNumber]
    if (isNaN(char) && isLetter(char)) {
      temp = setCharAt(temp, charNumber, char.toUpperCase())
      didUppercase = true
    }
  }


  return temp
}