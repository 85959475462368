import actionCreatorFactory, { ActionCreator, Action } from 'typescript-fsa';


const actionCreator = actionCreatorFactory();

export type GetPaymentsDonePayload = {
  data: PaymentItemType[],
  currencies: currencyType[]
};
export const triggerGetPayments = actionCreator("payment/TRIGGER_GET_PAYMENTS");
export const getPayments = actionCreator.async<{}, GetPaymentsDonePayload>(
  "payment/GET_PAYMENTS"
);

export type GetUserCardsDonePayload = {
  data: UserCardItemType[];
};
export const triggerGetUserCards = actionCreator('payment/TRIGGER_GET_USER_CARDS');
export const stopPaymentBgSync = actionCreator<PaymentItemType>('payment/STOP_PAYMENT_BG_SYNC')
export const cancelPaymentBgSync = actionCreator<null>('payment/CANCEL_PAYMENT_BG_SYNC')
export type StartPaymentBgSyncPayload = {
  data: PaymentItemType,
  params: TriggerGetLastPaymentPayload
}
export const startPaymentBgSync = actionCreator<StartPaymentBgSyncPayload>('payment/START_PAYMENT_BG_SYNC')


export const getUserCards = actionCreator.async<
  {},
  GetUserCardsDonePayload
>("payment/GET_USER_CARDS");
export const triggerAddUserCard = actionCreator(
  "payment/TRIGGER_ADD_USER_CARD"
);

export type ActiveUserCardPayload = {
  isActive: boolean;
  cardId: string;
};

export const triggerSetActiveUserCard = actionCreator<
  ActiveUserCardPayload
>("payment/TRIGGER_SET_ACTIVE_USER_CARD");

export const setActiveUserCard = actionCreator.async<
  Action<ActiveUserCardPayload>,
  null
>("payment/SET_ACTIVE_USER_CARD", {
  skipStartedAction: true,
});

export const triggerDeleteUserCard = actionCreator<string>(
  "payment/TRIGGER_DELETE_USER_CARD"
);
export const deleteUserCard = actionCreator.async<Action<string>, null>(
  "payment/DELETE_USER_CARD",
  {
    skipStartedAction: true,
  }
);

export type CancelPaymentPayload = {
  projectId: string,
  systemName: string,
  paymentId: string
}

export const triggerCancelPayment = actionCreator<CancelPaymentPayload>(
  "paynent/TRIGGER_CANCEL_PAYMENT"
)

export const cancelPayment = actionCreator.async<Action<CancelPaymentPayload>, null>(
  "payment/CANCEL_PAYMENT"
)

export type PaymentItemType = {
  id: string;
  userId: number;
  serviceName?: string;
  friendlyName?: string;
  projectId: string;
  projectName?: string;
  paymentDate: string;
  period: number;
  price: number;
  currency: number;
  paymentMethod: {
    method?: string;
    cardBrand?: string;
    account?: string;
    paymentSystem?: string;
  };
  ticketUrl?: string;
  transactionId?: string;
  paymentUrl?: string,
  paymentStateId: number;
  isProcessed: boolean;
};

export type UserCardItemType = {
  cardId: number;
  cardBrand?: string;
  account?: string;
  isActive: boolean;
};

export type currencyType = {
  key: number,
  value: string
}

export type PaymentsResponseType = {
  payments: PaymentItemType[],
  currencies: currencyType[]
}

export type UserCardsResponseType = UserCardItemType[];



export type TriggerGetLastPaymentPayload = {
  systemName: string,
  projectId: string
}

export const triggerGetLastPayment = actionCreator<TriggerGetLastPaymentPayload>('payment/TRIGGER_LAST_PAYMENT')

export const getLastPayment = actionCreator.async<Action<TriggerGetLastPaymentPayload>, PaymentItemType>('payment/GET_LAST_PAYMENT')


export type PaymentsStateType = {
  loading: boolean,
  payments: PaymentItemType[],
  userCards: UserCardItemType[],
  lastPayment?: PaymentItemType,
  currencies: currencyType[]
};
export type GetUserCardResponseType = {
  //тут оставил через _ потому что это респонс, и он нужен только внутри саги
  order_id?: string;
  payment_url?: string;
  is_payment_in_process: boolean;
};


