import React, { Suspense, useEffect } from 'react'
import CategoriesShimmer from 'src/elements/loaders/CategoriesShimmer'

const EditCategoriesList = React.lazy(() => import('src/modules/categories/components/EditCategoriesMain'))

const EditCategoriesPage = ({
  categories,
  getCategories,
  updateCategories,
  match,
  loading
}) => {
  const { id } = match.params
  useEffect(() => {
    if (id) {
      getCategories(id)
    }
  }, [id, getCategories])
  return (
    <Suspense fallback={<CategoriesShimmer />}>
      <EditCategoriesList categories={categories} updateCategories={updateCategories} projectId={id}  loading={loading} />
    </Suspense>
  )
}

export default EditCategoriesPage