import React, {
  useCallback,
  useContext,
  useEffect,
  Fragment,
  Suspense,
  useState,
  useMemo,
  memo,
} from "react";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import { func, object, bool } from "prop-types";
import qs from "qs";
import {
  flattenCategoriesBreadcrumb,
  SecondaryRoundButton,
  PrimaryRoundButton,
  useDelayedCallback,
  ControlPanel,
  PalleteIcon,
  AddButton,
  EditIcon,
  Tooltip,
  Popup,
} from "hopshopui";
import { match, locationShape, logsShape } from "src/utils/propTypes";
import history from "src/utils/browserHistory";
import getImageUrl from "src/utils/getImageUrl";
import {
  selectProjectGoodsCategoryTotal,
  selectProjectSearchLoading,
  selectActiveProjectGoods,
  selectProjectGoodsSorting,
  selectProjectGoodsTotal,
  selectActiveCategories,
  selectActiveProject,
} from "src/store/projects";
import {
  getTemplateItemList,
  getTemplateHeader,
  getTemplateFooter,
  getTemplateCover,
} from "src/utils/getTemplate";
import { useT } from "src/hooks/useT";
import ItemLink from "src/elements/template/ItemLink";
import TemplateProvider from "src/providers/TemplateProvider";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import DesignLayout from "src/modules/design/containers/DesignLayout";
import MenuContext from "../../templates/utils/providers/MenuProvider";
import { TemplateGlobalStyles } from "src/elements/styles/TemplateGlobal";
import PopupCategories from "src/modules/projects/containers/PopupCategories";
import DesignCoverButton from "src/modules/design/components/DesignCoverButton";
import { FixedContainer } from "src/modules/projects/components/ProjectContent";

const GoodsWidget = React.lazy(() =>
  import("src/modules/goods/containers/GoodsWidgetContainer")
);

const ProjectPage = ({
  triggerGetSubscriptions,
  triggerGetProjectSync,
  coverSettingsDesign,
  isUpdateInprogress,
  triggerEditRange,
  toggleDesignMode,
  loadMoreLoading,
  getProjectGoods,
  resetGoodsCount,
  triggerGetLogs,
  bookingEnabled,
  designSelection,
  designBlocked,
  loadMoreGoods,
  categoriesMap,
  getCategories,
  goodsLoading,
  designMode,
  location,
  mainPage,
  loading,
  match,
  sync,
  logs,
}) => {

  const [showGoodsWidget, setShowGoodsWidget] = useState(false);
  const { id } = match.params;

  const isMain = useMemo(() => {
    if (!mainPage) return false
    return match.url.includes(`${match.params.id}/${mainPage.alias}`)
  }, [mainPage, match])

  const project = useSelector((state) => selectActiveProject(state, id));
  const goods = useSelector(selectActiveProjectGoods);
  const totalCount = useSelector(selectProjectGoodsTotal);
  const categoriesTotalCount = useSelector(selectProjectGoodsCategoryTotal);
  const categories = useSelector((state) => selectActiveCategories(state, id));
  const searchLoading = useSelector(selectProjectSearchLoading);
  const sortOptions = useSelector(selectProjectGoodsSorting);

  const [activeCategoryShape, setActiveCategoryShape] = useState();
  const [activeCategory, setActiveCategory] = useState();
  const [popupCategories, setPopupCategories] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [sortActive, setSortActive] = useState(0);
  const [isFooterVisible, setIsFooterVisible] = useState(0)

  const [buttonPlus, setButtonPlus] = useState(false);

  const [isChangeSyncPopupVisible, setChangeSyncPopupVisible] = useState(false);
  const { isMenuVisibleWithScroll } = useContext(MenuContext);

  const onEditClick = () => {
    window.scrollTo(0, 0);
    setShowGoodsWidget(true);
  };

  useEffect(() => {
    getCategories(id);
  }, []);

  useEffect(() => {
    if (id) {
      triggerGetLogs({
        projectId: id,
      });
    }
  }, [triggerGetLogs, id]);

  useEffect(() => {
    if (id) {
      triggerGetProjectSync({ projectId: id });
    }
  }, [triggerGetProjectSync, id]);

  useEffect(() => {
    const search = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    setActiveCategory(search.category);
    const _activeCategory = categoriesMap[search.category];
    setActiveCategoryShape(_activeCategory);
    setSearchValue(search.search);

    const breadcrumbs = flattenCategoriesBreadcrumb(
      search.category,
      categoriesMap
    );
    setBreadcrumbs(breadcrumbs);
  }, [
    location.search,
    setActiveCategoryShape,
    setActiveCategory,
    setSearchValue,
    categoriesMap,
    setBreadcrumbs,
  ]);

  useEffect(() => {
    const search = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (search.filterType) {
      setSortActive(search.filterType);
    }
  }, []);

  const getGoods = () => {
    const search = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (!isUpdateInprogress) {
      getProjectGoods({
        projectId: id,
        categoryId: search.category,
        search: search.search,
        filterType: search.filterType,
      });
    }
  };

  useEffect(() => {
    getGoods();
  }, [location.search, id, getProjectGoods]);

  const loadMoreCallback = useCallback(() => {
    if (goods && goods.length && !loadMoreLoading) {
      const search = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      loadMoreGoods({
        projectId: id,
        categoryId: search.category,
        search: search.search,
        filterType: search.filterType,
      });
    }
  }, [location, id, loadMoreGoods, goods, loadMoreLoading]);

  const handleItemClick = useCallback((alias) => {
    history.push(`/project/${id}/${alias}`);
  }, [id]);

  const handlePopupClose = useCallback(
    (active) => {
      setPopupCategories(false);
    },
    [setPopupCategories]
  );

  const handleSetActiveCategory = useCallback(
    (active) => {
      const search = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      search.category = active;
      history.push({
        search: qs.stringify(search),
      });
      handlePopupClose();
    },
    [setActiveCategory, location.search, handlePopupClose]
  );

  const handleDelayedSearch = useDelayedCallback(
    (value) => {
      const search = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      search.search = value;
      history.push({
        ...search,
        search: qs.stringify(search),
      });
    },
    [location.search]
  );

  const handleSearchChange = useCallback(
    (value) => {
      setSearchValue(value);
      handleDelayedSearch(value);
    },
    [setSearchValue, handleDelayedSearch]
  );

  const handleAdminClick = useCallback(() => { }, []);

  const handleBreadCrumbClick = useCallback(() => {
    setPopupCategories(true);
  }, [setPopupCategories, popupCategories]);

  const onAllCategoriesClick = useCallback(() => {
    setPopupCategories(true);
  }, [setPopupCategories]);

  const handleSortChange = useCallback(
    (active) => {
      setSortActive(active);
      const search = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      search.filterType = active;
      history.push({
        ...search,
        search: qs.stringify(search),
      });
      resetGoodsCount();
    },
    [setSortActive, location.search, resetGoodsCount]
  );

  const handleAddButtonCart = useCallback((e, type) => {
    e.preventDefault();
    switch (type) {
      case "Manual":
        history.push(`/project/${id}/goods/addgood/`);
        break;
      case "Import":
        history.push(`/project/${id}/main/sync`);
        break;
      case "CreateService":
        if (!bookingEnabled) {
          history.push(`/project/${id}/main/booking`);
        } else {
          history.push(`/project/${id}/goods/addservice/`);
        }
        break;
      case "Paint":
        toggleDesignSelectionCallback(e)
        break;
      case "Logo":
        history.push(`/project/${id}/main/about`);
        break;
      default:
        return null;
    }
  }, [activeCategory, sync, history, id])

  const handleAddButton = useCallback(
    (e, type) => {
      setButtonPlus(!buttonPlus);
      e.preventDefault();
      console.log(sync);
      if (sync.settings.policy === 0 && sync.settings.type === 1) {
        setChangeSyncPopupVisible(true);
      } else {
        if (activeCategory) {
          if (type === "service") {
            history.push(`/project/${id}/goods/addservice/${activeCategory}`);
          } else {
            history.push(`/project/${id}/goods/addgood/${activeCategory}`);
          }
        } else {
          if (type === "service") {
            history.push(`/project/${id}/goods/addservice/`);
          } else {
            history.push(`/project/${id}/goods/addgood/`);
          }
        }
      }
    },
    [activeCategory, sync, history, id]
  );

  useScrollPosition(
    ({ currPos }) => {
      const shouldLoad =
        currPos.y + window.innerHeight >= document.body.clientHeight - 100;
      if (shouldLoad && !isUpdateInprogress) {
        loadMoreCallback();
      }
    },
    [loadMoreCallback],
    false,
    350
  );

  const { t } = useT();

  const toggleDesignSelectionCallback = useCallback(
    (e) => {
      e.preventDefault();
      toggleDesignMode();
    },
    [toggleDesignMode]
  );

  const handleWidgetSubmit = useCallback(
    ({
      goodsIds,
      targetStatusId,
      callback,
      rangeEditType,
      activeCategory,
      searchValue,
      allSelected,
    }) => {
      if ((goodsIds && goodsIds.length > 0) || allSelected) {
        const payload = {
          goodsIds: goodsIds,
          projectId: id,
          statusId: targetStatusId,
          callback,
          rangeEditType,
          activeCategory,
          allSelected,
        };
        triggerEditRange(payload);
      }
    },
    [id]
  );

  const handleCloseWidget = () => {
    setShowGoodsWidget(false);
    getGoods();
  };

  useEffect(() => {
    if (id) {
      triggerGetSubscriptions(id);
    }
  }, [triggerGetSubscriptions, id]);

  const isCoverImage = useMemo(() => {
    return (coverSettingsDesign?.coverSettings?.image || coverSettingsDesign?.newCoverImage)
  }, [coverSettingsDesign])

  return (
    <DesignLayout>
      <TemplateProvider>
        <Fragment>
          <TemplateGlobalStyles />
          {popupCategories && (
            <PopupCategories
              active={activeCategory}
              projectId={id}
              categories={categories}
              onClose={handlePopupClose}
              onAccept={handleSetActiveCategory}
            />
          )}
          {(designSelection && isMain && !isCoverImage) && <DesignCoverButton />}
          {(isMain && isCoverImage)
            && <Suspense fallback={""}>
              {getTemplateCover("default", {
                coverSettingsDesign: coverSettingsDesign?.coverSettings,
                newCoverImage: coverSettingsDesign?.newCoverImage,
              })}
            </Suspense>
          }

          <Suspense fallback={""}>
            {getTemplateHeader("default", {
              project,
              shouldDisplayHeaderMobile: true,
              shouldDisplaySubHeaderMobile: true,
              noMobileMargin: true,
              homeActive: true,
              searchValue,
              onSearchChange: handleSearchChange,
            })}
          </Suspense>
          <Suspense fallback={<div></div>}>
            {getTemplateItemList("default", {
              goods,
              match,
              project,
              sortActive,
              totalCount,
              sortOptions,
              breadcrumbs,
              searchValue,
              getImageUrl,
              categoriesMap,
              searchLoading,
              bookingEnabled,
              link: ItemLink,
              handleItemClick,
              handleAdminClick,
              isUpdateInprogress,
              handleAddButtonCart,
              onAllCategoriesClick,
              categoriesTotalCount,
              goodsLoading: loading,
              onSortChange: handleSortChange,
              activeCategory: activeCategoryShape,
              onCategoryClick: handleSetActiveCategory,
              onCategoryBreadCrumbClick: handleBreadCrumbClick,
              searchLoadingLabel: t("project.searchLoadingLabel"),
              toggleDesignSelectionCallback: toggleDesignSelectionCallback,
            })}
          </Suspense>
          <Suspense fallback={""}>
            {getTemplateFooter("default", {
              isFooterVisible,
              setIsFooterVisible,
              title: project.title,
              isAdult: project.isAdult || project.hasAdultGoods,
              mobileHide: designSelection || designMode ? false : true,
            })}
          </Suspense>
        </Fragment>

        <Fragment>
          {!designBlocked && (
            <FixedContainer id="settingsButtons" visible={isMenuVisibleWithScroll} isFooterVisible={isFooterVisible}>
              <ControlContainer>
                <StyledControlPanel isBooking={true && buttonPlus} buttonPlus={buttonPlus}>
                  {!isUpdateInprogress &&
                    <Fragment>
                      {!buttonPlus &&
                        <Fragment>
                          <ButtonSecondary onClick={toggleDesignSelectionCallback} Icon={<PalleteIcon fill="#000" />} round={true} size="small" tooltipText={<Tooltip position="top">{t("buttonControlPanel.design")}</Tooltip>} />
                          <ButtonSecondary data-testid="edit-good-button" onClick={onEditClick} Icon={<StyledEditIcon />} round={true} size="small" tooltipText={<Tooltip position="top">{t("buttonControlPanel.edit")}</Tooltip>} />
                        </Fragment>
                      }
                      <StyledButtonsContainer buttonPlus={buttonPlus}>
                        <StyledSecondaryRoundButton onClick={(e) => handleAddButton(e, "service")} buttonPlus={buttonPlus}>
                          {t("profile.payments.service")}
                        </StyledSecondaryRoundButton>
                        <StyledSecondaryRoundButton onClick={handleAddButton} buttonPlus={buttonPlus}>
                          {t("orders.productLabel")}
                        </StyledSecondaryRoundButton>
                      </StyledButtonsContainer>
                      {bookingEnabled ?
                        <AddMultiPrimaryButton data-testid="add-good" onClick={() => setButtonPlus(!buttonPlus)} buttonPlus={buttonPlus} Icon={<ButtonAdd />} round={true} size="large" tooltipText={!buttonPlus && <Tooltip position="top">{t("buttonControlPanel.create")}</Tooltip>} /> :
                        <StyledPrimaryRoundButton data-testid="add-good" onClick={handleAddButton} buttonPlus={buttonPlus} Icon={<ButtonAdd />} round={true} size="large" tooltipText={<Tooltip position="top">{t("buttonControlPanel.create")}</Tooltip>} />
                      }
                    </Fragment>
                  }
                </StyledControlPanel>
              </ControlContainer>
            </FixedContainer>
          )}
        </Fragment>


        {isChangeSyncPopupVisible && (
          <Popup
            title={t("popup.verify")}
            text={t("popup.verifyChangeSync")}
            buttons={[t("buttons.yes"), t("buttons.no")]}
            handleButtons={(index) => {
              if (index === 0) {
                history.push(`/project/${id}/main/sync`);
              }
              setChangeSyncPopupVisible(false);
            }}
          />
        )}
        {showGoodsWidget && (
          <Suspense fallback={<div></div>}>
            <GoodsWidget
              limit={null}
              projectId={id}
              onClose={handleCloseWidget}
              onSubmit={handleWidgetSubmit}
              editRangeMode
            />
          </Suspense>
        )}
      </TemplateProvider>
    </DesignLayout>
  );
};

const StyledControlPanel = styled(ControlPanel)`
  gap: 10px;
`

const ControlContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  >div{
    pointer-events: auto;
  }

`

const ButtonAdd = styled(AddButton)`
  width: 100%;
  height: 100%;
  background: #000;
`

const StyledEditIcon = styled(EditIcon)`
  path {
    fill: #000;
  }
`;

const fadeIn = keyframes`
  from {
    transform: translateX(112px);
  }
  to {
    transfrom: translateX(0)
  }
`

const StyledButtonsContainer = styled.div`
  display: ${(props) => props.buttonPlus ? 'flex' : 'none'};
  animation-name: ${(props) => props.buttonPlus ? fadeIn : `none`};
  animation-duration: 0.3s;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`

const ButtonSecondary = styled(SecondaryRoundButton)`
  width: 40px;
  height: 40px;
`

const StyledSecondaryRoundButton = styled(SecondaryRoundButton)`
  transition: 0.3s;
  transform: ${(props) => props.buttonPlus ? "translateX(0px)" : "translateX(115px)"};
`;

const AddMultiPrimaryButton = styled(PrimaryRoundButton)`
  transition: 0.3s;
  transform: ${(props) =>
    props.buttonPlus ? "rotate(135deg) scale(0.9)" : "rotate(0deg) scale(1)"};
  :hover{
    button{
      box-shadow: unset;
      background: ${props => props.theme.colors.roundButtonPrimaryHover};
    }
  }
  :hover:active{
    button{
      background: ${props => props.theme.colors.roundButtonPrimary};
    }
  }
`;

const StyledPrimaryRoundButton = styled(PrimaryRoundButton)`
  :hover{
    button{
      box-shadow: unset;
      background: ${props => props.theme.colors.roundButtonPrimaryHover};
    }
  }
  :hover:active{
    button{
      background: ${props => props.theme.colors.roundButtonPrimary};
    }
  }
`;

ProjectPage.propTypes = {
  //это всё уже не нужно?
  //deleteCategory: func.isRequired,
  //getProjects: func.isRequired,
  //addCategory: func.isRequired,
  //editCategory: func.isRequired,
  //getProjectDetails: func.isRequired,
  getCategories: func.isRequired,
  getProjectGoods: func.isRequired,
  incrementGoodsCount: func.isRequired,
  resetGoodsCount: func.isRequired,
  match: match,
  location: locationShape,
  getInitialProjectSyncData: func.isRequired,
  sync: object,
  isUpdateInprogress: bool,
  loadMoreGoods: func,
  loadMoreLoading: bool,
  loading: bool,
  logs: logsShape,
  bookingEnabled: bool,
};

export default memo(ProjectPage);
