import { RootState } from "../rootTypes";
import { createSelector } from "reselect";
import produce from "immer";

const selectCheckout = (state: RootState) => state.customCheckout;

export const selectCheckoutFormLoading = createSelector(
  selectCheckout,
  (state) => state.loading
);

export const selectCheckoutForm = createSelector(
  selectCheckout,
  (state) => {
    return state.checkoutFields
  }
);

export const selectShowPromocode = createSelector(
  selectCheckout,
  (state) => {
    return state.showPromocode
  }
);