import React, {
  memo,
  MouseEvent,
  FC,
  useEffect,
  useState,
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  Suspense,
  useRef,
  FormEvent,
  SyntheticEvent,
} from "react";
import styled from "styled-components";
import { EditorState, RichUtils } from "draft-js";
import { Input, Toggle } from "hopshopui";
import EditorButton from "src/elements/buttons/EditorButton";
import {useT} from 'src/hooks/useT';

import Check from "src/elements/icons/Check";
import LaunchLink from "src/elements/icons/LaunchLink";

const InserLink = React.lazy(() => import("src/assets/insertLink"));

type Props = {
  editorState: EditorState;
  onToggle: (e: SyntheticEvent<HTMLElement>) => void;
  changeState: (state: EditorState) => void;
};

const LinkControls: FC<Props> = ({ editorState, onToggle, changeState }) => {
  const [link, setLink] = useState("");
  const [target, setTarget] = useState(false);
  const containerRef = useRef<HTMLFormElement>(null);
  const { t } = useT()
  // find and define link state
  useEffect(() => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.querySelector("input")?.focus();
      }
      // inputRef.current?.focus()
    }, 10);
  }, []);
  useEffect(() => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithPotentialLinkAtBeggining = contentState.getBlockForKey(
        startKey
      );
      const linkKey = blockWithPotentialLinkAtBeggining.getEntityAt(
        startOffset
      );
      // TODO: change entity here to highlight active link
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        const data = linkInstance.getData();
        setLink(data.url);
        setTarget(data.target == "_self");
      }
    }
  }, [setLink, editorState, setTarget]);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setLink(value);
    },
    [setLink]
  );

  const confirmLink = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      if (link !== "") {
        const contentState = editorState.getCurrentContent();
        const contentWithEntity = contentState.createEntity("LINK", "MUTABLE", {
          url: link,
          target: target ? "_self" : "_blank",
        });
        const entityKey = contentWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
          currentContent: contentWithEntity,
        });
        const finalState = RichUtils.toggleLink(
          newEditorState,
          newEditorState.getSelection(),
          entityKey
        );
        changeState(finalState);
      }
      onToggle(e);
    },
    [link, onToggle, editorState, changeState, target]
  );
  const handleToggleChange = useCallback(
    (e) => {
      setTarget((t) => !t);
    },
    [setTarget]
  );

  return (
    <LinkContainer ref={containerRef} onSubmit={confirmLink}>
      <Suspense fallback={<div></div>}>
        <EditorButton>
          <InserLink />
        </EditorButton>
      </Suspense>
      <StyledInput
        type="text"
        onChange={handleInputChange}
        value={link}
        placeholder={t("editor.linkPlaceholder")}
      />
      <StyledToggle
        small
        values={[<LaunchLink />, <LaunchLink rotated/>]}
        checked={target}
        onChange={handleToggleChange}
      />
      <EditorButton active={false} type="submit">
        <Check />
      </EditorButton>
    </LinkContainer>
  );
};

const LinkContainer = styled.form`
  background: ${(props) => props.theme.colors.light};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
`;

const StyledToggle = styled(Toggle)`
  width: auto;
`

const StyledInput = styled.input`
  display: flex;
  flex: 1;
  margin-right: 10px;
  appearance: none;
  border: none;
  background: none;
  width: 100%;
  outline: none;
`;

export default memo(LinkControls);
