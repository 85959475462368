import { RootState } from '../rootTypes'
import { createSelector } from 'reselect'
import { CardViewInitialValues, ProjectDesignResponseType } from './projectSettingsTypes'
import { DefaultHeaderSettings, DefaultFooterSettings } from './projectSettingsReducer'
import { FooterSettings, HeaderSettings, GradientBackground, MonotoneBackground } from 'src/templates/utils/types/projectSettings'
import { BlockCustomizationSettings, ButtonBlockCustomizationSettings, CoverBlockCustomizationType } from 'src/templates/utils/types/goodType'

export const selectProjectSettings = (state: RootState) => state.projectSettings

export const selectCardView = createSelector(
  selectProjectSettings,
  (_: RootState, id: string) => id,
  (state, id): CardViewInitialValues => {
    const stateView = state.cardView[id] || {}
    const cardView: CardViewInitialValues = {
      ...stateView,
      buttonColor: stateView.buttonColor || '',
      buttonColorHover: stateView.buttonColorHover || '',
      rectangular: stateView.form === 'Rectangular'
    }
    return cardView
  }
)

export const selectProjectSettingsLoading = createSelector(
  selectProjectSettings,
  (state) => state.loading
)

export const selectProjectSettingsDesignSelection = createSelector(
  selectProjectSettings,
  (state): boolean => state.designSelection
)

export const selectProjectSettingsDesignModeSelected = createSelector(
  selectProjectSettings,
  (state) => state.designModeSelected
)

export const selectProjectSettingsHeaderDesign = createSelector(
  selectProjectSettings,
  (state): HeaderSettings => state.header || DefaultHeaderSettings
)

export const selectModuleButtonDesign = createSelector(
  selectProjectSettings,
  (state): ButtonBlockCustomizationSettings | null => state.moduleButton
)

export const selectProjectSettingsFooterDesign = createSelector(
  selectProjectSettings,
  (state): FooterSettings => state.footer || DefaultFooterSettings
)

export const selectProjectSettingsInitialDesign = createSelector(
  selectProjectSettings,
  (state): HeaderSettings => state.initialLayoutView.header || DefaultHeaderSettings
)

export const selectDesignBlocked = createSelector(
  selectProjectSettings,
  (state): boolean => state.designSelection || !!state.designModeSelected
)


export const selectProjectLayoutSettings = createSelector(
  selectProjectSettings,
  (state): ProjectDesignResponseType => {
    return {
      layoutView: {
        header: state.header,
        footer: state.footer,
        background: state.background
      }
    }
  }
)

export const selectDesignConfirmation = createSelector(
  selectProjectSettings,
  (state): boolean => {
    return state.designCancelConfirmation
  }
)

export const selectDesignBackground = createSelector(
  selectProjectSettings,
  (state): GradientBackground | MonotoneBackground => {
    return state.background
  }
)

export const selectDesignBlock = createSelector(
  selectProjectSettings,
  (state): BlockCustomizationSettings | null => {
    return state.block
  }
)

export const selectDesignCover = createSelector(
  selectProjectSettings,
  (state): CoverBlockCustomizationType | null => {
    return state.cover
  }
)