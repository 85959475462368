import React, { Component, Fragment } from 'react'
import Router from 'src/router'
import { connect } from 'react-redux'
import { getStyleVars } from 'hopshopui'
import { bool, func } from 'prop-types'
import { init } from './utils/analyticks'
import history from 'src/utils/browserHistory'
import AppLoader from 'src/elements/loaders/AppLoader'
import { SocketProvider } from 'src/providers/SocketProvider'
import AutoscrollProvider from 'src/providers/AutoscrollProvider'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { MenuProvider } from "./templates/utils/providers/MenuProvider"
import { CartProvider } from './templates/utils/providers/CartProvider'
import Notification from 'src/modules/notification/containers/Notification'
import { appInit, selectAppLoading, selectAuthrozied } from 'src/store/auth'
import NotificationOrderProvider from 'src/providers/NotificationOrderProvider'
import { FavoriteProvider } from './templates/utils/providers/FavoriteProvider'
import NotificationAction from 'src/modules/notification/containers/NotificationAction'
import NotificationSearch from 'src/modules/notification/containers/NotificationSearch'

const AppGlobalStyles = createGlobalStyle`
  .container {
    max-width: 960px;
  }
  * {
    -webkit-locale: auto;
  }
`
init()
export class App extends Component {

  componentDidMount() {
    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    this.props.appInit({ accessToken, refreshToken })
  }

  render() {
    const theme = getStyleVars()
    const { isAppInitializing, authorized } = this.props

    // TODO: Add loader @loading
    if (isAppInitializing) {
      return <AppLoader />
    }

    return (
      <Fragment>
        <FavoriteProvider>
          <CartProvider>
            <ThemeProvider theme={theme}>
              <MenuProvider>
                <Fragment>
                  <AppGlobalStyles />
                  <Notification />
                  <NotificationAction />
                  <NotificationSearch />
                  <NotificationOrderProvider />
                  <Router authorized={authorized} history={history} />
                </Fragment>
              </MenuProvider>
            </ThemeProvider>
          </CartProvider>
        </FavoriteProvider>
        {/*<Fonts />*/}
        <AutoscrollProvider />
        <SocketProvider />
      </Fragment>
    )
  }
}

App.propTypes = {
  isAppInitializing: bool,
  appInit: func,
  authorized: bool.isRequired
}

const mapStateToProps = (state) => ({
  isAppInitializing: selectAppLoading(state),
  authorized: selectAuthrozied(state)
})

const mapDispatchToProps = {
  appInit
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
