import React, { useEffect, useState } from 'react'
import { string, func, bool, object, array } from 'prop-types'
import styled from 'styled-components'
import { Popup, Input } from 'hopshopui'

const PopupInputForm = (
  {
    title,
    handleSubmit,
    errors,
    label,
    touched,
    placeholder,
    type,
    handleChange,
    handleBlur,
    isValid,
    values,
    buttons,
    handleButtons,
    onClose,
    validationSchema,
    withDomain,
    domain
  }
) => {
  const [mappedButtons, setMappedButtons] = useState([])
  const handleButtonsCallback = (index) => {
    handleButtons(index)
    if (buttons[index].shouldSubmit) {
      handleSubmit()
    }

  }

  useEffect(() => {
    setMappedButtons(
      buttons.map((button) => {
        let disabled
        if (button.shouldValidate) {
          disabled = !isValid
        }
        return { ...button, disabled }
      })
    )
  }, [isValid, buttons])

  return (
    <Popup title={title}
      values={values}
      onClose={onClose}
      text={
        <Form onSubmit={handleSubmit}>
          <Input
            // {...formikProps}
            validationSchema={validationSchema}
            errors={errors}
            label={label}
            touched={touched}
            placeholder={placeholder}
            name='input'
            id='input'
            type={type}
            withDomain={withDomain}
            domain={domain}
            value={values.input || ''}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form>
      }
      buttons={mappedButtons}
      handleButtons={handleButtonsCallback} />
  )
}

const Form = styled.form`
  padding: 0 20px;
`

PopupInputForm.propTypes = {
  title: string,
  label: string,
  handleSubmit: func,
  errors: object,
  touched: object,
  placeholder: string,
  type: string,
  handleChange: func,
  handleBlur: func,
  isValid: bool,
  values: object,
  buttons: array,
  handleButtons: func,
  onClose: func,
  validationSchema: func
}

PopupInputForm.defaultProps = {
  handleChange: () => { }
}

export default PopupInputForm