import { handleActions, createAction } from 'redux-actions'
import Immutable from 'seamless-immutable'

// ---
// CONSTANTS
// ---

export const LOGIN = 'auth/LOGIN'
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS'
export const AUTH_TOGGLE_LOADING = 'auth/TOGGLE_LOADING'
export const AUTH_VK = 'auth/AUTH_VK'
export const AUTH_OK = 'auth/AUTH_OK'
export const AUTH_INST = 'auth/AUTH_INST'
export const AUTH_VK_SUCCESS = 'auth/AUTH_VK_SUCCESS'
export const SET_TOKEN = 'auth/SET_TOKEN'
export const APP_INIT_TOGGLE = 'auth/APP_INIT_TOGGLE'
export const APP_INIT = 'auth/APP_INIT'
export const AUTH_TEST = 'auth/AUTH_TEST'
export const UPDATE_AUTH = 'auth/UPDATE_AUTH'
export const REFRESH_TOKENS = 'auth/REFRESH_TOKENS'
export const JUST_TEST = 'auth/JUST_TEST'
export const SIGNUP = 'auth/SIGNUP'
export const RESTORE_PASSWORD = 'auth/RESTORE_PASSWORD'
export const RESET_PASSWORD = 'auth/RESET_PASSWORD'
export const SIGNOUT = 'auth/SIGNOUT'
export const RESET_AUTH = 'auth/RESET'
export const EXCHANGE_TOKENS = 'auth/EXCHANGE_TOKENS'
export const EXCHANGE_TOKENS_SUCCESS = 'auth/EXCHANGE_TOKENS_SUCCESS'
export const EXCHANGE_TOKENS_FAILURE = 'auth/EXCHANGE_TOKENS_FAILURE'
export const SET_PASSWORD_ERROR = 'auth/SET_PASSWORD_ERROR'

// ---
// ACTION CREATORS
// ---

export const login = createAction(LOGIN)
export const loginSuccess = createAction(LOGIN_SUCCESS)
export const authToggleLoading = createAction(AUTH_TOGGLE_LOADING)
export const authVk = createAction(AUTH_VK)
export const authOk = createAction(AUTH_OK)
export const authVkSuccess = createAction(AUTH_VK_SUCCESS)
export const setToken = createAction(SET_TOKEN)
export const appInit = createAction(APP_INIT)
export const appInitToggle = createAction(APP_INIT_TOGGLE)
export const authTest = createAction(AUTH_TEST)
export const updateAuth = createAction(UPDATE_AUTH)
export const refreshToken = createAction(REFRESH_TOKENS)
export const justTest = createAction(JUST_TEST)
export const signup = createAction(SIGNUP)
export const restorePassword = createAction(RESTORE_PASSWORD)
export const resetPassword = createAction(RESET_PASSWORD)
export const signOut = createAction(SIGNOUT)
export const resetAuth = createAction(RESET_AUTH)
export const exchangeTokens = createAction(EXCHANGE_TOKENS)
export const exchangeTokensSuccess = createAction(EXCHANGE_TOKENS_SUCCESS)
export const exchangeTokensFailure = createAction(EXCHANGE_TOKENS_FAILURE)
export const authInst = createAction(AUTH_INST)
export const setPasswordError = createAction(SET_PASSWORD_ERROR)

// ---
// INITIAL STATE
// ---

const initialState = Immutable({
  accessToken: '',
  refreshToken: '',
  username: '',
  authorized: false,
  appInit: true,
  loading: false,
  passwordError: false,
})

// ---
// REDUCER
// ---

export default handleActions(
  {
    [SET_TOKEN]: (state, { payload: { accessToken, refreshToken } }) => {
      return Immutable.merge(state, { accessToken, refreshToken, authorized: true })
    },
    [AUTH_TOGGLE_LOADING]: (state, action) => {
      return Immutable.merge(state, { loading: action.payload })
    },
    [SET_PASSWORD_ERROR]: (state, { payload }) => {
      return Immutable.merge(state, { passwordError: payload })
    },
    [APP_INIT_TOGGLE]: (state, action) => {
      return Immutable.merge(state, { appInit: action.payload })
    },
    [UPDATE_AUTH]: (state, { payload }) => {
      return Immutable.merge(state, payload)
    },
    [RESET_AUTH]: () => Immutable.merge(initialState, { appInit: false, authorized: false })
  },
  initialState
)

