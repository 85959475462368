import React, { Fragment, memo, FC, useMemo } from "react";
import { EditorState } from "draft-js";
import InlineControl from "./InlineControl";

export type BlockStyle = "header-two";

const BLOCK_TYPES: {
  label: string,
  style: BlockStyle
}[] = [{ label: "H2", style: "header-two" }];

type Props = {
  editorState: EditorState;
  onToggle: (props: string) => void;
};

const BlockControls: FC<Props> = ({ editorState, onToggle }) => {
  const selection = useMemo(() => {
    return editorState.getSelection();
  }, [editorState]);
  const blockType = useMemo(() => {
    return editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
  }, [editorState, selection]);
  return (
    <Fragment>
      {BLOCK_TYPES.map((type) => (
        <InlineControl
          onToggle={onToggle}
          style={type.style}
          active={type.style === blockType}
        />
      ))}
    </Fragment>
  );
};

export default memo(BlockControls);
