import React, { Fragment } from 'react'
import styled from 'styled-components'
import { shimmerStyles } from 'hopshopui'
import { Col, Grid, Row } from 'react-flexbox-grid'

const OrderColumnsShimmer = () => {
  return (
    <Fragment>
      <ShimmerCol md="4">
        <ShimmerCard></ShimmerCard>
        <ShimmerCard></ShimmerCard>
        <ShimmerCard></ShimmerCard>
      </ShimmerCol>
      <ShimmerCol md="4">
        <ShimmerCard></ShimmerCard>
      </ShimmerCol>
      <ShimmerCol md="4">
        <ShimmerCard></ShimmerCard>
        <ShimmerCard></ShimmerCard>
      </ShimmerCol>
    </Fragment>
  )
}

const ShimmerCol = styled(Col)`
  padding: 20px 10px;
`

const ShimmerCard = styled.div`
  ${shimmerStyles};
  height: 240px;
  margin-bottom: 10px;
  border-radius: 10px;
`

export default OrderColumnsShimmer
