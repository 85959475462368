import React, { Suspense } from 'react'

const HtmlCode = React.lazy(() => import('src/modules/projects/containers/SettingsHtmlCode'))

const SettingsMainMarketplacesPage = () => {
    return (
        <Suspense fallback={''}>
            <HtmlCode />
        </Suspense>
    )
}

export default SettingsMainMarketplacesPage