import React, { Suspense } from 'react'

import ProfileShimmer from 'src/modules/profile/components/ProfileShimmer'
const AddMail = React.lazy(() => import('src/modules/profile/containers/NotificationsAddEmail'))

const NotificationsAddEmail = () => {
  return (
    <Suspense fallback={<ProfileShimmer />}>
      <AddMail />
    </Suspense>
  )
}

export default NotificationsAddEmail
