import React, { useEffect, useState, memo, useCallback } from 'react'
import { IconFile } from 'hopshopui'
import { useDispatch } from 'react-redux'
import { string, func, oneOfType, number, bool, object } from 'prop-types'
import getImageUrl from 'src/utils/getImageUrl'
import { showNotification } from 'src/store/notification'
import { useT } from 'src/hooks/useT'

const LogoInput = ({
  id,
  logo,
  name,
  empty,
  logoId,
  deleted,
  multiple,
  newImage,
  onChange,
  fillesCount,
  setFieldValue,
  handleDeleteIcon,
  ...props
}) => {

  const [background, setBackground] = useState('')
  const dispatch = useDispatch()
  const { t } = useT();

  useEffect(() => {
    if (logoId && !empty) {
      setBackground(`${process.env.REACT_APP_API_URL}/images/${logoId}/thumb`)
    }
    else if (logo && !empty) {
      setBackground(getImageUrl(logo))
    }
    if (newImage) {
      setBackground(newImage)
    }
    if (deleted) {
      setBackground('')
    }
  }, [logoId, empty, newImage, logo, deleted])

  const handleFileError = useCallback((error, countArr) => {
    const notification = (countArr && countArr.length)
      ? { text: t(`notifications.logo.${error}`, { currentCount: countArr[0], maxCount: countArr[1] }) }
      : { key: `notifications.logo.${error}` }
    dispatch(showNotification(notification))
  }, [dispatch, t])


  const removeIconFile = useCallback(() => {
    handleDeleteIcon()
    setBackground('')
  }, [handleDeleteIcon])


  return (
    <IconFile
      id={id}
      type="file"
      name={name}
      multiple={multiple}
      onChange={onChange}
      background={background}
      onError={handleFileError}
      fillesCount={fillesCount}
      onDeleteIcon={handleDeleteIcon && removeIconFile}
      {...props}
    />
  )
}

LogoInput.propTypes = {
  name: string,
  id: string,
  onChange: func.isRequired,
  empty: bool,
  logoId: oneOfType([string, number]),
  newImage: object,
  logo: object
}

export default memo(LogoInput)