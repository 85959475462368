import { takeEvery, put } from "redux-saga/effects";
import { handleServerError, showNotification } from "src/store/notification";
import { get, post } from "src/utils/requestSaga";
import {
  toggleDeliveryLoading,
  GET_DELIVERY_METHODS,
  DeliveryResponseType,
  setDeliveryMethods,
  SetDeliveryPayload,
  UPDATE_DELIVERY_METHODS,
  DeliveryItemType,
  getDeliveryMethods,
} from "./deliveryTypes";
import { Action } from "redux-actions";
import { AxiosResponse } from "axios";

function* getDeliveryMethodsSaga({ payload }: Action<string>) {
  yield put(toggleDeliveryLoading(true))
  try {
    const { data }: AxiosResponse<DeliveryResponseType> = yield get(
      `/projects/${payload}/deliveryMethods`
    );
    yield put(
      setDeliveryMethods({
        projectId: payload,
        data: data.delivery_method_list,
      })
    );
  } catch (e) {
    yield put(handleServerError(e));
  }
  yield put(toggleDeliveryLoading(false))
}

export const checkPrice = (price: number | null): number | null => {
  if (price === null || price >= 0) {
    return price;
  }
  return null;
};

export const formaDeliveryMethods = (data: DeliveryItemType[]): DeliveryItemType[] => {
  return data
    .filter((item) => !!item.name)
    .map((item) => {
      const price = checkPrice(item.price);
      if (item.is_optional) {
        const options = item.options
          .filter((option) => !!option.name)
          .map((option) => {
            const optionPrice = checkPrice(option.price);
            return {
              ...option,
              price: optionPrice,
            };
          });
        return {
          ...item,
          price,
          options,
        };
      }
      return item;
    });
};

function* updateDeliveryMethodsSaga({ payload }: Action<SetDeliveryPayload>) {
  yield put(toggleDeliveryLoading(true))
  const formatedData = formaDeliveryMethods(payload.data);
  try {
    yield post(`/projects/${payload.projectId}/deliveryMethods`, {
      delivery_method_list: formatedData
    })
    yield put(showNotification({
      key: 'notifications.deliveryMethodsSaved'
    }))
    yield getDeliveryMethodsSaga(
      yield getDeliveryMethods(payload.projectId)
    )
  } catch(e) {
    yield put(handleServerError(e))
  }
  yield put(toggleDeliveryLoading(false))
}

export default function* () {
  yield takeEvery(GET_DELIVERY_METHODS, getDeliveryMethodsSaga);
  yield takeEvery(UPDATE_DELIVERY_METHODS, updateDeliveryMethodsSaga);
}
