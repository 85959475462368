import React, { FC, Suspense, memo } from "react";
import styled, { css } from "styled-components";

const LaunchLinkIcon = React.lazy(() => import("src/assets/launchLink"));

type Props = {
  rotated?: boolean;
};

const LaunchLink: FC<Props> = ({rotated}) => {
  return <Container rotated={rotated}>
    <Suspense fallback={<span></span>}>
      <LaunchLinkIcon />
    </Suspense>
  </Container>;
};

const Container = styled.div<Props>`
  display: flex;
  ${props => props.rotated && css`
    transform: rotate(180deg);
  `}
`;

export default memo(LaunchLink)