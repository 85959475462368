import { GoodCardType } from "src/templates/utils/types/goodType";
import actionCreatorFactory, { Action } from "typescript-fsa";

export type GoodsWidgetStateType = {
  readonly loading: boolean
  readonly goods: GoodCardType[]
  readonly allGoodsCount: number
  readonly goodsTotalCount: number
  readonly offset: number
}

// ACTIONS
const actionCreator = actionCreatorFactory()

type TriggetGetWidgetGoodsPayloadType = {
  projectId: string,
  categoryId?: number,
  search?: string,
  filterType?: number
}

export const resetLoadMore = actionCreator("goodsWidget/RESET_LOAD_MORE")
export const triggerLoadMoreGoodsWidget = actionCreator<TriggetGetWidgetGoodsPayloadType>
  ("goodsWidget/TRIGGER_GET_GOODS")

export const loadMoreGoodsWidget = actionCreator.async<Action<TriggetGetWidgetGoodsPayloadType>, {
  goods: GoodCardType[],
  offset: number,
  totalCount: number
} | null>
  ("goodsWidget/GET_GOODS")