import {
  PromocodesStateType,
  getPromocodes, addPromocode, changePromocode
} from "./promocodesTypes";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";
import { camelize } from "casing";

const INITIAL_STATE: PromocodesStateType = {
  loading: false,
  promocodes: [],
  discountTypes: [],
  discountValueTypes: [],
};

export default reducerWithInitialState(INITIAL_STATE)
  .cases(
    [
      getPromocodes.started,
    ],
    (state) => ({
      ...state,
      loading: true,
    })
  )
  .case(getPromocodes.done, (state, action) => {
    return produce(state, (draft) => {
      draft.loading = false;
      if (action.result) {
        draft.promocodes = action.result.items
        draft.discountTypes = action.result.discountTypes
        draft.discountValueTypes = action.result.discountValueTypes
      }
      return draft;
    });
  });

  