import { camelize } from 'casing'
import { findIndex } from 'lodash-es'
import { GoodType, ImageType } from './types/goodType'

export const addDefaultImageToImagesArray = (item: GoodType) => {
  if (item.hasUnsavedChanges) {
    return item.images
  }
  const index = (item.defaultImage && item.defaultImage.id) ? findIndex(item.images, { 'id': item.defaultImage.id }) : -1
  if (index >= 0 || !item.images || (item.images.length === 0 && !item.defaultImage)) {
    return item.images
  } else {
    return [item.defaultImage, ...item.images]
  }
}

export const checkImageUnloaded = (image: ImageType) => {
  const mappedImage = camelize(image)
  if (mappedImage && mappedImage.id && !mappedImage.fullUrl) {
    return true
  } else {
    return false
  }
}