import React, { useEffect, Suspense, useState, useCallback, Fragment } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { func, arrayOf, bool, string, number } from 'prop-types'
import { Grid } from 'react-flexbox-grid'
import { AddGoodButton, ArrowLeftIcon, DoneIcon, fullHeight, Header, HeaderText, SecondaryRoundButton } from 'hopshopui'
import findIndex from 'lodash/findIndex'

import { match, vkGood, VkProjecType } from 'src/utils/propTypes'
import AuthGlobalStyles from 'src/modules/auth/components/AuthGlobalStyles'
import { useT } from 'src/hooks/useT'
import { size } from 'src/utils/styles'
import Shimmer from 'src/modules/projects/components/VkGoodsShimmer'
import { selectActiveVkProject } from 'src/store/projects'
import history from 'src/utils/browserHistory'

const GoodsList = React.lazy(() => import('src/modules/projects/components/VkGoodsList'))

const VK_GOODS_COUNT = 20

const VkGoods = ({
  getVkGoods,
  match,
  goods,
  loading,
  projects,
  getProjectList,
  signout,
  loadMoreVKGoods,
  type,
  totalCount,
  setFinishProjectData
}) => {

  const [projectId, setProjectId] = useState(null)
  const [hasMore, setHasMore] = useState(true)
  const [selected, setSelected] = useState([])
  const [all, setAll] = useState(false)
  const activeProject = useSelector(state => selectActiveVkProject(state, projectId))
  const { id } = match.params
  const { t } = useT()

  const handleBack = useCallback(() => {
    if (projects.length === 1) {
      signout({ history })
    } else {
      history.push('/import')
    }
  }, [projects, signout])

  const loadMore = useCallback((params) => {
    if (type) {
      const offset = params * VK_GOODS_COUNT
      loadMoreVKGoods({ id, count: VK_GOODS_COUNT, offset, type })
      if (totalCount > 0 && offset >= totalCount) {
        setHasMore(false)
      }
    }
  }, [loadMoreVKGoods, id, type, totalCount, setHasMore])

  useEffect(() => {
    setProjectId(id)
    if (activeProject.screen_name !== undefined) {
      getVkGoods({ id, count: VK_GOODS_COUNT, screen_name: activeProject.screen_name })

    }
  }, [id, getVkGoods, setProjectId, activeProject.screen_name])

  useEffect(() => {
    getProjectList()
  }, [getProjectList])

  const handleSubmit = useCallback(({ goods, indexes, all }) => {
    setFinishProjectData({ social: true, data: { goods, indexes, projectId: activeProject.screen_name, all } })
    history.push('/payment')
  }, [activeProject.screen_name, setFinishProjectData])

  const handeSubmitCallback = useCallback(() => {
    const indexes = selected.map((good) => {
      return findIndex(goods, { id: good })
    })

    handleSubmit({
      goods: selected,
      indexes: indexes,
      all
    })
  }, [selected, all, handleSubmit, goods])

  return (
    <Background>
      <AuthGlobalStyles />
      <Container>
        <StyledHeader isForm={true}>
          <StyledContainer>
            <StyledBackButton onClick={handleBack}>
              <ArrowLeftIcon fill="#000000" />
            </StyledBackButton>
            <Fragment>
              <HeaderTextStyled>{t("project.whichItemsInclude")}</HeaderTextStyled>
              <StyledSecondaryRoundButton
                onClick={handeSubmitCallback}
                load={loading}
                type="button"
                disabled={loading}
                data-testid="addgood-submit"
                Icon={<DoneIcon stroke="#000000" />}
                IconPosition="left"
              >
                {t("buttons.apply")}
              </StyledSecondaryRoundButton>
              <StyledDoneButtonMobile disabled={loading} onClick={handeSubmitCallback}>
                <DoneIcon stroke="#000000" />
              </StyledDoneButtonMobile>
            </Fragment>
          </StyledContainer>
        </StyledHeader>
        <CenterContainer>
          <Suspense fallback={<Shimmer />}>
            <GoodsList
              loadMore={loadMore}
              loading={loading}
              goods={goods}
              hasMore={hasMore}
              handleSubmit={handleSubmit}
              selected={selected}
              setSelected={setSelected}
              all={all}
              setAll={setAll}
            />
          </Suspense>
        </CenterContainer>
      </Container>
    </Background>
  )
}

VkGoods.propTypes = {
  match: match.isRequired,
  getVkGoods: func.isRequired,
  importVkProject: func.isRequired,
  goods: arrayOf(vkGood),
  loading: bool,
  getProjectList: func.isRequired,
  projects: arrayOf(VkProjecType).isRequired,
  signout: func.isRequired,
  loadMoreVKGoods: func.isRequired,
  type: string,
  totalCount: number,
  setFinishProjectData: func.isRequired
}

const CenterContainer = styled.div`
  max-width: 960px;
  margin-top: 25px;
`

const Background = styled.div`
  background-color: #ECEFF1;
  ${fullHeight};
`

const Container = styled(Grid)`
  position: relative;
  @media screen and (max-width: ${size.mobile}) {
    position: static;
  }
`

const StyledHeader = styled(Header)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  ${Container} {
    align-items: center;
  }
  ${HeaderText} {
    width: 100%;
  }
  & > div{
    justify-content: center;
  }
  @media screen and (max-width: ${size.mobile}) {
    ${HeaderText} {
      text-align: center;
    }
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  margin: 0;
  @media screen and (max-width: ${size.mobile}) {
    justify-content: center;
    align-items: center;
  }
`;

const StyledBackButton = styled(AddGoodButton)`
  height: 40px;
  width: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  flex: none;
  background: #FFFFFF;
  border: none;
  &:hover{
    background: none;
  }
`;

const HeaderTextStyled = styled(HeaderText)`
  font-weight: 500;
  color: #000000;
`

const StyledSecondaryRoundButton = styled(SecondaryRoundButton)`
  margin-left: auto;
  flex-grow: 0;
  @media screen and (max-width: ${size.mobile}) {
    display: none;
  }
`

const StyledDoneButtonMobile = styled(AddGoodButton)`
  height: 40px;
  width: 40px;
  padding: 0;
  display: none;
  flex: none;
  background: none;
  border: none;
  @media screen and (max-width: ${size.mobile}) {
    display: block;
  }
`;

export default VkGoods