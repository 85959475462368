import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import { node } from 'prop-types'

const FixedBlurPanel = ({ children, ...props }) => {
  return (
    <PanelContainer {...props}>
      <BluredBackground>
        {children}
      </BluredBackground>
    </PanelContainer>
  )
}

FixedBlurPanel.propTypes = {
  children: node
}


const PanelContainer = styled.div`
 ${props => props.fixed && css`
    position: fixed;
    border-top: 1px solid ${props => props.theme.colors.button};
    background: ${props => props.theme.colors.greyBlurGradient};
 `}
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
`

const BluredBackground = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 14px 20px;
  backdrop-filter: blur(6px);
  display: flex;
  justify-content: center;
`

export default memo(FixedBlurPanel)