import React, { Suspense } from 'react'


const BookingLogMain = React.lazy(() => import('src/modules/bookingLog/containers/BookingLogMain'))

const BookingLogPage = () => {
  return (
    <Suspense fallback={'Загрузка...'}>
      <BookingLogMain/>
    </Suspense>
  )
}

export default BookingLogPage
