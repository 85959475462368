import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getProjectDetails,
  getProjectGoodsByIds,
  selectProjectsLoading,
  submitCheckingBookingOrder,
} from "src/store/projects";
import { submitOrder } from "src/store/orders";
import ProjectCartCheckoutPage from "./ProjectCartCheckoutPage";
import { selectDeliveryMethods, getDeliveryMethods } from "src/store/delivery";
import { matchParamsId } from "src/utils/params";
import { DefinedRouterComponentProps } from "src/types";
import { getOrders } from "src/store/orders";
import { selectActiveProjectWithTypes } from "src/store/projects/types";
import {
  triggerGetPS,
  selectCheckoutPaymentSystems,
  triggerGetCheckoutPS,
} from "src/store/paymentSystems";

import { triggerGetCheckoutForm, selectCheckoutForm, selectShowPromocode } from "src/store/customCheckout"
import { selectDocumentsLinks } from "src/store/marketplaces"
import { triggerGetDocumentsLinks } from "src/store/marketplaces";
import {selectOrders} from "../../../../store/orders";

const selectLoading: (state: any) => boolean = selectProjectsLoading;

const mapStateToProps = (state: any, props: DefinedRouterComponentProps) => ({
  paymentSystems: selectCheckoutPaymentSystems(state, matchParamsId(props)),
  deliveryMethods: selectDeliveryMethods(state, matchParamsId(props)),
  project: selectActiveProjectWithTypes(state, matchParamsId(props)),
  documentsLinks: selectDocumentsLinks(state),
  showPromocode: selectShowPromocode(state),
  checkoutForm: selectCheckoutForm(state),
  orders: selectOrders(state),
  loading: selectLoading(state),
});

const mapDispatchToProps = {
  submitCheckingBookingOrder,
  triggerGetDocumentsLinks,
  triggerGetCheckoutForm,
  getProjectGoodsByIds,
  triggerGetCheckoutPS,
  getDeliveryMethods,
  getProjectDetails,
  triggerGetPS,
  submitOrder,
  getOrders,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProjectCartCheckoutPageProps = ConnectedProps<
  typeof connector
> &
  DefinedRouterComponentProps;

export default withRouter(connector(ProjectCartCheckoutPage));
