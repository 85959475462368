import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { authInst } from 'src/store/auth'

import AuthInstStart from './AuthInstStartPage'

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  authInst
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthInstStart))
