import React, {
  FC,
  memo,
  useMemo,
  Fragment,
  Suspense,
  useEffect,
  useCallback,
   useContext,
} from "react";
import { ConnectedProjectContentProps } from "../containers/ProjectContent";
import TemplateProvider from "src/providers/TemplateProvider";
import { TemplateGlobalStyles } from "src/elements/styles/TemplateGlobal";
import { getTemplateCover, getTemplateHeader } from "src/utils/getTemplate";
import { getAliasFromPath } from "src/templates/utils/alias";
import styled from "styled-components";
import { size, ControlPanel, SecondaryRoundButton, PrimaryRoundButton, PalleteIcon, EditIcon, Tooltip } from "hopshopui";
import history from "src/utils/browserHistory";
import {
  ContentPageBlocks,
  GoodSetDataMap,
} from "src/templates/utils/types/goodType";
import ProjectContentEmpty from "./ProjectContentEmpty";
import ProjectContentPageLink from "./ProjectContentPageLink";
import { CardViewFormTypes } from "src/templates/utils/types/projectSettings";
import ItemLink from "src/elements/template/ItemLink";
import DesignLayout from "src/modules/design/containers/DesignLayout";
import { useT } from "src/hooks/useT";
import MenuContext from "../../../templates/utils/providers/MenuProvider";
import DesignCoverButton from "src/modules/design/components/DesignCoverButton";

type Props = ConnectedProjectContentProps & {};

const DefaultTemplate = React.lazy(
  () => import("../../../templates/default/Item/ItemBlocks")
);

const getTemplate = (
  template: string,
  props: {
    item: {
      modules: ContentPageBlocks;
    };
    itemType: "Goods" | "Page";
    goodsMap: GoodSetDataMap;
    isProjectAdult: boolean;
    isForPublic: boolean;
    itemId: string;
    link: FC<{
      id: number;
      alias: string;
      disabled?: boolean;
      form: CardViewFormTypes;
    }>;
  }
) => {
  switch (template) {
    default:
      return <DefaultTemplate {...props} />;
  }
};

const ProjectContet: FC<Props> = ({
  triggerGetPageContent,
  coverSettingsDesign,
  isUpdateInprogress,
  triggerGetGoodsSet,
  toggleDesignMode,
  designSelection,
  designBlocked,
  menuPagesMap,
  mainPage,
  goodsMap,
  project,
  content,
  match,
}) => {
  const alias = useMemo(() => {
    return getAliasFromPath(match.url);
  }, [match.url]);

  const isMain = useMemo(() =>{
    if(!mainPage)return false
   return match.url.includes(`${match.params.id}/${mainPage.typeName.toLowerCase()}/${mainPage.alias}`)
  },[mainPage, match])

  const { isMenuVisibleWithScroll } = useContext(MenuContext);

  useEffect(() => {
    if (match.params.id && alias) {
      triggerGetPageContent({
        projectId: match.params.id,
        alias,
      });
    }
  }, [alias, match.params, triggerGetPageContent]);

  const item = useMemo(() => {
    return {
      modules: content,
    };
  }, [content]);

  const handleEditRedirect = useCallback(() => {
    history.push(`/project/${match.params.id}/content/edit/${alias}`);
  }, [alias, match]);

  const activeAlias = useMemo(() => {
    const page = menuPagesMap[alias];
    return (
      `${project.display_domain}/${page?.typeName.toLowerCase()}/` +
      page?.parentAlias +
      alias
    );
  }, [alias, menuPagesMap, project.display_domain]);

  useEffect(() => {
    if (project.domain && alias) {
      triggerGetGoodsSet({
        alias,
        aliasType: "Page",
        domain: project.display_domain as string,
      });
    }
  }, [project, alias, triggerGetGoodsSet]);

  const toggleDesignSelectionCallback = useCallback(
    (e) => {
      e.preventDefault();
      toggleDesignMode();
    },
    [toggleDesignMode]
  );
  const { t } = useT();

  const isCoverImage = useMemo(()=>{
    return (coverSettingsDesign?.coverSettings?.image || coverSettingsDesign?.newCoverImage)
  },[coverSettingsDesign])

  return (
    <DesignLayout>
      <TemplateProvider isAdmin={!isUpdateInprogress}>
        <TemplateGlobalStyles />
        {content.length !== 0 && !designBlocked && (
          <Fragment>
            <FixedContainer visible={isMenuVisibleWithScroll}>
              <ControlContainer>
                <ControlPanel>
                  <SecondaryRoundButton onClick={toggleDesignSelectionCallback} data-testid="edit-content" Icon={<PalleteIcon fill="#000" />} round={true} size="small" load={false} tooltipText={<Tooltip position="top">{t("buttonControlPanel.design")}</Tooltip>} />
                  <PrimaryRoundButton onClick={handleEditRedirect} Icon={<EditIcon />} round={true} size="large" data-testid="edit-good-button" load={false} tooltipText={<Tooltip position="top">{t("buttonControlPanel.edit")}</Tooltip>} />
                </ControlPanel>
              </ControlContainer>

            </FixedContainer>
          </Fragment>
        )}
        {(designSelection && isMain && !isCoverImage) && <DesignCoverButton />}
          {(isMain && isCoverImage)
            && <Suspense fallback={""}>
              {getTemplateCover("default", {
                coverSettingsDesign: coverSettingsDesign?.coverSettings,
                newCoverImage: coverSettingsDesign?.newCoverImage,
              })}
            </Suspense>
            }
        <Suspense fallback={<div></div>}>
          {getTemplateHeader("default", {
            project,
            shouldDisplayHeaderMobile: true,
            shouldDisplaySubHeaderMobile: true,
            onScrollTop: true,
            staticAdminHeader: false,
            noMobileMargin: true,
          })}
          {content.length === 0 ? (
            <ProjectContentEmpty handleEditRedirect={handleEditRedirect} />
          ) : (
            getTemplate("default", {
              item,
              goodsMap,
              link: ItemLink,
              isProjectAdult: false,
              isForPublic: false,
              itemType: "Page",
              itemId: alias,
            })
          )}
          <ProjectContentPageLink pageLink={activeAlias} />
        </Suspense>
      </TemplateProvider>
    </DesignLayout>
  );
};

export const FixedContainer = styled.div<{
  visible?: boolean;
  isFooterVisible?: number;
}>`
  position: fixed;
  max-width: 960px;
  bottom: 140px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 4;
  pointer-events: none;
  @media screen and (max-width: ${size.mobile}) {
    right: 15px;
    bottom: ${props => props.visible ? '82px' : (props.isFooterVisible ? `${props.isFooterVisible}px` : '15px')};
  }
`;

const ControlContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  >div{
    pointer-events: auto;
  }
`

export default memo(ProjectContet);
