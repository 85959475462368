import {
  takeEvery,
  put,
  call,
  take,
  fork,
  takeLatest,
} from "redux-saga/effects";
import { get, post } from "src/utils/requestSaga";
import { SagaIterator } from "redux-saga";
import { AxiosResponse } from "axios";
import { handleServerError, showNotification } from "../notification";
import { camelize, snakeize } from "casing";
import { triggerGetLastPayment } from "../payment";
import { forEachLeadingCommentRange } from "typescript";
import {
  getScenarios,
  ScenariosResponseType,
  triggerGetScenarios,
  triggerSetScenarios,
  setScenarios,
} from "./scenariosTypes";
import { bindAsyncAction } from "typescript-fsa-redux-saga";

const getScenariosSaga = bindAsyncAction(getScenarios)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<ScenariosResponseType> = yield call(
      get,
      `projects/${payload.projectId}/buttonsSettings`
    );
    return { scenarios: camelize(data.buttons) };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const setScenariosSaga = bindAsyncAction(setScenarios)(function* ({
  payload,
}): SagaIterator {
  try {
    const body = snakeize(
      payload.scnearios
    );
    const { data }: AxiosResponse<ScenariosResponseType> = yield call(
      post,
      `projects/${payload.projectId}/buttonsSettings`,
      body
    );
    yield put(triggerGetScenarios({projectId: payload.projectId}));
    yield put(showNotification({
      key: 'notifications.changesSaved'
    }))
    return { scenarios: camelize(data.buttons) };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

export default function* () {
  yield takeLatest(triggerGetScenarios.type, getScenariosSaga);
  yield takeLatest(triggerSetScenarios.type, setScenariosSaga);
}
