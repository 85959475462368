import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { string, bool } from 'prop-types'
import history from 'src/utils/browserHistory'
import copyToClipboard from 'src/utils/copyToClipboard'
import { showNotification } from 'src/store/notification'
import { H3, textParagraph, size, IconButton, SubMenu, useMobile, Popup, P } from 'hopshopui'

const HeaderDomain = ({ domain, isProfile, isProject, id, isOrders, setOrders, setIsActiveLink, t }) => {
  const [showPopupActive, setShowPopupActive] = useState(false);
  const [menuHeader, setOpenMenuHeader] = useState(false)

  const [isMobile] = useMobile()
  const dispatch = useDispatch()

  const domainId = 'header_domain'
  const copyDomain = useCallback(() => {
    copyToClipboard(domainId)
    dispatch(showNotification({
      key: 'notifications.domainCopied'
    }))
  }, [])

  const handleBack = useCallback(() => {
    setIsActiveLink('');
    if (history.location.pathname.includes("main/promocodes/form")) {
      history.goBack()
    } else {
      history.push(`/project/${id}`)
    }

  }, [id])


  const onHistoryChange = useCallback(() => {
    setOrders(history.location.pathname.indexOf('/orders') >= 0)
  }, [])

  useEffect(() => {
    history.listen(onHistoryChange)
  }, [onHistoryChange])

  const menu = [
    { title: t('domain.changeAdress') },
    { title: t('domain.copyLink') },
    { title: t('domain.openWebsite') }
  ]

  const closeMenu = () => {
    setOpenMenuHeader(false)
  }

  const handleOpenMenu = () => {
    if (isMobile) {
      setOpenMenuHeader(true)
    } else {
      setShowPopupActive(true);
    }
  }

  const handleClose = (e) => {
    setShowPopupActive(false);
  }

  const handleButtons = () => {
    setShowPopupActive(prev => !prev);
  }

  const handleClickMenu = useCallback((index) => {
    switch (index) {
      case 0:
        history.push(`/project/${id}/main/access`)
        break;
      case 1:
        copyDomain();
        break;
      case 2:
        window.open(`https://${domain}`, '_blank');
        break;
      default:
        return null;
    }
    handleClose();
    closeMenu();
  }, [copyDomain, id, domain])


  return (
    <Container isActive={(!isProfile && !isProject && !isOrders)}>
      {(!isProfile && !isProject && !isOrders) ? <DomainContainer>
        <StyledDomain onClick={() => handleOpenMenu()}>
          <span>{domain}</span>
          <HiddenDomain id="header_domain">{'https://' + domain}</HiddenDomain>
        </StyledDomain>
      </DomainContainer> : <StyledIconButton iconType='arrow' square onClick={handleBack} />}
      {isMobile && <SubMenuContainer items={menu} position="top" open={menuHeader} handleClick={handleClickMenu} />}
      {menuHeader && <MenuBackground onClick={closeMenu} />}
      {showPopupActive
        && <Popup
          onClose={handleClose}
          handleButtons={handleButtons}
          text={
            <>
              {
                menu.map((el, index) => {
                  return (
                    <PopupButton key={index} onClick={() => handleClickMenu(index)}>{el.title}</PopupButton>
                  )
                })
              }
            </>
          }
        />
      }
    </Container>
  )
}
HeaderDomain.propTypes = {
  domain: string,
  isProfile: bool,
  isProject: bool,
  id: string
}

const StyledIconButton = styled(IconButton)`
  &:hover{ 
    background: none;
  }
`

export const PopupButton = styled(P)`
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
`;

const SubMenuContainer = styled(SubMenu)`
  background-color: #FFFFFF;
  top: 70px;
  z-index: 6;
`

const HiddenDomain = styled.span`
  position: absolute;
  opacity: 0;
`

const StyledDomain = styled(H3)`
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  width: 100%;
  position: relative;
  @media screen and (max-width: ${size.mobile}) {
    ${textParagraph}
    align-items: center;
  }
`

const Container = styled.div`
  width: fit-content;
  a {
    display: flex;
  }

  @media screen and (max-width: ${size.mobile}) {
    width: ${({ isActive }) => isActive ? '100%' : 'fit-content'}
  }
`
const DomainContainer = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  max-height: 40px;
  width: 100%;
  color: ${props => props.theme.colors.white};
`

const MenuBackground = styled.div`
  background: ${props => props.theme.colors.buttonPushed};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
`

export default HeaderDomain