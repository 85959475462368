import { createSelector } from 'reselect'
import { providers } from 'src/utils/variables'
import findIndex from 'lodash/findIndex'

export const selectProfileState = state => state.profile

export const selectProfileLoading = createSelector(
  selectProfileState,
  (profile) => profile.loading
)

export const selectProfileAuthSettings = createSelector(
  selectProfileState,
  (profile) => {
    return profile.authSettings
  }
)

export const selectProfileAuthProviders = createSelector(
  selectProfileAuthSettings,
  (authSettings) => {
    const authProviders = authSettings.providers || {}
    return Object.values(providers)
      .map((provider, index) => {
        const foundIndex = findIndex(authProviders, (el) => el.provider === provider)
        if (foundIndex >= 0) {
          return authProviders[foundIndex]
        }
        return {
          provider,
          key: null
        }
      })
  }
)

// linkedProjects
export const selectProfileProjects = createSelector(
  selectProfileState,
  (profile) => {
    return profile.linkedProjects
  }
)
export const selectCurrentEmail = createSelector(
  selectProfileState,
  (profile) => profile.authSettings && profile.authSettings.email
)

export const selectProfilePayments = createSelector(
  selectProfileState,
  (profile) => {
    return profile.payments
  }
)

export const selectProfileNotifications = createSelector(
  selectProfileState,
  (profile) => {
    return profile.notifications
  }
)

export const selectCurrentNotificationsEmail = createSelector(
  selectProfileNotifications,
  (notifications) => {
    const email = notifications.find(item => item.name === 'Email')
    return email ? email.key : null
  }
)

export const selectProfileEmail = createSelector(
  selectProfileAuthSettings,
  (settings) => settings.email
)

export const selectLanguage = createSelector(
  selectProfileState,
  (settings) => settings.language
);

export const selectAvailableLanguages = createSelector(
  selectProfileState,
  (settings) => settings.availableLanguages
);

export const selectProfileWebhook = createSelector(
  selectProfileState,
  (profile) => {
    return profile.webhook;
  }
);

export const selectCurrentWebhookURL = createSelector(
  selectProfileWebhook,
  (webhook) => webhook.webhook_url
);

export const selectWebhookEvents = createSelector(
  selectProfileWebhook,
  (webhook) => webhook.webhooks
);