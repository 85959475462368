import { shape, string, bool, object } from 'prop-types'

export const match = shape({
  path: string,
  url: string,
  isExact: bool
})

export const routerProps = {
  match: shape({
    path: string,
    url: string,
    isExact: bool
  }),
  params: object,
  location: object,
  history: object
}