import { providers, ProviderType } from "./variables";

export const getProviderFromString = (url: string = ''): ProviderType => {
  if (url.includes('instagram.com')) {
    return providers.inst
  } 
  if (url.includes('ok.ru')) {
    return providers.ok
  }
  return providers.vk
};
