// import React, { Fragment, Suspense, useEffect, useCallback, useState } from 'react'
// import { useSelector } from 'react-redux'
// import { func, bool } from 'prop-types'
// import styled from 'styled-components'

// import { selectActiveProject, selectActiveProjectGoods } from 'src/store/projects'
// import { match, history } from 'src/utils/propTypes'
// import { selectActiveGood } from 'src/store/goods'
// import { TemplateGlobalStyles } from 'src/elements/styles/TemplateGlobal'
// import TemplateProvider from 'src/providers/TemplateProvider'
// import { getTemplateHeader, getTemplateSubHeader, getTemplateFooter } from 'src/utils/getTemplate'
// import ProjectItemPopupWrapper from 'src/modules/projects/components/ProjectItemPopupWrapper'
// import ItemLink from 'src/elements/template/ItemLink'

// const DefaultTemplate = React.lazy(() => import('../../../templates/default/Item/ItemMain'))

// const getTempalte = (template, props) => {
//   switch (template) {
//     default:
//       return <DefaultTemplate {...props} />
//   }
// }

// const ProjectItem = ({ 
//   match,
//   getGoodByAlias, 
//   history,
//   getProjectGoods,
//   deleteGood,
//   archiveGood,
//   isUpdateInprogress,
//   resetProjectGoods,
//   loading,
// }) => {
//   const { id, alias } = match.params
//   const project = useSelector(state => selectActiveProject(state, id))  
//   const good = useSelector(state => selectActiveGood(state, alias))
//   const goods = useSelector(selectActiveProjectGoods)
//   const [filteredGoods, setFilteredGoods] = useState(null)

//   const onItemClick = useCallback((alias) => {
//     history.push(`/project/${id}/${alias}`)
//   }, [history, id])

//   const onEditClick = useCallback(() => {
//     history.push(`/project/${id}/${alias}/edit`)
//   }, [history, id, alias])

//   const onCategoryRedirect = useCallback((e) => {
//     e.preventDefault()
//     good.category_id ? history.push(`/project/${id}?category=${good.category_id}`) : history.push(`/project/${id}`)
//   }, [id, good, history])

//   const redirectProject = useCallback(() => {
//     history.push(`/project/${id}`)
//   }, [id, history])

//   const onDelete = useCallback(() => {
//     if(!loading) {
//       deleteGood({ projectId: id, goodId: good.id, callback: redirectProject })
//     }
//   }, [good, deleteGood, id, redirectProject, loading])

//   const onArchive = useCallback(() => {
//     archiveGood({ goodId: good.id })
//     history.push(`/project/${id}`)
//   }, [good, archiveGood, history, id])

//   useEffect(() => {
//     return () => {
//       resetProjectGoods()
//     }
//   }, [resetProjectGoods])

//   useEffect(() => {
//     getGoodByAlias({ id, alias })
//   }, [alias, id, getGoodByAlias])

//   const handleDomainClick = useCallback(() => {
//     history.push('/project/' + project.id)
//   }, [project, history])

//   useEffect(() => {
//     getProjectGoods({
//       projectId: id,
//       categoryId: good.category_id,
//       count: 4
//     })
//   }, [good, getProjectGoods, id])

//   useEffect(() => {
//     const _goods = [...goods]
//     const index = _goods.findIndex(item => item.id === good.id)
//     if (index !== undefined) {
//       _goods.splice(index, 1)
//     }
//     if (_goods.length > 3) {
//       _goods.splice(3)
//     }
//     setFilteredGoods(_goods)
//   }, [goods, good])
//   return (
//     <TemplateProvider isAdmin={!isUpdateInprogress}>
//       <TemplateGlobalStyles />
//       <Suspense fallback={''}>
//         {getTemplateHeader('default', { ...project, shouldDisplayMobile: false })}
//         {getTemplateSubHeader('default', { shouldDisplayMobile: true, onScrollTop: true, staticAdminHeader: true, noMobileMargin: true })}
//       </Suspense>
//       <Suspense fallback={<div></div>}>
//         {good && getTempalte('default', {
//           popup: !isUpdateInprogress && <ProjectItemPopupWrapper onArchive={onArchive} onDelete={onDelete} onEditClick={onEditClick} />,
//           project,
//           item: good,
//           onDomainClick: handleDomainClick,
//           goods: filteredGoods,
//           onItemClick, onEditClick,
//           onCategoryRedirect,
//           link: ItemLink
//         })}
//       </Suspense>
//       <Suspense fallback={''}>
//         {getTemplateFooter('default', {
//           title: project.title,
//           isAdult: project.isAdult || project.hasAdultGoods
//         })}
//       </Suspense>
//     </TemplateProvider>
//   )
// }

// const Container = styled.div`
//   position: relative;
// `

// ProjectItem.propTypes = {
//   match: match,
//   getGoodByAlias: func.isRequired,
//   getProjectGoods: func.isRequired,
//   deleteGood: func.isRequired,
//   archiveGood: func.isRequired,
//   resetProjectGoods: func.isRequired,
//   history: history,
//   isUpdateInprogress: bool
// }

// export default ProjectItem

import React, { memo, Suspense } from 'react'

const ProjectItem = React.lazy(() => import('src/modules/projects/containers/ProjectItem'))

const ProjectItemPage = () => {
  return (
    <Suspense fallback={<div></div>}>
      <ProjectItem />
    </Suspense>
  )
}

export default memo(ProjectItemPage)