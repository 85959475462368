import difference from 'lodash/difference'

export const mapCategoriesWithPositions = (categories = [], returnCategories = [], parentId = null) => {
  returnCategories = categories.map((category, index) => {
    const newCategory = { ...category, position_index: index, parent_id: parentId }
    if (category.children && category.children.length > 0) {
      newCategory.children = mapCategoriesWithPositions(category.children, returnCategories, category.id)
    }
    return newCategory
  })
  return returnCategories
}


const shouldPushCategory = (category, allCategories) => {
  if (!category.name) {
    return false
  }
  if (category.id) {
    const oldCategory = allCategories[category.id]
    const equalName = oldCategory.name === category.name
    const equalParent = oldCategory.parent_id === category.parent_id
    const equalPosition = oldCategory.position_index === category.position_index
    return !equalName || !equalParent || !equalPosition
  }
  return true
}

export const getCategoriesDiff = (categories = [], allCategories = {}, returnCategories = []) => {
  categories.forEach((category) => {
    const shouldPush = shouldPushCategory(category, allCategories)
    if (shouldPush) {
      const parentCategory = category
      const childrenDiff = getCategoriesDiff(category.children, allCategories)
      returnCategories.push({ ...parentCategory, children: childrenDiff })
    } else if (category.children && category.children.length > 0) {
      getCategoriesDiff(category.children, allCategories, returnCategories)
    }
  })
  return returnCategories
}

export const sortCategories = (categories = []) => {
  const newCategogires = categories.map((category) => {
    let children = category.children
    if (category.children && category.children.length > 0) {
      children = sortCategories(category.children)
    }
    return { ...category, children }
  }).sort((a, b) => {
    return a.position_index -  b.position_index
  })
  
  return newCategogires
}

export const filterDeletedCategories = (oldCategoriesMap = {}, newCategoriesMap = {}) => {
  const oldKeys = Object.keys(oldCategoriesMap)
  const newKeys = Object.keys(newCategoriesMap).filter(key => key !== null)
  return difference(oldKeys, newKeys)
}