import { RootState } from "../rootTypes";
import { createSelector } from "reselect";

const selectDelivery = (state: RootState) => state.delivery;


export const selectDeliveryLoading = createSelector(
  selectDelivery,
  (state) => state.loading
)


export const selectDeliveryMethods = createSelector(
  selectDelivery,
  (_: RootState , id: string) => id,
  (state, id) => {
    const methods = state.data
    if (methods[id]) {
      return methods[id].asMutable({ deep: true })
    }
    return []
  }
)