import { takeEvery, put, call, take, race, throttle } from 'redux-saga/effects'
import jwtDecode from 'jwt-decode'

import { setupToken, resetToken } from 'src/utils/request'
import { LOGIN, authToggleLoading, AUTH_VK, LOGIN_SUCCESS, setToken, appInitToggle, APP_INIT, AUTH_TEST, updateAuth, JUST_TEST, SIGNUP, RESTORE_PASSWORD, RESET_PASSWORD, loginSuccess, SIGNOUT, resetAuth, EXCHANGE_TOKENS, exchangeTokensSuccess, exchangeTokensFailure, AUTH_INST, AUTH_OK, setPasswordError } from './authReducer'
import { handleServerError } from '../notification'
import { get, post } from 'src/utils/requestSaga'
import { providers } from 'src/utils/variables'
import { getProjects, FINISH_GET_PROJECTS, resetProject, RESET_PROJECTS } from '../projects'
import { identify } from 'src/utils/analyticks'
import { sanitize } from 'src/utils/token'
import { instLogin, vkLogin, instDelayedLogin, okLogin } from 'src/utils/localStorage'
import history from 'src/utils/browserHistory'
import { camelize } from 'casing'
import qs from "qs";

export function* signin({ payload: { email, password, history } }) {
  yield put(authToggleLoading(true))
  const getParams = qs.parse(history.location.search, {ignoreQueryPrefix: true})
  try {
    const response = yield post('/auth/signin', { email, password, registration_agent_system_name: getParams.regagent })
    const { access_token: accessToken, refresh_token: refreshToken } = response.data
    yield call(loginSuccessSaga, loginSuccess({ accessToken, refreshToken }))
    history.push('/project')
  } catch (e) {
    console.log('atch')
    yield put(handleServerError(e))
    yield put(authToggleLoading(false))
    yield put(setPasswordError(true))
  }
}

export function* vkAuth({payload}) {
  let getParams = qs.parse(payload.location.search, {ignoreQueryPrefix: true})
  const noRegagent = ({ regagent, ...rest }) => rest
  localStorage.setItem('get-params', JSON.stringify(noRegagent({...getParams, registration_agent_system_name: getParams.regagent})))
  const queryString = payload.location.search.replace('regagent', 'registrationAgent')
  yield put(authToggleLoading(true))
  window.location.replace(`${process.env.REACT_APP_API_URL}/auth/external/${providers.vk}${queryString}`)
  yield put({ type: 'vkRedirect' })
}

export function* okAuth({payload}) {
  let getParams = qs.parse(payload.location.search, {ignoreQueryPrefix: true})
  localStorage.setItem('get-params', JSON.stringify(getParams))
  const queryString = payload.location.search.replace('regagent', 'registrationAgent')
  yield put(authToggleLoading(true))
  window.location.replace(`${process.env.REACT_APP_API_URL}/auth/external/${providers.ok}${queryString}`)
  yield put({ type: 'okRedirect' })
}

export function* authInst({payload}) {
  let getParams = qs.parse(payload.location.search, {ignoreQueryPrefix: true})
  localStorage.setItem('get-params', JSON.stringify(getParams))
  const queryString = payload.location.search.replace('regagent', 'registrationAgent')
  yield put(authToggleLoading(true))
  window.location.replace(`${process.env.REACT_APP_API_URL}/auth/external/${providers.inst}${queryString}`)
  yield put({ type: 'instRedirect' })
}

export function* vkAuthSuccess({ payload: { history } }) {
  let params = (new URL(document.location)).searchParams
  const accessToken = sanitize(params.get('accessToken'))
  const refreshToken = sanitize(params.get('refreshToken'))
  if (accessToken && refreshToken) {
    yield call(loginSuccessSaga, loginSuccess({ accessToken, refreshToken }))
    localStorage.setItem('vkLogin', true)
    history.push('/project')
  } else {
    yield call(signOutSaga)
  }
}

export function* loginSuccessSaga({ payload: { accessToken, refreshToken } }) {
  yield put(authToggleLoading(true))
  localStorage.setItem('accessToken', accessToken)
  localStorage.setItem('refreshToken', refreshToken)
  setupToken(accessToken)
  yield put(setToken({ accessToken, refreshToken }))
  yield put(getProjects())
  const { finish } = yield race({
    finish: take(FINISH_GET_PROJECTS),
    reset: take(RESET_PROJECTS)
  })
  if (finish) {
    const decoded = jwtDecode(accessToken)
    identify(decoded['urn:systemidentifier'])
    yield put(updateAuth({ authorized: true, loading: false }))
  }
  yield put(authToggleLoading(false))
}

// seperate call to toggle app initialising flag
export function* appInit({ payload }) {
  yield put(appInitToggle(true))
  if (payload.accessToken && payload.refreshToken) {
    yield call(testAuth, { payload })
  }
  yield put(appInitToggle(false))
}

export function* exchangeTokensSaga() {
  const accessToken = localStorage.getItem('accessToken')
  const refreshToken = localStorage.getItem('refreshToken')
  try {
    const decoded = jwtDecode(accessToken)
    const username = decoded['urn:systemidentifier']
    const response = yield post('/auth/refresh', {
      username,
      refresh_token: refreshToken
    })
    const { access_token, refresh_token } = response.data
    yield call(loginSuccessSaga, loginSuccess({
      accessToken: access_token,
      refreshToken: refresh_token
    }))
    yield put(exchangeTokensSuccess())
    return {
      accessToken: access_token,
      refreshToken: refresh_token
    }
  } catch (e) {
    yield call(signOutSaga)
    yield put(exchangeTokensFailure())
  }
}

// TODO: add test for signout saga

export function* signOutSaga() {
  const accessToken = localStorage.getItem('accessToken')
  const refreshToken = localStorage.getItem('refreshToken')
  if (accessToken && refreshToken) {
    // TODO: make an API call
  }
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem(vkLogin)
  localStorage.removeItem(okLogin)
  localStorage.removeItem(instLogin)
  localStorage.removeItem(instDelayedLogin)
  resetToken()
  yield put(resetAuth())
  yield put(resetProject())
}

export function* checkTokens({ payload: { accessToken, refreshToken } }) {
  try {
    const decoded = jwtDecode(accessToken)
    const timestamp = new Date().getTime() / 1000
    if (decoded.exp < timestamp) {
      // TODO: refreshToken change
      const newTokens = yield call(exchangeTokensSaga, { payload: { accessToken, refreshToken } })
      console.log(newTokens, 'newTokens')
      return newTokens
    }
    return { accessToken, refreshToken }
  } catch (e) {
    localStorage.clear()
  }
}

export function* testAuth({ payload }) {
  const tokens = yield call(checkTokens, { payload })

  if (tokens) {
    // call for projectSettings here
    yield call(loginSuccessSaga, { payload: tokens })
  } else {
    yield put(updateAuth({ authorized: false, loading: false }))
  }
}

export function* signupSaga({ payload: { email, history } }) {
  const getParams = qs.parse(history.location.search, {ignoreQueryPrefix: true})
  console.log(getParams)
  yield put(authToggleLoading(true))
  try {
    const response = yield post('/auth/signup', {
      email,
      registration_agent_name: getParams.regagent,
    }
    )
    const data = camelize(response.data)
    yield put(authToggleLoading(false))
    history.push(`/auth/token/${data.accessToken}/${data.refreshToken}`)
  } catch (e) {
    if (
      e &&
      e.response &&
      e.response.data &&
      e.response.data.title &&
      e.response.data.title.indexOf('UserAlreadyExists') >= 0
    ) {
      history.push(`/auth/email/${email}/auth${history.location.search}`)
    } else {
      yield put(handleServerError(e))
    }
  }
  yield put(authToggleLoading(false))
}

export function* test() {
  try {
    yield get('/auth/test')
  } catch (e) {
    yield put(handleServerError(e))
  }
}

export function* restorePasswordSaga({ payload: { email, history } }) {
  yield put(authToggleLoading(true))
  try {
    yield post('/auth/restore', {
      email,
    })
    history.push(`/auth/reset/${email}`)
  }
  catch (e) {
    yield put(handleServerError(e))
  }
  yield put(authToggleLoading(false))
}

export function* resetPasswordSaga({ payload: { restoreToken, email, password } }) {
  yield put(authToggleLoading(true))
  try {
    const response = yield post('/auth/signin', {
      email,
      password,
      restore_token: restoreToken
    })
    const { access_token: accessToken, refresh_token: refreshToken } = response.data
    yield call(loginSuccessSaga, loginSuccess({ accessToken, refreshToken }))
    history.push('/project')
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(authToggleLoading(false))
}

// export function* resetP

export default function* () {
  yield takeEvery(LOGIN, signin)
  yield takeEvery(AUTH_VK, vkAuth)
  yield takeEvery(AUTH_OK, okAuth)
  yield takeEvery(AUTH_INST, authInst)
  yield takeEvery(LOGIN_SUCCESS, loginSuccessSaga)
  yield takeEvery(APP_INIT, appInit)
  yield takeEvery(AUTH_TEST, testAuth)
  yield takeEvery(JUST_TEST, test)
  yield throttle(500, SIGNUP, signupSaga)
  yield takeEvery(RESTORE_PASSWORD, restorePasswordSaga)
  yield takeEvery(RESET_PASSWORD, resetPasswordSaga)
  yield takeEvery(SIGNOUT, signOutSaga)
  yield throttle(2000, EXCHANGE_TOKENS, exchangeTokensSaga)
}