import { shape, string, number, arrayOf, bool } from 'prop-types'


export const projectShape = shape({
  currency: string,
  domain: string,
  id: number,
  logo_id: number,
  display_domain: string,
  status: number,
  title: string
})

export const goodSape = shape({
  base_price: number,
  default_image_id: number,
  id: number,
  normalized_title: string,
  old_price: number,
  title: string
})

export const deliveryShape = shape({
  name: string,
  description: string,
  price: number,
  is_optional: bool,
  options: arrayOf(shape({
    name: string,
    price: number
  }))
})

export const categoryShape = shape({
  id: number,
  parent_name: string,
  name: string
})

export const goodDetailedShape = shape({
  id: number,
  default_image_id: number,
  title: string,
  normalized_title: string,
  price: number,
  old_price: number,
  currency: string,
  description: string,
  image_ids: arrayOf(number),
  is_adult: bool,
  // TODO: finish categories
  category: categoryShape,
  // TODO: test blocks
  // blocks: 
})
