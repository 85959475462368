import { put, takeEvery, select, call, throttle } from 'redux-saga/effects'
import { post, get, putRequest } from 'src/utils/requestSaga'
import qs from 'qs'
import { camelize, snakeize } from "casing";
import { toggleOrdersLoading, SUBMIT_ORDER,SUBMIT_CHECKING_BOOKING_ORDER, GET_ORDERS, setOrders, ADD_ORDER_DESCRIPTION, toggleOrderNoteLoading, addOrderDescriptionSuccess, togglePresellLoading, SET_PRESELL_PRICE, updatePrices, COMPLETE_ORDER, swapOrders, setOrdersPositions, UPDATE_ORDERS_POSITIONS, SAVE_ORDERS_POSITIONS, saveOrdersPositions, POST_ORDERS_REPORT } from './ordersReducer'
import { handleServerError, showNotification } from '../notification'
import { selectOrderColumnIndex, selectOrdersPositions, selectColumnsOrders, selectPaymentMethods, selectOrdersStatuses } from './ordersSelector'

function* submitOrder({ payload }) {
  console.log(payload)
  const { email, phone, contact, comment, payment, goods, id, clearCart, delivery_info, customData, promocode, serviceOrders, location } = payload
  yield put(toggleOrdersLoading(true))

  const details = Object.keys(goods).map((key) => {
    return {
      goods_id: key,
      count: goods[key]
    }
  })
  try {
    const { data } = yield post('/orders', {
      project_id: id,
      email,
      phone,
      contact_name: contact,
      domain: location,
      commentary: comment,
      payment_method: payment,
      details: details,
      delivery_info,
      service_orders: serviceOrders,
      custom_data: customData,
      promocode
    })
    clearCart()
    if (data.pay_url) {
      window.location.replace(data.pay_url)
    } else if (payload.redirectSuccess) {
      payload.redirectSuccess()
    }
  } catch (e) {
    if (payload.redirectFailure) {
      payload.redirectFailure(e.response.data.title)
    }
  }
  yield put(toggleOrdersLoading(false))
}

function* getOrdersSaga({ payload }) {
  yield put(toggleOrdersLoading(true))
  try {
    const search = qs.stringify({ count: 10000 })
    const response = yield get(`/projects/${payload}/orders?${search}`)
    const { data: { positions } } = yield get(`/projects/${payload}/orders/positions`)
    yield put(setOrders({
      ...response.data,
      positions
    }))
    yield put(setOrdersPositions(positions))
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleOrdersLoading(true))
}

function* addOrderDescriptionSaga({ payload: { projectId, orderId, description } }) {
  yield put(toggleOrderNoteLoading(true))
  yield post(`/projects/${projectId}/orders/addNote`, {
    order_id: orderId,
    description
  })
  yield put(addOrderDescriptionSuccess({ orderId, description }))
  yield put(toggleOrderNoteLoading(false))
}

function* setPresellPriceSaga({ payload }) {
  const { projectId, orderId, details, domain } = payload
  yield put(togglePresellLoading(true))
  try {
    yield putRequest(`/projects/${projectId}/orders/setPrice`, {
      order_id: orderId,
      domain,
      details
    })
    yield put(updatePrices({ orderId, details, domain }))
    if (payload.callback) {
      console.log(payload, 'payload')
      payload.callback()
    }
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(togglePresellLoading(false))
}

function* completeOrderSaga({ payload: { id, destinationId, projectId, status } }) {
  const index = yield select(state => selectOrderColumnIndex(state, { id: id, status}))
  // TODO: add ProjectId here
  yield updateOrdersPositionsSaga({
    payload: {
      destination: {
        index: 0,
        droppableId: destinationId
      },
      source: {
        index,
        droppableId: status,
      },
      draggableId: id,
      projectId
    }
  })
}

function* updateOrdersPositionsSaga({ payload }) {
    yield put(swapOrders(payload))
    yield call(updaterOrderStatus, { payload })
    yield put(saveOrdersPositions(payload))
}

function* updaterOrderStatus({ payload }) {
  try {
    const sourceStatus = payload.source.droppableId
    const destinationStatus = payload.destination.droppableId
    if (sourceStatus !== destinationStatus) {
      const orderStates = yield select(selectOrdersStatuses)
      yield putRequest(`projects/${payload.projectId}/orders/changeState`, {
        order_id: payload.draggableId,
        state_id: orderStates[destinationStatus].key
      })
    }
  } catch (e) {
    yield put(handleServerError(e))
  }
}

function* saveOrdersPositionsSaga({ payload }) {
  const newColumns = yield select(selectColumnsOrders)
  const orderStates = yield select(selectOrdersStatuses)
  const columnAction = newColumns.map((column, index) => {
    return {
      key: orderStates[index].key,
      value: column
    }
  })  
  try {
    yield post(`/projects/${payload.projectId}/orders/positions`, { positions: columnAction })
  } catch (e) {
    yield put(handleServerError(e))
  }
}

function* postOrdersReportSaga({ payload }) {

  const Offset = new Date().getTimezoneOffset() / -60;
  console.log(Offset)
  Offset.toString(); 
  console.log(Offset)

  try {
    yield post(`/projects/${payload}/orders/getReport`, {
      "time_zone_offset" : Offset,
    })
    yield put(showNotification({
      key: 'orders.newReport'
    }))
  } catch (e) {
    yield put(handleServerError(e))
  }
}


export default function* () {
  yield takeEvery(SUBMIT_ORDER, submitOrder)
  yield takeEvery(GET_ORDERS, getOrdersSaga)
  yield takeEvery(ADD_ORDER_DESCRIPTION, addOrderDescriptionSaga)
  yield takeEvery(SET_PRESELL_PRICE, setPresellPriceSaga)
  yield takeEvery(COMPLETE_ORDER, completeOrderSaga)
  yield takeEvery(UPDATE_ORDERS_POSITIONS, updateOrdersPositionsSaga)
  yield throttle(20000, SAVE_ORDERS_POSITIONS, saveOrdersPositionsSaga)
  yield takeEvery(POST_ORDERS_REPORT, postOrdersReportSaga)
}