import React, { FC, memo } from "react";
import { ContentState } from "draft-js";
import styled from "styled-components";

type Props = {
  contentState: ContentState;
  entityKey: string;
};

const EditorLink: FC<Props> = (props) => {
  const { url, target } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <Link href={url} target={target && "_blank"}>
      {props.children}
    </Link>
  );
};

const Link = styled.a``;

export default memo(EditorLink)