import {
  put,
  call,
  take,
  fork,
  race,
  delay,
  cancel,
  takeEvery,
} from "redux-saga/effects";
import { camelize } from "casing";
import { AxiosResponse } from "axios";
import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import { handleServerError } from "src/store/notification";
import { get, post, deleteRequest } from "src/utils/requestSaga";
import {
  getPayments,
  getUserCards,
  cancelPayment,
  getLastPayment,
  deleteUserCard,
  PaymentItemType,
  stopPaymentBgSync,
  setActiveUserCard,
  startPaymentBgSync,
  triggerAddUserCard,
  triggerGetPayments,
  triggerGetUserCards,
  cancelPaymentBgSync,
  PaymentsResponseType,
  triggerCancelPayment,
  triggerDeleteUserCard,
  triggerGetLastPayment,
  UserCardsResponseType,
  GetUserCardResponseType,
  triggerSetActiveUserCard,
  StartPaymentBgSyncPayload,
  TriggerGetLastPaymentPayload,
} from "./paymentTypes";
import { triggerGetSubscriptions as triggerGetActiveSubscriptions } from "../subscriptions/subscriptionTypes";

const cancelPaymentSaga = bindAsyncAction(cancelPayment)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(
      post,
      `/payments/${payload.paymentId}/cancel`
    );
    yield put(triggerGetLastPayment({
      projectId: payload.projectId,
      systemName: payload.systemName
    }))
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(cancelPaymentBgSync(null))
});

const getPaymentsSaga = bindAsyncAction(getPayments)(
  function* (): SagaIterator {
    try {
      const { data }: AxiosResponse<PaymentsResponseType> = yield call(
        get,
        `/users/current/payments`
      );
      return camelize({ data: data.payments, currencies: data.currencies });
    } catch (e) {
      yield put(handleServerError(e));
    }
  }
);

const getUserCardsSaga = bindAsyncAction(getUserCards)(
  function* (): SagaIterator {
    try {
      const { data }: AxiosResponse<UserCardsResponseType> = yield call(
        get,
        `/userCards`
      );
      return camelize({ data });
    } catch (e) {
      yield put(handleServerError(e));
    }
  }
);

function* addUserCardSaga() {
  try {
    const { data }: AxiosResponse<GetUserCardResponseType> = yield call(
      post,
      `/userCards`
    );
    if (data.payment_url) {
      window.location.replace(data.payment_url);
    }
  } catch (e) {
    yield put(handleServerError(e));
  }
}

const setUserCardActiveSaga = bindAsyncAction(setActiveUserCard)(function* ({
  payload,
}): SagaIterator {
  try {
    const res: AxiosResponse<GetUserCardResponseType> = yield call(
      post,
      `/userCards/${payload.cardId}/setActivity`,
      { is_active: payload.isActive }
    );
    if (res) {
      yield call(getUserCardsSaga, {});
    }
    return null;
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const deleteUserCardSaga = bindAsyncAction(deleteUserCard)(function* ({
  payload,
}): SagaIterator {
  try {
    const res: AxiosResponse<GetUserCardResponseType> = yield call(
      deleteRequest,
      `/userCards/${payload}`
    );
    if (res) {
      yield call(getUserCardsSaga, {});
    }
    return null;
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getLastPaymentSaga = bindAsyncAction(getLastPayment)(function* ({
  payload,
}): SagaIterator {
  try {
    const res: AxiosResponse<PaymentItemType> = yield call(
      get,
      `/services/${payload.systemName}/payments?projectId=${payload.projectId}`
    );
    const data: PaymentItemType = camelize(res.data);
    // start proccess in the background
    yield put(
      startPaymentBgSync({
        data,
        params: payload,
      })
    );
    return data;
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const startLastPaymentInfinite = function* ({
  payload,
}: Action<StartPaymentBgSyncPayload>) {
  if (payload.data && !payload.data.isProcessed) {
    const infinite = yield fork(lastPaymentInfinite, payload.params);
    yield race({
      finish: take(stopPaymentBgSync.type),
      cancel: take(cancelPaymentBgSync.type),
    });
    yield cancel(infinite);
  }
};

const lastPaymentInfinite = function* (payload: TriggerGetLastPaymentPayload) {
  try {
    while (true) {
      const res: AxiosResponse<PaymentItemType> = yield call(
        get,
        `/services/${payload.systemName}/payments?projectId=${payload.projectId}`
      );
      const data: PaymentItemType = camelize(res.data);
      if (data.isProcessed || !data) {
        yield put(triggerGetLastPayment(payload));
        yield put(triggerGetActiveSubscriptions(payload.projectId));
        yield put(stopPaymentBgSync(data));
      }
      yield delay(3000);
    }
  } finally {
  }
};

export default function* () {
  yield takeEvery(triggerGetPayments.type, getPaymentsSaga);
  yield takeEvery(triggerGetUserCards.type, getUserCardsSaga);
  yield takeEvery(triggerAddUserCard.type, addUserCardSaga);
  yield takeEvery(triggerSetActiveUserCard.type, setUserCardActiveSaga);
  yield takeEvery(triggerDeleteUserCard.type, deleteUserCardSaga);
  yield takeEvery(triggerGetLastPayment.type, getLastPaymentSaga);
  yield takeEvery(startPaymentBgSync, startLastPaymentInfinite);
  yield takeEvery(triggerCancelPayment.type, cancelPaymentSaga)
}
