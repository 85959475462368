import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { 
  getCategories, 
  updateCategories, 
  selectProjectsLoading, 
  selectProjectCategories, 
} from 'src/store/projects'

import EditCategoriesPage from './EditCategoriesPage'

const mapStateToProps = (state, props) => ({
  categories: selectProjectCategories(state, props.match.params.id),
  loading: selectProjectsLoading(state)
})

const mapDispatchToProps = {
  getCategories,
  updateCategories
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditCategoriesPage))
