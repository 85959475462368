import React, {memo, FC, useCallback, Fragment} from "react";
import styled from "styled-components";
import {IconButton, HeaderText, size, Header, ArrowLeftIcon, AddGoodButton} from "hopshopui";
import {useT} from "src/hooks/useT";
import {ConnectedDesignSelectionHeaderProps} from "../containers/DesignSelectionHeader";
import Container from "src/elements/containers/Container";

const DesignSelectionHeader: FC<ConnectedDesignSelectionHeaderProps> = ({
                                                                            toggleDesignMode,
                                                                        }) => {
    const {t} = useT();
    const handleBackClick = useCallback(() => {
        toggleDesignMode();
    }, [toggleDesignMode]);
    return (
        <Fragment>
            <MarginContainer/>
            <StyledHeader isForm={true}>
                <Container>
                    <StyledBackButton onClick={handleBackClick}>
                        <ArrowLeftIcon fill="#000000"/>
                    </StyledBackButton>
                    <HeaderTextStyled>{t("design.selectionHeader")}</HeaderTextStyled>
                </Container>
            </StyledHeader>
        </Fragment>
    );
};

// const HeaderStyled = styled(Header)`
//   background: ${(props) => props.theme.colors.headerBackground};
//   display: flex;
//   padding: 15px 0;
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   z-index: 7;
//   ${Container} {
//     align-items: center;
//   }
//   ${HeaderText} {
//     margin-left: 20px;
//   }
//   @media screen and (max-width: ${size.mobile}) {
//     padding: 15px;
//   }
// `;

const StyledHeader = styled(Header)`
  width: 100%;
  padding: 0 20px;

  div {
    width: 100%;
    max-width: 940px;
  }

  position: fixed;
  top: 0;
  z-index: 7;

  ${Container} {
    align-items: center;
  }
`;

const MarginContainer = styled.div`
  height: 70px;
`;

const HeaderTextStyled = styled(HeaderText)`
  font-weight: 500;
  color: #000000;
  @media screen and (max-width: ${size.mobile}) {
    margin: 0 auto;
  }
`

const StyledBackButton = styled(AddGoodButton)`
  height: 40px;
  width: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  flex: none;
  background: #FFFFFF;
  border: none;
  &:hover{
    background: none;
  }
`;

export default memo(DesignSelectionHeader);
