import React, {
  memo,
  FC,
  useContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import styled from "styled-components";
import { P2, usePreventDefaultCallback, useBodyScrollHidden } from "hopshopui";
import { MenuPageType } from "../../utils/types/menuPage";
import { TemplateContext } from "../../utils/providers/TemplateProvider";
import MobileMenuItem from "./MobileMenuItem";
import CrossIcon from "../assets/cross.icon";

type Props = {
  pages: MenuPageType[];
  onClose: () => void;
};

const filterActiveMenuItem = (
  mappedPages: Record<string, MappedAliasMenuPageType>,
  pathName: string
): MenuPageType | undefined => {
  return Object.values(mappedPages).filter((page) => {
    return page.childrenAliases.indexOf(pathName) >= 0;
  })[0];
};

export type MappedAliasMenuPageType = MenuPageType & {
  childrenAliases: string[];
  parentAlias: string;
};

export type MappedMenuPageParent = MenuPageType & {
  isParent?: boolean;
};

export const flattenPages = (
  map: Record<string, MappedAliasMenuPageType>,
  pages: MenuPageType[],
  parentAlias: string
) => {
  pages.forEach((page) => {
    map[page.alias] = {
      ...page,
      childrenAliases: page.children.map((child) => child.alias),
      parentAlias: parentAlias,
    };
    if (page.children.length > 0) {
      flattenPages(map, page.children, parentAlias + page.alias + "/");
    }
  });
  return map;
};

const filterActiveMenuItems = (
  mappedPages: Record<string, MappedAliasMenuPageType>,
  pages: MenuPageType[],
  activeItem?: MenuPageType
) => {
  const activeParent = Object.values(mappedPages).filter((page, index) => {
    if (activeItem) {
      return page.childrenAliases.indexOf(activeItem.alias) >= 0;
    }
  })[0];
  return activeParent ? activeParent.children : pages;
};

const filterActivePageByChildrenAlias = (
  mappedPages: Record<string, MappedAliasMenuPageType>,
  activeItem?: MenuPageType
): MenuPageType | undefined => {
  return Object.values(mappedPages).filter((page) => {
    if (activeItem) {
      return page.childrenAliases.indexOf(activeItem.alias) >= 0;
    }
  })[0];
};

const MobileMenu: FC<Props> = ({ pages, onClose }) => {
  const { pathName, redirectMenu, isAdmin, t } = useContext(TemplateContext);
  const mappedPages = useMemo(() => flattenPages({}, pages, ""), [pages]);
  const [activeItem, setActiveItem] = useState(() =>
    filterActiveMenuItem(mappedPages, pathName)
  );

  const activeMenuItems = useMemo(() => {
    return activeItem ? activeItem.children : pages;
  }, [activeItem, pages]);

  const handleNavigate = useCallback(
    (alias: string, typeName: string, isParent?: boolean) => {
      if (alias === "editPages") {
        redirectMenu({
          typeName: typeName,
          alias: alias,
        });
      } else {
        const page = mappedPages[alias];
        if (isParent || !page.children || page.children.length == 0) {
          redirectMenu({
            typeName: page.typeName,
            alias: page.parentAlias + alias,
          });
        } else {
          setActiveItem(page);
          return;
        }
      }
      onClose();
    },
    [setActiveItem, mappedPages, redirectMenu, onClose]
  );

  const handleBackButton = useCallback(() => {
    // find new parent id
    setActiveItem((active) => {
      const newActiveItem = filterActivePageByChildrenAlias(
        mappedPages,
        active
      );
      return newActiveItem;
    });
  }, [mappedPages, setActiveItem]);

  const handleCrossClick = usePreventDefaultCallback(() => onClose(), []);

  useBodyScrollHidden();

  return (
    <MobileMenuContainer>
      <MobileMenuInnerContainer>
        <MobileMenuHeader>
          {activeItem ? (
            <BackButton onClick={handleBackButton}>
              <P2>{t("backTitle")}</P2>
            </BackButton>
          ) : (
            <MenuTitle>{t("siteMenuTitle")}</MenuTitle>
          )}
          <CrossLink onClick={handleCrossClick}>
            <CrossIcon></CrossIcon>
          </CrossLink>
        </MobileMenuHeader>
        {activeItem && (
          <MobileMenuItem
            {...activeItem}
            onNavigate={handleNavigate}
            isParent
            pathName={pathName}
          />
        )}
        {activeMenuItems.map((page, index) => (
          <MobileMenuItem
            {...page}
            key={page.alias}
            onNavigate={handleNavigate}
            pathName={pathName}
            hasSubmenu={page.children.length !== 0}
          />
        ))}
        {isAdmin && (
          <MobileMenuItem
            name={t("subHeader.addPage")}
            pathName={pathName}
            typeName="EditPages"
            alias={"editPages"}
            editLink
            onNavigate={handleNavigate}
          />
        )}
      </MobileMenuInnerContainer>
    </MobileMenuContainer>
  );
};

const MobileMenuContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  background: ${(props) => props.theme.colors.light};
  z-index: 12;
  padding: 0;
`;

const MobileMenuInnerContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

const MobileMenuHeader = styled.div`
  display: flex;
  align-items: center;
  a {
    cursor: pointer;
  }
`;

const CrossLink = styled.a`
  padding: 18px 20px;
  margin-left: auto;
  height: 100%;
  display: flex;
  align-items: center;
  &:hover,
  &:active {
    background: ${(props) => props.theme.colors.pillDisabled};
  }
`;

const BackButton = styled.a`
  padding: 20px;
  &:hover,
  &:active {
    background: ${(props) => props.theme.colors.pillDisabled};
  }
`;

export const MenuTitle = styled(P2)`
  padding: 20px;
  opacity: 0.5;
`;

export default memo(MobileMenu);
