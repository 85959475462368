import {
  takeEvery,
  put,
  call,
  take,
  fork,
  all,
  throttle,
  select,
  takeLatest,
} from "redux-saga/effects";
import { produce } from "immer";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import {
  getPages,
  triggerGetPages,
  PagesResponseType,
  triggerChangePages,
  changePages,
  getMenuPages,
  triggerGetMenuPages,
  triggerGetPageContent,
  getPageContent,
  updatePageContent,
  triggerUpdatePageContent,
  PageType,
} from "./pagesTypes";
import { get, post, deleteRequest } from "src/utils/requestSaga";
import { SagaIterator } from "redux-saga";
import { AxiosResponse } from "axios";
import { handleServerError, showNotification } from "../notification";
import { camelize, snakeize } from "casing";
import { triggerGetLastPayment } from "../payment";
import { mapPagesWithPositions } from "src/utils/pages";
import { BlockTypes } from "src/utils/variables";
import { selectPageContent } from "./pagesSelector";
import { UpdatePageContentPayload } from "./pagesTypes";
import {
  uploadAndFilterBlockTypeImages,
} from "../images/imagesSaga";
import {
  ContentPageBlocks,
  ImageBlockType,
} from "src/templates/utils/types/goodType";
import { filterByOld } from "src/utils/filterOldIds";
import history from "src/utils/browserHistory";

const getPagesSaga = bindAsyncAction(getPages)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<PagesResponseType> = yield call(
      get,
      `projects/${payload}/pages`
    );
    return { ...camelize(data), id: payload };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const changePagesSaga = bindAsyncAction(changePages)(function* ({
  payload,
}): SagaIterator {
  try {
    const pagesToDelete = payload.deleteData;
    if (pagesToDelete.length > 0) {
      yield call(post, "pages/deleteList", {
        project_id: payload.id,
        page_ids: pagesToDelete,
      });
    }
    const newPages = mapPagesWithPositions(payload.data);
    const response = yield call(post, `projects/${payload.id}/pages`, {
      pages: snakeize(newPages),
    });
    const data: {
      pages: PageType[];
    } = camelize(response.data);

    if (payload.callback) {
      payload.callback(data.pages);
    }

    yield put(triggerGetMenuPages(payload.id));

    return {
      projectId: payload.id,
      ...data,
    };
  } catch (e) {
    yield put(handleServerError(e));
    yield put(triggerGetPages(payload.id));
  }
});

const getMenuPagesSaga = bindAsyncAction(getMenuPages)(function* ({
  payload,
}): SagaIterator {
  try {
    const response = yield call(get, `/projects/${payload}/menu`);
    const data = camelize(response.data);
    return {
      projectId: payload,
      data: data,
    };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getPageContentSaga = bindAsyncAction(getPageContent)(function* ({
  payload,
}): SagaIterator {
  try {
    const response = yield call(
      get,
      `/projects/${payload.projectId}/pages/${payload.alias}/content`
    );
    const data = camelize(response.data);
    const pageSEO = data.seoParameters
      ? {
        ...data.seoParameters,
        keywords: data.seoParameters.keywords
          ? data.seoParameters.keywords.join(", ")
          : "",
      }
      : {
        title: "",
        description: "",
        keywords: "",
        head: "",
      };

    return {
      ...payload,
      data: { ...data, seoParameters: pageSEO },
    };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const deletePageContent = function* (payload: UpdatePageContentPayload) {
  try {
    const oldContent: ContentPageBlocks = yield select((state) =>
      selectPageContent(state, payload.projectId, payload.alias)
    );

    const deleteIds = filterByOld(oldContent, payload.modules);

    if (deleteIds.length > 0) {
      const body = snakeize({
        projectId: payload.projectId,
        moduleIds: deleteIds,
      });
      yield call(post, `/pages/${payload.alias}/deleteContent`, body);
    }
  } catch (e) {
    yield put(handleServerError(e));
  }
};


const updatePageContentSaga = bindAsyncAction(updatePageContent)(function* (
  action
): SagaIterator {
  try {
    const { payload } = action;

    const finalModules = yield call(
      uploadAndFilterBlockTypeImages,
      payload.modules
    );

    yield call(deletePageContent, { ...payload, modules: finalModules });

    const seoParameters = { ...payload.seoParameters, keywords: typeof payload.seoParameters.keywords === 'string' ? payload.seoParameters.keywords.split(', ') : [] }

    const body = snakeize({ modules: finalModules, seoParameters, cleanSeo: payload.cleanSeo });

    // TODO: filter by old content
    const oldContent: ContentPageBlocks = yield select((state) =>
      selectPageContent(state, payload.projectId, payload.alias)
    );

    const response = yield call(
      post,
      `/projects/${payload.projectId}/pages/${payload.alias}/content`,
      body
    );
    const data = camelize(response.data);
    history.push(`/project/${payload.projectId}/content/${payload.alias}`);
    return {
      projectId: payload.projectId,
      alias: payload.alias,
      data: data,
    };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

export default function* () {
  yield takeLatest(triggerGetPages.type, getPagesSaga);
  yield takeLatest(triggerChangePages.type, changePagesSaga);
  yield takeLatest(triggerGetMenuPages.type, getMenuPagesSaga);
  yield takeLatest(triggerGetPageContent.type, getPageContentSaga);
  yield takeLatest(triggerUpdatePageContent.type, updatePageContentSaga);
}
