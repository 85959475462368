import React, { Suspense } from 'react'

import ProfileShimmer from 'src/modules/profile/components/ProfileShimmer'

const ChangeWebhook = React.lazy(() => import('src/modules/profile/containers/NotificationsChangeWebhook'))

const NotificationsChangeWebhook = () => {
  return (
    <Suspense fallback={<ProfileShimmer />}>
      <ChangeWebhook />
    </Suspense>
  );
}

export default NotificationsChangeWebhook
