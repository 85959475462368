import i18n from 'i18next'
import en from 'src/locales/en'
import ru from 'src/locales/ru'
import backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import XHR from "i18next-http-backend" // <---- add this
import LanguageDetector from 'i18next-browser-languagedetector'


// TODO: for testing
const localePath = process.env.REACT_APP_LOCALE_URL || 'https://hs-stage-localization.s3.eu-central-1.amazonaws.com'

i18n
  .use(XHR)
  .use(backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'ru',
    ns: ['common', 'templates'],
    defaultNS: 'common',
    backend: {
      loadPath: `${localePath}/{{lng}}/{{ns}}.json`,
    }
  })

export function t(name, params = {}) {
  return i18n.t(name, params)
}

export function templateT(name, params = {}) {
  if (!i18n.exists(`templates:${name}`)) {
    i18n.loadNamespaces('templates')
  }
  return i18n.t(`templates:${name}`, params)
}

export default i18n
