import Immutable from 'seamless-immutable'
import { handleActions, createAction } from 'redux-actions'
import { mapEnumArray } from 'src/utils/mapEnumArray'

export const TOGGLE_ORDERS_LOADING = 'orders/TOGGLE_LOADING'
export const TOGGLE_ORDER_NOTE_LOADING = 'orders/TOGGLE_ORDER_NOTE_LOADING'
export const TOGGLE_PRESELL_LOADING = 'orders/TOGGLE_PRESELL_LOADING'
export const SUBMIT_ORDER = 'orders/SUBMIT_ORDER'
export const GET_ORDERS = 'orders/GET_ORDERS'
export const SET_ORDERS = 'orders/SET_ORDER'
export const SWAP_ORDERS = 'orders/SWAP_ORDERS'
export const UPDATE_ORDERS_POSITIONS = 'orders/UPDATE_ORDERS_POSITIONS'
export const ADD_ORDER_DESCRIPTION = 'orders/ADD_ORDER_DESCRIPTION'
export const ADD_ORDER_DESCRIPTION_SUCCESS = 'orders/ADD_ORDER_DESCRIPTION_SUCCESS'
export const SET_PRESELL_PRICE = 'orders/SET_PRESELL_PRICE'
export const UPDATE_PRICES = 'orders/UPDATE_PRICES'
export const COMPLETE_ORDER = 'orders/COMPLETE_ORDER'
export const SET_ORDERS_POSITIONS = 'orders/SET_ORDERS_POSITIONS'
export const SAVE_ORDERS_POSITIONS = 'orders/SAVE_ORDERS_POSITIONS'
export const POST_ORDERS_REPORT = 'orders/POST_ORDERS_REPORT'

export const toggleOrdersLoading = createAction(TOGGLE_ORDERS_LOADING)
export const toggleOrderNoteLoading = createAction(TOGGLE_ORDER_NOTE_LOADING)
export const submitOrder = createAction(SUBMIT_ORDER)
export const getOrders = createAction(GET_ORDERS)
export const setOrders = createAction(SET_ORDERS)
export const swapOrders = createAction(SWAP_ORDERS)
export const addOrderDescription = createAction(ADD_ORDER_DESCRIPTION)
export const addOrderDescriptionSuccess = createAction(ADD_ORDER_DESCRIPTION_SUCCESS)
export const setPresellPrice = createAction(SET_PRESELL_PRICE)
export const togglePresellLoading = createAction(TOGGLE_PRESELL_LOADING)
export const updatePrices = createAction(UPDATE_PRICES)
export const completeOrder = createAction(COMPLETE_ORDER)
export const setOrdersPositions = createAction(SET_ORDERS_POSITIONS)
export const updateOrdersPositions = createAction(UPDATE_ORDERS_POSITIONS)
export const saveOrdersPositions = createAction(SAVE_ORDERS_POSITIONS)
export const postOrdersReport = createAction(POST_ORDERS_REPORT)

const initialState = Immutable({
  loading: false,
  orderNoteLoading: false,
  presellLoading: false,
  data: {},
  totalCount: 0,
  paymentStates: {},
  paymentMethods: {},
  currencies: {},
  positions: [],
  searchOptions: [],
  orderStates: [],
  columnsOrders: [],
})

const setOrdersHelper = (state, payload) => {
  const orderStates = payload.order_states
  const orders = {}

  payload.orders.forEach((order) => {
    orders[order.id] = order
  })

  const formatedPositions = payload.positions.map((position) => position.value)

  const columnsOrders = orderStates.map((orderState, columnIndex) => {
    return payload.orders.filter((order) => {
      return order.order_status_id === orderState.key
    }).map((order) => order.id).sort((a, b) =>
      formatedPositions[columnIndex].indexOf(a) - formatedPositions[columnIndex].indexOf(b)
    )
  })

  const paymentStates = mapEnumArray(payload.payment_states)
  const paymentMethods = mapEnumArray(payload.payment_methods)
  const currencies = mapEnumArray(payload.currencies)

  const newState = state
    .set('orderStates', orderStates)
    .set('data', orders)
    .set('columnsOrders', columnsOrders)
    .set('paymentStates', paymentStates)
    .set('paymentMethods', paymentMethods)
    .set('currencies', currencies)


  return Immutable.merge(state, newState)

}

const swapOrdersHandler = (state, payload) => {
  const { source, destination, draggableId } = payload
  let columnsOrders = Immutable(state.columnsOrders)
  const sourceColumn = columnsOrders[source.droppableId].filter(id => id !== draggableId)
  columnsOrders = Immutable.setIn(columnsOrders, [source.droppableId], sourceColumn)

  const destinationColumn = columnsOrders[destination.droppableId].asMutable()
  destinationColumn.splice(destination.index, 0, draggableId)

  let newState = Immutable.setIn(state, ['columnsOrders', source.droppableId], sourceColumn)
  newState = Immutable.setIn(newState, ['columnsOrders', destination.droppableId], destinationColumn)

  return Immutable.merge(state, newState)
}

export default handleActions({
  [TOGGLE_ORDERS_LOADING]: (state, { payload }) => state.set('loading', payload),
  [SET_ORDERS]: (state, { payload }) => {
    return setOrdersHelper(state, payload)
  },
  [SWAP_ORDERS]: (state, { payload }) => {
    return swapOrdersHandler(state, payload)
  },
  [TOGGLE_ORDER_NOTE_LOADING]: (state, { payload }) => state.set('orderNoteLoading', payload),
  [ADD_ORDER_DESCRIPTION_SUCCESS]: (state, { payload }) => {
    let notes = state.getIn(['data', [payload.orderId], 'notes'])
    notes = notes.concat({ description: payload.description })
    return Immutable.setIn(state, ['data', [payload.orderId], 'notes'], notes)
  },
  [UPDATE_PRICES]: (state, { payload }) => {
    let order = Immutable.getIn(state, ['data', [payload.orderId]])
    const details = order.details.map((detail, index) => {
      if (payload.details[index]) {
        return Immutable.merge(detail, payload.details[index])
      }
      return detail
    })
    const totalSum = details.reduce((currentValue, detail) => {
      return Math.ceil(detail.count * detail.price + currentValue)
    }, 0)
    const deliveryPrice = order?.delivery_info?.price || 0
    order = Immutable.setIn(order, ['details'], details).set('total_sum', (totalSum + deliveryPrice))
    return Immutable.setIn(state, ['data', [payload.orderId]], order)
  },
  [SET_ORDERS_POSITIONS]: (state, { payload }) => {
    return state.set('positions', payload)
  }

}, initialState)
