import React, { useEffect } from 'react'
import { func } from 'prop-types'
import qs from 'qs'

import { routerProps } from 'src/types/routerProps'
import { sanitize } from 'src/utils/token'
import { vkLogin, instLogin, okLogin} from 'src/utils/localStorage'


const getToken = (search, loginSuccess, history) => {
  let params = (new URL(document.location)).searchParams
  const accessToken = sanitize(params.get('accessToken'))
  const refreshToken = sanitize(params.get('refreshToken'))
  if (accessToken && refreshToken) {
    localStorage.removeItem(instLogin)
    localStorage.removeItem(okLogin)
    localStorage.setItem(vkLogin, true)
    loginSuccess({ accessToken, refreshToken })
    history.push('/project')
  }
}

const AuthVk = ({ location, loginSuccess, history, showNotification, ...rest }) => {
  getToken(location.search, loginSuccess, history)
  useEffect(() => {
    const search = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })
    if (search.error) {
      history.replace('/redirect')
    }
  }, [location.search, history])
  return <div>redirecting...</div>
}

AuthVk.propTypes = {
  ...routerProps,
  loginSuccess: func.isRequired
}

export default AuthVk