import React, { memo, Fragment, FC, useCallback, Suspense, ChangeEvent } from "react";
import { InputPill } from "hopshopui";
import styled from "styled-components";
import { useColorPicker } from "src/hooks/colorPicker";

type Props = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  label: string | undefined;
  scrollTop?: number;
  scrollLeft?: number;
};

const ColorPickerTooltip = React.lazy(
  () => import("src/elements/Tooltip/ColorPickerTooltip")
);

const DesignColorPickerInput: FC<Props> = ({
  value,
  onChange,
  placeholder,
  label,
  scrollTop,
  scrollLeft,
}) => {
  const {
    colorPickerVisible,
    toggleTooltipContainer,
    tooltipPosition,
    handleFocusCallback,
  } = useColorPicker(scrollTop, scrollLeft);

  const handleBlur = useCallback(() => {}, []);
  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange(value)
  }, [onChange]);
  return (
    <Fragment>
      <InputPill
        onFocus={handleFocusCallback}
        value={value}
        onChange={handleInputChange}
        onBlur={handleBlur}
        name="colorpicker"
        errors={{}}
        touched={{}}
        disabled={false}
        placeholder={placeholder}
        label={label}
      />
      {colorPickerVisible && (
        <Suspense fallback={<AbsoluteContainer />}>
          <ColorPickerTooltip
            color={value}
            top={tooltipPosition.top}
            left={tooltipPosition.left}
            onChange={onChange}
            onClose={toggleTooltipContainer}
          />
        </Suspense>
      )}
    </Fragment>
  );
};

const AbsoluteContainer = styled.div`
  position: absolute;
`;

export default memo(DesignColorPickerInput);
