import React, { Fragment, useContext, useCallback } from "react";
import { createGlobalStyle } from "styled-components";
import { TemplateContext } from "src/templates/utils/providers/TemplateProvider";
import {
  MonotoneBackground,
  GradientBackground,
} from "src/templates/utils/types/projectSettings";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { selectProjectSettingsDesignSelection, changeProjectDesignType } from "src/store/projectSettings";

export const GlobalStyles = createGlobalStyle<{
  backgroundSettings: MonotoneBackground | GradientBackground;
}>`
  html, #root {
    height: 100%;
  }
    body {
      background: ${(props: any) =>
        props.backgroundSettings.type === "Monotone" &&
        props.backgroundSettings.payload.color
          ? props.backgroundSettings.payload.color
          : props.theme.colors.mildBackground};
      position: relative;
      min-height: 100%;
    }
    * {
      box-sizing: border-box;
    }
`;

export const TemplateGlobalStyles = () => {
  const { backgroundSettings } = useContext(TemplateContext);
  const designSelection = useSelector(selectProjectSettingsDesignSelection);
  const dispatch = useDispatch();

  const handleBackgroundClick = useCallback(() => {
    dispatch(changeProjectDesignType("Background"))
  }, [dispatch]);
  return (
    <Fragment>
      <BackgroundSelection
        active={designSelection}
        onClick={handleBackgroundClick}
      />
      <GlobalStyles backgroundSettings={backgroundSettings} />
    </Fragment>
  );
};

const BackgroundSelection = styled.div<{
  active?: boolean;
}>`
  display: ${(props) => (props.active ? "block" : "none")};
  z-index: 4;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &:hover {
    background: ${props => props.theme.colors.dark};
  }
`;

export default TemplateGlobalStyles;
