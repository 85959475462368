import React, {
  memo,
  FC,
  useCallback,
  ChangeEvent,
  FormEvent,
  useMemo,
  MouseEvent,
} from "react";
import {
  PillCard,
  PillMarginContainer,
  PillLineItem,
  SmallTogleContainer,
  PillLineLabel,
  Toggle,
  PillHorizontalContainer,
  InputPill,
  Divider,
  Header,
  IconButton,
  HeaderText,
  DoneIcon,
  size, ArrowLeftIcon, AddGoodButton,
} from "hopshopui";
import styled from "styled-components";
import { ConnectedDesignHeaderFormProps } from "../containers/DesignHeaderForm";
import { useT } from "src/hooks/useT";
import DesignColorPickerInput from "./DesignColorPickerInput";
import { DesignHeader } from "src/elements/layout/DesignHeader";

type DesignHeaderFormProps = {};

const DesignHeaderForm: FC<ConnectedDesignHeaderFormProps> = ({
  headerDesign,
  changeHeaderTextColor,
  changeHeaderBackgroundColor,
  changeHeaderLogoRounding,
  toggleProjectDesignHeaderKey,
  triggerSetProjectDesignSettings,
  match,
  loading,
  checkAndDisableDesignType,
}) => {
  const { t } = useT();

  const projectId = useMemo(() => {
    return match.params.id;
  }, [match]);

  // const handleFullScreenChange = useCallback(() => {
  //   updateProjectDesignHeader({
  //     ...headerDesign,
  //     extendToFullScreen: !headerDesign.extendToFullScreen,
  //   });
  // }, [headerDesign, updateProjectDesignHeader]);

  // const handleLogoChange = useCallback(() => {
  //   updateProjectDesignHeader({
  //     ...headerDesign,
  //     showLogo: !headerDesign.showLogo,
  //   });
  // }, [headerDesign, updateProjectDesignHeader]);

  // const handleTitleChange = useCallback(() => {}, ][])

  const updateHeaderDesign = useCallback(
    (e: ChangeEvent) => {
      const key = e.target.getAttribute("name");
      if (key) {
        // TODO: fix types
        toggleProjectDesignHeaderKey(key as any);
      }
    },
    [toggleProjectDesignHeaderKey]
  );

  const handleTextColorChange = useCallback(
    (value: string) => {
      changeHeaderTextColor(value);
    },
    [changeHeaderTextColor]
  );

  const handleBackgroundChange = useCallback(
    (value: string) => {
      changeHeaderBackgroundColor(value);
    },
    [changeHeaderBackgroundColor]
  );

  const handleLogoRoundingChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      changeHeaderLogoRounding(value);
    },
    [changeHeaderLogoRounding]
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      triggerSetProjectDesignSettings(projectId);
    },
    [triggerSetProjectDesignSettings, projectId]
  );

  const handleDecline = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      checkAndDisableDesignType();
    },
    [checkAndDisableDesignType]
  );

  return (
    <form onSubmit={handleSubmit}>
      <DesignHeader isForm={true}>
        <StyledBackButton onClick={handleDecline} >
          <ArrowLeftIcon fill="#000000"/>
        </StyledBackButton>
        <HeaderTextStyled>{t('design.headerDesign')}</HeaderTextStyled>
        <StyledDoneButton>
          <DoneIcon stroke="#000000" />
        </StyledDoneButton>
      </DesignHeader>
      <DesignFormContainer>
        <PillCard white>
          <PillMarginContainer>
            <PillLineItem>
              <PillLineLabel>{t("design.headerFullScreen")}</PillLineLabel>
              <SmallTogleContainer>
                <Toggle
                  small
                  values={[]}
                  onChange={updateHeaderDesign}
                  checked={headerDesign.extendToFullScreen}
                  toggleBackground
                  name="extendToFullScreen"
                  textHints={t("bookingJournal.DependsOnTheDayHint")}
                ></Toggle>
              </SmallTogleContainer>
            </PillLineItem>

            <PillLineItem>
              <PillLineLabel>{t("design.logo")}</PillLineLabel>
              <SmallTogleContainer>
                <Toggle
                  small
                  values={[]}
                  onChange={updateHeaderDesign}
                  checked={headerDesign.showLogo}
                  toggleBackground
                  name="showLogo"
                  textHints={t("design.logoHint")}
                ></Toggle>
              </SmallTogleContainer>
            </PillLineItem>

            <PillLineItem>
              <PillLineLabel>{t("design.title")}</PillLineLabel>
              <SmallTogleContainer>
                <Toggle
                  small
                  values={[]}
                  onChange={updateHeaderDesign}
                  checked={headerDesign.showTitle}
                  toggleBackground
                  name="showTitle"
                  textHints={t("design.titleHint")}
                ></Toggle>
              </SmallTogleContainer>
            </PillLineItem>

            <PillLineItem>
              <PillLineLabel>{t("design.description")}</PillLineLabel>
              <SmallTogleContainer>
                <Toggle
                  small
                  values={[]}
                  onChange={updateHeaderDesign}
                  checked={headerDesign.showDescription}
                  toggleBackground
                  name="showDescription"
                  textHints={t("design.descriptionHint")}
                ></Toggle>
              </SmallTogleContainer>
            </PillLineItem>

            <PillLineItem>
              <PillLineLabel>{t("design.contacts")}</PillLineLabel>
              <SmallTogleContainer>
                <Toggle
                  small
                  values={[]}
                  onChange={updateHeaderDesign}
                  checked={headerDesign.showContacts}
                  toggleBackground
                  name="showContacts"
                  textHints={t("design.contactsHint")}
                ></Toggle>
              </SmallTogleContainer>
            </PillLineItem>
            <Divider />
            <PillHorizontalContainer itemCount={2}>
              <DesignColorPickerInput
                value={headerDesign.textColor || ""}
                onChange={handleTextColorChange}
                placeholder={t("design.placeholderDefault")}
                label={t("design.textColorLabel")}
              />
              <DesignColorPickerInput
                value={headerDesign.backgroundColor || ""}
                onChange={handleBackgroundChange}
                placeholder={t("design.placeholderDefault")}
                label={t("design.headerBackgroundLabel")}
              />
            </PillHorizontalContainer>
            <Divider />
            <PillHorizontalContainer itemCount={1}>
              <InputPill
                label={t("design.logoRoundingLabel")}
                value={headerDesign.logoRounding.toString()}
                placeholder={"200"}
                onChange={handleLogoRoundingChange}
                onBlur={() => {}}
                errors={{}}
                touched={{}}
                name="logorounding"
                disabled={false}
                type="number"
              />
            </PillHorizontalContainer>
          </PillMarginContainer>
        </PillCard>
      </DesignFormContainer>
    </form>
  );
};

const Form = styled.form``;

export const DesignFormContainer = styled.div`
  padding: 15px 15px 15px 15px;
  @media screen and (max-width: ${size.mobile}) {
    padding: 85px 15px 15px 15px;
  }
`

const StyledBackButton = styled(AddGoodButton)`
  height: 40px;
  width: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  flex: none;
  background: #FFFFFF;
  border: none;
  &:hover{
    background: none;
  }
`;

const StyledDoneButton = styled(AddGoodButton)`
  display: block;
  flex: none;
  color: #000000;
  border: none;
  background: none;
  &:hover{
    background: none;
  }
`;

const HeaderTextStyled = styled(HeaderText)`
  font-weight: 500;
  color: #000000;
`

export default memo(DesignHeaderForm);
