import React, { Suspense } from 'react'

import ProfileShimmer from 'src/modules/profile/components/ProfileShimmer'

const Payments = React.lazy(() => import('src/modules/projects/containers/SettingsMainPayments'))

const SettingsMainPayments = () => {
  return (
    <Suspense fallback={<ProfileShimmer />}>
      <Payments />
    </Suspense>
  )
}


export default SettingsMainPayments