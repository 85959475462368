import React, { Suspense } from 'react'
import ProfileShimmer from 'src/modules/profile/components/ProfileShimmer'

const Contacts = React.lazy(() => import('src/modules/projects/containers/SettingsMainContacts'))

const SettingsMainContacts = () => {
  return (
    <Suspense fallback={<ProfileShimmer />}>
      <Contacts />
    </Suspense >
  )
}

export default SettingsMainContacts
