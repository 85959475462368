import { createSelector } from 'reselect'
import get from 'lodash/get'
import findIndex from 'lodash/findIndex'

const selectOrdersState = state => state.orders

export const selectOrders = createSelector(
  selectOrdersState,
  (orders) => orders.data || {}
)

export const selectColumnsOrders = createSelector(
  selectOrdersState,
  (orders) => orders.columnsOrders || []
)

export const selectOrdersPaymentsStates = createSelector(
  selectOrdersState,
  (orders) => orders.paymentStates || {}
)


const filterByActive = (state, props) => {
  return get(state, ['orders', 'data', [props.active]], {})
}


export const selectActiveOrder = createSelector(
  filterByActive,
  (order) => {
    return { details: [], notes: [], ...order }
  }
)

export const selectOrderToEdit = createSelector(
  filterByActive,
  (order) => {
    if (order && Object.keys(order).length > 0) {
      const deliveryId = order.delivery_info ? order.delivery_info.id : null
      const deliveryOptionString = order.delivery_info ? order.delivery_info.option : null
      const payment = order.payment_method.system_name
      
      return {
        id: order.id,
        email: order.contact_email,
        phone: order.contact_phone,
        contact: order.contact_name,
        comment: order.commentary,
        payment: order.payment_method,
        agree: true,
        deliveryId,
        payment,
        //optionIndex: {},
        deliveryOptionString,
      }
    } else {
      return null
    }
  }
)

export const selectPaymentMethods = createSelector(
  selectOrdersState,
  (state) => state.paymentMethods
)

export const selectOrdersCurrencies = createSelector(
  selectOrdersState,
  (state) => state.currencies
)

export const selectOrderPaymentMethod = createSelector(
  filterByActive,
  selectPaymentMethods,
  (activeOrder, paymentMethods) => {
    const activePaymentMethod = get(activeOrder, ['payment_method'])
    return get(paymentMethods, [activePaymentMethod])
  }
)

export const selectOrderCurrency = createSelector(
  filterByActive,
  selectOrdersCurrencies,
  (activeOrder, currencies) => {
    return get(currencies, [activeOrder.currency_id])
  }
)

export const selectOrderCurrencyProps = createSelector(
  (_, props) => props.order,
  selectOrdersCurrencies,
  (order, currencies) => {
    return get(currencies, [order.currency_id])
  }
)

export const selectOrderNoteLoading = createSelector(
  selectOrdersState,
  (state) => state.orderNoteLoading
)

export const selectOrderColumnIndex = createSelector(
  (_, props) => props,
  selectColumnsOrders,
  (props, state) => {
    if (!state.length) return undefined
    const { id, status } = props
    return state[status].indexOf(id)
  }
)

export const selectOrdersPositions = createSelector(
  selectOrdersState,
  (state) => state.positions
)

export const selectOrdersStatuses = createSelector(
  selectOrdersState,
  (state) => state.orderStates
)

export const selectOrderStatus = createSelector(
  (_, props) => props.active,
  selectColumnsOrders,
  (active, columns) => {
    return columns.findIndex((column) => column.indexOf(active) >= 0)
  }
)