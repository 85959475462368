import { connect, ConnectedProps } from "react-redux";
import { RootState } from "src/store/rootTypes";
import { DefinedRouterComponentProps } from "src/types";
import { withRouter } from "react-router-dom";
import { selectProjectSettingsFooterDesign, selectProjectSettingsLoading, toggleProjectDesignFooterKey, changeFooterBackgroundColor, changeFooterTextColor, triggerSetProjectDesignSettings, checkAndDisableDesignType } from "src/store/projectSettings";
import DesignFooterForm from '../components/DesignFooterForm'

const mapStateToProps = (state: RootState, props: DefinedRouterComponentProps) => ({
  footerDesign: selectProjectSettingsFooterDesign(state),
  loading: selectProjectSettingsLoading(state)
})

const mapDispatchToProps = {
  toggleProjectDesignFooterKey,
  changeFooterTextColor,
  changeFooterBackgroundColor,
  triggerSetProjectDesignSettings,
  checkAndDisableDesignType
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ConnectedDesignFooterFormProps = ConnectedProps<typeof connector> & DefinedRouterComponentProps

export default withRouter(connector(DesignFooterForm))