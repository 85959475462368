import {
  addFileToQueue,
  deleteFile,
  DigitalGoodsSettingsStateType,
  getDigitalGoodsSettings, 
  getFreeSpaceCloud, 
  removeFileFromQueue, 
  toggleFileLoading, 
  uploadFile, 
  triggerClearFileByStoreName, 
  getDigitalFileName, 
  clearDigitalFilesNames, 
  triggerDeleteDigitalFile, 
  deleteDigitalFileConfirmation,
  clearDeleteFilesIds,
  removeIdFromDeleteDigitalFileIdsList,
} from "./digitalGoodsTypes";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";

const INITIAL_STATE: DigitalGoodsSettingsStateType = {
  loading: false,
  loadFile: false,
  loadNames: false,
  digitalGoodsSettings: null,
  sendingCases: [],
  cloudInfo: null,
  fileQueue: [],
  submitFilesInfo: [],
  fileLink: [],
  digitalFilesNames:[],
  deletedFilesIds: [],
};

export default reducerWithInitialState(INITIAL_STATE)
  .cases(
    [
      getDigitalGoodsSettings.started,
    ],
    (state) => ({
      ...state,
      loading: true,
    })
  )
  .cases(
    [ getDigitalFileName.started ],
    (state) => ({
      ...state,
      loadNames: true,
    })
  )
  .case(getDigitalGoodsSettings.done, (state, action) => {
    return produce(state, (draft) => {
      draft.loading = false;
      draft.digitalGoodsSettings = action.result.digitalGoodsSettings
      draft.sendingCases = action.result.sendingCases
      return draft;
    });
  })
  .case(getFreeSpaceCloud.done, (state, action) => {
    return produce(state, (draft) => {
      draft.cloudInfo = action.result;
      return draft;
    });
  })
  .case(deleteFile.done, (state, action) => {
    return produce(state, (draft) => {
      draft.submitFilesInfo = draft.submitFilesInfo.filter(item => item.index !== action.params.payload.index);
      return draft;
    });
  })
  .case(triggerClearFileByStoreName, (state) => {
    return produce(state, (draft) => {
      draft.submitFilesInfo = [];
      return draft;
    });
  })
  .case(addFileToQueue, (state, action) => {
    return produce(state, (draft) => {
      draft.fileQueue.push(action);
      return draft;
    });
  })
  .case(removeFileFromQueue, (state, action) => {
    return produce(state, (draft) => {
      draft.fileQueue = draft.fileQueue.filter(item => item !== action);
      return draft;
    });
  })
  .case(toggleFileLoading, (state, action) => {
    return produce(state, (draft) => {
      draft.loadFile = action;
      return draft;
    });
  })
  .case(getDigitalFileName.done, (state, action) => {
    return produce(state, (draft) => {
      draft.digitalFilesNames = [...draft.digitalFilesNames, action.result];
      draft.loadNames = false;
      return draft;
    });
  })
  .case(clearDigitalFilesNames, (state, action) => {
    return produce(state, (draft) => {
      draft.digitalFilesNames = [];
      return draft;
    });
  })
    .case(clearDeleteFilesIds, (state, action) => {
    return produce(state, (draft) => {
      draft.deletedFilesIds = [];
      return draft;
    });
  })
  .case(deleteDigitalFileConfirmation.done, (state, action) => {
    return produce(state, (draft) => {
      draft.deletedFilesIds = [];
      return draft;
    });
  })
  .case(triggerDeleteDigitalFile, (state, action) => {
    return produce(state, (draft) => {
      draft.deletedFilesIds = [...draft.deletedFilesIds, action];
      draft.submitFilesInfo = draft.submitFilesInfo.filter(item => item.fileId !== action.fileId )
      return draft;
    });
  })
  .case(removeIdFromDeleteDigitalFileIdsList, (state, action) => {
    return produce(state, (draft) => {
      draft.deletedFilesIds = draft.deletedFilesIds.filter(item =>item.fileId !== action.id);
      return draft;
    });
  })
  .case(uploadFile.done, (state, action) => {
    return produce(state, (draft) => {
      if (!action.result.canceled){
      draft.fileLink = [...draft.fileLink, action.result.data.fileLink];
      draft.submitFilesInfo = [...draft.submitFilesInfo, action.result.data];
    }
      return draft;
    });
  });