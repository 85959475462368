import React, {
  useMemo,
  Fragment,
  Suspense,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import getImageUrl from "src/utils/getImageUrl";
import { TemplateGlobalStyles } from "src/elements/styles/TemplateGlobal";
import {
  selectProjectGoodsBooking,
} from "src/store/projects";
import { CartContext } from "src/templates/utils/providers/CartProvider";
import TemplateProvider from "src/providers/TemplateProvider";
import {
  getTemplateHeader,
  getTemplateFooter,
} from "src/utils/getTemplate";
import { useCartUrl } from "src/hooks/cartUrl";
import { ConnectedProjectCartCheckoutPageProps } from "./ProjectCartCheckoutPageContainer";
import { useQuery } from "src/hooks/query";
import {
  selectFavoriteProjectGoodsWithTypes,
} from "src/store/projects/types";
import { selectOrderToEditWithTypes } from "src/store/orders/types";

type Props = ConnectedProjectCartCheckoutPageProps & {};

const DefaultTemplate = React.lazy(() =>
  import("../../../../templates/default/cart/Checkout")
);

const getTemplate = (template: any, props: any) => {
  switch (template) {
    default:
      return <DefaultTemplate {...props} />;
  }
};

const ProjectCartCheckoutPage = ({
  submitCheckingBookingOrder,
  triggerGetDocumentsLinks,
  triggerGetCheckoutForm,
  getProjectGoodsByIds,
  triggerGetCheckoutPS,
  getDeliveryMethods,
  deliveryMethods,
  paymentSystems,
  documentsLinks,
  showPromocode,
  checkoutForm,
  triggerGetPS,
  submitOrder,
  getOrders,
  loading,
  project,
  history,
  match,
  orders,
}: Props) => {
  const { id } = match.params;
  const { clearCartItems, cartBookingItems }: any = useContext(CartContext);
  const { cartItems, memoLoading, shouldRedirectRef } = useCartUrl(loading);
  const query = useQuery();

  useEffect(() => {
    triggerGetDocumentsLinks({ domain: window.location.hostname })
    triggerGetCheckoutForm({ id, showPromocode: true })
  }, [triggerGetCheckoutForm, id])

  useEffect(() => {
    getOrders(id);
  }, [getOrders, id]);

  useEffect(() => {
    if (cartBookingItems) {
      submitCheckingBookingOrder({
        bookingOrder: cartBookingItems
      })
    }
  }, [cartBookingItems, submitCheckingBookingOrder])

  useEffect(() => {
    if (project.display_domain) {
      triggerGetCheckoutPS(project.display_domain)
    }
  }, [project, triggerGetCheckoutPS])

  const queryOrderId = useMemo(() => {
    return query.orderId as string;
  }, [query]);
  const goods = useSelector((state) =>
    selectFavoriteProjectGoodsWithTypes(state, id, cartItems)
  );
  const goodsBooking = useSelector(state => selectProjectGoodsBooking(state))
  const editOrder = useSelector((state) =>
    selectOrderToEditWithTypes(state, { active: queryOrderId })
  );

  const redirectSuccess = useCallback(() => {
    history.push("/project/" + id + "/cart/success");
  }, [history, id]);

  const redirectFailure = useCallback((title?: 'GoodsQuantityChanged') => {
    if (title === 'GoodsQuantityChanged') {
      history.push("/project/" + id + "/cart/failure?type=goodCount");
    } else if (title === 'InvalidPromocode') {
      history.push("/project/" + id + "/cart/failure?type=InvalidPromocode");
    } else {
      history.push("/project/" + id + "/cart/failure");
    }
  }, [history, id]);

  const location = window.location.hostname

  const onCheckout = useCallback(
    (values) => {
      shouldRedirectRef.current = false;
      submitOrder({
        ...values,
        redirectFailure,
        redirectSuccess,
        location,
        history,
        id,
        clearCart: clearCartItems,
      });
    },
    [history, submitOrder, redirectFailure, redirectSuccess, id, clearCartItems]
  );

  useEffect(() => {
    const keys = Object.keys(cartItems);
    if (keys.length > 0) {
      getProjectGoodsByIds(keys);
    }
  }, [cartItems, getProjectGoodsByIds]);

  useEffect(() => {
    if (id) {
      getDeliveryMethods(id);
    }
  }, [id, getDeliveryMethods]);

  useEffect(() => {
    if (id) {
      triggerGetPS(id)
    }
  }, [id, triggerGetPS]);

  const onItemClick = (alias: string) => {
    history.push(`/project/${id}/goods/${alias}`)
  }

  return (
    // TODO: move this to app level and pull data from selector
    <TemplateProvider currency={project.currency}>
      <Fragment>
        <Suspense fallback={""}>
          {getTemplateHeader("default", { project, cartActive: true })}
        </Suspense>
        <TemplateGlobalStyles />
        {/* TODO: add loading state */}
        <Suspense fallback={""}>
          {getTemplate("default", {
            goods,
            project,
            editOrder,
            onCheckout,
            onItemClick,
            goodsBooking,
            checkoutForm,
            isEdit: true,
            showPromocode,
            paymentSystems,
            documentsLinks,
            deliveryMethods,
            loading: memoLoading,
            orders: orders,
          })}
        </Suspense>
        <Suspense fallback={""}>
          {getTemplateFooter("default", {
            title: project.title,
            isAdult: project.isAdult || project.hasAdultGoods,
          })}
        </Suspense>
      </Fragment>
    </TemplateProvider>
  );
};

export default ProjectCartCheckoutPage;
