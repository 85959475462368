import React, { Suspense } from 'react'

const MoreServices = React.lazy(() => import('src/modules/projects/containers/SettingsMoreServices'))


const SettingsMainMoreServicesPage = () => {
    return (
        <Suspense fallback={''}>
            <MoreServices />
        </Suspense>
    )
}

export default SettingsMainMoreServicesPage