import React, { Suspense } from 'react'

const SettingsMainDigitalGoods = React.lazy(() => import('src/modules/projects/containers/SettingsMainDigitalGoods'))

const SettingsMainDigitalGoodsPage = () => {
  return (
    // TODO: add loading shimmer
    <Suspense fallback={''}>
      <SettingsMainDigitalGoods />
    </Suspense>
  )
}

export default SettingsMainDigitalGoodsPage