import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { signOut } from "src/store/auth";
import ProfileMenu from "../components/ProfileMenu";
import {
  getSettingsAbout,
  selectSettingsAbout,
  selectProjectsSelector,
  changeSettingsAbout,
} from "../../../store/projects";

import {
  selectLanguage,
  changeLanguage,
  selectAvailableLanguages,
} from "../../../store/profile";

const mapStateToProps = (state) => ({
  about: selectSettingsAbout(state),
  projects: selectProjectsSelector(state),
  language: selectLanguage(state),
  availableLanguages: selectAvailableLanguages(state),
});

const mapDispatchToProps = {
  signOut,
  getSettingsAbout,
  changeLanguage,
  changeSettingsAbout,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileMenu)
);
