const copyToClipboard = (id) => {
  const node = document.getElementById(id)
  if (document.body.createTextRange) {
    const range = document.body.createTextRange()
    range.moveToElementText(node)
    range.select()
  } else if (window.getSelection) {
    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(node)
    selection.removeAllRanges()
    selection.addRange(range)
  }
  
  document.execCommand("copy")

  if (window.getSelection) {
    if (window.getSelection().empty) {  // Chrome
      window.getSelection().empty()
    } else if (window.getSelection().removeAllRanges) {  // Firefox
      window.getSelection().removeAllRanges()
    }
  } else if (document.selection) {  // IE?
    document.selection.empty()
  }
}

export default copyToClipboard