import { connect } from 'react-redux'

import SettingsMainAnalytics from './SettingsMainAnalytics'

const mapStateToProps = () => ({})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMainAnalytics)
