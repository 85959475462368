import React, { Suspense } from 'react'
import ProfileShimmer from 'src/modules/profile/components/ProfileShimmer'

const About = React.lazy(() => import('src/modules/projects/containers/SettingsMainAbout'))

const SettingsMainAbout = () => {
  return (
    <Suspense fallback={<ProfileShimmer />}>
      <About />
    </Suspense>
  )
}

export default SettingsMainAbout
