import { connect } from 'react-redux'

import Notification from 'src/modules/notification/components/Notification'
import { selectNotification, delNotification } from 'src/store/notification'

const mapStateToProps = (state) => ({
  text: selectNotification(state)
})

const mapDispatchToProps = {
  delNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
