import React, { Suspense } from 'react'

import ProfileShimmer from 'src/modules/profile/components/ProfileShimmer'

const Notifications = React.lazy(() => import('src/modules/profile/containers/ProfileNotifications'))

const ProfileNotifications = () => {
  return (
    <Suspense fallback={<ProfileShimmer />}>{/* TODO: add shimmer для всех fallback где не стилизована загрузка */}
      <Notifications />
    </Suspense>
  )
}

export default ProfileNotifications
