import { RootState } from "../rootTypes";
import { createSelector } from "reselect";
import { UserCardsResponseType } from "./paymentTypes";
import { getCurrency } from "src/templates/utils/getCurrency";

const selectPayment = (state: RootState) => state.payment;

export const selectPaymentLoading = createSelector(
  selectPayment,
  (state) => state.loading
);

export const selectPaymentCurrencyCodes = createSelector(
  selectPayment,
  (state) => state.currencies || []
);

export const selectPaymentCurrencySymbols = createSelector(
  selectPaymentCurrencyCodes,
  (state) => {
    const mappedSymbols: Record<string, string> = {};
    state.forEach((currency) => {
      mappedSymbols[currency.key] = getCurrency(currency.value);
    });
    return mappedSymbols;
  }
);

export const selectPayments = createSelector(selectPayment, (state) => {
  const payments = state.payments.map((payment) => {
    const paymentMethod = { ...payment?.paymentMethod };
    const method = paymentMethod?.method;
    if (method) {
      if (method === "card") {
        const account = paymentMethod.account
          ? paymentMethod.account.slice(paymentMethod.account.length - 4)
          : "";
        paymentMethod.method = `${formatCardBrand(
          paymentMethod.cardBrand
        )} * ${account}`;
      }
    }
    return {
      ...payment,
      paymentMethod: paymentMethod,
    };
  });
  const _payments = payments.sort(
    (paymentA, paymentB) =>
      new Date(paymentB.paymentDate).getTime() -
      new Date(paymentA.paymentDate).getTime()
  );
  return _payments;
});

const formatCardBrand = (cardBrand: string | undefined | null) => {
  switch (cardBrand) {
    case "MASTERCARD":
      return "Mastercard";
    case "MAESTRO":
      return "Maestro";
    case "VISA":
      return "Visa";
    case "NSPK MIR":
      return "Мир";
    case null:
      return "Card";
    case undefined:
      return "Card";
    default:
      return cardBrand;
  }
};

export const selectUserCards = createSelector(selectPayment, (state) => {
  const userCards = state.userCards.map((userCard) => {
    const _account = userCard?.account;
    const _cardBrand = userCard?.cardBrand;
    return {
      ...userCard,
      account: _account ? _account.slice(_account.length - 4) : "",
      cardBrand: formatCardBrand(_cardBrand),
    };
  });
  return userCards;
});

export const selectLastPaymentInProccess = createSelector(
  selectPayment,
  (_: RootState, systemName: string) => systemName,
  (payments) => {
    console.log(payments.loading, "payments.loading");
    if (payments.loading) {
      return true;
    }
    if (!payments.lastPayment) {
      return false;
    }
    return !payments.lastPayment?.isProcessed;
  }
);

export const selectLastPayment = createSelector(
  selectPayment,
  (_: RootState, systemName: string) => systemName,
  (payments) => {
    return payments?.lastPayment;
  }
);
