import {
  ProjectSettingsCardView,
  HeaderSettings,
  FooterSettings,
  GradientBackground,
  MonotoneBackground,
} from "src/templates/utils/types/projectSettings";
import actionCreatorFactory, { Action } from "typescript-fsa";
import { BlockCustomizationSettings, ButtonBlockCustomizationSettings, CoverBlockCustomizationType, CoverBlockSettingsCustomizationType, ImageType } from "src/templates/utils/types/goodType";

export type DesignSettingsType = {
  header: HeaderSettings;
  footer: FooterSettings;
};

export type CardViewInitialValues = ProjectSettingsCardView & {
  rectangular: boolean;
};

export type DesignModeFormType =
  | "Footer"
  | "Header"
  | "Background"
  | "Block-GoodSet"
  | "Button"
  | "Cover";

export type ProjectSettingsStateType = {
  designSelection: boolean;
  designModeSelected?: DesignModeFormType;
  designCancelConfirmation: boolean;
  loading: boolean;
  cardView: Record<string, ProjectSettingsCardView>;
  header: HeaderSettings;
  initialLayoutView: {
    header: HeaderSettings;
    footer: FooterSettings;
    background: GradientBackground | MonotoneBackground;
    block: BlockCustomizationSettings | null;
    moduleButton: ButtonBlockCustomizationSettings | null;
    cover: CoverBlockCustomizationType | null;
  };
  moduleButton: ButtonBlockCustomizationSettings | null;
  footer: FooterSettings;
  background: GradientBackground | MonotoneBackground;
  block: BlockCustomizationSettings | null;
  cover: CoverBlockCustomizationType |  null;
};

const actionCreator = actionCreatorFactory();

export const toggleProjectSettingsLoading = actionCreator(
  "projectSettings/TOGGLE_LOADING"
);

// CardView settings
export const triggerGetCardViewSettings = actionCreator<string>(
  "projectSettings/TRIGGER_GET_CARD_VIEW_SETTINGS"
);

export type GetCardViewPayload = ProjectSettingsCardView & {
  projectId: string;
};

export const getCardViewSettings = actionCreator.async<
  Action<string>,
  GetCardViewPayload
>("projectSettings/GET_CARD_VIEW_SETTINGS");

type UpdateCardPayload = CardViewInitialValues & {
  projectId: string;
};

export const triggerUpdateCardViewSettings = actionCreator<UpdateCardPayload>(
  "projectSettings/TRIGGER_UPDATE_CARD_VIEW_SETTINGS"
);
export const updateCardView = actionCreator.async<
  Action<UpdateCardPayload>,
  UpdateCardPayload
>("projectSettings/UPDATE_CARD_VIEW_SETTINGS");

// ProjectDesignSettings
export const toggleDesignMode = actionCreator(
  "projectSettings/TOGGLE_DESIGN_MODE"
);

export const triggerGetProjectDesignSettings = actionCreator<string>(
  "projectSettings/TRIGGER_GET_PROJECT_DESIGN_SETTINGS"
);

export type ProjectDesignResponseType = {
  layoutView: {
    header: HeaderSettings;
    footer: FooterSettings;
    background: GradientBackground | MonotoneBackground;
  };
};

export const getProjectDesignSettings = actionCreator.async<
  Action<string>,
  ProjectDesignResponseType
>("projectSettings/GET_PROJECT_DESIGN_SETTINGS");
export const triggerSetProjectDesignSettings = actionCreator<string>(
  "projectSettings/TRIGGER_SET_PROJECT_DESIGN_SETTINGS"
);
export const setProjectDesignSettings = actionCreator.async<
  Action<string>,
  ProjectDesignResponseType
>("projectSettings/SET_PROJECT_DESIGN_SETTINGS");
export const changeProjectDesignType = actionCreator<DesignModeFormType>(
  "projectSettings/CHANGE_PROJECT_DESIGN_TYPE"
);
export const updateProjectDesignHeader = actionCreator<HeaderSettings>(
  "projectSettings/UPDATE_PROJECT_DESIGN_HEADER"
);
export const toggleProjectDesignHeaderKey = actionCreator<
  "showLogo" | "showContacts" | "showTitle" | "showDescription"
>("projectSettings/TOGGLE_HEADER_HEADER_KEY");
export const disableDesignType = actionCreator(
  "projectSettings/DISABLE_DESIGN_TYPE"
);
export const checkAndDisableDesignType = actionCreator(
  "projectSettings/CHECK_AND_DISABLE_DESIGN_TYPE"
);
export const toggleDesignConfimation = actionCreator(
  "projectSettings/TOGGLE_DESIGN_CONFIRMATION"
);
export const changeHeaderTextColor = actionCreator<string>(
  "projectSettings/CHANGE_HEADER_TEXT_COLOR"
);
export const changeHeaderBackgroundColor = actionCreator<string>(
  "projectSettings/CHANGE_HEADER_BACKGROUND_COLOR"
);
export const changeHeaderLogoRounding = actionCreator<string>(
  "projectSettings/CHANGE_HEADER_LOGO_ROUNDING"
);
export const changeDesignBackground = actionCreator<
  GradientBackground | MonotoneBackground
>("projectSettings/CHANGE_DESIGN_BACKGROUND");

export const changeFooterTextColor = actionCreator<string>(
  "projectSettings/CHANGE_FOOTER_TEXT_COLOR"
);
export const changeFooterBackgroundColor = actionCreator<string>(
  "projectSettings/CHANGE_FOOTER_BACKGROUND_COLOR"
);
export const toggleProjectDesignFooterKey = actionCreator<
  "extendToFullScreen" | "showTitle" | "showPlatformName"
>("projectSettings/TOGGLE_FOOTER_KEY");
export const setBlockDesignSettings =
  actionCreator<BlockCustomizationSettings | null>(
    "projectSettings/UPDATE_DESIGN_BLOCK"
  );
export const setInitialBlockDesignSettings =
  actionCreator<BlockCustomizationSettings | null>(
    "projectSettings/UPDATE_INITIAL_DESIGN_BLOCK"
  );

export const triggerPushBlockDesignSettings =
  actionCreator<BlockCustomizationSettings & { projectId: number }>(
    "projectSettings/TRIGGER_PUSH_BLOCK_DESIGN_SETTINGS"
  );
export const updateBlockDesignSettings = actionCreator.async<
  Action<BlockCustomizationSettings & { projectId: number }>,
  BlockCustomizationSettings & { projectId: number }
>("projectSettings/UPDATE_BLOCK_DESIGN_SETTINGS");

export const setInitialModuleButtonDesign =
  actionCreator<ButtonBlockCustomizationSettings | null>(
    "projectSettings/UPDATE_INITIAL_MODULE_BUTTON_BLOCK"
  );

export const setModuleButtonDesign =
  actionCreator<ButtonBlockCustomizationSettings | null>(
    "projectSettings/UPDATE_MODULE_BUTTON_DESIGN"
  );
export const triggerPushButtonDesignSettings =
  actionCreator<ButtonBlockCustomizationSettings & { projectId: number }>(
    "projectSettings/TRIGGER_PUSH_BUTTON_DESIGN_SETTINGS"
  );
export const updateButtonkDesignSettings = actionCreator.async<
  Action<ButtonBlockCustomizationSettings & { projectId: number }>,
  ButtonBlockCustomizationSettings & { projectId: number }
>("projectSettings/UPDATE_BUTTON_DESIGN_SETTINGS");

// GET COVER CUSTOMIZATION SETTINGS
export const triggerGetCoverCustomizationSettings =
  actionCreator<{ projectId: number }>(
    "projectSettings/TRIGGER_GET_COVER_CUSTOMIZATION_SETTINGS"
  );

export const getCoverCustomizationSettings = actionCreator.async<
  Action<{ projectId: number }>,
  CoverBlockCustomizationType
>("projectSettings/GET_COVER_CUSTOMIZATION_SETTINGS");

// UPDATE COVER CUSTOMIZATION SETTINGS
export const triggerUpdateCoverCustomizationSettings =
  actionCreator<{
    coverSettingsDesign: CoverBlockSettingsCustomizationType,
    projectId: number 
  }>(
    "projectSettings/TRIGGER_UPDATE_COVER_CUSTOMIZATION_SETTINGS"
  );

export const updateCoverCustomizationSettings = actionCreator.async<
  Action<{
      coverSettingsDesign: CoverBlockSettingsCustomizationType,
      projectId: number 
    }>,
  CoverBlockCustomizationType 
>("projectSettings/UPDATE_COVER_CUSTOMIZATION_SETTINGS");

export const toggleProjectDesignCoverKey = actionCreator<
  "extendToFullScreen" | "moveOnClick" | "showText" 
>("projectSettings/TOGGLE_COVER_DESIGN_KEY");

export const changeStringProjectDesignCoverKey = actionCreator<
  {
    field: "link" | "text" | "textAlignment" |"textSize",
    value: string
  }
>("projectSettings/CHANGE_STRING_COVER_DESIGN_KEY");

export const changeNumberProjectDesignCoverKey = actionCreator<
  {
    field: "heightPx" | "textSizePx",
    value:  number
  }
>("projectSettings/CHANGE_NUMBER_COVER_DESIGN_KEY");

export const loadNewCover = actionCreator<
  {
    imageUrl: string,
    image:  ImageType,
  }
>("projectSettings/CHANGE_COVER_IMAGE");

export const resetCover = actionCreator("projectSettings/RESET_COVER_IMAGE");