import React, { Fragment, useCallback } from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'

import { P, PrimaryBlackButton, GlasesIcon } from 'hopshopui'

import { size } from 'src/utils/styles'
import { routerProps } from 'src/types/routerProps'
import { instDelayedLogin } from 'src/utils/localStorage'
import history from 'src/utils/browserHistory'
import { useT } from 'src/hooks/useT'

const vkPress = (authVk, history) => {
  authVk(history)
}
const okPress = (authOk, history) => {
  authOk(history)
}

const byMail = (history) => {
  history.push(`/auth/email${history.location.search}`)
}

const AuthButtons = ({ authVk, loading, authOk }) => {
  const { t } = useT()
  const instPress = useCallback(() => {
    history.push('/auth/inst-start')
  }, [])
  const instDelayedPress = useCallback(() => {
    localStorage.setItem(instDelayedLogin, true)
    history.push(`/auth/email${history.location.search}`)
  }, [])
  return (
    <Fragment>
      <Container>
      <IconWrapper>
        <GlasesIcon/>
      </IconWrapper>
        <AddGoodText> {t("auth.loginOrRegistr")} </AddGoodText>
        <DescriptionGoodText> {t("auth.loginDescription")} </DescriptionGoodText>
        <Button 
          load={loading} 
          onClick={() => byMail(history)}> 
          {t("auth.byMail")} 
        </Button>
        <Button 
          load={loading} 
          onClick={() => vkPress(authVk, history)}>
          {t("auth.vk")} 
        </Button>
        <Button
          load={loading} 
          onClick={instDelayedPress}> 
          {t("auth.inst")} 
        </Button>
        <Button
          load={loading} 
          onClick={() => okPress(authOk, history)}> 
          {t("auth.ok")} 
        </Button>
      </Container>
    </Fragment>
  )
}

const Container = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: ${size.mobile}) {
    padding-top: 0;
  }
`;

const IconWrapper = styled.div`
  width:150px;
  height: 150px;
  @media screen and (max-width: ${size.mobile}) {
    height: 100px;
    svg{
      height: 120px;
    }
  }
`;

export const AddGoodText = styled(P)`
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  @media screen and (max-width: ${size.mobile}) {
    font-size: 20px;
    padding-top: 20px;
  }
`;

export const DescriptionGoodText = styled(P)`
  text-align: center;
  font-size: 14px;
  margin: 30px 0;
  @media screen and (max-width: ${size.mobile}) {
    font-size: 12px;
    margin: 20px 0;
  }
`;

const Button = styled(PrimaryBlackButton)`
  max-width: 100%;
  font-weight: 500;
  font-size: 18px;
  margin: 5px 0;
  max-height: 48px;
  @media screen and (max-width: ${size.mobile}) {
    font-size: 14px;
  }
`;

// TODO: add react router redirect
AuthButtons.propTypes = {
  ...routerProps,
  authVk: func.isRequired
}

export default AuthButtons