import { createSelector } from "reselect";
import { RootState } from "../rootTypes";

export const selectImages = (state: RootState) => state.images;

export const selectImagesQueue = createSelector(
  selectImages,
  (state) => state.data
);

export const selectImagesLoading = createSelector(
  selectImages,
  (state) => state.loading
);

export const selectDuplicateImagesLoading = createSelector(
  selectImages,
  (state) => state.duplicateImageLoading
);