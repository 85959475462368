import React from 'react'
import styled from 'styled-components'
import { Redirect } from 'react-router-dom'
import { useMobile } from 'hopshopui'

import { locationShape, match } from 'src/utils/propTypes'
import ProjectMenu from 'src/modules/projects/components/ProjectMenu'
// import ProjectSubHeader from 'src/modules/projects/components/ProjectSubHeader'

const ProjectMenuPage = ({ location, match, project, services}) => {
  const [isMobile] = useMobile()
  const { id } = match.params
  const servicesAll = services[id]
  return isMobile ? (
    <Container>
      <ProjectMenu location={location} match={match} project={project} services={servicesAll}/>
    </Container>
  ) : <Redirect to={`/project/${id}/main/about`} />
}

const Container = styled.div`
  // padding-top: 60px;
`

ProjectMenuPage.propTypes = {
  location: locationShape,
  match: match
}

export default ProjectMenuPage