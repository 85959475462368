import { fork, all } from "redux-saga/effects";

import { notificationSaga } from 'src/store/notification'
import { authSaga } from 'src/store/auth'
import { projectsSaga } from 'src/store/projects'
import { profileSaga } from 'src/store/profile'
import { goodsSaga } from 'src/store/goods'
import { ordersSaga } from 'src/store/orders'
import { deliverySaga } from 'src/store/delivery'
import { paymentSaga } from 'src/store/payment'
import { subscriptionsSaga } from 'src/store/subscriptions'
import { syncSaga } from 'src/store/sync'
import { psSaga } from './paymentSystems'
import { analyticsSaga } from './analytics'
import { pagesSaga } from './pages'
import { imagesSaga } from './images'
import { projectSettingsSaga } from './projectSettings'
import { goodsWidgetSaga } from './goodsWidget'
import { checkoutSaga } from './customCheckout'
import { promocodesSaga } from './promocodes'
import { scenariosSaga } from './scenarios'
import { digitalGoodsSaga } from "./digitalGoods";
import { marketplacesSaga } from "./marketplaces";
import { bookingSaga } from "./bookingLog";
import { feedbackSaga } from "./feedbacks";
import {htmlCodeSaga} from "./htmlCode";

export default function* rootSaga() {
  yield all(
    [
      fork(authSaga),
      fork(goodsSaga),
      fork(notificationSaga),
      fork(projectsSaga),
      fork(profileSaga),
      fork(ordersSaga),
      fork(deliverySaga),
      fork(paymentSaga),
      fork(subscriptionsSaga),
      fork(syncSaga),
      fork(psSaga),
      fork(analyticsSaga),
      fork(pagesSaga),
      fork(imagesSaga),
      fork(projectSettingsSaga),
      fork(goodsWidgetSaga),
      fork(checkoutSaga),
      fork(promocodesSaga),
      fork(scenariosSaga),
      fork(digitalGoodsSaga),
      fork(marketplacesSaga),
      fork(bookingSaga),
      fork(feedbackSaga),
      fork(htmlCodeSaga)
    ]
  )
}
