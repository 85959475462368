import { handleActions, createAction } from 'redux-actions'
import Immutable from 'seamless-immutable'

// ---
// CONSTANTS
// ---

export const HANDLE_SERVER_ERROR = 'notifications/HANDLE_SERVER_ERROR'
export const SET_NOTIFICATION_TEXT = 'notifications/SET_NOTIFICATION_TEXT'
export const DEL_NOTIFICATION = 'notifications/DEL_NOTIFICATION'
export const SHOW_NOTIFICATION = 'notifications/SHOW_NOTIFICATION'
export const SHOW_ORDER_NOTIFICATION = 'notifications/SHOW_ORDER_NOTIFICATION'
export const HIDE_ORDER_NOTIFICATION = 'notifications/HIDE_ORDER_NOTIFICATION'
export const ORDER_NOTIFICATION_ORDER_SET = 'notifictions/ORDER_NOTIFICATION_ORDER_SET'

export const SET_NOTIFICATION_ACTION_TEXT = 'notifications/SET_NOTIFICATION_ACTION_TEXT'
export const SET_NOTIFICATION_ACTION_REDIRECT_PATH = 'notifications/SET_NOTIFICATION_ACTION_REDIRECT_PATH'
export const DEL_NOTIFICATION_ACTION = 'notifications/DEL_NOTIFICATION_ACTION'
export const SHOW_NOTIFICATION_ACTION = 'notifications/SHOW_NOTIFICATION_ACTION'


// ---
// ACTION CREATORS
// ---

export const setNotificaionText = createAction(SET_NOTIFICATION_TEXT)
export const delNotification = createAction(DEL_NOTIFICATION)
export const handleServerError = createAction(HANDLE_SERVER_ERROR)
export const showNotification = createAction(SHOW_NOTIFICATION)

export const setNotificaionActionText = createAction(SET_NOTIFICATION_ACTION_TEXT)
export const setNotificaionActionRedirectPath = createAction(SET_NOTIFICATION_ACTION_REDIRECT_PATH)
export const delNotificationAction = createAction(DEL_NOTIFICATION_ACTION)
export const showNotificationAction = createAction(SHOW_NOTIFICATION_ACTION)
export const showOrderNotification = createAction(SHOW_ORDER_NOTIFICATION)
export const setOrderNotification = createAction(ORDER_NOTIFICATION_ORDER_SET)
export const hideOrderNotification = createAction(HIDE_ORDER_NOTIFICATION)
// ---
// INITIAL STATE
// ---

const initialState = Immutable({
  notification: '',
  notificationAction: '',
  notificationActionRedirectPath: '',
  showNotificationOrder: false,
  notificationOrder: {}
})

// ---
// REDUCER
// ---

export default handleActions(
  {
    [SET_NOTIFICATION_TEXT]: (state, action) =>
      Immutable.merge(state, { notification: action.payload }),
    [DEL_NOTIFICATION]: (state) => (
      Immutable.merge(state, { notification: '' })
    ),
    [SET_NOTIFICATION_ACTION_TEXT]: (state, action) =>
      Immutable.merge(state, { notificationAction: action.payload }),
    [ORDER_NOTIFICATION_ORDER_SET]: (state, action) =>
      Immutable.merge(state, { notificationOrder: action.payload, showNotificationOrder: true }),
    [HIDE_ORDER_NOTIFICATION]: (state) => state.set('showNotificationOrder', false).set('notificationOrder', {}),
    [SET_NOTIFICATION_ACTION_REDIRECT_PATH]: (state, action) =>
      Immutable.merge(state, { notificationActionRedirectPath: action.payload }),
    [DEL_NOTIFICATION_ACTION]: (state) => (
      Immutable.merge(state, { notificationAction: '', notificationActionRedirectPath: '' })
    )
  },
  initialState
)

