import { connect, ConnectedProps } from "react-redux";
import { RootState } from "src/store/rootTypes";
import { DefinedRouterComponentProps } from "src/types";
import { withRouter } from "react-router-dom";
import {
  selectDesignBackground,
  changeDesignBackground,
  checkAndDisableDesignType,
  selectProjectSettingsLoading,
  triggerSetProjectDesignSettings,
} from "src/store/projectSettings";
import DesignBackgroundForm from '../components/DesignBackgroundForm'

const mapStateToProps = (state: RootState, props: DefinedRouterComponentProps) => ({
  background: selectDesignBackground(state),
  loading: selectProjectSettingsLoading(state),
})

const mapDispatchToProps = {
  changeDesignBackground,
  checkAndDisableDesignType,
  triggerSetProjectDesignSettings,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ConnectedDesignBackgroundForm = ConnectedProps<typeof connector> & DefinedRouterComponentProps

export default withRouter(connector(DesignBackgroundForm))