import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  getPayments,
  PaymentsStateType,
  getUserCards,
  getLastPayment,
  stopPaymentBgSync,
} from "./paymentTypes";

const INITIAL_STATE: PaymentsStateType = {
  loading: false,
  payments: [],
  userCards: [],
  currencies: []
};

export default reducerWithInitialState(INITIAL_STATE)
  .cases([getPayments.started, getLastPayment.started], (state) => {
    return { ...state, loading: true };
  })
  .case(getPayments.done, (state, payload) => ({
    ...state,
    payments: payload.result.data,
    currencies: payload.result.currencies,
    loading: false,
  }))
  .case(getUserCards.started, (state) => ({ ...state, loading: true }))
  .case(getUserCards.done, (state, payload) => ({
    ...state,
    userCards: payload.result.data,
    loading: false,
  }))
  .cases([getLastPayment.done], (state, { result }) => {
    return { ...state, lastPayment: result, loading: false };
  })
  .case(stopPaymentBgSync, (state, data) => {
    return { ...state, lastPayment: data, loading: false };
  });
