import { createSelector } from 'reselect'
import nanoid from 'nanoid'
import { RootState } from '../rootTypes'
import { addDefaultImageToImagesArray } from 'src/templates/utils/defaultImage'
import { GoodFormInitialValues, GoodFormVariantType, GoodVariantMappedType, PropertySetNestedMapped, PropertySetTypeMapped } from './goodsTypes'
import { PropertySetType, PropertySetTypeNested, GoodCardType, GoodSetDataMap, ServiceGoodCalendar } from 'src/templates/utils/types/goodType'
import { displayBookingCalendarAvailableDay } from '../bookingLog'

const selectGoods = (state: RootState) => state.goods

export const selectActiveGood = createSelector(
  selectGoods,
  (_: RootState, alias: string) => alias,
  (state, id) => {
    return state.data[id] || {
      variants: []
    }
  }
)

export const selectUnsavedGoodsData = createSelector(
  selectGoods,
  (_: RootState, alias: string) => alias,
  (state, id) => {
    return state.data[id] || state.data.addgood || []
    }
)

export const selectGoodProperties = createSelector(
  selectGoods,
  (_: RootState, alias: string) => alias,
  (state, id) => {
    const properties = state.properties[id] || state.properties.addgood || {
      data: []
    }
    return properties
  }
)

export const selectGoodNestedPropertiesMap = createSelector(
  selectGoodProperties,
  (state) => {
    const mapNestedProperties: Record<string, PropertySetTypeNested & {
      setId: string | number,
      setPosition: number
    }> = {}

    Object.values(state.data).forEach((item) => {
      item.properties.forEach((nestedItem) => {
        if (nestedItem.customId) {
          mapNestedProperties[nestedItem.customId.toString()] = { ...nestedItem, setId: item.customSetId, setPosition: item.position }
        }
      })
    })

    return mapNestedProperties
  }
)

export const selectGoodPropertiesVariants = createSelector(
  selectGoodProperties,
  (state): Record<string, PropertySetNestedMapped> => {
    const variantsRecord: Record<string, PropertySetNestedMapped> = {}
    // TODO: think about that better
    const data = state.data || {}
    Object.values(data).forEach((setVariant) => {
      setVariant.properties.forEach((property) => {
        const key = property.customId || property.propertyId
        variantsRecord[key as string] = {
          ...property,
          setCustomId: setVariant.customSetId,
          canImpactPrice: setVariant.canImpactPrice,
          canImpactDescription: setVariant.canImpactDescription,
          canImpactQuantity: setVariant.canImpactQuantity,
          canImpactDigital: setVariant.canImpactDigital
        }
      })
    })
    return variantsRecord
  }
)

export const mapVariants = (variants: GoodVariantMappedType[], properties: Record<string, PropertySetNestedMapped>): GoodFormVariantType[] => {
  return variants.map((variant) => {
    const key1 = variant.property1CustomId || variant.propertyId1
    const key2 = variant.property2CustomId || variant.propertyId2
    const activeProperty1 = properties[key1 as string]
    const activeProperty2 = properties[key2 as string]

    const canImpactPrice = activeProperty1?.canImpactPrice || activeProperty2?.canImpactPrice
    const canImpactDescription = activeProperty1?.canImpactDescription || activeProperty2?.canImpactDescription
    const canImpactQuantity = activeProperty1?.canImpactQuantity || activeProperty2?.canImpactQuantity
    const canImpactDigital = activeProperty1?.canImpactDigital || activeProperty2?.canImpactDigital
    return { ...variant, canImpactPrice, canImpactDescription, canImpactQuantity, canImpactDigital, property1CustomId: key1, property2CustomId: key2 }
  })
}

export const selectEditGoodInitialValue = createSelector(
  selectActiveGood,
  selectGoodPropertiesVariants,
  (good, properties): GoodFormInitialValues => {
    let mixedImages = addDefaultImageToImagesArray(good) || [];
    mixedImages = mixedImages.map((image) => ({
      ...image,
      customId: image?.id?.toString() || nanoid()
    }))
    let modules = good?.modules || []

    modules = modules.map((module) => {
      if (module.moduleType === "GoodsSet") {
        return {
          ...module,
          categoryActive: !!module.payload.categoryId,
          customId: String(module.id) || nanoid(),
        }
      }
      return {
        ...module,
        customId: String(module.id) || nanoid(),
      }
    })

    const variants = mapVariants(good.variants || [], properties)
    const isAvailable = good.statusId ? good.statusId > 1 ? false : true : true
    let cleanSeo = true
    good.seoParameters && Object.values(good.seoParameters).forEach((value) => {
      if ((Array.isArray(value) && value.length > 0) || (typeof value === 'string' && value.length > 0)) {
        cleanSeo = false
      }
    })
    good.variants.forEach((variant) => {
      variant.seoParameters && Object.values(variant.seoParameters).forEach((value) => {
        if ((Array.isArray(value) && value.length > 0) || (typeof value === 'string' && value.length > 0)) {
          cleanSeo = false
        }
      })
    })

    console.log(cleanSeo, good, 'cleanseo')

    return { ...good, images: mixedImages, discount: '', modules, variants, isAvailable, cleanSeo }
  }
)

export const selectAddGoodPropertyInitialValues = createSelector(
  selectGoodProperties,
  (properties): PropertySetTypeMapped => {
    const lastIndex = properties.data ? Object.values(properties.data).length : 0
    return {
      customSetId: nanoid(),
      setId: null,
      goodsId: null,
      name: "",
      canImpactPrice: false,
      canImpactQuantity: false,
      canImpactDescription: false,
      canImpactDigital: false,
      position: lastIndex,
      properties: [
        {
          customId: nanoid(),
          propertyId: null,
          name: "",
          position: 0,
        },
      ],
    };
  }
)

export const selectProjectEditGood = createSelector(
  selectActiveGood,
  () => {
    // const good = JSON.parse(JSON.stringify(editGood))
    // let modules = get(good, 'modules', []) || []
    // modules = modules.map((block) => {
    //   return { ...block, customId: nanoid(), oldPrice: Number(block.oldPrice) }
    // })
    // if (modules.length) {
    //   set(good, 'modules', modules)
    // }
    // // TODO: move this to variable
    // return good && !isEmpty(good) ? good : {
    //   logo_id: '',
    //   title: '',
    //   price: 0,
    //   old_price: 0,
    //   is_adult: false,
    //   description: '',
    //   images: [],
    //   modules: []
    // }
    return {}
  }
)

export const selectGoodsLoading = createSelector(
  selectGoods,
  state => state.loading
)

export const selectGoodPropertyById = createSelector(
  selectGoods,
  (_: RootState, alias: string) => alias,
  (_: RootState, __: string, propertyId: number) => propertyId,
  (state, id, propertyId) => {
    const goodProperties = state.properties[id] || state.properties.addgood || {}
    return goodProperties.data && goodProperties.data[propertyId];
  }
)

export const selectAddGoodInitialValues = createSelector(
  selectGoods,
  (_: RootState, projectId: string) => projectId,
  (_: RootState, __: string, categoryId?: string) => categoryId,
  ((_: RootState, __: string, ___: string) => selectGoodPropertiesVariants(_, 'addgood')),
  (state, projectId, categoryId, properties): GoodFormInitialValues => {
    const goodData = state.data['addgood']
    const images = goodData?.images || []
    const modules = goodData?.modules || []
    const variants = mapVariants(goodData?.variants || [], properties)
    return {
      categoryId: categoryId ? Number(categoryId) : 0,
      quantity: null,
      discount: '',
      ...goodData,
      isAvailable: true,
      images,
      modules,
      variants,
      seoParameters: {
        title: '',
        description: '',
        keywords: '',
        head: '',
      },
      cleanSeo: true,
      goodsTypeId: 1,
    }
  }
)

export const selectGoodVariantsMapped = createSelector(
  selectGoods,
  (_: RootState, alias: string) => alias,
  selectGoodPropertiesVariants,
  (state, alias, properties): GoodFormVariantType[] => {
    const goodData = state.data[alias]
    const variants = mapVariants(goodData?.variants || [], properties)
    return variants
    // goodData.variants
  }
)

export const selectMasterGoodImpact = createSelector(
  selectGoodProperties,
  (state) => {
    let canImpactPrice = true
    let canImpactQuantity = true
    let canImpactDescription = true
    let canImpactDigital = true
    state.data && Object.values(state.data).forEach((prop) => {
      if (prop.canImpactPrice) {
        canImpactPrice = false
      }
      if (prop.canImpactQuantity) {
        canImpactQuantity = false
      }
      if (prop.canImpactDescription) {
        canImpactDescription = false
      }
      if (prop.canImpactDigital) {
        canImpactDigital = false
      }
    })
    return {
      canImpactDescription,
      canImpactPrice,
      canImpactQuantity,
      canImpactDigital
    }
  }
)

export const selectPropertiesList = createSelector(
  selectGoodProperties,
  (state): PropertySetType[] => {
    return Object.values(state.data || {}) || []
  }
)

export const selectGoodsListMap = createSelector(
  selectGoods,
  (_: RootState, ids: number[]) => ids,
  (state, ids): Record<number, GoodCardType> => {
    const returnObject: Record<number, GoodCardType> = {}
    ids.forEach((id) => {
      if (state.listData[id]) {
        returnObject[id] = state.listData[id]
      }
    })
    return returnObject
  }
)

export const selectGoodsSetDataMap = createSelector(
  selectGoods,
  (state): GoodSetDataMap => {
    return state.goodsSetDataMap || {}
  }
)

export const selectServiceGoodCalendar = createSelector(
  selectGoods,
  (state): ServiceGoodCalendar => {
    return state.serviceDayCalendar || {}
  }
)

export const selectCalendarGoodsAvailableDay = createSelector(
  selectGoods,
  (state): any => {
    const calendarAvailableDay: displayBookingCalendarAvailableDay = {availableDays: []}
    if(state?.serviceDayCalendar?.availableDays){
      state.serviceDayCalendar.availableDays.map((item: any)=>{
        calendarAvailableDay.availableDays.push(new Date(item.date))
      })
      return calendarAvailableDay
    }
  }
)