import { connect } from 'react-redux'

import SettingsMainCustomCheckout from './CustomCheckout'

const mapStateToProps = () => ({})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMainCustomCheckout)
