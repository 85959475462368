import React, { memo, Suspense } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { bool, node } from 'prop-types'
import { useSelector } from 'react-redux'
import { selectProjectsSelector } from 'src/store/projects'
import { vkLogin, instLogin, okLogin } from 'src/utils/localStorage'

const HaveProjectRoute = ({ component: Component, layout: Layout, authroized, staticAdminHeader, ...rest }) => {
  const project = useSelector(selectProjectsSelector)
  const vk = !!localStorage.getItem(vkLogin)
  const inst = !!localStorage.getItem(instLogin)
  const ok = !!localStorage.getItem(okLogin)
  if (!authroized) {
    return <Redirect to='/redirect' />
  } else if (project.length === 0) {
    if (vk || inst || ok) {
      return <Redirect to='/import' />
    } else {
      return <Redirect to='/createProject' />
    }
  }
  return <Route {...rest} render={props => {
    return (
      <Suspense fallback={<div></div>}>
        <Layout {...props} staticAdminHeader={staticAdminHeader}>
          <Component {...props} />
        </Layout>
      </Suspense>
    )
  }} />
}

HaveProjectRoute.propTypes = {
  component: node,
  layout: node,
  authroized: bool,
  staticAdminHeader: bool
}

export default memo(HaveProjectRoute)
