import React, { memo, FC, Suspense, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectOrderNotification, hideOrderNotification } from "src/store/notification";
import { OrderMessage } from "./SocketProvider";

import history from 'src/utils/browserHistory'

const OrderPopup = React.lazy(() => import("src/elements/popups/OrderPopup"));

const NotificationOrderProvider: FC = memo(() => {
  const dispatch = useDispatch()
  const notificationData: {
    showNotificationOrder: boolean;
    notificationOrder: OrderMessage;
  } = useSelector(selectOrderNotification);

  const showPopup = useMemo(() => {
    return notificationData.showNotificationOrder;
  }, [notificationData.showNotificationOrder]);

  const handleClose = useCallback(() => {
    dispatch(hideOrderNotification())
  }, [dispatch])

  const handleAction = useCallback(() => {
    dispatch(hideOrderNotification())
    console.log(notificationData.notificationOrder, 'ordert')
    const {projectId, orderIds} = notificationData.notificationOrder
    history.push(`/project/${projectId}/orders/${orderIds[0]}`)
  }, [dispatch, notificationData.notificationOrder])

  return (
    <Suspense fallback={<div> </div>}>
      {showPopup ? (
        <OrderPopup
          onClose={handleClose}
          onAction={handleAction}
        />
      ): <div></div>}
    </Suspense>
  );
});

export default NotificationOrderProvider;
