import React, { Suspense, Fragment, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { func, bool } from 'prop-types'
import { fullHeight, gradientBackground, size } from 'hopshopui'

import { VkStyledArrow, VkHeader } from 'src/elements'
import AuthGlobalStyles from 'src/modules/auth/components/AuthGlobalStyles'
import VkShimmer from 'src/modules/projects/components/VkShimmer'
import {useT} from 'src/hooks/useT'
import { history } from 'src/utils/propTypes'
import { useSignout } from 'src/hooks/signout'
import { instDelayedLogin, instLogin } from 'src/utils/localStorage'
import { CardComponentStyled } from '../auth/AuthPage'
import { HeaderWrapper } from '../payment/Payment'

const CreateProjectForm = React.lazy(() => import('src/modules/projects/components/CreateProject'))


const CreateProject = ({ 
  createProject,
  history,
  setFinishProjectData,
  delayedInstLogin,
  profileLoading,
  projectLoading,
  imagesLoading,
  triggerUploadImageAsync
}) => {
  const handleArrowClick = useSignout()
  useEffect(() => {
    const instDelayed = localStorage.getItem(instDelayedLogin)
    const inst = localStorage.getItem(instLogin)
    if (instDelayed) {
      console.log('inst delayed')
      delayedInstLogin()
    } else if (inst) {
      history.push('/import')
    }
  }, [])
  const loading = useMemo(() => {
    return profileLoading || projectLoading
  }, [profileLoading, projectLoading])
  const { t } = useT()
  return (
    <Fragment>
      <AuthGlobalStyles />
      <Background>
        <Container>
          <HeaderWrapper>
            <VkStyledArrow onClick={handleArrowClick} />
            <VkHeader>{t('project.addIconAndTitle')}</VkHeader>
          </HeaderWrapper>
          <Suspense fallback={VkShimmer}>
            <CardComponentStyled>
              <CreateProjectForm
                createProject={createProject}
                setFinishProjectData={setFinishProjectData}
                history={history}
                loading={loading}
                imagesLoading={imagesLoading}
                triggerUploadImageAsync={triggerUploadImageAsync}
              />
            </CardComponentStyled>
          </Suspense>
        </Container>
      </Background>
    </Fragment>
  )
}

CreateProject.propTypes = {
  createProject: func.isRequired,
  history: history.isRequired,
  signOut: func.isRequired,
  setFinishProjectData: func.isRequired,
  delayedInstLogin: func.isRequired,
  projectLoading: bool,
  profileLoading: bool
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  && {
    padding: 0 20px;
  }
`

const Background = styled.div`
  background-color: ${props => props.theme.colors.black};
  ${fullHeight};
`

export default CreateProject
