import actionCreatorFactory, { ActionCreator, Action } from "typescript-fsa";

const actionCreator = actionCreatorFactory();

export type AnalyticsType = {
  name: string;
  identifier?: string | null;
  content?: string | null;
};
export type GetAnalyticsDonePayload = AnalyticsType[];

export const triggerGetAnalytics = actionCreator<string>(
  "payment/TRIGGER_GET_ANALYTICS"
);
export const getAnalytics = actionCreator.async<
  Action<string>,
  GetAnalyticsDonePayload
>("payment/GET_ANALYTICS");

type ChangeAnalyticsPayloadType = {
  data: AnalyticsType[];
  id: string;
}
export const triggerChangeAnalytics = actionCreator<ChangeAnalyticsPayloadType>(
  "payment/TRIGGER_CHANGE_ANALYTICS"
);
export const changeAnalytics = actionCreator.async<
  Action<ChangeAnalyticsPayloadType>,
  null
>("payment/CHANGE_ANALYTICS");

export type AnalyticsStateType = {
  readonly loading: boolean;
  readonly analytics: Record<string, AnalyticsType>;
};

export type AnalyticsResponseType = {
  items: GetAnalyticsDonePayload;
};
