import { useContext, useEffect, useState, useMemo, useRef } from "react";
import { useQuery } from "src/hooks/query";
import history from "src/utils/browserHistory";
import { stringify, parse } from 'src/templates/utils/stringifyer'
import { CartContext } from "src/templates/utils/providers/CartProvider";

export const useCartUrl = (loading: boolean) => {
  const shouldRedirectRef = useRef(true);

  const query = useQuery();

  const [hasLoadedItemsFromUrl, setHasLoadedItemsFromUrl] = useState(false)
  const { cartItems, setCartItems }: any = useContext(CartContext)

  useEffect(() => {
    const queryItems = query.cartItems as string;
    const parsedQueryItems = queryItems ? parse(queryItems) : {};
    if (Object.keys(parsedQueryItems).length > 0 && !hasLoadedItemsFromUrl) {
      localStorage.setItem('cart', queryItems)
      setCartItems(parsedQueryItems)
      setHasLoadedItemsFromUrl(true)
    } else {
      setHasLoadedItemsFromUrl(true);
    }
  }, [query, setHasLoadedItemsFromUrl, hasLoadedItemsFromUrl, setCartItems])

  useEffect(() => {
    if (shouldRedirectRef.current) {
      const searchString = `cartItems=${stringify(cartItems)}`;
      const orderId = query.orderId
      history.push(`${history.location.pathname}?${searchString}${orderId ? `&orderId=${orderId}` : ''}`);
    }
  }, [cartItems])

  const memoLoading = useMemo(() => {
    return loading || !hasLoadedItemsFromUrl;
  }, [loading, hasLoadedItemsFromUrl]);

  return {
    cartItems,
    memoLoading,
    shouldRedirectRef
  };
};
