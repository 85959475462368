import { RootState } from "../rootTypes";
import { createSelector } from "reselect";
import produce from "immer";

const selectMarketplaces = (state: RootState) => state.marketplaces;

export const selectMarketplacesFormLoading = createSelector(
  selectMarketplaces,
  (state) => state.loading
);

export const selectMarketplacesForm = createSelector(
  selectMarketplaces,
  (state) => {
    return state.marketplaces
  }
);

export const selectDocumentsLinks = createSelector(
  selectMarketplaces,
  (state) => state.documentsLinks
);
