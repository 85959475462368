import React, { FC, memo, useMemo } from "react";
import { MenuPageType } from "../../utils/types/menuPage";
import { H2, P, usePreventDefaultCallback } from "hopshopui";
import MenuArrow from "../assets/menuArrow";
import styled, { css } from "styled-components";

type Props = Omit<MenuPageType, "hasContent" | "children"> & {
  isParent?: boolean;
  hasSubmenu?: boolean;
  onNavigate: (alias: string, typeName: string, isParent?: boolean) => void;
  pathName: string;
  editLink?: boolean;
};

const MobileMenuItem: FC<Props> = ({
  name,
  onNavigate,
  alias,
  pathName,
  hasSubmenu,
  editLink = false,
  typeName,
  isParent,
  ...rest
}) => {
  const handleClick = usePreventDefaultCallback(() => {
    onNavigate(alias, typeName, isParent);
  }, [rest, onNavigate, name, isParent]);
  const active = useMemo(() => {
    return alias === pathName;
  }, [alias, pathName]);
  return (
    <Item onClick={handleClick} bold={active} hasSubmenu={hasSubmenu} editLink={editLink}>
      {isParent ? (
        <H2>{name}</H2>
      ) : (
        <P>
          {name}{" "}
          {hasSubmenu && (
            <MenuArrowContainer>
              <MenuArrow></MenuArrow>
            </MenuArrowContainer>
          )}
        </P>
      )}
    </Item>
  );
};

const Item = styled.a<{
  editLink?: boolean;
  bold?: boolean;
  hasSubmenu?: boolean;
}>`
  font-size: ${(props) => props.theme.typo.fontSize};
  padding: 12px 20px;
  color: ${(props) => props.editLink && props.theme.colors.gradientStart};
  cursor: pointer;
  &:hover,
  &:focus {
    background: ${(props) => props.theme.colors.pillDisabled};
  }
  font-weight: ${(props) =>
    props.bold ? props.theme.typo.fontSemiBold : props.theme.typo.regular};
  ${(props) =>
    props.hasSubmenu &&
    css`
      > p {
        position: relative;
        display: inline-block;
        padding-right: 20px;
      }
    `}
`;

const MenuArrowContainer = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -22px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: auto 20px auto 0;
`;

export default memo(MobileMenuItem);
