import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getProjectList, selectProjectsLoading, selectVkProjects, importVkProject, setFinishProjectData } from 'src/store/projects'
import ImportProject from './ImportProject'
import { signOut } from 'src/store/auth'

const mapStateToProps = (state) => ({
  loading: selectProjectsLoading(state),
  projects: selectVkProjects(state)
})

const mapDispatchToProps = {
  getProjectList,
  signout: signOut,
  importVkProject,
  setFinishProjectData
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(ImportProject)
)
