import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { createProject } from 'src/store/projects'

import CreateProject from './CreateProject'

import { selectProjectsLoading, setFinishProjectData } from 'src/store/projects'
import { delayedInstLogin, selectProfileLoading } from 'src/store/profile'

import { triggerUploadImageAsync, selectImagesLoading } from 'src/store/images'

const mapStateToProps = (state) => ({
  projectLoading: selectProjectsLoading(state),
  profileLoading: selectProfileLoading(state),
  imagesLoading: selectImagesLoading(state),
})

const mapDispatchToProps = {
  createProject,
  setFinishProjectData,
  delayedInstLogin,
  triggerUploadImageAsync
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(CreateProject)
)
