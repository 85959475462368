import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { parseSearch } from 'src/utils/search'
import { useSignout } from 'src/hooks/signout'
import history from 'src/utils/browserHistory'
import { instLogin, instDelayedLogin, vkLogin } from 'src/utils/localStorage'

const AuthInstDelayedPage = () => {
  const { search } = useLocation()
  const signout = useSignout()
  useEffect(() => {
    const { error } = parseSearch(search)
    if (error) {
      signout()
      history.push('/auth')
    } else {
      localStorage.removeItem(instDelayedLogin)
      localStorage.removeItem(vkLogin)
      localStorage.removeItem(vkLogin)
      localStorage.setItem(instLogin, true)
      history.push('/import')
    }
  }, [search, signout])
  return (
    <div></div>
  )
}

export default AuthInstDelayedPage