import { number, shape, string, bool, func, object, arrayOf, objectOf, oneOfType } from 'prop-types'

// export const RouterPropTypes = shapeOf()

export const VkProjecType = shape({
  id: number,
  name: string,
  screen_name: string,
  photo_50: string,
  photo_100: string,
  photo_200: string,
  is_user_profile: bool,
  type: string,
  any_goods: bool
})

// TODO: finish history PropTypes shape
export const history = shape({
  push: func,
  goBack: func
})

export const logsShape = shape({
    projectId: number,
    bookingJournals:arrayOf({
      title: number,
      id: number,
    })
})

export const locationShape = shape({
  pathname: string
})

export const match = shape({
  isExact: bool,
  params: object,
  path: string,
  url: string
})

export const vkGood = shape({
  id: number.isRequired,
  thumbnail: string,
  // screen_name: string,
  // is_user_profile: bool,
  // any_goods: bool
})

export const authSettingsShape = shape({
  email: string,
  vkontakte_id: string,
  last_changed: string,
  providers: arrayOf(shape({
    provider: string,
    key: string
  }))
})

export const paymentShape = shape({
  service_name: string,
  project_name: string,
  payment_date: string,
  period: string,
  price: number,
  currency: string,
  payment_method: string,
  ticket_url: string
})

export const notificationShape = shape({
  id: number,
  is_enabled: bool,
  name: string
})

export const notificationChannelShape = shape({
  key: string,
  name: string,
  notifications: arrayOf(notificationShape)
})

export const projectShape = shape({
  id: number
})


// orders

export const orderDetailShape = shape({
  goods_id: oneOfType([string, number]),
  title: string,
  count: number,
  price: number
})


export const orderNotesShape = arrayOf(shape({
  description: string
}))

export const orderShape = shape({
  id: string,
  created_date: string,
  details: arrayOf(orderDetailShape),
  notes: orderNotesShape
})

export const ordersShape = objectOf(orderShape)

export const webhookShape = shape({
    http_additional_headers: shape({
      additionalProp1: string,
      additionalProp2: string,
      additionalProp3: string,
    }),
    is_active: bool,
    webhook_url: string,
    webhooks: arrayOf(string),
 });
