import { connect } from 'react-redux'

import SettingsMainDelivery from './SettingsMainDelivery'

const mapStateToProps = () => ({})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMainDelivery)
