import { connect, ConnectedProps } from "react-redux";
import { RootState } from "src/store/rootTypes";
import { DefinedRouterComponentProps } from "src/types";
import { withRouter } from "react-router-dom";
import DesignSelectionHeader from '../components/DesignSelectionHeader'
import { toggleDesignMode } from "src/store/projectSettings";

const mapStateToProps = (state: RootState, props: DefinedRouterComponentProps) => ({
})

const mapDispatchToProps = {
  toggleDesignMode
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ConnectedDesignSelectionHeaderProps = ConnectedProps<typeof connector> & DefinedRouterComponentProps

export default withRouter(connector(DesignSelectionHeader))