import React, { Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { func } from 'prop-types'

import { CheckoutStatusContainer } from 'src/elements'
import TemplateProvider from 'src/providers/TemplateProvider'
import { TemplateGlobalStyles } from 'src/elements/styles/TemplateGlobal'
import { match } from 'src/utils/propTypes'
import { selectActiveProject } from 'src/store/projects'
import { getTemplateHeader } from 'src/utils/getTemplate'

const DefaultTemplate = React.lazy(() => import('../../../../templates/default/cart/CheckoutSuccess'))

const getTemplate = (template, props) => {
  switch (template) {
    default:
      return <DefaultTemplate {...props} />
  }
}

const CheckoutSuccessPage = ({
  match,
  getProjectDetails,
}) => {
  const { id } = match
  const project = useSelector(state => selectActiveProject(state, id))
  useEffect(() => {
    getProjectDetails(project.id)
  }, [project.id, getProjectDetails])
  return (
    <TemplateProvider>
      <CheckoutStatusContainer>
        <TemplateGlobalStyles />
        <Suspense fallback={''}>
          {getTemplateHeader('default', { project, shouldDisplayHeaderMobile: true, shouldDisplaySubHeader: false, noBottomMargin: true })}
        </Suspense>
        {/* TODO: add loading state */}
        <Suspense fallback={<div></div>}>
          {getTemplate('default', { project })}
        </Suspense>
      </CheckoutStatusContainer>
    </TemplateProvider>
  )
}

CheckoutSuccessPage.propTypes = {
  match: match,
  getProjectDetails: func
}

export default CheckoutSuccessPage