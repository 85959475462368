import React from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'

import { useMobile } from 'hopshopui'

import Menu from 'src/modules/profile/containers/ProfileMenu'


const ProfileMenu = () => {
  const [isMobile] = useMobile()
  return isMobile ? (
    <Container>
      <Menu />
    </Container>
  ) : <Redirect to={'/profile/auth'} />
}

const Container = styled.div`
      `

export default ProfileMenu
