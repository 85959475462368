import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ProjectListPage from './ProjectListPage'
import { selectProjectsLoading, getProjects, selectProjectsSelector } from 'src/store/projects'

const mapStateToProps = (state) => ({
  loading: selectProjectsLoading(state),
  projects: selectProjectsSelector(state)
})

const mapDispatchToProps = {
  getProjects,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(
    ProjectListPage
  )
)
