import actionCreatorFactory, { Action } from "typescript-fsa";

const actionCreator = actionCreatorFactory();

export type GetServicesPayload = ServiceItemType[];

export type ServiceTypeResponseItem = ServicesResponseType & {
  additionalContent: string;
};

export type ServicesAllResponseType = {
  data: ServiceTypeResponseItem[];
};

export type ServicesResponseType = {
  data: ServiceTypeResponseItem[];
};

export type QuotationItemType = {
  quotationId: number;
  price: number;
  currencyId: number;
  durationInDays: number;
  trialDays: number;
  isTrialUsed: boolean;
  isHidden: boolean;
};

export type ServiceItemType = {
  serviceId: number;
  // this is main field do not use service_id
  systemName: string;
  name: string;
  description: string;
  additionalContent: {
    href: string;
    text: string;
  }[];
  quotations: QuotationItemType[];
  isPackage: boolean;
  isServiceActive: string;
};

export type SubscriptionitemType = {
  subscriptionId: number;
  quotationId: number;
  systemName: string;
  price: number;
  currencyId: number;
  period: number;
  endDate: string;
  dueDate: string;
  startDate: string;
  isActive: boolean;
  name: string;
  canChangeQuotation: boolean;
};

export type MappedSubscriptionItemType = SubscriptionitemType & {
  currencySymbol: string;
};

export type CurrencyCode = {
  key: number;
  value: string;
};

export type MappedQuotation = QuotationItemType & {
  currencySymbol: string;
  pricePerPeriod: number;
};

export const triggerGetServices = actionCreator<string>(
  "subscriptions/TRIGGER_GET_SERVICES"
);

export const getServices = actionCreator.async<Action<string>, GetServicesPayload>(
  "subscrtiptions/GET_SERVICES"
);

export const triggerGetSubscriptions = actionCreator<string>(
  "subscriptions/TRIGGER_GET_SUBSCRIPIONS"
);

export type GetSubscriptionsPayload = {
  currencyCodes: CurrencyCode[];
  subscriptions: SubscriptionitemType[];
};

export const getSubscriptions = actionCreator.async<
  Action<string>,
  GetSubscriptionsPayload
>("subscriptions/GET_SUBSCRIPTIONS", {
  skipStartedAction: true,
});

export type ActivateSubscriptionPayload = {
  projectId: string;
  quotationId: number;
  relativeUrl: string;
  systemName: string
};

export type ActivateSubscriptionResponse = {
  orderId: string;
  paymentUrl: string;
  isPaymentInProccess: string;
};

export const triggerActivateSubscription = actionCreator<
  ActivateSubscriptionPayload
>("subscriptions/TRIGGER_SUBSCRIPTION");

export const activateSubscription = actionCreator.async<
  Action<ActivateSubscriptionPayload>,
  null
>("subscriptions/ACTIVATE_SUBSCRIPTION");

export type DisableSubscriptionPayload = {
  projectId: string;
  subscriptionId: number;
};

export const triggerDisableSubscription = actionCreator<
  DisableSubscriptionPayload
>("subscriptions/TRIGGER_DISABLED_SUBSCRIPTION");

export const disableSubscription = actionCreator.async<
  Action<DisableSubscriptionPayload>,
  null
>("subscriptions/DISABLED_SUBSCRIPTION");

export type SubscriptionStateType = {
  readonly loading: boolean;
  readonly services: Record<string, ServiceItemType[]>;
  readonly currencyCodes: CurrencyCode[];
  readonly subscriptions: Record<string, SubscriptionitemType[]>;
};

export type ChangeSubscriptionQuotationPayload = {
  projectId: string;
  quotationId: number;
  subscriptionId: number;
};

export const triggerChangeSubscriptionQuotation = actionCreator<
  ChangeSubscriptionQuotationPayload
>("subscriptions/TRIGGER_CHANGE_SUBSCRIPTION_QUOTATION");

export const changeSubscriptionQuotation = actionCreator.async<
  Action<ChangeSubscriptionQuotationPayload>,
  null
>("subscriptions/CHANGE_SUBSCRIPTIONS_QUOTATION");
