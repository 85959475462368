import React, { Suspense } from 'react'

const SettingsMainCustomCheckout = React.lazy(() => import('src/modules/projects/containers/SettingsMainCustomCheckout'))

const SettingsMainCustomCheckoutPage = () => {
  return (
    // TODO: add loading shimmer
    <Suspense fallback={''}>
      <SettingsMainCustomCheckout />
    </Suspense>
  )
}

export default SettingsMainCustomCheckoutPage