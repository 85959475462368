import React, { memo, useCallback, FC, MouseEvent, Suspense } from "react";

import { InlineStyle } from "./InlineControls";
import EditorButton from "src/elements/buttons/EditorButton";
import { BlockStyle } from "./BlockControls";

type Props = {
  active: boolean;
  style: InlineStyle | BlockStyle;
  onToggle: (style: string) => void;
};

const Bold = React.lazy(() => import("src/assets/formatBold"));
const Italic = React.lazy(() => import("src/assets/formatItalic"));
const Underline = React.lazy(() => import("src/assets/formatUnderline"));
const Strikethrough = React.lazy(() =>
  import("src/assets/formatStrikeThrough")
);

const Header = React.lazy(() =>
  import("src/assets/formatHeader")
);

const InlineControl: FC<Props> = ({ onToggle, style, active }) => {
  const handleControlClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onToggle(style);
    },
    [onToggle, style]
  );

  return (
    <EditorButton onMouseDown={handleControlClick} active={active} type="button">
      <Suspense fallback={<div></div>}>
        {
          {
            BOLD: <Bold />,
            ITALIC: <Italic />,
            UNDERLINE: <Underline />,
            STRIKETHROUGH: <Strikethrough />,
            "header-two": <Header />,
          }[style]
        }
      </Suspense>
    </EditorButton>
  );
};

export default memo(InlineControl);
