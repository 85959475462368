import React from 'react'
import styled from 'styled-components'
import { newLoading, ProfileCard, size } from 'hopshopui'

const FeedbacksSettingsShimmer = () => {
  return (
    <Container>
      <FormContainer>
        <InputShimmer />
        <InputShimmer />
        <InputShimmer />
        <InputShimmer />
        <InputShimmer />
        <TextShimmer />
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  max-width: 500px;
  margin: 60px auto 0 auto;
`
const TextShimmer = styled.div`
  margin-top: 20px;
  height: 20px;
  border-radius: 10px;
  width: 80%;
  ${newLoading}
`

const InputShimmer = styled.div`
  height: 40px;
  border-radius: 10px;
  display: block;
  ${newLoading};
  margin: 20px 0;
`

const FormContainer = styled(ProfileCard)`
  padding: 20px;
  margin-top: 20px;
  @media screen and (max-width: ${size.mobile}){
    box-shadow: unset;
  }
`

export default FeedbacksSettingsShimmer