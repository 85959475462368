import find from 'lodash/find'
import qs from 'qs'

import { flattenCategories } from 'hopshopui'

import { put, takeEvery, select, delay, all, call } from 'redux-saga/effects'
import { get, post, putRequest, deleteRequest } from 'src/utils/requestSaga'
import { ContactsSocials } from 'src/utils/variables'
import { toggleProjectLoading, toggleGoodsLoading, setFinishProjectData, SUBMIT_CHECKING_BOOKING_ORDER, GET_PROJECT_LIST, GET_VK_GOODS, setVkGoods, IMPORT_VK_PROJECT, CREATE_PROJECT, GET_PROJECTS, setProjects, GET_SETTINGS_ABOUT, CHANGE_SETTINGS_ABOUT, setSettingsAbout, GET_PROJECT_DETAILS, setProjectDetails, GET_PROJECT_GOODS, setProjectGoods, setProjectGoodsBooking, GET_CATEGORIES, setCategories, GET_PROJECT_ACCESS, setProjectAccess, UPDATE_PROJECT_DOMAIN, UPDATE_PROJECT_PUBLICITY, ADD_CATEGORY, addCategorySuccess, EDIT_CATEGORY, editCategorySuccess, DELETE_CATEGORY, deleteCategorySuccess, toggleProjectSearchLoading, GET_PROJECT_CONTACTS, UPDATE_PROJECT_CONTACTS, setProjectContacts, setSortingTypes, GET_PROJECT_GOODS_BY_IDS, finishGetProjects, setVkProjectsList, getProjects, LOAD_MORE_VK_GOODS, setVkProjectType, setMoreVkGoods, LOAD_MORE_GOODS, incrementGoodsCount, addMoreGoods, resetGoodsCount, toggleMoreLoading, deleteCategories, getCategories, UPDATE_CATEGORIES, getProjectGoodsWithSearch, GET_PROJECT_GOODS_WITH_SEARCH, DELEGATE_DOMAIN } from './projectsReducer'
import { handleServerError, showNotification } from 'src/store/notification'
import { vkLogin, instLogin, instDelayedLogin, finishProjectData } from 'src/utils/localStorage'
import { selectProjectGoodsOffsetCount, selectProjectGoodsOffset, selectProjectCategoriesMap, selectProjectGoodsTotalCount } from './projectsSelector'
import history from 'src/utils/browserHistory'
import { getLSProvider } from 'src/utils/providersLocalStorage'
import { getCategoriesDiff, mapCategoriesWithPositions, filterDeletedCategories } from 'src/utils/categories'
import { NEW_CATEGORY } from 'src/constants/Categories'
import { snakeize } from 'casing'
import { formatUrl } from 'src/utils/url'
import i18n, { t } from 'src/locales/i18n'
import { camelize } from 'casing'
import { clearDeleteFilesIds, clearDigitalFilesNames } from '../digitalGoods'

// TODO: rename to getVkProject list
function* getProjectListSaga({ }) {
  const provider = getLSProvider()
  yield put(toggleProjectLoading(true))
  try {
    const params = new URLSearchParams('')
    params.set('count', 100)
    const { data: { projects } } = yield get('/projects/import/' + provider + '?' + params.toString())
    // TRIGGER CHANGE WHEN PROJECT CHANGE BY HANDS
    i18n.language = projects[0].language
    yield put(setVkProjectsList(projects))
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
}

function* getProviderGoods({ payload: { id, count, offset, screen_name } }) {
  yield put(toggleProjectLoading(true))
  try {
    const provider = getLSProvider()
    const getProjectsParams = new URLSearchParams('')
    getProjectsParams.set('count', 100)
    const { data: { projects } } = yield get('/projects/import/' + provider + '?' + getProjectsParams.toString())
    yield put(setVkProjectsList(projects))

    const activeProject = find(projects, ['id', Number(id)])
    const params = new URLSearchParams('')
    params.set('ownerId', id)
    count && params.set('count', count)
    offset && params.set('offset', offset)
    params.set('ownerType', activeProject.type)
    const { data: { items, total_count } } = yield get('/goods/import/' + provider + '?' + params.toString())
    yield put(setVkGoods({
      items,
      totalCount: total_count
    }))
    yield put(setVkProjectType(activeProject.type))
    if (total_count === 0) {
      yield put(setFinishProjectData({
        social: true,
        data: { projectId: screen_name, all: true, goods: [] }
      }))
      history.push('/payment')
    }
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
}

function* loadMoreVKGoodsSaga({ payload: { id, count, offset, type } }) {
  try {
    const params = new URLSearchParams('')
    params.set('ownerId', id)
    count && params.set('count', count)
    offset && params.set('offset', offset)
    params.set('ownerType', type)
    const provider = getLSProvider()
    const { data: { items } } = yield get('/goods/import/' + provider + '?' + params.toString())
    yield put(setMoreVkGoods(items))
  } catch (e) {
    yield put(handleServerError(e))
  }

}


function* importProjectVk({ payload: { projectId, goods, indexes, all, AgreeToAcceptCards } }) {
  let {checkingmarkets, enablemarkets, othermarkets} = JSON.parse(localStorage.getItem('get-params'))
  yield put(toggleProjectLoading(true))
  const provider = getLSProvider()
  try {
    yield post('/projects/import/' + provider, {
      project_id: projectId,
      goods_ids: goods,
      goods_indexes: indexes,
      import_all: all,
      agree_to_accept_cards: AgreeToAcceptCards,
      checking_markets: checkingmarkets && checkingmarkets.split(','),
      enable_markets: enablemarkets && enablemarkets.split(','),
      other_markets_status: othermarkets && othermarkets
    })
    yield put(showNotification({ key: 'project.successfullImport' }))
    yield put(getProjects())
    yield delay(3000)
    localStorage.removeItem(finishProjectData)
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
}

function* createProject({ payload: { title, logoId, AgreeToAcceptCards, history } }) {
  let {checkingmarkets, enablemarkets, othermarkets} = JSON.parse(localStorage.getItem('get-params'))
  
  yield put(toggleProjectLoading(true))
  try {
    const body = {
      title,
      agree_to_accept_cards: AgreeToAcceptCards,
      logo_id: logoId,
      checking_markets: checkingmarkets && checkingmarkets.split(','),
      enable_markets: enablemarkets && enablemarkets.split(','),
      other_markets_status: othermarkets && othermarkets
    }
    const { data } = yield post('/projects', body)
    yield put(setProjectDetails(data))
    history.push('/payment')
    localStorage.removeItem(finishProjectData)
    localStorage.removeItem("get-params")
  }
  catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
}

function* getProjectsSaga({ payload }) {
  yield put(toggleProjectLoading(true))
  const vk = localStorage.getItem(vkLogin) === 'true'
  try {
    const { data: { projects } } = yield get('/users/current/projects')
    
    if (projects && projects.length) {
      yield put(setProjects(projects))
      localStorage.removeItem(vkLogin)
      localStorage.removeItem(instLogin)
      localStorage.removeItem(instDelayedLogin)
    }
    else {
      const history = payload && payload.history
      if (history) {
        if (vk) {
          history.push('/import')
        } else {
          history.push('/createProject')
        }
      }
    }
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
  yield put(finishGetProjects())
}

function* getSettingsAbout({ payload: { projectID } }) {
  yield put(toggleProjectLoading(true))
  try {
    const project = yield get(`/projects/${projectID}/about`)
    yield put(setSettingsAbout({ ...project.data, projectID: projectID }))
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
}

function* changeSettingsAboutSaga({ payload }) {
  yield put(toggleProjectLoading(true))
  try {
    const { data, logoFile } = payload
    let logo = data.logo
    if (logoFile) {
      logo = logoFile
    }
    yield post(`/projects/${payload.projectID}/about`, { ...data, logo })
  }
  catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
  yield getSettingsAbout({ payload: { projectID: payload.projectID } })
  yield getProjectDetails({ payload: payload.projectID })
}

export function* getDelayedGoodsWithSearchSaga({ payload }) {
  const goodsCount = yield call(getProjectGoodsTotalCount, payload, "");
  if (goodsCount === 0) {
    yield delay(3000)
    yield getProjectGoodsWithSearch({ payload })
  }
}

function* getGoodsWithSearchSaga({ payload }) {
  const search = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  })
  const newPayload = {
    ...payload,
    categoryId: search.category,
    search: search.search,
    filterType: search.filterType
  }
  yield getProjectGoods({
    payload: newPayload
  })
}

function* getProjectDetails({ payload }) {
  yield put(toggleProjectLoading(true))
  try {
    const response = yield get(`/projects/${payload}`)
    yield put(setProjectDetails(response.data.project))
    yield put(setSortingTypes(response.data.sorting_types))
    yield put(clearDigitalFilesNames())
    yield put(clearDeleteFilesIds())
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
}

function* getProjectGoods({ payload: {
  projectId,
  categoryId,
  search,
  offset,
  filterType
} }) {
  yield put(toggleProjectLoading(true))
  yield put(toggleGoodsLoading(true))
  yield put(resetGoodsCount())
  if (search && search.length) {
    yield put(toggleProjectSearchLoading(true))
  }
  try {
    const count = yield select(selectProjectGoodsOffsetCount)
    const searchString = qs.stringify({
      categoryId,
      queriedSubstring: search,
      offset,
      count,
      filterType
    })
    const response = yield get(`/projects/${projectId}/goods?${searchString}`)
    const totalCount = yield getProjectGoodsTotalCount(projectId, search)
    yield put(setProjectGoods({ ...response.data, totalCount }))
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectSearchLoading(false))
  yield put(toggleProjectLoading(false))
  yield put(toggleGoodsLoading(false))
}

function* projectLoadMoreGoods({ payload: {
  projectId,
  categoryId,
  filterType,
  search
} }) {
  yield put(toggleMoreLoading(true))
  const count = yield select(selectProjectGoodsOffsetCount)
  const offset = yield select(selectProjectGoodsOffset)
  const totalCount = yield select(selectProjectGoodsTotalCount)

  try {
    const searchString = qs.stringify({
      categoryId,
      queriedSubstring: search,
      offset,
      count,
      filterType
    })
    const response = yield get(`/projects/${projectId}/goods?${searchString}`)
  if (response.data.total_count > totalCount ){
    yield put(addMoreGoods(response.data.goods))
    yield put(incrementGoodsCount())
  }
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleMoreLoading(false))
}

export function* getProjectGoodsTotalCount(projectId, search) {
  const response = yield get(`projects/${projectId}/goods?count=0&queriedSubstring=${search}`)
  return response.data.total_count
}

function* getProjectGoodsByIdsSaga({ payload }) {
  yield toggleProjectLoading(true)
  const filteredIds = payload.filter((id) => !isNaN(id))
  const response = yield post('/goods/getByIds', {
    goods_ids: filteredIds
  })
  yield put(setProjectGoods({ ...response.data }))
  yield toggleProjectLoading(false)
}

function* submitCheckingBookingOrder({ payload }) {
  const { bookingOrder } = payload
  const dataPost = {
      serviceOrders: bookingOrder
  }
  yield put(toggleProjectLoading(true))
  try {
    const response = yield post('/checkBookings', snakeize(dataPost));
    // действие дальше
    yield put(setProjectGoodsBooking({ ...response.data }))
  } catch (e) {
    if (payload.redirectFailure) {
      payload.redirectFailure(e.response.data.title)
    }
  }
  yield put(toggleProjectLoading(false))
}


function* getProjectContacts({ payload }) {
  yield toggleProjectLoading(true)
  try {
    const response = yield get(`/projects/${payload.projectID}/contacts`)
    yield put(setProjectContacts(camelize(response.data)))
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield toggleProjectLoading(false)
}

function* getProjectAccess({ payload }) {
  yield put(toggleProjectLoading(true))
  try {
    const response = yield get(`/projects/${payload.projectID}/access`)
    yield put(setProjectAccess(response.data))
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
}

function* delegateDomainSaga({ payload }) {
  yield put(toggleProjectLoading(true))
  try {
    yield post(`/projects/${payload.projectID}/delegate`, JSON.stringify(payload.domain))
    yield put(showNotification({
      key: 'notifications.changesSaved'
    }))
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
}

export function* updateProjectContacts({ payload }) {
  yield put(toggleProjectLoading(true))
  try {
    const contacts = yield all(payload.data.contactList.map(function (contact) {
      const _contact = { ...contact }
      ContactsSocials.forEach((social) => {
        if (_contact[social]) {
          _contact[social] = _contact[social].map((url) => formatUrl(url))
        }
      })
      if (_contact.logoFile) {
        _contact.avatar = _contact.logoFile
        delete _contact.logoFile
      }
      return _contact
    }))
    const mappedContacts = snakeize(contacts)

    yield post(`/projects/${payload.projectID}/contacts`, { contact_list: mappedContacts })
    yield put(setProjectContacts({contactList: payload.data.contactList}));
  }
  catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
}

function* getCategoriesSaga({ payload }) {
  yield put(toggleProjectLoading(true))
  try {
    const { data } = yield get(`/categories/?projectId=` + payload)
    if (data.length === 0) {
      data.push(NEW_CATEGORY)
    }
    yield put(setCategories({
      projectId: payload,
      data,
    }))
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
}

function* deleteCategoriesSaga({ payload: {
  projectId,
  oldCategoriesMap,
  categories
} }) {
  const newCategoriesMap = flattenCategories(categories)
  const deleteCategories = filterDeletedCategories(oldCategoriesMap, newCategoriesMap)
  if (deleteCategories.length > 0) {
    yield post('/categories/deleteList', {
      project_id: projectId,
      category_ids: deleteCategories
    })
  }
}

function* updateCategoriesSaga({ payload: {
  categories,
  projectId
} }) {
  yield toggleProjectLoading(true)
  try {
    const categoriesMap = yield select(selectProjectCategoriesMap)
    const mappedCategories = mapCategoriesWithPositions(categories)
    const diff = getCategoriesDiff(mappedCategories, categoriesMap)
    yield post(`/categories/modifyList`, {
      project_id: projectId,
      categories: diff
    })
    yield deleteCategoriesSaga(
      deleteCategories({
        projectId,
        oldCategoriesMap: categoriesMap,
        categories
      })
    )
    yield put(getCategories(projectId))
    // @satanworker: Think about uniform callback
    history.goBack()
    yield put(showNotification({
      key: 'notifications.changesSaved'
    }))
  } catch (e) {
    if (e && e.response && e.response.data && e.response.data.title === 'CategoryAlreadyExists') {
      yield put(showNotification(
        {
          text: t('notifications.CategoryAlreadyExistsWithName', { title: e.response.data.detail })
        }
      ))
      yield put(getCategories(projectId))
    } else {
      yield put(handleServerError(e))
    }
  }
  yield toggleMoreLoading(false)
}

function* updateProjectDomain({ payload }) {
  yield put(toggleProjectLoading(true))
  try {
    // TODO: make base_domain dynamic
    const { projectID, ...body } = payload

    const res = yield putRequest(`/projects/${payload.projectID}/domain`, snakeize(body))
    if (res) {
      yield getProjectDetails({ payload: payload.projectID })
      yield getProjectAccess({ payload: { projectID: payload.projectID } })
      yield put(showNotification({
        key: 'notifications.changesSaved'
      }))
    }
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
}

function* updateProjectPublicity({ payload }) {
  yield put(toggleProjectLoading(true))
  try {
    const res = yield putRequest(`/projects/${payload.projectID}/publicity`, payload.isOn)
    if (res) yield getProjectAccess({ payload: { projectID: payload.projectID } })
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
}

function* addCategory({ payload: { projectId, parentId, name } }) {
  yield put(toggleProjectLoading(true))
  try {
    const { data } = yield post(`/categories`, {
      project_id: projectId,
      parent_id: parentId,
      name: name
    })
    yield put(showNotification({ key: 'notifications.categoryAdded' }))
    yield put(addCategorySuccess({
      projectId,
      category: data
    }))
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
}

function* editCategory({ payload: { projectId, categoryId, name } }) {
  yield put(toggleProjectLoading(true))
  try {
    const { data } = yield putRequest(`/categories/${categoryId}`, {
      project_id: projectId,
      name
    })
    yield put(editCategorySuccess({ projectId, category: data }))
    yield put(showNotification({ key: 'notifications.categoryUpdated' }))
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
}

function* deleteCategorySaga({ payload: { projectId, categoryId } }) {
  yield put(toggleProjectLoading(true))
  try {
    yield deleteRequest(`/categories/${categoryId}?projectId=${projectId}`)
    yield put(deleteCategorySuccess({
      projectId,
      categoryId
    }))
    yield put(showNotification({ key: 'notifications.categoryDelete' }))
  } catch (e) {
    yield put(handleServerError(e))
  }
  yield put(toggleProjectLoading(false))
}

// function* deleteGoodSaga({ payload }) {
//   yield put(toggleProjectLoading(true))
//   try {
//     yield deleteRequest(`/projects/${payload.projectId}/goods/${payload.goodId}`)
//     if (payload.callback) {
//       payload.callback()
//     }
//   } catch (e) {
//     yield put(handleServerError(e))
//   }
//   yield put(toggleProjectLoading(false))
// }
// function* archiveGoodSaga({ payload }) {
//   yield put(toggleProjectLoading(true))
//   try {
//     const res = yield deleteRequest(`/goods/${payload.goodId}`)
//     return res
//   } catch (e) {
//     yield put(handleServerError(e))
//   }
//   yield put(toggleProjectLoading(false))
// }


export default function* () {
  yield takeEvery(GET_PROJECT_LIST, getProjectListSaga)
  yield takeEvery(GET_VK_GOODS, getProviderGoods)
  yield takeEvery(IMPORT_VK_PROJECT, importProjectVk)
  yield takeEvery(CREATE_PROJECT, createProject)
  yield takeEvery(GET_PROJECTS, getProjectsSaga)
  yield takeEvery(GET_SETTINGS_ABOUT, getSettingsAbout)
  yield takeEvery(CHANGE_SETTINGS_ABOUT, changeSettingsAboutSaga)
  yield takeEvery(GET_PROJECT_DETAILS, getProjectDetails)
  yield takeEvery(GET_PROJECT_GOODS, getProjectGoods)
  yield takeEvery(GET_PROJECT_CONTACTS, getProjectContacts)
  yield takeEvery(UPDATE_PROJECT_CONTACTS, updateProjectContacts)
  yield takeEvery(GET_CATEGORIES, getCategoriesSaga)
  yield takeEvery(GET_PROJECT_ACCESS, getProjectAccess)
  yield takeEvery(UPDATE_PROJECT_DOMAIN, updateProjectDomain)
  yield takeEvery(UPDATE_PROJECT_PUBLICITY, updateProjectPublicity)
  yield takeEvery(SUBMIT_CHECKING_BOOKING_ORDER, submitCheckingBookingOrder)
  yield takeEvery(ADD_CATEGORY, addCategory)
  yield takeEvery(EDIT_CATEGORY, editCategory)
  yield takeEvery(DELETE_CATEGORY, deleteCategorySaga)
  yield takeEvery(GET_PROJECT_GOODS_BY_IDS, getProjectGoodsByIdsSaga)
  yield takeEvery(LOAD_MORE_VK_GOODS, loadMoreVKGoodsSaga)
  yield takeEvery(LOAD_MORE_GOODS, projectLoadMoreGoods)
  yield takeEvery(UPDATE_CATEGORIES, updateCategoriesSaga)
  yield takeEvery(DELEGATE_DOMAIN, delegateDomainSaga)
  yield takeEvery(GET_PROJECT_GOODS_WITH_SEARCH, getGoodsWithSearchSaga)
}