import React, { PureComponent, Suspense } from 'react'
import { Route, withRouter, Redirect, Switch, Router } from 'react-router-dom'
import { func, bool, node, object } from 'prop-types'

import history from 'src/utils/browserHistory'

import MainLayout from 'src/layouts/MainLayout'
import ProfileLayout from 'src/layouts/ProfileLayout'


import AuthPage from './pages/auth/AuthPageContainer'
import AuthVkStart from './pages/auth/AuthVkStart/AuthVkStartPageContainer'
import AuthVk from 'src/pages/authVk/AuthVkContainer'
import AuthOk from 'src/pages/authOk/AuthOkContainer'
import AuthInstDelayed from 'src/pages/auth/AuthInstDelayed/AuthInstDelayedPage'
import SecuredTest from 'src/pages/securedTest/SecuredTestContainer'
import ResetPassword from 'src/pages/resetPassword/ResetPasswordContainer'
import ImportProject from 'src/pages/importProject/ImportProjectContainer'
import VkGoods from 'src/pages/vkGoods/VkGoodsContainer'
import CreateProject from 'src/pages/createProject/CreateProjectContainer'
import ProjectListPage from 'src/pages/project/projectList/ProjectListPageContainer'
import ProjectPage from './pages/project/ProjectPageContainer'
import AnonRoute from './guards/AnonGuard'

import Authorize from 'src/pages/authorize/AuthorizeContainer'

import SettingsMainAbout from './pages/project/main/about/SettingsMainAboutcontainer'
import SettingsMainContacts from './pages/project/main/contacts/SettingsMainContactsContainer'
import SettingsMainAccess from './pages/project/main/access/SettingsMainAccessContainer'
import SettingsMainPayments from './pages/project/main/payments/SettingsMainPaymentsContainer'
import SettinsMainDelivery from './pages/project/main/delivery/SettingsMainDeliveryContainer'
import SettingsMainAnalytics from './pages/project/main/analytics/SettingsMainAnalyticsContainer'
import SettingsMainPromocodes from './pages/project/main/promocodes/ProjectPromocodes'
import SettingsMainDigitalGoods from './pages/project/main/digitalGoods/ProjectDigitalGoods'
import SettingsMainCustomCheckout from './pages/project/main/customCheckout/CustomCheckoutContainer'
import SettingsMainScenarios from './pages/project/main/scenarios/SettingsScenarios'
import SettingsMainMarketplaces from './pages/project/main/marketplace/SettingsMarketplaceContainer'
import HtmlCode from './pages/project/main/htmlCode/HtmlCodeContainer'
import SettingsMoreServices from './pages/project/main/moreServices/moreServicesContainer'

import ProfileAuth from './pages/profile/auth/ProfileAuthContainer'
import ProfileMenu from './pages/profile/menu/ProfileMenuContainer'
import AuthInstStartPageContainer from './pages/auth/AuthInstStart/AuthInstStartPageContainer'
import AuthOkStartPageContainer from './pages/auth/AuthOkStart/AuthOkStartPageContainer'
import ProfilePassword from './pages/profile/password/ProfilePassword'
import ProfileEmail from './pages/profile/profileEmail/ProfileEmail'
import ProfileAddEmail from './pages/profile/profileAddEmail/ProfileAddEmail'
import ProfilePayments from './pages/profilePayments/ProfilePaymentsContainer'
import ProfileNotifications from './pages/profile/notifications/ProfileNotificationsContainer'
import SettingsMainSync from './pages/project/main/sync/SettingsMainSyncContainer'
import ProfileProjects from './pages/profile/projects/ProfileProjectsContainer'
import NotificationsChangeEmail from './pages/profile/notifications/notificationsChangeEmail/NotificationsChangeEmailContainer'
import NotificationsChangeWebhook from "./pages/profile/notifications/notificationsChangeWebhook/NotificationsChangeWebhookContainer";
import NotificationsAddEmail from './pages/profile/notifications/notificationsAddEmail/NotificationsAddEmailContainer'

import HaveProjectRoute from './guards/ProjectsGuard'
import HaveNoProjectRoute from './guards/NoProjectsGuard'

import ProjectLayout from './layouts/ProjectLayout'
import ProjectMenu from './pages/project/menu/ProjectMenuPageContainer'
import ProjectItemPage from './pages/project/projectItem/ProjectItemPage'
import ProjectAddGood from './pages/project/addGood/ProjectAddGoodContainer'
import ProjectEditGood from './pages/project/editGood/ProjectEditGoodContainer'
import ProjectContentPage from './pages/project/content/ProjectContentPage'
import ProjectContentEditPage from './pages/project/content/ProjectContentEditPage/ProjectContentEditPage'
import ProjectCart from './pages/project/cart/ProjectCartPageContainer'
import CheckoutSuccess from './pages/project/cart/checkoutSuccess/CheckoutSuccessPageContainer'
import CheckoutFailure from './pages/project/cart/checkoutFailure/CheckoutFailurePageContainer'
import ProjectCartCheckout from './pages/project/cart/projectCartCheckout/ProjectCartCheckoutPageContainer'
import ProjectFavorites from './pages/project/favorites/ProjectFavoritesPageContainer'
import PaymentPage from './pages/payment/PaymentContainer'
import ProjectGoodDesignPage from './pages/project/goodDesign/GoodDesignPage'

import EditorPage from './modules/editor/components/Editor'

import ExternalRedirect from './pages/externalRedirect/ExternalRedirect'

// orders
import OrdersPage from './pages/orders/OrdersPageContainer'
import Forbidden from './modules/errors/Forbidden'
import AuthInstContainerPage from './pages/auth/AuthInst/AuthInstContainerPage'


// categories
import EditCategories from './pages//editCategories/EditCategoriesContainer'

//pages
import EditPages from './pages/editPages/EditPages'
import ProjectIndexPage from './pages/project/index/ProjectIndexPage'

//good property
import ProgectAddGoodProperty from './pages/project/addPropertySet/ProjectAddProperty'
import ProgectEditGoodProperty from './pages/project/editPropertySet/ProjectEditProperty'

//booking components
import ProjectPackages from './pages/project/packages/ProjectPackages'
import BookingLogPage from './pages/booking/bookingLog/BookingLogPage'
import BookingLogSettingsPage from './pages/booking/bookingSettings/BookingLogSettingsPage'
import BookingAddLogSettingsPage from './pages/booking/bookingAddLogSettings/BookingAddLogSettingsPage'
import ServiceBookingPage from './pages/project/main/BookingService/ServiceBooking'
import ProfileLayoutHeader from './layouts/ProfileLayoutHeader'

//feedback components
import FeedbacksModerationPage from './pages/feedback/feefbacksModeration/FeedbacksModerationPageContainer'
import FeedbacksSettingsPage from './pages/feedback/feedbackSettings/FeedbacksSettingsPageContainer'

// TODO: move to guards folder

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => {
    return (
      <Suspense fallback={<div></div>}>
        <Layout>
          <Component {...props} />
        </Layout>
      </Suspense>
    )
  }} />
)

const AuthRoute = ({ component: Component, layout: Layout, authroized, ...rest }) => {
  if (authroized) {
    return <Route {...rest} render={props => {
      return (
        <Suspense fallback={<div></div>}>
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        </Suspense>
      )
    }} />
  } else { 
    return <Redirect to='/redirect' />
  }
}

AuthRoute.propTypes = {
  component: node,
  layout: func,
  authroized: bool
}

export class Routes extends PureComponent {
  render() {
    const { authorized } = this.props
    return (
      <Router history={history}>
        <Switch>
          <Redirect exact from='/' to='/project' />

          <AppRoute exact path='/auth/reset/:reset' layout={MainLayout} component={AuthPage} />

          <AnonRoute exact authroized={authorized} path='/auth/signin-vkontakte' layout={MainLayout} component={AuthVk} />
          <AnonRoute exact authroized={authorized} path='/auth/signin-ok' layout={MainLayout} component={AuthOk} />
          <AnonRoute exact authroized={authorized} path='/auth/signin-instagram' layout={MainLayout} component={AuthInstContainerPage} />
          <AnonRoute exact authroized={authorized} path='/auth/vk-start' layout={AuthVkStart} component={AuthVkStart} />
          <AnonRoute exact authroized={authorized} path='/auth/inst-start' layout={MainLayout} component={AuthInstStartPageContainer} />
          <AnonRoute exact authroized={authorized} path='/auth/ok-start' layout={MainLayout} component={AuthOkStartPageContainer} />
          <AnonRoute exact path='/auth/signin-vkontakte/forbidden' layout={MainLayout} component={Forbidden} />
          <AppRoute exact path='/auth/inst-delayed' layout={MainLayout} component={AuthInstDelayed} />
          {/* TODO: TEST EDITOR REMOTE THAT */}
          <AppRoute exact path='/test-editor' layout={MainLayout} component={EditorPage} />
          <AnonRoute exact authroized={authorized} path='/auth/:type' layout={MainLayout} component={AuthPage} />
          <AnonRoute exact authroized={authorized} path='/auth/email/:email' layout={MainLayout} component={AuthPage} />
          <AnonRoute exact authroized={authorized} path='/auth/email/:email/:auth' layout={MainLayout} component={AuthPage} />
          <AnonRoute exact authroized={authorized} path='/auth' layout={MainLayout} component={AuthPage} />

          <AnonRoute exact authroized={authorized} path='/auth/token/:token/:refreshToken?' layout={MainLayout} component={Authorize} />

          <AnonRoute exact authroized={authorized} path='/redirect' layout={MainLayout} component={ExternalRedirect} />

          <AppRoute exact path='/resetPassword' layout={MainLayout} component={ResetPassword} />
          
          {/* BOOKING */}
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/booking/:alias/bookingDetails/:bookingId' layout={ProfileLayoutHeader} component={BookingLogPage} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/booking/:alias' layout={ProfileLayoutHeader} component={BookingLogPage} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/booking/:alias/edit' layout={MainLayout} component={BookingLogSettingsPage} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/booking/default/addLog' layout={MainLayout} component={BookingAddLogSettingsPage} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/booking' layout={ProfileLayout} component={ServiceBookingPage} />

          {/* FEEDBACKS */}
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/feedbacks/New' layout={ProfileLayoutHeader} component={FeedbacksModerationPage} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/feedbacks/Published' layout={ProfileLayoutHeader} component={FeedbacksModerationPage} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/feedbacks/Hidden' layout={ProfileLayoutHeader} component={FeedbacksModerationPage} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/feedbacks/edit' layout={MainLayout} component={FeedbacksSettingsPage} />

          <HaveNoProjectRoute authroized={authorized} exact path='/import' layout={MainLayout} component={ImportProject} />
          <HaveNoProjectRoute authroized={authorized} exact path='/import/goods/:id' layout={MainLayout} component={VkGoods} />
          <HaveNoProjectRoute authroized={authorized} exact path='/createProject' layout={MainLayout} component={CreateProject} />
          <HaveNoProjectRoute authroized={authorized} exact path='/test' layout={MainLayout} component={SecuredTest} />
          <HaveNoProjectRoute authroized={authorized} exact path='/payment' layout={MainLayout} component={PaymentPage} />


          {/* Project */}
          <HaveProjectRoute exact authroized={authorized} path='/project/:id' layout={ProjectLayout} component={ProjectIndexPage} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/goods' layout={ProjectLayout} component={ProjectPage} />

          {/* Content */}
          <HaveProjectRoute authroized={authorized} path='/project/:id/content/edit/**' layout={MainLayout} component={ProjectContentEditPage} />
          <HaveProjectRoute authroized={authorized} path='/project/:id/content/**' layout={ProjectLayout} component={ProjectContentPage} />


          {/* TODO: check out all the redirects */}
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/goods/addgood' layout={MainLayout} component={ProjectAddGood} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/goods/addgood/addproperty' layout={MainLayout} component={ProgectAddGoodProperty} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/goods/addgood/editproperty/:propertyId' layout={MainLayout} component={ProgectEditGoodProperty} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/goods/addgood/:category' layout={MainLayout} component={ProjectAddGood} />

          
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/goods/addservice' layout={MainLayout} component={ProjectAddGood} />
          {/* ORDERS */}
          <HaveProjectRoute authroized={authorized} path='/project/:id/orders/:orderId' layout={ProjectLayout} component={OrdersPage} />
          <HaveProjectRoute authroized={authorized} path='/project/:id/orders' layout={ProjectLayout} component={OrdersPage} />
          <HaveProjectRoute authroized={authorized} path='/project/:id/design' layout={MainLayout} component={ProjectGoodDesignPage} />

          <HaveProjectRoute exact authroized={authorized} path='/project/:id/favorites' layout={ProjectLayout} component={ProjectFavorites} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/cart' layout={ProjectLayout} component={ProjectCart} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/cart/checkout' layout={ProjectLayout} component={ProjectCartCheckout} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/cart/success' layout={ProjectLayout} component={CheckoutSuccess} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/cart/failure' layout={ProjectLayout} component={CheckoutFailure} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main' layout={ProfileLayout} component={ProjectMenu} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/editCategories' layout={MainLayout} component={EditCategories} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/editPages' layout={MainLayout} component={EditPages} />
          {/* GOODS */}
          <HaveProjectRoute exact authroized={authorized} staticAdminHeader path='/project/:id/goods/:alias' layout={ProjectLayout} component={ProjectItemPage} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/:alias/edit' layout={MainLayout} component={ProjectEditGood} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/:alias/edit/addproperty' layout={MainLayout} component={ProgectAddGoodProperty} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/:alias/edit/editproperty/:propertyId' layout={MainLayout} component={ProgectEditGoodProperty} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/about' layout={ProfileLayout} component={SettingsMainAbout} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/sync' layout={ProfileLayout} component={SettingsMainSync} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/contacts' layout={ProfileLayout} component={SettingsMainContacts} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/access' layout={ProfileLayout} component={SettingsMainAccess} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/payments' layout={ProfileLayout} component={SettingsMainPayments} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/delivery' layout={ProfileLayout} component={SettinsMainDelivery} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/analytics' layout={ProfileLayout} component={SettingsMainAnalytics} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/promocodes' layout={ProfileLayout} component={SettingsMainPromocodes} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/promocodes/form' layout={ProfileLayout} component={SettingsMainPromocodes} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/checkout' layout={ProfileLayout} component={SettingsMainCustomCheckout} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/scenarios' layout={ProfileLayout} component={SettingsMainScenarios} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/packages' layout={MainLayout} component={ProjectPackages} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/digitalGoods' layout={ProfileLayout} component={SettingsMainDigitalGoods} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/marketplaces' layout={ProfileLayout} component={SettingsMainMarketplaces} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/htmlcode' layout={ProfileLayout} component={HtmlCode} />
          <HaveProjectRoute exact authroized={authorized} path='/project/:id/main/moreservices' layout={ProfileLayout} component={SettingsMoreServices} />
          <HaveProjectRoute authroized={authorized} path='/project/' layout={ProjectLayout} component={ProjectListPage} />
          

          {/* PROFILE */}
          <AuthRoute exact authroized={authorized} path='/profile' layout={ProfileLayout} component={ProfileMenu} />
          <AuthRoute exact authroized={authorized} path='/profile/auth' layout={ProfileLayout} component={ProfileAuth} />
          <AuthRoute exact authroized={authorized} path='/profile/auth/email' layout={ProfileLayout} component={ProfileAddEmail} />
          <AuthRoute exact authroized={authorized} path='/profile/auth/password' layout={ProfileLayout} component={ProfilePassword} />
          <AuthRoute exact authroized={authorized} path='/profile/auth/changeEmail' layout={ProfileLayout} component={ProfileEmail} />
          <AuthRoute exact authroized={authorized} path='/profile/payments' layout={ProfileLayout} component={ProfilePayments} />
          <AuthRoute exact authroized={authorized} path='/profile/notifications' layout={ProfileLayout} component={ProfileNotifications} />
          <AuthRoute exact authroized={authorized} path='/profile/notifications/editemail' layout={ProfileLayout} component={NotificationsChangeEmail} />
          <AuthRoute exact authroized={authorized} path='/profile/notifications/editwebhook/:webhookId' layout={ProfileLayout} component={NotificationsChangeWebhook} />
          <AuthRoute exact authroized={authorized} path='/profile/notifications/addemail' layout={ProfileLayout} component={NotificationsAddEmail} />
          <AuthRoute exact authroized={authorized} path='/profile/services' layout={ProfileLayout} component={ProfileProjects} />
          <Route render={() => <Redirect to={'/'} />} />
        </Switch>
      </Router>
    )
  }
}

AppRoute.propTypes = {
  component: func,
  layout: func
}

Routes.propTypes = {
  authorized: bool,
  history: object
}


export default withRouter(Routes)
