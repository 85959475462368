import React, { Suspense } from 'react'

const SettingsMainMarketplaces = React.lazy(() => import('src/modules/projects/containers/SettingsMainMarketplaces'))

const SettingsMainMarketplacesPage = () => {
  return (
    // TODO: add loading shimmer
    <Suspense fallback={''}>
        <SettingsMainMarketplaces/>
    </Suspense>
  )
}

export default SettingsMainMarketplacesPage