import {
  getServices,
  getSubscriptions,
  disableSubscription,
  activateSubscription,
  SubscriptionStateType,
  changeSubscriptionQuotation,
} from "./subscriptionTypes";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";

const INITIAL_STATE: SubscriptionStateType = {
  loading: false,
  services: {},
  subscriptions: {},
  currencyCodes: [],
};

export default reducerWithInitialState(INITIAL_STATE)
  .cases(
    [
      getServices.started,
      getSubscriptions.started,
      activateSubscription.started,
      disableSubscription.started,
    ],
    (state) => ({
      ...state,
      loading: true,
    })
  )
  // TODO: idk why it didn't work
  .cases([
    changeSubscriptionQuotation.started
  ], (state) => ({
    ...state,
    loading: true
  }))
  .case(getServices.done, (state, { result, params }) => {
    return produce(state, (draft) => {
      draft.services[params.payload] = result;
      draft.loading = false;
      return draft;
    });
  })
  .case(getSubscriptions.done, (state, { result, params }) => {
    return produce(state, (draft) => {
      draft.subscriptions[params.payload] = result.subscriptions;
      draft.currencyCodes = result.currencyCodes;
      draft.loading = false;
      return draft;
    });
  })
  .cases(
    [changeSubscriptionQuotation.done],
    (state, { result, params }) => {
      return produce(state, (draft) => {
        draft.loading = false;
        return draft;
      });
    }
  );
