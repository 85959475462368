import { connect, ConnectedProps } from "react-redux";
import { RootState } from "src/store/rootTypes";
import { DefinedRouterComponentProps } from "src/types";
import { withRouter } from "react-router-dom";
import DesignModeWrapper from '../components/DesignModeWrapper'
import { selectProjectSettingsDesignSelection, changeProjectDesignType, toggleDesignMode, selectProjectSettingsDesignModeSelected } from "src/store/projectSettings";

const mapStateToProps = (state: RootState, props: DefinedRouterComponentProps) => ({
  designSelection: selectProjectSettingsDesignSelection(state),
  designMode: selectProjectSettingsDesignModeSelected(state)
})

const mapDispatchToProps = {
  changeProjectDesignType,
  toggleDesignMode
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ConnectedDesignModeWrapperProps = ConnectedProps<typeof connector> & DefinedRouterComponentProps & {
  type: "Header" | "Goods" | "Block-GoodSet" | "Button" | "Cover",
  zIndex?: number;
}

export default withRouter(connector(DesignModeWrapper))