import React, {
  memo,
  useRef,
  useMemo,
  Suspense,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  P,
  P2,
  useMobile,
  HeaderText,
  IconButton,
} from "hopshopui";
import {
  getProjectDetails,
  handleImportLoading,
  selectActiveProject,
} from "src/store/projects";
import {
  triggerGetServices,
  bookingServiceKeyEnabled,
  triggerGetSubscriptions,
  selectPackageAdvancedEnabled,
} from "src/store/subscriptions";
import { useT } from "src/hooks/useT";
import { size } from "src/utils/styles";
import { Row } from "react-flexbox-grid";
import { AdminHeader } from "src/elements";
import { useSticky } from "src/hooks/sticky";
import { PartitionSettingsIcon } from "hopshopui";
import { useSelector, useDispatch } from "react-redux";
import MobileMenu from "src/elements/layout/MobileMenu";
import styled, { createGlobalStyle } from "styled-components";

const PopupImportLoading = React.lazy(() =>
  import("src/elements/popups/PopupImportLoading")
);

const SubscriptionMain = React.lazy(() =>
  import("src/modules/subscriptions/containers/SubscriptionMainContainer")
);

const LayoutGlobalStyles = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.colors.mildBackground};
  }
`;

const ProfileLayoutHeader = ({
  match,
  history,
  location,
  children,
  ...props
}) => {
  const { t } = useT();
  const [isMobile] = useMobile();
  const isProfile = useMemo(() => {
    return location.pathname.indexOf("/profile") >= 0;
  }, [location.pathname]);
  const isProject = useMemo(() => {
    return location.pathname.indexOf("/project") >= 0;
  }, [location.pathname]);
  const [isTopProfile, setTopProfile] = useState(false);
  const isFeedbacks = useMemo(() => {
    return location.pathname.indexOf("/feedbacks") >= 0;
  }, [location.pathname]);
  const [localePath, setLocalePath] = useState("");
  useEffect(() => {
    setLocalePath(location.pathname.replace(/\/\d+/g, ""));
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.indexOf("/profile") >= 0) {
      if (
        location.pathname.length <
        location.pathname.indexOf("/profile") + 9
      ) {
        setTopProfile(true);
      } else {
        setTopProfile(false);
      }
    } else if (location.pathname.indexOf("/project") >= 0) {
      setTopProfile(false);
    }
  }, [location.pathname]);

  const { id } = match.params;
  const handleBack = useCallback(() => {
    if (isProfile) {
      if (isTopProfile) {
        history.push("/project");
      } else {
        history.push("/profile");
      }
    } else if (isProject) {
      if (
        location.pathname.length <
        location.pathname.indexOf(`/project/${id}/main`) +
        `/project/${id}/main`.length +
        1
      ) {
        history.push(`/project/${id}`);
      } else {
        history.push(`/project/${id}/main`);
      }
    }
  }, [isProfile, isProject, isTopProfile, history, id, location.pathname]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getProjectDetails(id));
      dispatch(handleImportLoading({ projectId: id }));
      dispatch(triggerGetServices(id));
      dispatch(triggerGetSubscriptions(id));
    }
  }, [match.params, dispatch, id]);
  const project = useSelector((state) => selectActiveProject(state, id));
  const bookingEnabled = useSelector((state) =>
    bookingServiceKeyEnabled(state, id)
  );
  const packageAdvancedEnabled = useSelector((state) =>
    selectPackageAdvancedEnabled(state, id)
  );

  const containerRef = useRef();

  const [fixed] = useSticky({
    ref: containerRef,
    deps: [],
  });

  const packagesRedirect = useCallback((e) => {
    e.preventDefault();
    history.push(`/project/${id}/main/packages`);
  }, []);

  const showMobilePackage = useMemo(() => {
    return localePath === "/project/main" || localePath === "/project/main/";
  }, [localePath]);

  const headerTitle = useMemo(() => {
    if (localePath.includes('/project/booking')) {
      return t(`router./project/main/booking`)
    } else if (localePath.includes('/project/orders')) {
      return t(`router./project/orders`)
    } else if (localePath.includes('/project/feedbacks')) {
      return t(`router./project/feedbacks`)
    } else {
      return t(`router.${localePath}`)
    }
  }, [localePath, t]);

  const openSettings = useCallback(() => {
    history.push(`/project/${id}/feedbacks/edit`)
  }, [history, id])
  return (
    <>
      <LayoutGlobalStyles />
      <AdminHeaderContainer>
        <AdminHeader
          id={project.id}
          domain={project && project.display_domain}
          isProfile={isProfile}
          isProject={isProject}
          location={location}
        />
      </AdminHeaderContainer>
      <MobileHeaderContainer ref={containerRef}>
        <HeaderRowMobile fixed={fixed}>
          <Arrow
            onClick={handleBack}
            iconType="arrow"
            square
            mobileCornered
            transparent
          />
          <HeaderText>{headerTitle}</HeaderText>
          {isFeedbacks && <StyledPartitionSettingsIcon onClick={openSettings} />}

        </HeaderRowMobile>
      </MobileHeaderContainer>
      {isMobile && (
        <MobileMenu bookingEnabled={bookingEnabled} id={id} domain={project && project.display_domain} />
      )}
      {showMobilePackage && (
        <MobilePackageBlock onClick={packagesRedirect}>
          <PackagesTitle>{t("packages.linkTitle")}</PackagesTitle>
          <PackagesSelect>
            {packageAdvancedEnabled
              ? t("packages.advanced.header")
              : t("packages.basic.header")}
            {" ↓"}
          </PackagesSelect>
        </MobilePackageBlock>
      )}

      {children}
      <Suspense fallback={<div></div>}>
        <SubscriptionMain />
      </Suspense>
      <Suspense fallback={<div></div>}>
        <PopupImportLoading />
      </Suspense>
    </>
  );
};

const StyledPartitionSettingsIcon = styled(PartitionSettingsIcon)`
  position: absolute;
  right: 20px;
  & path{
    fill: #FFFFFF;
  }
`;

const MobilePackageBlock = styled("div")`
  display: none;
  margin: 10px 10px 20px 10px;
  background: ${(props) => props.theme.colors.light};
  border-radius: 10px;
  max-height: 60px;
  width: calc(100% - 20px);
  text-align: center;
  cursor: pointer;
  padding: 10px 15px;
  text-align: left;
  @media screen and (max-width: ${size.mobile}) {
    display: block;
  }
`;

const PackagesTitle = styled(P2)``;

const PackagesSelect = styled(P)``;

const AdminHeaderContainer = styled.div`
  @media screen and (max-width: ${size.mobile}) {
    display: none;
  }
`;

const Arrow = styled(IconButton)`
  top: -80px;
`;

const HeaderRowMobile = styled(Row)`
  && {
    display: none;
  }
  @media screen and (max-width: ${size.mobile}) {
    background: #000000;
    justify-content: center;
    position: ${(props) => (props.fixed ? "fixed" : "static")};
    left: 0;
    right: 0;
    top: 0;
    line-height: 10px;
    height: 70px;
    align-items: center;
    z-index: 7;
    && {
      display: flex;
    }
  }
`;

const MobileHeaderContainer = styled.div`
  position: relative;
  height: 70px;
  width: 100%;
  overflow: hidden;
  display: none;
  @media screen and (max-width: ${size.mobile}) {
    display: block;
  }
`;

export default memo(ProfileLayoutHeader);
