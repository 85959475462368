import { connect, ConnectedProps } from "react-redux";
import { RootState } from "src/store/rootTypes";
import { DefinedRouterComponentProps } from "src/types";
import { withRouter } from "react-router-dom";
import { selectDesignBlock, setBlockDesignSettings, checkAndDisableDesignType, triggerPushBlockDesignSettings } from "src/store/projectSettings";
import DesignGoodsSetBlockForm from '../components/DesignGoodsSetBlockForm'

const mapStateToProps = (state: RootState, props: DefinedRouterComponentProps) => ({
  blockDesign: selectDesignBlock(state),
})

const mapDispatchToProps = {
  setBlockDesignSettings,
  checkAndDisableDesignType,
  triggerPushBlockDesignSettings
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ConnectedDesignGoodsSetBlockFormProps = ConnectedProps<typeof connector> & DefinedRouterComponentProps

export default withRouter(connector(DesignGoodsSetBlockForm))