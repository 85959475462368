import React, { Suspense } from 'react'

const SettingsMainScenarios = React.lazy(() => import('src/modules/projects/containers/SettingsMainScenarios'))

const SettingsMainScenariosPage = () => {
  return (
    // TODO: add loading shimmer
    <Suspense fallback={''}>
        <SettingsMainScenarios/>
    </Suspense>
  )
}

export default SettingsMainScenariosPage