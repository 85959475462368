import { camelize } from "casing"

const getImageUrl = (defaultImage, type = 'thumb') => {
  if (defaultImage) {
    const mappedImage = camelize(defaultImage)
    const imageUrl = type === 'thumb' ? mappedImage.thumbUrl : defaultImage.fullUrl
    return imageUrl
  } else {
    return '/empty_image.png'
  }
}

export default getImageUrl