import React, { Fragment, useEffect, memo, Suspense } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { node, bool } from 'prop-types'

import { AdminHeader } from 'src/elements'
import { match, history } from 'src/utils/propTypes'
import { selectActiveProject, getProjectDetails, handleImportLoading, getProjectContacts } from 'src/store/projects'
import { triggerGetMenuPages } from 'src/store/pages'
import { triggerGetCardViewSettings, triggerGetProjectDesignSettings, selectProjectSettingsDesignSelection, selectProjectSettingsDesignModeSelected, triggerGetCoverCustomizationSettings } from 'src/store/projectSettings'
import DesignSelectionHeader from 'src/modules/design/containers/DesignSelectionHeader'
import { triggerGetScenarios } from 'src/store/scenarios'
const PopupImportLoading = React.lazy(() => import('src/elements/popups/PopupImportLoading'))

const ProjectLayout = ({ match, children, history, staticAdminHeader }) => {
  const { params: {id}, path } = match
  const project = useSelector(state => selectActiveProject(state, id))
  const designSelection = useSelector(selectProjectSettingsDesignSelection)
  const designMode = useSelector(selectProjectSettingsDesignModeSelected)
  const dispatch = useDispatch()

  useEffect(() => {
    if (id) {
      dispatch(getProjectDetails(id))
      dispatch(triggerGetMenuPages(id))
      dispatch(triggerGetCardViewSettings(id))
      dispatch(triggerGetProjectDesignSettings(id))
      dispatch(triggerGetCoverCustomizationSettings({ projectId: id }))
      dispatch(handleImportLoading({ projectId: id }))
      dispatch(getProjectContacts({ projectID: id }))
      dispatch(triggerGetScenarios({ projectId: id }))
    }
  }, [id, dispatch])

  return (
    <Fragment>
      {!designMode &&
        <Fragment>
          {designSelection ? <DesignSelectionHeader /> : <AdminHeader id={id} path={path} domain={project && project.display_domain} staticHeader={staticAdminHeader}  location={history.location}/>}
        </Fragment>
      }
      {children}
      <Suspense fallback={<div></div>}>
        <PopupImportLoading />
      </Suspense>
    </Fragment>
  )
}

ProjectLayout.propTypes = {
  match: match.isRequired,
  history: history.isRequired,
  children: node,
  staticAdminHeader: bool
}

const Container = styled.div``

export default withRouter(
  memo(ProjectLayout)
)