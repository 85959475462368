import React, {
  memo,
  FC,
  Fragment,
  ReactNode,
  useMemo,
  useCallback,
} from "react";
import styled, { createGlobalStyle } from "styled-components";
import { ConnectedDesignLayoutProps } from "../containers/DesignLayout";
import { size } from "hopshopui";
import DesignHeaderForm from "../containers/DesignHeaderForm";
import DesignFooterForm from "../containers/DesignFooterForm";
import PopupLeaveConfirmation from "src/elements/popups/PopupLeaveConfirmation";
import DesignBackgroundForm from "../containers/DesignBackgroundForm";
import DesignGoodsSetBlockForm from '../containers/DesignGoodsSetBlockForm'
import DesignButtonForm from "../containers/DesignButtonForm";
import DesignCoverForm from "../containers/DesignCoverForm";

type DesignLayoutProps = ConnectedDesignLayoutProps & {
  headerForm?: ReactNode;
  backgroundForm?: ReactNode;
  footerForm?: ReactNode;
  goodSetBlockForm?: ReactNode;
  buttonsBlockForm?:ReactNode;
  coverBlockForm?:ReactNode;
};

const DesignLayout: FC<DesignLayoutProps> = ({
  children,
  designMode,
  disableDesignType,
  checkAndDisableDesignType,
  confirmation,
  toggleDesignConfimation,
  headerForm = <DesignHeaderForm />,
  backgroundForm = <DesignBackgroundForm />,
  footerForm = <DesignFooterForm />,
  goodSetBlockForm = <DesignGoodsSetBlockForm />,
  buttonsBlockForm = <DesignButtonForm />,
  coverBlockForm = <DesignCoverForm />,
}) => {
  const designModeActive = useMemo((): boolean => {
    return !!designMode;
  }, [designMode]);

  const handleLayoutClose = useCallback(() => {
    checkAndDisableDesignType();
  }, [checkAndDisableDesignType]);

  const handleButtons = useCallback(
    (index: number) => {
      if (index === 0) {
        disableDesignType();
      }
      toggleDesignConfimation();
    },
    [disableDesignType, toggleDesignConfimation]
  );

  const handlePopupClose = useCallback(() => {
    toggleDesignConfimation();
  }, [toggleDesignConfimation]);

  return (
    <Fragment>
      <BodyDesignStyles active={designModeActive} />
      {designModeActive && <CancelWrapper onClick={handleLayoutClose} />}
      <DesignFormContainer active={designModeActive}>
        {/* TODO: change that to performant case switch */}
        {designMode === "Header" && headerForm}
        {designMode === "Background" && backgroundForm}
        {designMode === "Footer" && footerForm}
        {designMode === "Block-GoodSet" && goodSetBlockForm}
        {designMode === "Button" && buttonsBlockForm}
        {designMode === "Cover" && coverBlockForm}
      </DesignFormContainer>
      {children}
      {confirmation && (
        <PopupLeaveConfirmation
          handleButtons={handleButtons}
          handleClose={handlePopupClose}
        />
      )}
    </Fragment>
  );
};

const CancelWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
`;

const BodyDesignStyles = createGlobalStyle<{
  active?: boolean;
}>`
  body {
    padding-left: ${(props) => (props.active ? "500px" : 0)};
  }
  @media screen and (max-width: ${size.mobile}) {
    body {
      padding: 0;
    }
  }
`;

const DesignFormContainer = styled.div<{
  active?: boolean;
}>`
  position: fixed;
  left: 0;
  top: 0;
  width: 500px;
  height: 100%;
  background: ${(props) => props.theme.colors.designBackground};
  display: ${(props) => (props.active ? "block" : "none")};
  z-index: 6;
  @media screen and (max-width: ${size.mobile}) {
    position: relative;
    width: 100%;
  }
`;

export default memo(DesignLayout);
