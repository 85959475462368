import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  PaymentSystemsStateType,
  getPS,
  getCheckoutPS,
} from "./paymentSystemsTypes";
import produce from "immer";

const INITIAL_STATE: PaymentSystemsStateType = {
  loading: false,
  paymentSystems: {},
  checkoutPaymentSystems: []
};

export default reducerWithInitialState(INITIAL_STATE)
  .cases([getPS.started], (state) => {
    return { ...state, loading: true };
  })
  .case(getPS.done, (state, payload) => {
    return produce(state, (draft) => {
      draft.loading = false;
      if (payload.result) {
        const { result } = payload;
        draft.paymentSystems[result.projectId] = result.data;
      }
      return draft;
    });
  })
  .case(getCheckoutPS.done, (state, payload) => {
    return produce(state, (draft) => {
      if (payload.result) {
        draft.checkoutPaymentSystems = payload.result
      }
      return draft
    });
  });
