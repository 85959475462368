import React, { Suspense } from 'react'

const Access = React.lazy(() => import('src/modules/projects/containers/SettingsMainAccess'))

const SettingsMainAccess = () => {
  return (
    <Suspense fallback={<div>loading</div>}>{/* TODO: add shimmer для всех fallback где не стилизована загрузка */}
      <Access />
    </Suspense>
  )
}

export default SettingsMainAccess
