import React, { useCallback, useMemo } from "react";
import { Popup, P } from "hopshopui";

import { useT } from "src/hooks/useT";
import styled from "styled-components";

type Props = {
  onClose: () => any;
  onSubmit: () => void;
  currentLanguage: string;
  selectLanguage: string;
  type: TypePopup;
};

export enum TypePopup {
  client = "client",
  admin = "admin",
}

const ProfileLanguagePopup = ({
  onClose,
  onSubmit,
  currentLanguage,
  selectLanguage,
  type,
}: Props) => {
  const { t } = useT();
  const buttons = useMemo(() => [t("buttons.yes"), t("buttons.no")], [t]);
  const handleButtons = useCallback(
    (index) => {
      if (index === 0) {
        onSubmit();
      } else {
        onClose();
      }
    },
    [onSubmit, onClose]
  );

  return (
    <Popup
      title={t("popup.attention")}
      text={
        <PStyle
          dangerouslySetInnerHTML={{
            __html: t(`profile.language.popup.${type}`, {
              currentLanguage: t(
                `profile.language.prepositional.${currentLanguage}`
              ),
              selectLanguage: t(`profile.language.${selectLanguage}`),
              selectLanguagePrepositional: t(`profile.language.prepositional.${selectLanguage}`),
            }),
          }}
        ></PStyle>
      }
      buttons={buttons}
      handleButtons={handleButtons}
      onClose={onClose}
    />
  );
};

const PStyle = styled(P)`
  text-align: start;
  padding: 0 20px;
`;

export default ProfileLanguagePopup;
