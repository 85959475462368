import React, { memo, useCallback } from 'react'
import { PopupCategoriesSimple } from 'hopshopui'
import history from 'src/utils/browserHistory'
import { useParams } from 'react-router-dom'
import { useT } from 'src/hooks/useT'

const Popup = ({ onAdminClick, ...props }) => {
  const { id } = useParams()
  const { t } = useT()
  const handleEditClick = useCallback(() => {
    if (onAdminClick) {
      onAdminClick()
    }
    history.push(`/project/${id}/editCategories`)
  }, [id, onAdminClick])
  console.log(t('popup.selectAllCategory'))
  return (
    <PopupCategoriesSimple
      headerLabel={t('popup.categoriesTitle')}
      searchPlaceholder={t('popup.categoriesSearch')}
      allCategoriesLabel={props.seeAllLabel || t('popup.categoriesAll')}
      editCategoriesLabel={t('popup.categoryEditor')}
      onAdminClick={handleEditClick}
      isAdmin={true}
      {...props}
    />
  )
}

export default memo(Popup)