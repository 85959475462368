import React, { memo, Suspense } from "react";
const ProjectContent = React.lazy(() => import("src/modules/projects/containers/ProjectContent"));

const ProjectContentPage = () => {
  return <Suspense fallback={<div></div>}>
    <ProjectContent />
  </Suspense>;
};

export default memo(ProjectContentPage);
