import React from 'react'
import styled from 'styled-components'
import { IconButton, H2, size } from 'hopshopui'

export const StyledArrow = styled(IconButton)`
  margin: 30px;
  align-self: center;
  @media screen and (max-width: ${size.mobile}) {
    margin: 0;
  }
`

// TODO: remove this
export const VkStyledArrow = (props) => {
  return <StyledArrow iconType='arrow' square mobileCornered {...props} />
}

export const VkHeader = styled(H2)`
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  color: #ffff;
  @media screen and (max-width: ${size.mobile}) {
    font-weight: 500;
    font-size: 16px;
    margin: 20px 0;
  }
`

export const VkH1Container = styled.div`
  display: flex;
  padding-top: 80px;
  width: 500px;
  align-items: center;
  @media screen and (max-width: ${size.mobile}) {
    width: unset;
  }
`