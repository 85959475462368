import { createGlobalStyle } from 'styled-components'
import { gradientBackground } from 'hopshopui'

export default createGlobalStyle`
  /* body {
    ${gradientBackground};
  } */
  html, body, #root, .root-child {
    height: 100%;
  }
`