// Filter 2 arrays by ids to find remaining from old

export function filterByOld<T extends {
  id?: number | string
}>(oldItems: T[], newItems: T[]): (number | string)[] {
  const oldIds: (string)[] = oldItems
  .filter((id) => !!id)
  .map((item) => item.id as string)

  const newIds = newItems
  .filter(id => !!id)
  .map((item) => item.id)

  return oldIds.filter((id) => newIds.indexOf(id) == -1)
}