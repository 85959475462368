import React, { FC } from 'react'
import { useCart } from '../hooks/useCart'

export const CartContext = React.createContext<Partial<ReturnType<typeof useCart>>>({})

export const CartProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const props = useCart()
  return (
    <CartContext.Provider value={props}>
      {children}
    </CartContext.Provider>
  )
}

export default CartProvider