import React, {
  memo,
  useMemo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  size,
  Header,
  LogIcon,
  BellIcon,
  useMobile,
  HeaderText,
  ReviewsIcon,
  AccountIcon,
  HoverTooltip,
  IconsContainer,
  SettingsMenuIcon,
} from "hopshopui";
import { useT } from "src/hooks/useT";
import MobileMenu from "./MobileMenu";
import { string, bool } from "prop-types";
import HeaderDomain from "./HeaderDomain";
import { NavLink } from "react-router-dom";
import history from "src/utils/browserHistory";
import { Row, Grid } from "react-flexbox-grid";
import styled, { css } from "styled-components";
import { paymentAgree } from "src/utils/localStorage";
import { getProfileData, selectProfileEmail } from "src/store/profile";
import NoEmailPopup from "src/modules/projects/components/SettingsMainPaymentsPopupEmail";
import {
  triggerGetPS,
  selectRequireUserAction,
} from "src/store/paymentSystems";
import {
  showNotificationAction,
} from "src/store/notification";
import { useSticky } from "src/hooks/sticky";
import { useRef } from "react";
import {
  bookingServiceKeyEnabled,
  triggerGetSubscriptions,
} from "src/store/subscriptions";

const handleSelectStyle = (name) => {
  switch (name) {
    case "feedbacks":
      return css`
              background: radial-gradient(52.5% 52.5% at 50% 82.5%, rgba(255, 226, 137, 0.5) 0%, rgba(255, 226, 137, 0) 100%);
              border: 1px solid rgba(255, 255, 255, 0.15);
            `;
    case "options":
      return css`
              background: radial-gradient(52.5% 52.5% at 50% 82.5%, rgba(45, 198, 219, 0.5) 0%, rgba(45, 198, 219, 0) 100%);
              border: 1px solid rgba(255, 255, 255, 0.15);
            `;
    case "account":
      return css`
              background: radial-gradient(52.5% 52.5% at 50% 82.5%, rgba(45, 198, 219, 0.5) 0%, rgba(45, 198, 219, 0) 100%);
              border: 1px solid rgba(255, 255, 255, 0.15);
            `;
    case "booking":
      return css`
              background: radial-gradient(52.5% 52.5% at 50% 82.5%, rgba(255, 226, 137, 0.5) 0%, rgba(255, 226, 137, 0) 100%);
              border: 1px solid rgba(255, 255, 255, 0.15);
            `;
    case "home":
      return css`
              background: radial-gradient(52.5% 52.5% at 50% 82.5%, rgba(93, 95, 239, 0.5) 0%, rgba(93, 95, 239, 0) 100%);
              border: 1px solid rgba(255, 255, 255, 0.15);
            `;
    default:
      return css`
              background: radial-gradient(52.5% 52.5% at 50% 82.5%, rgba(255, 64, 129, 0.5) 0%, rgba(255, 64, 129, 0) 100%);
              border: 1px solid rgba(255, 255, 255, 0.15);
            `;
  }
};

// TODO: move that
const AdminHeader = ({ domain, id, isProfile, isProject, staticHeader, path, location }) => {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const [isOrders, setOrders] = useState(history.location.pathname.indexOf('/orders') >= 0)

  const [showEmailPopup, setEmailPopup] = useState(false);
  const [isActiveLink, setIsActiveLink] = useState('');
  const ordersRef = useRef(null);

  useEffect(() => {
    if (!ordersRef.current) return
    Array.from(ordersRef.current.children).forEach(el => {
      const temp = Array.from(el.classList).find(item => item === 'active')
      if (temp) {
        setIsActiveLink(el.name)
      }
    })
  }, [path])

  const payment = useMemo(() => localStorage.getItem(paymentAgree), []);

  useEffect(() => {
    dispatch(getProfileData());
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(triggerGetPS(id));
      dispatch(triggerGetSubscriptions(id));
    }
  }, [id, dispatch]);

  const email = useSelector(selectProfileEmail);
  const requireUserAction = useSelector((state) =>
    selectRequireUserAction(state, id)
  );
  const bookingEnabled = useSelector((state) =>
    bookingServiceKeyEnabled(state, id)
  );

  const [localePath, setLocalePath] = useState("");
  useEffect(() => {
    setLocalePath(location.pathname.replace(/\/\d+/, ""));
  }, [location.pathname]);
  const { t } = useT();

  useEffect(() => {
    if (requireUserAction) {
      dispatch(
        showNotificationAction({
          text: t("notifications.needPaymentAction"),
          path: `/project/${id}/main/payments`,
        })
      );
    }
  }, [requireUserAction, dispatch, id, t]);

  useEffect(() => {
    if (payment === "yes" && !email && email !== undefined) {
      setEmailPopup(true);
    }
  }, [payment, email]);

  const handleEmailPopupClose = useCallback(() => {
    localStorage.removeItem(paymentAgree);
    setEmailPopup(false);
  }, [setEmailPopup]);
  const handleEmailPopupSubmit = useCallback(() => {
    localStorage.removeItem(paymentAgree);
    history.push("/profile/auth/email");
  }, []);

  const [isMobile] = useMobile();

  const onClose = useCallback((e) => {
    e.preventDefault();
  }, []);

  // don't forget that admin header can be static `staticHeader` in that case it should not take effect
  const [fixed] = useSticky({
    ref: containerRef,
    deps: [],
  });

  const headerTitle = useMemo(() => {
    if (localePath.includes('/project/booking')) {
      setIsActiveLink('booking')
      if (location.state && location.state.modalOpen) {
        return t(`routeBookingDate`)
      } else {
        return t(`router./project/main/booking`)
      }
    } else if (localePath.includes('/project/orders')) {
      return t(`router./project/orders`)
    } else if (localePath.includes('/project/feedbacks')) {
      setIsActiveLink('feedbacks')
      return t(`router./project/feedbacks`)
    } else if (localePath.includes('/project/main/promocodes')) {
      return t(`router./project/main/promocodes`)
    } else {
      return t(`router.${localePath}`)
    }
  }, [localePath, t]);

  if (path === '/project/:id/goods/:alias' && isMobile)
    return (
      <div ref={containerRef}></div>
    )

  return (
    <Fragment>
      <HeaderContainer ref={containerRef}>
        <StyledHeader staticHeader={staticHeader} fixed={fixed}>
          <StyledGrid>
            <HeaderRow>
              <HeaderDomain
                t={t}
                id={id}
                domain={domain}
                isOrders={isOrders}
                isProfile={isProfile}
                isProject={isProject}
                setOrders={setOrders}
                setIsActiveLink={setIsActiveLink}
              />
              {(isProfile || isProject || isOrders)
                && <HeaderText>{headerTitle}</HeaderText>}

              {id && <Icons ref={ordersRef}>
                {bookingEnabled && (
                  <HoverTooltip content={t("router./project/booking")}>
                    <CustomLink to={id && `/project/${id}/booking/default/`} name="booking" active={isActiveLink === 'booking'}>
                      <LogIcon fill={isActiveLink === 'booking' && '#FFE289'} />
                    </CustomLink>
                  </HoverTooltip>
                )}

                <HoverTooltip content={t("router./project/orders")}>
                  <CustomLink to={id && `/project/${id}/orders`} name="orders" active={isActiveLink === 'orders'}>
                    <BellIcon fill={isActiveLink === 'orders' && '#FF4081'} />
                  </CustomLink>
                </HoverTooltip>

                <HoverTooltip content={t("router./project/feedbacks")}>
                  <CustomLink to={id && `/project/${id}/feedbacks/Published`} name="feedbacks" active={isActiveLink === 'feedbacks'}>
                    <ReviewsIcon fill={isActiveLink === 'feedbacks' && '#FFE289'} />
                  </CustomLink>
                </HoverTooltip>

                <HoverTooltip content={t("project.settings")}>
                  <CustomLink to={id && `/project/${id}/main/about`} name="options" active={isActiveLink === 'options'}>
                    <SettingsMenuIcon fill={isActiveLink === 'options' && '#2DC6DB'} />
                  </CustomLink>
                </HoverTooltip>

                <HoverTooltip content={t("project.menu.profileSettings")}>
                  <CustomLink to={isMobile ? `/profile` : `/profile/auth`} name="account" active={isActiveLink === 'account'}>
                    <AccountIcon fill={isActiveLink === 'account' && '#2DC6DB'} />
                  </CustomLink>
                </HoverTooltip>
              </Icons>
              }

            </HeaderRow>
          </StyledGrid>
        </StyledHeader>
        {isMobile && (
          <MobileMenu bookingEnabled={bookingEnabled} id={id} onClose={onClose} domain={domain} />
        )}
      </HeaderContainer>
      {showEmailPopup && (
        <NoEmailPopup
          onClose={handleEmailPopupClose}
          onSubmit={handleEmailPopupSubmit}
        />
      )}
    </Fragment>
  );
};

AdminHeader.propTypes = {
  id: string,
  domain: string,
  isProfile: bool,
  isProject: bool,
  staticHeader: bool,
};

const CustomLink = styled(NavLink)`
  width: 50px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  &:hover{
    background-color: rgba(255, 255, 255, 0.1);
  }
  ${props => props.active && handleSelectStyle(props.name)};
`

const HeaderContainer = styled.div`
  position: relative;
  z-index: 5;
  height: 70px;
  @media screen and (max-width: ${size.mobile}) {
    z-index: auto;
    position: relative;
  }
`;

const StyledHeader = styled(Header)`
  @media screen and (max-width: ${size.mobile}) {
    position: ${(props) =>
    props.staticHeader ? "absolute" : props.fixed ? "fixed" : "static"};
    top: 0;
    z-index: 5;
  }
`;

const HeaderRow = styled(Row)`
  align-items: center;
  && {
    width: 100%;
    padding: 0 0 0 10px;
  }
`;

const StyledGrid = styled(Grid)`
  padding-right: 0;
  @media screen and (max-width: 960px) {
    max-width: 100%;
  }
  @media screen and (max-width: ${size.mobile}) {
    width: 100%;
  }
`;

const Icons = styled(IconsContainer)`
  margin-left: auto;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${size.mobile}) {
    display: none;
  }
`;

export default memo(AdminHeader);
