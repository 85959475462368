import { createAction } from "redux-actions";
import { actionChannel } from "redux-saga/effects";

// actions

export const GET_DELIVERY_METHODS = "delivery/GET_DELIVERY_METHODS";
export const SET_DELIVERY_METHODS = "delivery/SET_DELIVERY_METHODS";
export const TOGGLE_DELIVERY_LOADING = "delivery/TOGGLE_DELIVERY_LOADING";
export const UPDATE_DELIVERY_METHODS = "delivery/UPDATE_DELIVERY_METHODS"

export type SetDeliveryPayload = {
  data: DeliveryItemType[],
  projectId: string
}

export const getDeliveryMethods = createAction<string>(GET_DELIVERY_METHODS);
export const setDeliveryMethods = createAction<SetDeliveryPayload>(SET_DELIVERY_METHODS);
export const toggleDeliveryLoading = createAction<boolean>(TOGGLE_DELIVERY_LOADING);
export const updateDeliveryMethods = createAction<SetDeliveryPayload>(UPDATE_DELIVERY_METHODS)


export type DeliveryActionCreatorsTypes =
  | typeof getDeliveryMethods
  | typeof setDeliveryMethods
  | typeof toggleDeliveryLoading
  | typeof updateDeliveryMethods;

export type DeliveryActionTypes = ReturnType<DeliveryActionCreatorsTypes>

export type DeliveryPayloads = 
| string
| boolean
| SetDeliveryPayload


// state types

export type DeliveryItemType = {
  name: string;
  description: string;
  price: number | null;
  is_optional: boolean;
  id?: string,
  options: {
    name: string;
    price: number | null;
  }[];
};

export type DeliveryStateType = {
  loading: boolean;
  data: Record<string, DeliveryItemType[]>;
};

export type DeliveryResponseType = {
  delivery_method_list: DeliveryItemType[]
}
