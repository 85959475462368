import React, { Suspense } from 'react'

import ProfileShimmer from 'src/modules/profile/components/ProfileShimmer'

const ChangeMail = React.lazy(() => import('src/modules/profile/containers/NotificationsChangeEmail'))

const NotificationsChangeEmail = () => {
  return (
    <Suspense fallback={<ProfileShimmer />}>
      <ChangeMail />
    </Suspense>
  )
}

export default NotificationsChangeEmail
