import React, { memo } from 'react'

import { PopupImportLoading as Popup } from 'hopshopui'
import { useT } from 'src/hooks/useT'
import { selectSyncInProgress, selelectIsUpdateInprogress } from 'src/store/sync'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const PopupImportLoading = () => {
  const { id } = useParams()
  const isPopupVisible = useSelector((state) => selectSyncInProgress(state, id))
  const isUpdateInProgress = useSelector((state) => selelectIsUpdateInprogress(state))
  const { t } = useT()
  return (
    (isPopupVisible || isUpdateInProgress) ? <StyledPopup
      title={t('popup.projectImportLoadingHeader')}
      text={t('popup.projectImportLoadingText')}
    /> : ''
  )
}

const StyledPopup = styled(Popup)`
  position: fixed;
`

export default memo(PopupImportLoading)