import React, { memo, Suspense } from 'react'
const ProjectEditContent = React.lazy(() => import("src/modules/projects/containers/ProjectEditContent"));

const ProjectContentEditPAge = () => {
  return (
    <Suspense fallback={<div></div>}>
      <ProjectEditContent></ProjectEditContent>
    </Suspense>
  )
}

export default memo(ProjectContentEditPAge)