import React, { Suspense } from 'react'

const Payments = React.lazy(() => import('src/modules/profile/containers/ProfilePayments.tsx'))

const ProfilePayments = () => {
  return (
    <Suspense fallback={<div>loading</div>}>{/* TODO: add shimmer для всех fallback где не стилизована загрузка */}
      <Payments />
    </Suspense>
  )
}

export default ProfilePayments
