import {
  put,
  call,
  takeLatest,
} from "redux-saga/effects";
import { deleteRequest, get, post, putRequest } from "src/utils/requestSaga";
import { SagaIterator } from "redux-saga";
import { AxiosResponse } from "axios";
import { handleServerError, showNotification } from "../notification";
import { camelize, snakeize } from "casing";
import {
  triggerGetLogs,
  getLogs,
  LogsResponseType,
  triggerGetLog,
  getLog,
  LogResponseType,
  triggerСhangeLog,
  changeLog,
  triggerDeleteLog,
  deleteLog,
  postLog,
  triggerPostLog,
  getServiceLog,
  GetServiceLogDone,
  triggerGetServiceLog,
  triggerPostServiceLog,
  postServiceLog,
  getDisplayLog,
  triggerDisplayLog,
  getUpdatedDisplayLog,
  triggerUpdatedDisplayLog,
  getStartDisplayLog,
  getEndDisplayLog,
  getDisplayLogID,
  triggerDisplayLogID,
  postDisplayLogDayEdit,
  triggerEndDisplayLog,
  triggerStartDisplayLog,
  GetDisplayLogDone,
  getBookingCalendarAvailableDay,
  triggerPostDisplayLogDayEdit,
  triggerGetBookingDetails,
  getBookingDetails,
  GetBookingDetailsResponseType,
  bookingCalendarAvailableDay,
  triggerGetBookingCalendarAvailableDay,
  triggerGetBookingCalendarAvailableDayEnd,
  getBookingCalendarAvailableDayEnd,
  postConfirmBookingOrder,
  triggerConfirmBookingOrder,
  postTransferringBooking,
  triggerTransferringBooking,
} from "./bookingTypes";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import history from "src/utils/browserHistory";
// import { GetDisplayLogDone, triggerEndDisplayLog, triggerStartDisplayLog } from ".";

const getBookingLogsSaga = bindAsyncAction(getLogs)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<LogsResponseType> = yield call(
      get,
      `projects/${payload.projectId}/bookingJournalSettings`
    );
    return { logs: camelize(data) };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getBookingLogSaga = bindAsyncAction(getLog)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<LogResponseType> = yield call(
      get,
      `projects/${payload.projectId}/bookingJournalSettings/${payload.bookingJournalId}`
    );
    return { log: camelize(data) }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const changeBookingLogSaga = bindAsyncAction(changeLog)(function* ({
  payload,
}): SagaIterator {
  try {
    // yield call(putRequest, `/bookingJournalSettings`, snakeize(payload));
    yield call(
      putRequest,
      `/bookingJournalSettings`,
      snakeize(payload.log.item)
    );
    yield put(showNotification({
      key: 'notifications.changesSaved'
    }))
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const postBookingLogSaga = bindAsyncAction(postLog)(function* ({
  payload,
}): SagaIterator {
  try {
    const dataPayload = payload.log.item
    dataPayload.projectId = Number(payload.projectId)
    const { data }: AxiosResponse<{
      item: {
        id: number;
      }
    }> = yield call(
      post,
      `/bookingJournalSettings`,
      snakeize(dataPayload)
    );
    yield put(showNotification({
      key: 'notifications.changesSaved'
    }))
    history.push(`/project/${payload.projectId}/booking/${data.item.id}`);
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const deleteBookingLogSaga = bindAsyncAction(deleteLog)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(
      deleteRequest,
      `projects/${payload.projectId}/bookingJournalSettings/${payload.bookingJournalId}`
    );
    yield put(showNotification({
      key: 'notifications.deleteLog'
    }))
    history.push(`/project/${payload.projectId}/booking/default`);
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getBookingLogServiceSaga = bindAsyncAction(getServiceLog)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<GetServiceLogDone> = yield call(
      get,
      `projects/${payload.projectId}/booking`
    );
    return { service: camelize(data) }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const postBookingLogServiceSaga = bindAsyncAction(postServiceLog)(function* ({
  payload,
}): SagaIterator {
  try {
    const data = payload.service.bookingSetting
    if (data.remindClient === false) {
      data.remindClientTime = null
    }
    if (data.remindMerchant === false) {
      data.remindMerchantTime = null
    }
    yield call(
      post,
      `projects/${payload.projectId}/booking`,
      snakeize(data)
    );
    yield put(showNotification({
      key: 'notifications.changesSaved'
    }))
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getUpdatedDisplayLogSaga = bindAsyncAction(getUpdatedDisplayLog)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<GetDisplayLogDone> = yield call(
      get,
      `projects/${payload.projectId}/bookingJournal/${payload.bookingJournalId}?startDate=${payload.startDate}&endDate=${payload.endDate}`,
    );
    return { displayLog: camelize(data.days) }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getDisplayLogSaga = bindAsyncAction(getDisplayLog)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<GetDisplayLogDone> = yield call(
      get,
      `projects/${payload.projectId}/bookingJournal/${payload.bookingJournalId}?startDate=${payload.startDate}&endDate=${payload.endDate}`,
    );
    return { displayLog: camelize(data.days) }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getStartDisplayLogSaga = bindAsyncAction(getStartDisplayLog)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<GetDisplayLogDone> = yield call(
      get,
      `projects/${payload.projectId}/bookingJournal/${payload.bookingJournalId}?startDate=${payload.startDate}&endDate=${payload.endDate}`,
    );
    return { displayLog: camelize(data.days) }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getEndDisplayLogSaga = bindAsyncAction(getEndDisplayLog)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<GetDisplayLogDone> = yield call(
      get,
      `projects/${payload.projectId}/bookingJournal/${payload.bookingJournalId}?startDate=${payload.startDate}&endDate=${payload.endDate}`,
    );
    return { displayLog: camelize(data.days) }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getDisplayLogIDSaga = bindAsyncAction(getDisplayLogID)(function* ({
  payload,
}): SagaIterator {
  try {
    return { logDisplayID: camelize(payload?.logId?.id) }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const postLogDayEditSaga = bindAsyncAction(postDisplayLogDayEdit)(function* ({
  payload,
}): SagaIterator {
  try {
    const res = yield call(
      post,
      `/projects/${payload.projectId}/bookingJournalDetail`,
      snakeize(payload.LogOneDay)
    );
    console.log(res)
    if (res.status === 200) {
      payload.callback && payload.callback(res);
    }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getBookingDetailsSaga = bindAsyncAction(getBookingDetails)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<GetBookingDetailsResponseType> = yield call(
      get,
      `/projects/${payload.projectId}/bookingDetails?serviceOrderId=${payload.bookingId}`,
      );
    return { bookingDetails: camelize(data) }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const postConfirmBookingSaga = bindAsyncAction(postConfirmBookingOrder)(function* ({
  payload,
}): SagaIterator {
  try {
    const destinationId = +payload.destinationId + 1;
    const res = yield call(
      putRequest,
      `projects/${payload.projectId}/orders/changeState`,
      {
            order_id: payload.id,
            state_id: destinationId,
          }
    );
    if (res.status === 200) {
      const { data }: AxiosResponse<GetBookingDetailsResponseType> = yield call(
        get,
        `/projects/${payload.projectId}/bookingDetails?serviceOrderId=${payload.serviceOrderId}`,
        );
      const { data: days }: AxiosResponse<GetDisplayLogDone> = yield call(
        get,
        `projects/${payload.projectId}/bookingJournal/${payload.bookingJournalId}?startDate=${payload.date}&endDate=${payload.date}`,
          );
   
    return { data: camelize(data), displayLog: camelize(days.days) }
    }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getBookingCalendarAvailableDaySaga = bindAsyncAction(getBookingCalendarAvailableDay)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<bookingCalendarAvailableDay> = yield call(
      get,
      `projects/${payload.projectId}/bookingJournal/${payload.bookingJournalId}/calendar?startDate=${payload.startDate}&endDate=${payload.endDate}`,
    );
    return { bookingCalendarAvailableDay: camelize(data) }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getBookingCalendarAvailableDaySagaDayEnd = bindAsyncAction(getBookingCalendarAvailableDayEnd)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<bookingCalendarAvailableDay> = yield call(
      get,
      `projects/${payload.projectId}/bookingJournal/${payload.bookingJournalId}/calendar?startDate=${payload.startDate}&endDate=${payload.endDate}`,
    );
    return { bookingCalendarAvailableDay: camelize(data) }
  } catch (e) {
    yield put(handleServerError(e));
  }
});


const postTransferringBookingSaga = bindAsyncAction(postTransferringBooking)(function* ({
  payload,
}): SagaIterator {
  try {
    const res = yield call(
      post,
      `/projects/${payload.projectId}/editBooking`,
      snakeize(payload.data)
    );
    if (res.status === 200 ) {
      history.push(`/project/${payload.projectId}/booking/${payload.journalId}/bookingDetails/${payload.data.serviceOrderId}?startDay=${payload.data.day?.date || payload.data.period?.startDate}`)
      yield put(showNotification({
        key: 'notifications.recordingTransferred'
      }))
    }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

export default function* () {
  yield takeLatest(triggerGetLogs.type, getBookingLogsSaga);
  yield takeLatest(triggerGetLog.type, getBookingLogSaga);
  yield takeLatest(triggerСhangeLog.type, changeBookingLogSaga);
  yield takeLatest(triggerDeleteLog.type, deleteBookingLogSaga);
  yield takeLatest(triggerPostLog.type, postBookingLogSaga);
  yield takeLatest(triggerGetServiceLog.type, getBookingLogServiceSaga);
  yield takeLatest(triggerPostServiceLog.type, postBookingLogServiceSaga);
  yield takeLatest(triggerDisplayLog.type, getDisplayLogSaga);
  yield takeLatest(triggerStartDisplayLog.type, getStartDisplayLogSaga);
  yield takeLatest(triggerEndDisplayLog.type, getEndDisplayLogSaga);
  yield takeLatest(triggerDisplayLogID.type, getDisplayLogIDSaga);
  yield takeLatest(triggerUpdatedDisplayLog.type, getUpdatedDisplayLogSaga);
  yield takeLatest(triggerPostDisplayLogDayEdit.type, postLogDayEditSaga);
  yield takeLatest(triggerTransferringBooking.type, postTransferringBookingSaga);
  yield takeLatest(triggerGetBookingDetails.type, getBookingDetailsSaga);
  yield takeLatest(triggerGetBookingCalendarAvailableDay.type, getBookingCalendarAvailableDaySaga);
  yield takeLatest(triggerGetBookingCalendarAvailableDayEnd.type, getBookingCalendarAvailableDaySagaDayEnd);
  yield takeLatest(triggerConfirmBookingOrder.type, postConfirmBookingSaga);
}
