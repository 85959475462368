import { connect } from 'react-redux'

import FeedbacksModerationPage from './FeedbacksModerationPage'

const mapStateToProps = () => ({})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbacksModerationPage)
