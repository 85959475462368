import React, {Fragment, memo, useMemo} from 'react'
import styled from 'styled-components'
import { object } from 'prop-types'
import { NavLink } from 'react-router-dom'

import { locationShape } from 'src/utils/propTypes'
import {useT} from 'src/hooks/useT'
import { MenuItem, MenuBlockHeader } from 'hopshopui'

const ProjectMenu = ({ location, match, project, services, ...props }) => {
  const active = location.pathname
  const { id } = match.params
  const { t } = useT()

  const isServicesDisable = useMemo(() => {
    if(!services) return {}
    const servicesActive = {}
    services.forEach(service => {
      servicesActive[service.systemName] = service.isServiceActive
    });

    return servicesActive;
  }, [services])

  return (
    <Container {...props}>
      {active.indexOf('main') >= 0 && <Fragment>
        <Link to={`/project/${id}/main/about`}>
          <MenuItem active={active.indexOf('/main/about') >= 0}>{t('project.menu.about')}</MenuItem>
        </Link>
        <Link to={`/project/${id}/main/contacts`}>
          <MenuItem active={active.indexOf('/main/contacts') >= 0}>{t('project.menu.contacts')}</MenuItem>
        </Link>
        <Link to={`/project/${id}/main/access`}>
          <MenuItem active={active.indexOf('/main/access') >= 0}>{t('project.menu.access')}</MenuItem>
        </Link>
        
        <MenuBlockHeader>{t('project.menu.header.sales')}</MenuBlockHeader>
        {isServicesDisable['Scenarios'] && <Link to={`/project/${id}/main/scenarios`}>
          <MenuItem active={active.indexOf('/main/scenarios') >= 0}>{t('project.menu.scenarios')}</MenuItem>
        </Link>}

        {isServicesDisable['CheckoutCustom'] && <Link to={`/project/${id}/main/checkout`}>
          <MenuItem active={active.indexOf('/main/checkout') >= 0}>{t('project.menu.checkout')}</MenuItem>
        </Link>}

        <Link to={`/project/${id}/main/delivery`}>
          <MenuItem active={active.indexOf('/main/delivery') >= 0}>{t('project.menu.delivery')}</MenuItem>
        </Link>
        {isServicesDisable['PromoCodes'] &&  <Link to={`/project/${id}/main/promocodes`}>
          <MenuItem active={active.indexOf('/main/promocodes') >= 0}>{t('project.menu.promocodes')}</MenuItem>
        </Link>}
        {project && project.is_payment_enabled && <Link to={`/project/${id}/main/payments`}>
          <MenuItem active={active.indexOf('/main/payments') >= 0}>{t('project.menu.payments')}</MenuItem>
        </Link>}

        {(isServicesDisable['Booking'] || isServicesDisable['DigitalGoods']) 
        && <MenuBlockHeader>{t('project.menu.header.goods')}</MenuBlockHeader>}

        {isServicesDisable['DigitalGoods'] && <Link to={`/project/${id}/main/digitalGoods`}>
          <MenuItem active={active.indexOf('/main/digitalGoods') >= 0}>{t('project.menu.digitalGoods')}</MenuItem>
        </Link>}
        {isServicesDisable['Booking'] && <Link to={`/project/${id}/main/booking`}>
          <MenuItem active={active.indexOf('/main/booking') >= 0}>{t('project.menu.booking')}</MenuItem>
        </Link>}


        <MenuBlockHeader>{t('project.menu.header.services')}</MenuBlockHeader>
        <Link to={`/project/${id}/main/sync`}>
          <MenuItem active={active.indexOf('/main/sync') >= 0}>{t('project.menu.sync')}</MenuItem>
        </Link>
        {isServicesDisable['Analytics'] && <Link to={`/project/${id}/main/analytics`}>
          <MenuItem active={active.indexOf('/main/analytics') >= 0}>{t('project.menu.analytics')}</MenuItem>
        </Link>}
        <Link to={`/project/${id}/main/marketplaces`}>
          <MenuItem active={active.indexOf('/main/marketplaces') >= 0}>{t('project.menu.marketplaces')}</MenuItem>
        </Link>
        {project && project.enabled_html_code && <Link to={`/project/${id}/main/htmlcode`}>
            <MenuItem active={active.indexOf('/main/html') >= 0}>Html-код</MenuItem>
          </Link>
        }
        <Link to={`/project/${id}/main/moreservices`}>
          <StyledMenuItem active={active.indexOf('/main/moreservices') >= 0}>{t('project.menu.moreSettings')}</StyledMenuItem>
        </Link>


      </Fragment>}
    </Container >
  )
}

ProjectMenu.propTypes = {
  location: locationShape,
  match: object
}

const Container = styled.div``

const StyledMenuItem = styled(MenuItem)`
  background: ${({active}) => active && '#EEDDE6'};
  color: #FF4081;
  margin-top: 50px;
  margin-bottom: 50px;
`


const Link = styled(NavLink)``

export default memo(ProjectMenu)