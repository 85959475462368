import {
  CheckoutFormStateType,
  getCheckoutForm
} from "./checkoutTypes";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";

const INITIAL_STATE: CheckoutFormStateType = {
  loading: false,
  checkoutFields: [],
  showPromocode: false,
};

export default reducerWithInitialState(INITIAL_STATE)
  .cases(
    [
      getCheckoutForm.started,
    ],
    (state) => ({
      ...state,
      loading: true,
    })
  )
  .case(getCheckoutForm.done, (state, action) => {
    return produce(state, (draft) => {
      draft.loading = false;
      if (action.result) {
        draft.checkoutFields = action.result.fields
        draft.showPromocode = action.result.showPromocode
      }
      return draft;
    });
  });