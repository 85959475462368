import React from 'react'
import styled from 'styled-components'
import { bool, string } from 'prop-types'

const Arrow = ({ rotated, className }) => {
  return (
    <ArrowSpan rotated={rotated} className={className}></ArrowSpan>
  )
}

Arrow.propTypes = {
  rotated: bool,
  className: string
}

const ArrowSpan = styled.span`
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
    transform: rotate(${props => props.rotated && '180deg'});
  `

export default Arrow