import React, { useEffect } from 'react'
import { func } from 'prop-types'
import { history } from 'src/utils/propTypes'

const AuthVkPageStart = ({ authVk, history }) => {
  useEffect(() => {
    authVk(history)
  }, [authVk, history])
  return (
    <div></div>
  )
}

AuthVkPageStart.propTypes = {
  authVk: func,
  history: history
}

export default AuthVkPageStart