import actionCreatorFactory, { ActionCreator, Action } from "typescript-fsa";

const actionCreator = actionCreatorFactory();

export type ScenariosType = {
  search: search;
  favorites: favorites;
  basket: basket;
  goodsCardBuy: goodsCardBuy;
  catalogueBuy: catalogueBuy;
  bookingBuy?:bookingBuy;
  bookingServiceCheckinName:string;
};

export type search = {
  show: boolean;
};

export type favorites = {
  show: boolean;
};

export type basket = {
  show: boolean;
};

export type bookingBuy = {
  name: string;
};

export type goodsCardBuy = {
  show: boolean;
  name: string;
  action: string;
  link: string;
};

export type catalogueBuy = {
  show: boolean;
  name: string;
  action: string;
  link: string;
};

// -----

export type GetScenariosDonePayload = {
  scenarios: ScenariosType;
  loading: boolean;
};

export type ScenariosStateType = {
  readonly loading: boolean;
  readonly scenarios: ScenariosType;
};

export type ScenariosResponseType = {
  buttons: ScenariosType;
  loading: boolean;
};

export const triggerGetScenarios = actionCreator<{ projectId: string }>(
  "project/TRIGGER_GET_SCENARIOS"
);
export const getScenarios = actionCreator.async<
  Action<{ projectId: string }>,
  GetScenariosDonePayload
>("project/GET_SCENARIOS");

export const triggerSetScenarios = actionCreator<{
  projectId: string;
  scnearios: ScenariosType;
}>("project/TRIGGER_SET_SCENARIOS");

export const setScenarios = actionCreator.async<Action<{
  projectId: string;
  scnearios: ScenariosType;
}>, ScenariosType>("project/SET_SCENARIOS")