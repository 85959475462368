import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDelayedCallback } from 'hopshopui'


export const useRedirectTemplate = (menuPages) => {
  const history = useHistory()
  const params = useParams()
  const redirect = useCallback((url) => {
    const { id } = params
    history.push(`/project/${id}${url}`)
  }, [history, params])

  const redirectToEditCategories = useCallback(() => {
    redirect(`/editCategories`, `/editCategories`)
  }, [redirect])

  const redirectCart = useCallback(() => {
    redirect('/cart')
  }, [redirect])

  const redirectCheckout = useCallback(() => {
    redirect('/cart/checkout')
  }, [redirect])

  const redirectFav = useCallback(() => {
    redirect('/favorites')
  }, [redirect])

  const redirectSearch = useDelayedCallback((searchValue) => {
    redirect(`/goods?search=${searchValue}`)
  }, [redirect])

  const redirectToGood = useDelayedCallback((goodTitle) => {
    redirect(`/goods/${goodTitle}`)
  }, [redirect])

  const redirectGoods = useCallback(() => {
    redirect('/goods')
  }, [redirect])

  const redirectItem = useCallback((title) => {
    redirect(`/${title}`)
  }, [redirect])

  const redirectProjectMain = useCallback(() => {
    redirect('/main/about')
  }, [redirect])

  const redirectMenu = useCallback(({ typeName, alias }, asPath = "") => {
    if (typeName == 'EditPages' || typeName == "Goods") {
      redirect(`/${alias}` + asPath)
    } else {
      redirect(`/${typeName.toLowerCase()}/${alias}` + asPath)
    }
  }, [redirect])

  const redirectIndex = useCallback(() => {
    redirectMenu(menuPages[0])
  }, [menuPages, redirectMenu])

  return {
    redirectFav,
    redirectMenu,
    redirectItem,
    redirectCart,
    redirectGoods,
    redirectIndex,
    redirectSearch,
    redirectToGood,
    redirectCheckout,
    redirectProjectMain,
    redirectToEditCategories,
  }
}