import { ImageType } from "src/templates/utils/types/goodType";
import actionCreatorFactory, { Action } from "typescript-fsa";

const actionCreator = actionCreatorFactory();

export type BookingType = {
  loading: boolean;
  logDisplayID: number;
  logs: LogType;
  log: LogOneType;
  service: ServiceLogType;
  displayLog: DisplayLogType;
  bookingCalendarAvailableDay: displayBookingCalendarAvailableDay;
  activeBookingDetails: BookingDetailsType | null;
};

export type DisplayLogType =  Array<{
    startTime?: string;
    endTime?: string;
    breaks?: Array<{ endTime: string; startTime: string; }>
    date?: string;
    dayOfWeek?: string;
    isDayWorking?: boolean;
  }>;

  export type DisplayLogDay = {
    bookingJournalId: string;
    date: string;
    isDayWorking: boolean;
    startTime?: string;
    endTime?: string;
    breaks?: Array<{ startTime: string; endTime: string; type:SlotsType.Break}>
  };

  export type DisplayLogDayEdit = {
    bookingJournalId: string;
    date: string;
    isDayWorking: boolean;
    working_Hours: {
      startTime?: string;
      endTime?: string;
      breaks?: Array<{ startTime: string; endTime: string;}>
    }
  };

  export type BookingDetailsType = {
    goodsId: number;
    image: ImageType;
    title: string;
    normalizedTitle:string;
    isPeriod: boolean;
    period: {
      startDate: string;
      endDate:string;
      endTime: string;
      startTime: string;
    },
    day: {
      day: string;
      allDay: boolean;
      time: string;
      durationInMinutes: number;
    },
    clientAmount: number;
    maxClientAmount: number;
    clients: BookingDetailsClientType[];
    notConfirmedClientsAmount:number;
    serviceGoodsId: number;
    splitBookingOptions: boolean;
  };

  export type BookingDetailsClientType = {
      clientName: string | null;
      clientEmail: string | null;
      clientPhone: string | null;
      orderNumber: string;
      orderStatusId: number;
      paymentMethod: {
        systemName: string;
        friendlyName: string;
      }
  }

  export type DisplayLogTypeItem = {
    startTime?: string;
    endTime?: string;
    slots?: SlotsArrayType;
    date?: string;
    dayOfWeek?: string;
    isDayWorking?: boolean;
  };

  export type SlotsArrayType =  Array<SlotBreakType| SlotBookingType>;

  export type SlotBreakType = {
    endTime: string;
    startTime: string; 
    type: SlotsType.Break
  } 

  export type SlotBookingType = {
    time: string;
    bookAllDay: boolean;
    bookingTimeType: string;
    clientEmail:null |  string;
    clientName: null | string;
    clientPhone: null | string;
    clientsAmount: number;
    interval?: null | {
      endTime: string
      startTime: string
    };
    concreteTime?: null | string;
    timeFrom?: null | string;
    timeTo?: null | string;
    isClientAmountMoreThanOne: boolean;
    notConfirmedClientsAmount:number;
    maxClientsAmount: number;
    title: string;
    serviceOrderId: "1";
    type: SlotsType.Booking;
  }

  export enum SlotsType {
    Booking = "Booking",
    Break = "Break",
  }

export type LogType = {
  projectId: number;
  bookingJournals: Array<bookingJournals>;
};

export type ServiceLogType = {
  bookingSetting: {
    confirmBooking: string | null,
    notificationText: string | null,
    remindClient: boolean,
    remindClientTime: string | null,
    remindClientTimeType: string | null,
    remindMerchant: boolean,
    remindMerchantTime: string | null,
    remindMerchantTimeType: string | null,
  }
};

export type LogOneType = {
  item: {
    id: number | null;
    projectId: number | undefined;
    title: string;
    description: string | null;
    image?: ImageType | null;
    workingDaysType: string;
    workingDaysInShift?: {
      daysOffsAmount: number;
      startDate: string;
      workingDaysAmount: number;
    } | null;
    workingDaysByWeekDay: workingDays;
    isWorkingHoursDependOnWeekDay: boolean;
    workingHoursNotDependOnWeekDay?: {
      endTime?: string;
      startTime?: string;
      breaks?: Array<{ endTime: string; startTime: string; }>
    } | null;
    workingHoursDependOnWeekDay: {
      friday: workingHours | null,
      monday: workingHours | null,
      saturday: workingHours | null,
      sunday: workingHours | null,
      thursday: workingHours | null,
      tuesday: workingHours | null,
      wednesday: workingHours | null,
    };
    crossBooking: boolean;
  }
  workingDaysTypes?: Array<string>;
};

export type workingDays = {
  friday?: boolean,
  monday?: boolean,
  saturday?: boolean,
  sunday?: boolean,
  thursday?: boolean,
  tuesday?: boolean,
  wednesday?: boolean,
}

export type workingHoursDays = {
  friday: workingHours,
  monday: workingHours,
  saturday: workingHours,
  sunday: workingHours,
  thursday: workingHours,
  tuesday: workingHours,
  wednesday: workingHours,
}

export type workingHours = {
  endTime?: string;
  startTime?: string;
  breaks?: Array<{ endTime: string; startTime: string; }>
}

export type bookingJournals = {
  title: number;
  id: number;
  alias: string;
};

// -----

// all journal 
export type GetLogsDone = {
  logs: LogType;
  loading: boolean;
};

// one journal 
export type GetLogDone = {
  log: LogOneType;
  loading: boolean;
};

// journal service
export type GetServiceLogDone = {
  service: ServiceLogType;
  loading: boolean;
};

// Display Log
export type GetDisplayLogDone = {
  days: DisplayLogType;
  loading: boolean;
};

// change one journal 
export type ChangeLogDone = {
  log: LogOneType;
  loading: boolean;
};

// post one journal 
export type PostLogDone = {
  log: LogOneType;
  loading: boolean;
};

// post edit one day log 
export type PostLogOneDayDone = {
  DisplayLogDay: DisplayLogDayEdit;
  loading: boolean;
};

// post confirm booking order
export type PostConfirmBookingOrder = {
  data: BookingDetailsType;
  displayLog: DisplayLogType;
  loading: boolean;
};

// get booking details by id

export type GetBookingDetailsResponseType = {
  bookingDetails: BookingDetailsType;
  loading: boolean;
}

// delete journal 
export type DeleteLogDone = {
  log: LogType;
  loading: boolean;
};


// all journal 
export type LogsResponseType = {
  logs: LogType;
  loading: boolean;
};

// one journal 
export type LogResponseType = {
  log: LogOneType;
  loading: boolean;
};

// journal service
export type LogServiceResponseType = {
  service: ServiceLogType;
  loading: boolean;
};

// display log
export type displayLogResponseType = {
  displayLog: DisplayLogType;
  loading: boolean;
};


// change one journal 
export type ChangeLogResponseType = {
  log: LogOneType;
  loading: boolean;
};

// post one journal 
export type PostLogResponseType = {
  log: LogOneType;
  loading: boolean;
};

// change one journal 
export type GetLogID = {
  logDisplayID: number;
  loading: boolean;
};

export type displayBookingCalendarAvailableDay = {
  availableDays: Date[];
}

export type bookingCalendarAvailableDay = {
  bookingCalendarAvailableDay: displayBookingCalendarAvailableDay;
  loading: boolean;
};


// all journal 
export const triggerGetLogs = actionCreator<{ projectId: string }>(
  "project/TRIGGER_GET_LOGS"
);
export const getLogs = actionCreator.async<
  Action<{ projectId: string }>,
  GetLogsDone
>("project/GET_LOGS");

// one journal 
export const triggerGetLog = actionCreator<{ projectId: string; bookingJournalId: string }>(
  "project/TRIGGER_GET_LOG"
);

export const getLog = actionCreator.async<
  Action<{ projectId: string; bookingJournalId: string }>,
  GetLogDone
>("project/GET_LOG");

export const resetLog = actionCreator("project/RESET_LOG")

// change one journal 
export const triggerСhangeLog = actionCreator<{ projectId: string | undefined; log: LogOneType }>(
  "project/TRIGGER_CHANGE_LOG"
);

export const changeLog = actionCreator.async<
  Action<{ projectId: string | undefined; log: LogOneType }>,
  ChangeLogDone
>("project/CHANGE_LOG");

// delete journal 
export const triggerDeleteLog = actionCreator<{ projectId: string; bookingJournalId: string }>(
  "project/TRIGGER_DELETE_LOGS"
);

export const deleteLog = actionCreator.async<
  Action<{ projectId: string; bookingJournalId: string }>,
  DeleteLogDone
>("project/DELETE_LOGS");

// post one journal 
export const triggerPostLog = actionCreator<{ projectId: string | undefined; log: LogOneType }>(
  "project/TRIGGER_POST_LOG"
);

export const postLog = actionCreator.async<
  Action<{ projectId: string | undefined; log: LogOneType }>,
  PostLogDone
>("project/POST_LOG");

// service Log
export const triggerGetServiceLog = actionCreator<{ projectId: string }>(
  "project/TRIGGER_GET_SERVICE_LOG"
);

export const getServiceLog = actionCreator.async<
  Action<{ projectId: string }>,
  GetServiceLogDone
>("project/GET_SERVICE_LOG");

// post service journal 
export const triggerPostServiceLog = actionCreator<{ projectId: string | undefined; service: ServiceLogType }>(
  "project/TRIGGER_POST_SERVICE_LOG"
);

export const postServiceLog = actionCreator.async<
  Action<{ projectId: string | undefined; service: ServiceLogType }>,
  PostLogDone
>("project/POST_SERVICE_LOG");

// get updated day display log

export const triggerUpdatedDisplayLog = actionCreator<{ projectId: string | undefined; bookingJournalId: string | undefined; startDate: string | undefined; endDate: string | undefined; update:boolean | undefined;}>(
  "project/TRIGGER_UPDATE_DISPLAY_LOG"
);

export const getUpdatedDisplayLog = actionCreator.async<
  Action<{  projectId: string | undefined; bookingJournalId: string | undefined; startDate: string | undefined; endDate: string | undefined; update:boolean | undefined;}>,
  displayLogResponseType
>("project/DISPLAY_UPDATE_LOG");


// get display log
export const triggerDisplayLog = actionCreator<{ projectId: string | undefined; bookingJournalId: string | undefined; startDate: string | undefined; endDate: string | undefined; lazyLoad:boolean | undefined; }>(
  "project/TRIGGER_DISPLAY_LOG"
);

export const getDisplayLog = actionCreator.async<
  Action<{  projectId: string | undefined; bookingJournalId: string | undefined; startDate: string | undefined; endDate: string | undefined; lazyLoad:boolean | undefined;}>,
  displayLogResponseType
>("project/DISPLAY_LOG");

export const resetDisplayLog = actionCreator("project/RESET_DISPLAY_LOG")

// get display log Start
export const triggerStartDisplayLog = actionCreator<{ projectId: string | undefined; bookingJournalId: string | undefined; startDate: string | undefined; endDate: string | undefined; lazyLoad:boolean | undefined;}>(
  "project/TRIGGER_START_DISPLAY_LOG"
);

export const getStartDisplayLog = actionCreator.async<
  Action<{  projectId: string | undefined; bookingJournalId: string | undefined; startDate: string | undefined; endDate: string | undefined; lazyLoad:boolean | undefined;}>,
  displayLogResponseType
>("project/START_DISPLAY_LOG");


// get display log End
export const triggerEndDisplayLog = actionCreator<{ projectId: string | undefined; bookingJournalId: string | undefined; startDate: string | undefined; endDate: string | undefined; }>(
  "project/TRIGGER_END_DISPLAY_LOG"
);

export const getEndDisplayLog = actionCreator.async<
  Action<{  projectId: string | undefined; bookingJournalId: string | undefined; startDate: string | undefined; endDate: string | undefined; }>,
  displayLogResponseType
>("project/END_DISPLAY_LOG");

// get id display log 
export const triggerDisplayLogID = actionCreator<{logId: {id: number};}>(
  "project/TRIGGER_DISPLAY_LOG_ID"
);

export const getDisplayLogID = actionCreator.async<
  Action<{logId: {id: number};}>,
  GetLogID
>("project/GET_DISPLAY_LOG_ID");

// post edit day on display log 
export const triggerPostDisplayLogDayEdit = actionCreator<{ projectId: string | undefined; LogOneDay: DisplayLogDayEdit; callback?: Function; }>(
  "project/TRIGGER_POST_DISPLAY_LOG_DAY_EDIT"
);

export const postDisplayLogDayEdit = actionCreator.async<
  Action<{ projectId: string | undefined; LogOneDay: DisplayLogDayEdit; callback?: Function; }>,
  PostLogOneDayDone
>("project/POST_DISPLAY_LOG_DAY_EDIT");

// post transferring a booking
export const triggerTransferringBooking = actionCreator<{ projectId: string | undefined; journalId: string | undefined; data: { serviceGoodsId: number | undefined; serviceOrderId: number| undefined; period: { startDate: string; endDate: string; } | undefined; day: {date: string; time: string; } | undefined; changeForAllClient: boolean | undefined; }}>(
  "project/TRIGGER_TRANSFERING_BOOKING"
);

export const postTransferringBooking = actionCreator.async<
  Action<{  projectId: string | undefined; journalId: string | undefined; data: {  serviceGoodsId: number | undefined; serviceOrderId: number| undefined; period: { startDate: string; endDate: string; } | undefined; day: {date: string; time: string; } | undefined; changeForAllClient: boolean | undefined; }}>,
  {loading: boolean;}
>("project/POST_TRANSFERING_BOOKING");

// get booking details by booking id
export const triggerGetBookingDetails = actionCreator<{ projectId: string | undefined; bookingOrderId: string | undefined}>(
  "project/TRIGGER_GET_BOOKING_DETAILS"
);

export const getBookingDetails = actionCreator.async<
  Action<{ projectId: string | undefined; bookingId: string | undefined }>,
  GetBookingDetailsResponseType
>("project/GET_BOOKING_DETAILS");

// comnfirm booking order

export const triggerConfirmBookingOrder = actionCreator<{id:string | undefined, destinationId:string , projectId: string | undefined; status: number | undefined; bookingId: string | undefined; serviceOrderId:string | undefined; bookingJournalId:string | undefined; date:string | undefined;}>(
  "project/TRIGGER_CONFIR_BOOKING_ORDER"
);

export const postConfirmBookingOrder = actionCreator.async<
  Action<{ id:string | undefined, destinationId:string , projectId: string | undefined; status: number | undefined; bookingId: string | undefined; serviceOrderId:string | undefined; bookingJournalId:string | undefined; date:string | undefined;}>,
  PostConfirmBookingOrder
>("project/POST_CONFIR_BOOKING_ORDER");

// get calendar day available
export const triggerGetBookingCalendarAvailableDay = actionCreator<{ projectId: string | undefined; bookingJournalId: string | undefined; startDate: string | undefined; endDate: string | undefined; }>(
  "project/TRIGGER_GET_BOOKING_CALENDAR_AVAILABLE_DAY"
);

export const getBookingCalendarAvailableDay = actionCreator.async<
  Action<{ projectId: string | undefined; bookingJournalId: string | undefined; startDate: string | undefined; endDate: string | undefined; }>,
  bookingCalendarAvailableDay
>("project/GET_BOOKING_CALENDAR_AVAILABLE_DAY");

// get calendar day available
export const triggerGetBookingCalendarAvailableDayEnd = actionCreator<{ projectId: string | undefined; bookingJournalId: string | undefined; startDate: string | undefined; endDate: string | undefined; }>(
  "project/TRIGGER_GET_BOOKING_CALENDAR_AVAILABLE_DAY_END"
);

export const getBookingCalendarAvailableDayEnd = actionCreator.async<
  Action<{ projectId: string | undefined; bookingJournalId: string | undefined; startDate: string | undefined; endDate: string | undefined; }>,
  bookingCalendarAvailableDay
>("project/GET_BOOKING_CALENDAR_AVAILABLE_DAY_END");
