import { RouteComponentProps } from "react-router-dom";
import { DomainServiceKey } from "src/utils/variables";
import { parseSearch } from "./search";
import { getMasterGoodAlias } from 'src/templates/utils/goodAlias'

export const matchParamsId = (props: RouteComponentProps<{
  id: string
}>) => {
  return props?.match?.params?.id
}

export const matchSusbcriptionName = (props: RouteComponentProps) => {
  const search = parseSearch(props.location.search)
  return search.activeService as string
}

export const matchSearchId = (props: RouteComponentProps) => {
  const search = parseSearch(props.location.search)
  return search.projectId as string
}

export const matchParamsAlais = (props: RouteComponentProps<{
  alias?: string
}>) => {
  return props?.match?.params?.alias as string
}
export const matchParamsbookingId = (props: RouteComponentProps<{
  bookingId?: string
}>) => {
  return props?.match?.params?.bookingId as string
}

export const matchParamsGoodMasterAlias = (props: RouteComponentProps<{
  alias?: string
}>): string => {
  return getMasterGoodAlias(props?.match?.params?.alias || '')
}
export const matchParamsPropertyId = (props: RouteComponentProps<{
  propertyId?: string
}>) => {
  return props?.match?.params?.propertyId as string
}