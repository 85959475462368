import { takeEvery, put, call, select, take, race } from "redux-saga/effects";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import {
  getDigitalGoodsSettings,
  triggerGetDigitalGoodsSettings,
  triggerChangeDigitalGoodsSettings,
  changeDigitalGoodsSettings,
  DigitalGoodsSettingsResponseType,
  triggerGetFreeSpaceCloud,
  getFreeSpaceCloud,
  triggerGetFileUploadLink,
  getFileUploadLink,
  uploadFile,
  triggerUploadFile,
  finishUploadFile,
  triggerFinishUploadFile,
  triggerClearFileByStoreName,
  addFileToQueue,
  removeFileFromQueue,
  toggleFileLoading,
  UploadFileType,
  StoreSpaceInfoType,
  cancelUploadFile,
  getDigitalFileName,
  triggerGetDigitalFileName,
  deleteDigitalFileConfirmation,
  triggerDeleteDigitalFileConfirmation,
  clearDigitalFilesNames,
  clearDeleteFilesIds,
} from "./digitalGoodsTypes";
import { deleteRequest, get, post, putRequest } from "src/utils/requestSaga";
import { SagaIterator } from "redux-saga";
import Axios, { AxiosResponse } from "axios";
import { handleServerError, showNotification } from "../notification";
import { camelize, snakeize } from "casing";
import { selectDeletedFilesIds, selectFileQueue } from "./digitalGoodsSelector";

const getDigitalGoodsSettingsSaga = bindAsyncAction(getDigitalGoodsSettings)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<DigitalGoodsSettingsResponseType> = yield call(
      get,
      `projects/${payload}/digitalSettings`
    );
    return camelize(data);
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const changeDigitalGoodsSettingsSaga = bindAsyncAction(changeDigitalGoodsSettings)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(post, `/digitalSettings`, snakeize(payload));
    yield put(showNotification({
      key: 'notifications.changesSaved'
    }))
    yield put(triggerGetDigitalGoodsSettings(payload.projectId.toString()));
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getFreeSpaceCloudSaga = bindAsyncAction(getFreeSpaceCloud)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<StoreSpaceInfoType> = yield call(post, `/file/loader/getStoreSpaceInfo`, { 'project-id': payload.projectId, });

    return camelize(data)
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getFileUploadLinkSaga = bindAsyncAction(getFileUploadLink)(function* ({
  payload,
}): SagaIterator {
  try {
    let res = yield call(post, `/file/loader/upload`, {
      name: payload.name,
      size: payload.size,
      project_id: payload.projectId
    });

    const data = camelize(res.data);
    if (data.fileLink) {
      yield put(triggerUploadFile({ data: data, file: payload.file, index: payload.index, callback: payload.callback }));
    }
  } catch (e) {

    if (e?.response?.data?.errors?.Validations && e?.response?.data?.errors?.Validations[0]) {
      payload.callback && payload.callback("Error", undefined, e?.response?.data?.errors?.Validations[0])
    } else {
      payload.callback && payload.callback("Error", undefined)
      yield put(handleServerError(e));
    }
  }
});

function* toogleLoadingSaga(payload: UploadFileType, status: "Canceled" | "Error" | "Ok") {

  yield put(removeFileFromQueue(payload.data.fileId))

  const queue: string[] = yield select(selectFileQueue)

  if (queue.length === 0) {
    yield put(toggleFileLoading(false));
  }
  payload.callback && payload.callback(status, payload.data.fileId);
  if (status === "Error") {
    yield put(showNotification({
      key: 'notifications.fileLoadingError'
    }))
  }
  if (status === "Canceled") {
    yield put(showNotification({
      key: 'notifications.uploadCanceled'
    }));
  }
}

const uploadFileSaga = bindAsyncAction(uploadFile)(function* ({
  payload,
}): SagaIterator {
  try {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    yield put(toggleFileLoading(true));

    yield put(addFileToQueue(payload.data.fileId))

    const [uploadResponse, cancelResponse] = yield race([
      call(putRequest, payload.data.fileLink, payload.file, { cancelToken: source.token }),
      take(cancelUploadFile),
    ])

    if (cancelResponse) {
      source.cancel('Operation canceled by the user.');
      yield call(toogleLoadingSaga, payload, "Canceled")
      return { data: { ...payload.data, index: payload.index }, canceled: true }
    }
    yield call(toogleLoadingSaga, payload, "Ok")

    return { data: { ...payload.data, index: payload.index }, canceled: false }
  } catch (e) {
    yield call(toogleLoadingSaga, payload, "Error")
  }
});

const finishUploadFileSaga = bindAsyncAction(finishUploadFile)(function* ({
  payload,
}): SagaIterator {
  try {
    const res = yield call(post, `/file/loader/finishUpload`, snakeize(payload));

    if (res.status === 200) {
      yield put(triggerClearFileByStoreName());
    }
  } catch (e) {
    yield put(showNotification({
      key: 'notifications.failedToCreateGood'
    }));
  }
});

const deleteDigitalFileConfirmationSaga = bindAsyncAction(deleteDigitalFileConfirmation)(function* ({
  payload
}): SagaIterator {
  try {
    const deletedFilesIds: { fileId: number; goodId: number }[] = yield select(selectDeletedFilesIds)
    if (deletedFilesIds.length) {
      for (let i = 0; i < deletedFilesIds.length; i++) {
        if (payload.payload.data.id === deletedFilesIds[i].goodId && payload.payload.data.goodsTypeId === 2) {
          yield call(deleteRequest, `/file/loader/${deletedFilesIds[i].fileId}`);
        } else if (payload.payload.data.variants.length) {
          for (let j = 0; j < payload.payload.data.variants.length; j++) {
            if (payload.payload.data.variants[j].variantId === deletedFilesIds[i].goodId
              && payload.payload.data.variants[j].goodsTypeId === 2) {
              yield call(deleteRequest, `/file/loader/${deletedFilesIds[i].fileId}`);
            }
          }
        }
      }
      yield put(clearDigitalFilesNames());
      yield put(clearDeleteFilesIds());
    }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getDigitalFileNameSaga = bindAsyncAction(getDigitalFileName)(function* ({
  payload,
}): SagaIterator {
  try {
    const res = yield call(get, `/file/loader/getName/${payload.id}`);
    return { id: payload.id, name: res.data }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

export default function* () {
  yield takeEvery(triggerGetDigitalGoodsSettings.type, getDigitalGoodsSettingsSaga);
  yield takeEvery(triggerChangeDigitalGoodsSettings.type, changeDigitalGoodsSettingsSaga);
  yield takeEvery(triggerGetFreeSpaceCloud.type, getFreeSpaceCloudSaga);
  yield takeEvery(triggerGetFileUploadLink.type, getFileUploadLinkSaga);
  yield takeEvery(triggerFinishUploadFile.type, finishUploadFileSaga);
  yield takeEvery(triggerUploadFile.type, uploadFileSaga);
  yield takeEvery(triggerDeleteDigitalFileConfirmation.type, deleteDigitalFileConfirmationSaga);
  yield takeEvery(triggerGetDigitalFileName.type, getDigitalFileNameSaga);
}