import React, { Fragment } from 'react'
import styled from 'styled-components'
import { shimmerStyles } from 'hopshopui'

const CartShimmer = () => {
  return (
    <Fragment>
      <CartItemShimmer />
    </Fragment>
  )
}

const CartItemShimmer = styled.div`
  ${shimmerStyles};
  width:  100%;
  max-width: 620px;
  height: 80px;
  margin: 0 auto;
  border-radius: 10px;
`

export default CartShimmer