import { connect } from 'react-redux'
import PopupCategories from '../components/PopupCategories'
import { addCategory, editCategory, deleteCategory } from 'src/store/projects'

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  addCategory,
  editCategory,
  deleteCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupCategories)
