import React, { memo } from "react";
import styled from "styled-components";
import { SecondaryRoundButton } from "hopshopui";
import DesignModeWrapper from 'src/modules/design/containers/DesignModeWrapper'
import { useT } from "src/hooks/useT";
import PlusIcon from "src/assets/plus.icon";

const DesignCoverButton = () => {
  const { t } = useT();
  return (
    <StyledWrapper>
      <DesignModeWrapper zIndex={2} type="Cover">
        <SecondaryRoundButton load={false}>
          <PlusIcon />
          {`  ${t('goodDesign.addCover')}`}
        </SecondaryRoundButton>
      </DesignModeWrapper>
    </StyledWrapper>
  );
};

export default memo(DesignCoverButton);

const StyledWrapper = styled.div`
  margin: 30px 0 30px 0;
  button{
    margin: 0 auto;
  }
`