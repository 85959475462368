import { takeEvery, put, call } from "redux-saga/effects";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import {
  getServices,
  ServicesResponseType,
  triggerGetServices,
  getSubscriptions,
  triggerGetSubscriptions,
  activateSubscription,
  triggerActivateSubscription,
  ActivateSubscriptionResponse,
  ServicesAllResponseType,
  ServiceTypeResponseItem,
  triggerDisableSubscription,
  disableSubscription,
  changeSubscriptionQuotation,
  triggerChangeSubscriptionQuotation,
} from "./subscriptionTypes";
import { get, post } from "src/utils/requestSaga";
import { SagaIterator } from "redux-saga";
import { AxiosResponse } from "axios";
import { handleServerError } from "../notification";
import { camelize, snakeize } from "casing";
import { triggerGetLastPayment } from "../payment";

const getSubscriptionServices = bindAsyncAction(getServices)(
  function* ({ payload }): SagaIterator {
    try {
      const response: AxiosResponse<ServicesAllResponseType> = yield call(
        get,
        `/services?projectId=${payload}`
      );
      const data: ServiceTypeResponseItem[] = camelize(response.data);
      return data.map((item) => {
        const additionalContent =
          item.additionalContent && JSON.parse(item.additionalContent);
        return { ...item, additionalContent };
      });
    } catch (e) {
      yield put(handleServerError(e));
    }
  }
);

const getSubscriptionsSaga = bindAsyncAction(getSubscriptions)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<ServicesResponseType> = yield call(
      get,
      `subscriptions?projectId=${payload}`
    );
    const returnData = { ...data };
    return camelize(data);
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const activateSubscriptionSaga = bindAsyncAction(activateSubscription)(
  function* ({ payload }): SagaIterator {
    try {
      const body = snakeize({
        ...payload,
      });
      const response: AxiosResponse<ActivateSubscriptionResponse> = yield call(
        post,
        `/subscriptions/enable`,
        body
      );
      const data: ActivateSubscriptionResponse = camelize(response.data);
      if (data.paymentUrl) {
        window.location.replace(data.paymentUrl);
      } else {
        yield put(triggerGetSubscriptions(payload.projectId))
        yield put(triggerGetLastPayment({
          projectId: payload.projectId,
          systemName: payload.systemName
        }))
      }
    } catch (e) {
      yield put(handleServerError(e));
    }
  }
);

const disableSubscriptionSaga = bindAsyncAction(disableSubscription)(
  function* ({ payload }): SagaIterator {
    try {
      const body = snakeize(payload);
      yield call(post, `subscriptions/disable`, body);
      yield put(triggerGetSubscriptions(payload.projectId));
    } catch (e) {
      yield put(handleServerError(e));
    }
  }
);

const changeSubscriptionSaga = bindAsyncAction(
  changeSubscriptionQuotation
)(function* ({ payload }): SagaIterator {
  try {
    const body = snakeize(payload)
    const response = yield call(post, `/subscriptions/changeQuotation`, body)
    yield put(triggerGetSubscriptions(payload.projectId))
    return null
  } catch(e) {
    yield put(handleServerError(e))
  }
});

export default function* () {
  yield takeEvery(triggerGetServices.type, getSubscriptionServices);
  yield takeEvery(triggerGetSubscriptions.type, getSubscriptionsSaga);
  yield takeEvery(triggerActivateSubscription.type, activateSubscriptionSaga);
  yield takeEvery(triggerDisableSubscription.type, disableSubscriptionSaga);
  yield takeEvery(triggerChangeSubscriptionQuotation.type, changeSubscriptionSaga)
}
