import { RootState } from "../rootTypes";
import { createSelector } from "reselect";
import produce from "immer";

const selectSubscriptions = (state: RootState) => state.analytics;

export const selectAnalyticsLoading = createSelector(
  selectSubscriptions,
  (state) => state.loading
);

export const selectAnalytics = createSelector(
  selectSubscriptions,
  (state) => {
    return state.analytics
  }
);