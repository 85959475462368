import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getProjectDetails, selectProjectsLoading, submitCheckingBookingOrder } from 'src/store/projects'
import { getProjectGoodsByIds } from 'src/store/projects'
import ProjectCartPage from './ProjectCartPage'

const mapStateToProps = (state) => ({
  loading: selectProjectsLoading(state),
})

const mapDispatchToProps = {
  getProjectDetails,
  getProjectGoodsByIds,
  submitCheckingBookingOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(ProjectCartPage)
)