import { connect } from 'react-redux'


import ResetPassword from './ResetPassword'
import { resetPassword, selectAuthLoading } from 'src/store/auth'

const mapStateToProps = (state) => ({
  loading: selectAuthLoading(state)
})

const mapDispatchToProps = {
  resetPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
