import React, { Suspense } from 'react'

const About = React.lazy(() => import('src/modules/projects/containers/SettingsMainSync'))

const SettingsMainSync = () => {
  return (
    <Suspense fallback={<div></div>}>{/* TODO: add shimmer для всех fallback где не стилизована загрузка */}
      <About />
    </Suspense>
  )
}

export default SettingsMainSync
