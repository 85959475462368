import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { signOut } from 'src/store/auth'

export const useSignout = (url) => {
  const dispatch = useDispatch()
  return useCallback(
    (e) => {
      try {
        e.preventDefault()
      } catch (e) { }
      dispatch(signOut())
    }, [dispatch]
  )
}