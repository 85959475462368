import React from 'react'
import styled from 'styled-components'

import { shimmerStyles } from 'hopshopui'

const AuthShimmer = () => {
  return (
    <Container>
      <HeaderBar />
      <InputBar />
      <InputBar />
    </Container>
  )
}

const Container = styled.div`
  /* margin-left: 70px; */
`

const HeaderBar = styled.div`
  ${shimmerStyles}
  height: 30px;
  margin-top: 15px;
  max-width: 300px;
  border-radius: 10px;
  margin-bottom: 30px;
`

const InputBar = styled.div`
${shimmerStyles}
  height: 50px;
  border-radius: 10px;
  max-width: 320px;
  margin-top: 20px;
  /* margin-top: 20px; */
`

export default AuthShimmer