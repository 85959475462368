import React, {Suspense, memo} from 'react'

const ProjectIndex = React.lazy(() => import('src/modules/projects/containers/ProjectIndex'))


const ProjectIndexPage = () => {
  return (
    <Suspense fallback={<div></div>}>
      <ProjectIndex />
    </Suspense>
  )
}

export default memo(ProjectIndexPage)