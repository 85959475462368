import React, { memo, useEffect } from 'react'
import { func } from 'prop-types'
import history from 'src/utils/browserHistory'
import { instDelayedLogin } from 'src/utils/localStorage'

const AuthInstStartPage = ({ authInst }) => {
  useEffect(() => {
    localStorage.setItem(instDelayedLogin, true)
    history.push(`/auth/email${history.location.search}`)
  }, [authInst, history])
  return (
    <div></div>
  )
}

AuthInstStartPage.propTypes = {
  authInst: func,
  history: history
}

export default memo(AuthInstStartPage)