import React, { memo } from 'react'
import { ProfileCard, shimmerStyles } from 'hopshopui'
import styled  from 'styled-components'

const ProfileShimmer = () => {
  return (
    <Container>
      <TitleShimmer />
      <TextShimmer />
      <TextShimmer />
    </Container>
  )
}

const Container = styled(ProfileCard)`
  height: 100%;
  padding: 40px;
`

const TitleShimmer = styled.div`
  height: 40px;
  ${shimmerStyles}
  border-radius: 10px;
`

const TextShimmer = styled.div`
  margin-top: 20px;
  height: 20px;
  border-radius: 10px;
  width: 80%;
  ${shimmerStyles}
`

export default memo(ProfileShimmer)