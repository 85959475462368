import {
  MarketplacesStateType,
  getMarketplaces,
  getDocumentsLinks
} from "./marketplacesTypes";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";


const INITIAL_STATE: MarketplacesStateType = {
  loading: false,
  marketplaces: {
    marketplaces: [
      {
        projectId: 0,
        marketplaceId: 0,
        state: "Disabled",
        logo: {
          id: 0,
          fullUrl: "full_url",
          thumbUrl: "thumb_url"
        },
        systemName: "system_name",
        link: "link",
        title: "title",
        description: "description",
        rules: "rules",
        baseDomain: "base_domain"
      }
    ],
    states: [
      {
        key: 0,
        value: "value"
      }
    ]
  },
  documentsLinks: {
    privacyPolicyLink: "https://files.hopshop.ru/privacy.pdf",
    userAgreementLink: "https://files.hopshop.ru/agreement-buyer.pdf"
  }
};

export default reducerWithInitialState(INITIAL_STATE)
  .cases(
    [
      getMarketplaces.started,
    ],
    (state) => ({
      ...state,
      loading: true,
    })
  )
  .case(getMarketplaces.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.marketplaces = action.result.marketplaces
      }
      draft.loading = false;
      return draft;
    })
  })
  .case(getDocumentsLinks.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.documentsLinks = action.result.documentsLinks
      }
      draft.loading = false;
      return draft;
    });
  })
