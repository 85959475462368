import produce from "immer";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import findIndex from "lodash/findIndex";

import {
  ImagesStateType,
  addImageToQueue,
  toggleImagesLoading,
  clearImagesQueue,
  removeImageById,
  disableImagesLoading,
  addDuplicateImageToQueue,
  removeDuplicateImageById,
  toggleDuplicateImageLoading,
} from "./imagesTypes";

const INITIAL_STATE: ImagesStateType = {
  loading: false,
  duplicateImageLoading: false,
  data: [],
  duplicateData:[],
};

export default reducerWithInitialState(INITIAL_STATE)
  // .case(addImageToQueue, (state) => ({
  //   ...state,
  //   loading: false,
  // }))
  .case(addImageToQueue, (state, action) => {
    return produce(state, (draft) => {
      draft.data.push(action);
      return draft;
    });
  })
  .case(addDuplicateImageToQueue, (state, action) => {
    return produce(state, (draft) => {
      draft.duplicateData.push(action);
      return draft;
    });
  })
  .case(toggleDuplicateImageLoading, (state, action) => {
    return produce(state, (draft) => {
      draft.duplicateImageLoading = action;
      return draft;
    });
  })
  .case(removeDuplicateImageById, (state, action) => {
    return produce(state, (draft) => {
      draft.duplicateData.filter(item => item !== action);
      return draft;
    });
  })
  .case(toggleImagesLoading, (state, action) => {
    return produce(state, (draft) => {
      draft.loading = action;
      return draft;
    });
  })
  .case(disableImagesLoading, (state) => {
    return produce(state, (draft) => {
      draft.loading = false;
      return draft;
    });
  })
  .case(clearImagesQueue, (state) => {
    return produce(state, (draft) => {
      draft.data = [];
      return draft;
    });
  })
  .case(removeImageById, (state, action) => {
    return produce(state, (draft) => {
      draft.data.splice(findIndex(draft.data, { id: action }), 1);
      return draft;
    });
  });
