import React, { MouseEvent } from "react";
import styled from "styled-components";
import EmptyContentFeather from "src/assets/EmptyContentFeather";
import {H1, P2, PrimaryRoundButton, size} from "hopshopui";
import {useT} from 'src/hooks/useT';

type Props = {
  handleEditRedirect: (a: MouseEvent<HTMLButtonElement>) => void;
}

const ProjectContentEmpty = ({
  handleEditRedirect
}: Props) => {
  const { t } = useT()
  return (
    <Container data-testid="content-empty">
      <Card>
        <Feather />
        <Header>{t("project.pages.EmptyPageHeader")}</Header>
        <Text>{t("project.pages.EmptyPageText")}</Text>
        <Button load={false} data-testid="page-empty-redirect" onClick={handleEditRedirect}>{t("project.pages.EmptyPageButton")}</Button>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Card = styled.div`
  background: ${(props) => props.theme.colors.light};
  border-radius: 10px;
  width: 500px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 10px ${props => props.theme.colors.button};
  @media screen and (max-width: ${size.mobile}) {
    margin-top: 0;
    width: 100%;
    border-radius: 0;
  }
`;

const Feather = styled(EmptyContentFeather)`
  margin-left: 30px;
  margin-top: 10px;
  @media screen and (max-width: ${size.mobile}) {
    display: none;
  }
`;

const Header = styled(H1)`
  margin-top: -15px; 
  @media screen and (max-width: ${size.mobile}) {
    margin-top: 25px;
  }
`;

const Text = styled(P2)`
  max-width: 300px;
  text-align: center;
  margin-top: 20px;
`;

const Button = styled(PrimaryRoundButton)`
  margin-top: 30px;
  margin-bottom: 45px;
  @media screen and (max-width: ${size.mobile}) {
    max-width: 280px;
  }
`;

export default ProjectContentEmpty;
