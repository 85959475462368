import { connect, ConnectedProps } from "react-redux";
import { RootState } from "src/store/rootTypes";
import { DefinedRouterComponentProps } from "src/types";
import { withRouter } from "react-router-dom";
import DesignLayout from '../components/DesignLayout'
import { selectProjectSettingsDesignModeSelected, disableDesignType, checkAndDisableDesignType, selectDesignConfirmation, toggleDesignConfimation } from "src/store/projectSettings";

const mapStateToProps = (state: RootState, props: DefinedRouterComponentProps) => ({
  designMode: selectProjectSettingsDesignModeSelected(state),
  confirmation: selectDesignConfirmation(state)
})

const mapDispatchToProps = {
  disableDesignType,
  checkAndDisableDesignType,
  toggleDesignConfimation
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ConnectedDesignLayoutProps = ConnectedProps<typeof connector> & DefinedRouterComponentProps

export default withRouter(connector(DesignLayout))