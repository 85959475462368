import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AuthOkPageStart from './AuthOkStartPage'
import { authOk } from 'src/store/auth'


const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  authOk
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthOkPageStart))
