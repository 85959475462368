import React, { Suspense } from 'react'

const BookingService = React.lazy(() => import('src/modules/projects/containers/BookingService'))

const ServiceBookingPage = () => {
  return (
    // TODO: add loading shimmer
    <Suspense fallback={''}>
        <BookingService/>
    </Suspense>
  )
}

export default ServiceBookingPage