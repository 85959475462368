import qs from 'querystring'
import { camelize } from "casing";
import { get } from 'src/utils/requestSaga'
import { handleServerError } from "../notification";
import { GoodsOffsetStep } from "./goodsWidgetReducer";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import { GoodCardType } from "src/templates/utils/types/goodType";
import { takeLatest, call, select, put } from "redux-saga/effects";
import { triggerLoadMoreGoodsWidget, loadMoreGoodsWidget } from './goodsWidgetType'
import { selectWidgetGoodsTotalCount, selectWidgetGoodsOffset } from "./goodsWidgetSelectors";

export const loadMoreGoodsWidgetSaga = bindAsyncAction(loadMoreGoodsWidget)(function* ({ payload: {
  projectId,
  categoryId,
  search,
  filterType
} }) {
  const goodsTotalCount: number = yield select(selectWidgetGoodsTotalCount)
  const offset: number = yield select(selectWidgetGoodsOffset)

  if (offset === goodsTotalCount && offset !== 0 && goodsTotalCount !== 0) {
    return null
  }

  let newOffset = offset + GoodsOffsetStep

  if (goodsTotalCount !== 0 && newOffset > goodsTotalCount) {
    newOffset = goodsTotalCount
  }

  if (goodsTotalCount === 0 || offset <= goodsTotalCount) {
    const searchParam: {
      offset: number,
      count: number,
      categoryId?: number,
      queriedSubstring?: string
      filterType?: number
    } = {
      offset,
      count: GoodsOffsetStep,
      categoryId
    }
    if (search) {
      searchParam.queriedSubstring = search
    }
    if (filterType && filterType >= 0) {
      searchParam.filterType = filterType
    }

    const searchString = qs.stringify(searchParam)
    try {
      const goodsResponse = yield call(get, `/projects/${projectId}/goods?${searchString}`)
      const data: {
        totalCount: number,
        goods: GoodCardType[]
      } = camelize(goodsResponse.data)

      return {
        offset: newOffset,
        totalCount: data.totalCount,
        goods: data.goods
      }
    } catch (e) {
      yield put(handleServerError(e))
    }

  }

  return null
})

export default function* () {
  yield takeLatest(triggerLoadMoreGoodsWidget.type, loadMoreGoodsWidgetSaga)
}