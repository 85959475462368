import React, { Suspense, Fragment } from "react";
import CategoriesShimmer from "src/elements/loaders/CategoriesShimmer";

const EditPages = React.lazy(() => import("src/modules/pages/containers/EditPagesContainer"));
const SubscriptionMain = React.lazy(
  () => import("src/modules/subscriptions/containers/SubscriptionMainContainer")
);

const EditPagesPage = () => {
  return (
    // TODO: add loading shimmer
    <Fragment>
      <Suspense fallback={<CategoriesShimmer />}>
        <EditPages />
      </Suspense>
      <Suspense fallback={<div></div>}>
        <SubscriptionMain />
      </Suspense>
    </Fragment>
  );
};

export default EditPagesPage;
