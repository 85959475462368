import actionCreatorFactory, { Action } from "typescript-fsa";

export type CapustaTypeSettings = {
  account: string;
  isTokenSet: boolean;
  projectId: string;
};

export type PaymenSystem = {
  psId: string | number;
  displayName?: string;
  isEnabled: boolean;
  psName: string;
  requireUserAction?: boolean;
  settings: CapustaTypeSettings | {};
  supportedCurrencies: string[];
};

export type PaymentSystemsStateType = {
  loading: boolean;
  paymentSystems: Record<string, PaymenSystem[]>;
  checkoutPaymentSystems: CheckoutPaymentSystem[]
};

const actionCreator = actionCreatorFactory();

export type CheckoutPaymentSystem = {
  id: string;
  friendlyName: string;
  systemName: string;
}

export type CheckoutPSPayload = CheckoutPaymentSystem[] | void;

export const triggerGetCheckoutPS = actionCreator<string>(
  "paymentSystems/TRIGGER_GET_CHECKOUT_PS"
);

export const getCheckoutPS = actionCreator.async<
  Action<string>,
  CheckoutPSPayload
>("paymentSystems/GET_CHECKOUT_PS");

export const triggerGetPS = actionCreator<string>(
  "paymentSystems/TRIGGER_GET_PS"
);

export type getPSPayload = {
  projectId: string;
  data: PaymenSystem[];
};

export const getPS = actionCreator.async<
  Action<string>,
  // TODO: check payload type
  getPSPayload
>("paymentSystems/GET_PS");

export type PSActionsPayload = {
  projectId: string;
  psId: string;
  displayName?: string;
};

export const triggerEnablePS = actionCreator<PSActionsPayload>(
  "payment/TRIGGER_ENABLE_PS"
);
export const enablePS = actionCreator.async<Action<PSActionsPayload>, null>(
  "payment/ENABLE_PS",
  {
    skipStartedAction: true,
  }
);

export const triggerDisablePS = actionCreator<PSActionsPayload>(
  "payment/TRIGGER_DISABLE_PS"
);
export const disablePS = actionCreator.async<Action<PSActionsPayload>, null>(
  "payment/DISABLE_PS",
  {
    skipStartedAction: true,
  }
);

export const triggerUpdatePS = actionCreator<PSActionsPayload>(
  "payment/TRIGGER_UPDATE_PS"
);
export const updatePS = actionCreator.async<Action<PSActionsPayload>, null>(
  "payment/UPDATE_PS",
  {
    skipStartedAction: true,
  }
);
