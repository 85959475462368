import { useState } from "react"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"


// ref is ref to container through useRef
// fixed is the default option if we need to pass true
// deps is an array of depepndencies
export const useSticky = ({
  ref,
  fixed: fixedProp,
  deps
}) => {
  const [fixed, setFixed] = useState(fixedProp)
  useScrollPosition(({ currPos }) => {
    const current = ref && ref.current
    if (currPos.y + current.offsetTop <= 0) {
      setFixed(true)
    } else {
      setFixed(false)
    }
  }, [...deps, ref])
  return [fixed, setFixed]
}