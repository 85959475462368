import { RootState } from "../rootTypes";
import { createSelector } from "reselect";

const selectFeedbacks = (state: RootState) => state.feedbacks;

export const selectFeedbacksSettingsLoading = createSelector(
  selectFeedbacks,
  (state) => state.loading
);

export const selectFeedbacksSettings = createSelector(
  selectFeedbacks,
  (state) => state.feedbacksSettings
);

export const selectFeedbacksStatistics = createSelector(
  selectFeedbacks,
  (state) => state.statistics
);

export const selectFeedbacksStates = createSelector(
  selectFeedbacks,
  (state) => state.states
);

export const selectFeedbacksForModeration = createSelector(
  selectFeedbacks,
  (state) => state.feedbacksForModeration
);

export const selectFeedbacksModaretionCount = createSelector(
  selectFeedbacks,
  (state) => state.feedbacksModerationCount
);

export const selectFeedbacksGoodCount = createSelector(
  selectFeedbacks,
  (state) => state.feedbacksGoodCount
);

export const selectFeedbacksForGood = createSelector(
  selectFeedbacks,
  (state) => state.feedbacks
);

export const selectLikedFeedbacksIds = createSelector(
  selectFeedbacks,
  (state) => state.likedFeedbacksIds
);

export const selectDislikedFeedbacksIds = createSelector(
  selectFeedbacks,
  (state) => state.dislikedFeedbacksIds
);
