import React, { memo, Suspense } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { func, bool } from 'prop-types'

const AnonRoute = ({ component: Component, layout: Layout, authroized, ...rest }) => {
  if (authroized) {
    return <Redirect to='/project' />
  } else {
    return <Route {...rest} render={props => {
      return (
        <Suspense fallback={<div></div>}>
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        </Suspense>
      )
    }} />
  }
}

AnonRoute.propTypes = {
  component: func,
  layout: func,
  authroized: bool
}

export default memo(AnonRoute)
