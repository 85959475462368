import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import {useState} from "react";

export const useMobileMenu = () => {
    const [isMenuVisibleWithScroll, setIsMenuVisibleWithScroll] = useState(true);
    const [isMenu, setIsMenu] = useState(false);

    const changeIsMenuVisible = (isVisible: boolean) => {
        setIsMenuVisibleWithScroll(isVisible)
    }

    const changeIsMenu = (isMenu: boolean) => {
        setIsMenu(isMenu)
    }

    useScrollPosition(
        ({ prevPos, currPos }) => {
            if (prevPos.y <= currPos.y) {
                setIsMenuVisibleWithScroll(true)
            }else{
                setIsMenuVisibleWithScroll(false)
            }
        },
        []
    );
    return {
        isMenuVisibleWithScroll,
        changeIsMenuVisible,
        changeIsMenu,
        isMenu,
        isMobileMenu: true
    }
}