import { bindAsyncAction } from "typescript-fsa-redux-saga";
import { SagaIterator } from "redux-saga";
import {
  getPS,
  triggerGetPS,
  PaymenSystem,
  enablePS,
  triggerEnablePS,
  disablePS,
  triggerDisablePS,
  updatePS,
  triggerUpdatePS,
  getCheckoutPS,
  CheckoutPSPayload,
  triggerGetCheckoutPS,
} from "./paymentSystemsTypes";
import { AxiosResponse } from "axios";
import { call, takeEvery, put } from "redux-saga/effects";
import { post, get } from "src/utils/requestSaga";
import { camelize } from "casing";
import { handleServerError, showNotification } from "../notification";

const getAvailablePSSaga = bindAsyncAction(getPS)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<any> = yield call(
      get,
      `/projects/${payload}/paymentSystems`
    );
    const ps: PaymenSystem[] = camelize(data);
    return {
      data: ps,
      projectId: payload,
    };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const enablePSSaga = bindAsyncAction(enablePS)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(
      post,
      `/projects/${payload.projectId}/paymentSystems/${payload.psId}/enable`
    );
    yield put(triggerGetPS(payload.projectId));
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const disablePSSaga = bindAsyncAction(disablePS)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(
      post,
      `/projects/${payload.projectId}/paymentSystems/${payload.psId}/disable`
    );
    yield put(triggerGetPS(payload.projectId));
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getCheckoutPSSaga = bindAsyncAction(getCheckoutPS)(function* ({
  payload,
}): SagaIterator<CheckoutPSPayload> {
  console.log(payload, "payload");
  try {
    const response = yield call(get, `/projects/${payload}/paymentSystems`);
    const {data} = response
    return camelize(data)
  } catch (e) {
    yield put(handleServerError(e))
  }
});

const updatePSSaga = bindAsyncAction(updatePS)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(post, "/paymentSystems/settings", {
      display_name: payload.displayName,
      payment_system_id: payload.psId,
      project_id: payload.projectId,
    });
    yield put(triggerGetPS(payload.projectId));
    yield put(
      showNotification({
        key: "notifications.changesSaved",
      })
    );
  } catch (e) {
    yield put(handleServerError(e));
  }
});

export default function* () {
  yield takeEvery(triggerGetPS.type, getAvailablePSSaga);
  yield takeEvery(triggerEnablePS.type, enablePSSaga);
  yield takeEvery(triggerDisablePS.type, disablePSSaga);
  yield takeEvery(triggerUpdatePS.type, updatePSSaga);
  yield takeEvery(triggerGetCheckoutPS.type, getCheckoutPSSaga);
}
