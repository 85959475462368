import React, { Suspense } from 'react'

import OrderColumnsShimmer from 'src/modules/orders/components/OrdersColumnShimmer'

const OrderMain = React.lazy(() => import('src/modules/orders/containers/OrderMain'))

const OrderPage = () => {
  return (
    <Suspense fallback={<OrderColumnsShimmer />}>
      <OrderMain />
    </Suspense>
  )
}

export default OrderPage