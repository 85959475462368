import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectCardView } from "src/store/projectSettings/projectSettingsSelectors"
import { RootState } from "src/store/rootTypes"
import { CardViewFormTypes } from "src/templates/utils/types/projectSettings"

export const useProjectSettings = (projectId: string) => {
  const cardViews = useSelector((state: RootState) => selectCardView(state, projectId))
  const [rounding, setRounding] = useState<number>(() => cardViews.rounding)
  const [buttonColor, setButtonColor] = useState<string>(() => cardViews.buttonColor)
  const [buttonColorHover, setButtonColorHover] = useState<string>(() => cardViews.buttonColorHover)
  const [showGoodsTitle, setShowGoodsTitle] = useState<boolean>(() => cardViews.showGoodsTitle)
  const [showGoodsPrice, setShowGoodsPrice] = useState<boolean>(() => cardViews.showGoodsPrice)
  const [form, setForm] = useState<CardViewFormTypes>(() => cardViews.form)

  // not the most efficient way to do 
  useEffect(() => {
    setRounding(cardViews.rounding)
    setButtonColor(cardViews.buttonColor)
    setButtonColorHover(cardViews.buttonColorHover)
    setShowGoodsPrice(cardViews.showGoodsPrice)
    setShowGoodsTitle(cardViews.showGoodsTitle)
    setForm(cardViews.form)
  }, [cardViews, setRounding, setButtonColor, setButtonColorHover, setShowGoodsPrice, setShowGoodsTitle, setForm])

  return {
    rounding,
    setRounding,
    buttonColor,
    setButtonColor,
    buttonColorHover,
    setButtonColorHover,
    showGoodsTitle,
    setShowGoodsTitle,
    showGoodsPrice,
    setShowGoodsPrice,
    form,
    setForm
  }
}