import React, { Suspense } from 'react'
import ProjectGoodShimmer from 'src/modules/projects/components/ProjectGoodShimmer'


const BookingAddLogSettings = React.lazy(() => import('src/modules/bookingLog/containers/BookingAddLogSettings'))

const BookingAddLogSettingsPage = () => {
  return (
    <Suspense fallback={<ProjectGoodShimmer />}>
      <BookingAddLogSettings/>
    </Suspense>
  )
}

export default BookingAddLogSettingsPage
