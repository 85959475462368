import { createSelector } from 'reselect'
import find from 'lodash/find'
import { camelize } from 'casing'
import sortBy from 'lodash/sortBy'
import { t } from 'src/locales/i18n'
import { getCurrency } from 'src/templates/utils/getCurrency'
import { selectDesignBlocked } from 'src/store/projectSettings'


const selectProjects = state => state.projects

export const selectVkProjectType = createSelector(
  selectProjects,
  (state) => state.vkProjectType
)

export const selectVkTotalCount = createSelector(
  selectProjects,
  (state) => state.vkTotalCount
)

export const selectProjectsSelector = createSelector(
  selectProjects,
  (state) => state.projects || []
)

export const selectProjectsLoading = createSelector(
  selectProjects,
  (state) => {
    return state.loading
  }
)

export const selectVkProjects = createSelector(
  selectProjects,
  (state) => state.vkProjects || []
)

export const selectActiveVkProject = createSelector(
  selectVkProjects,
  (_, param) => param,
  (state, id) => {
    return find(state, ['id', Number(id)]) || {}
  }
)

export const selectActiveProject = createSelector(
  selectProjects,
  (_, param) => param,
  (state, id) => {
    if (state.projects.length === 1) {
      return state.projects[0]
    }
    const activeProject = find(state.projects, ['id', Number(id)])
    return activeProject || {}
  }
)

export const selectActiveProjectDomain = createSelector(
  selectActiveProject,
  (state, id) => {
    return state.display_domain
  }
)

export const selectActiveProjectBaseDomain = createSelector(
  selectActiveProject,
  (state, id) => {
    return state.base_domain
  }
)

export const selectActiveProjectPlatformTitle = createSelector(
  selectActiveProject,
  (state, id) => {
    return state.platform_title
  }
)

export const selectActiveProjectCurrencySign = createSelector(
  selectActiveProject,
  (state, id) => {
    return getCurrency(state.currency)
  }
)

export const selectActiveProjectCurrency = createSelector(
  selectActiveProject,
  (state) => state.currency
)

export const selectActiveProjectPaymentsEnabled = createSelector(
  selectActiveProject,
  (project) => project.is_payment_enabled
)


const image = {
  fullUrl: '/Velsh-korgi-1.jpg',
  id: 1,
  thumbUrl: '/Velsh-korgi-1.jpg',
  customId: '1'
}

// TODO: there is no selection of activeProject just return
export const selectActiveProjectGoods = createSelector(
  selectProjects,
  selectDesignBlocked,
  (state, designBlocked) => {
    if (!designBlocked) {
      return state.goods
    }
    const designGoods = []
    for (let i = 0; i < 3; i++) {
      let hoverState = false
      if (i == 2) {
        hoverState = true
      }
      let good = state.goods[i] || {
        // TODO: get currency from project
        currency: 'RUR',
        id: i,
        normalizedTitle: `${i}`,
        defaultImage: image,
        basePrice: 2500,
        oldPrice: 3500,
        // TODO: localise that
        title: t('reallyLongGoodName'),
        isAdult: false,
        quantity: 10
      }
      designGoods.push({ ...good, hoverState })
    }
    return designGoods
  }
)

export const selectActiveProjectGoodsCased = createSelector(
  selectActiveProjectGoods,
  (goods) => {
    return camelize(goods)
  }
)

export const selectFavoriteProjectGoods = createSelector(
  selectActiveProjectGoods,
  (_, id) => id,
  (_, __, param) => param,
  (goods, id, favorites) => {
    const keys = Object.keys(favorites)
    return sortBy(goods.filter((good) => {
      return keys.indexOf(String(good.id)) >= 0
        && good.project_id === Number(id)
        && favorites[good.id] > 0
    }), 'base_price')
  }
)

export const selectProjectGoodsTotal = createSelector(
  selectProjects,
  (state) => state.totalCount
)

export const selectProjectGoodsBooking = createSelector(
  selectProjects,
  (state) => state.goodsBooking
)

export const selectProjectGoodsCategoryTotal = createSelector(
  selectProjects,
  (state) => state.categoriesTotal
)

export const selectSettingsAbout = createSelector(
  selectProjects,
  (state) => {
    return state.settings.about
  }
)

export const selectVkGoods = createSelector(
  selectProjects,
  (state) => state.vkGoods
)

// export const selectSync = createSelector(
//   selectProjects,
//   (state) => state.sync
// )

// export const selectSyncFormValues = createSelector(
//   selectSync,
//   (sync) => {
//     return sync && sync.settings && {
//       frequency: sync.settings.frequency || 0,
//       import_hashtags_only: sync.settings.import_hashtags_only,
//       last_update: sync.settings.last_update,
//       policy: sync.settings.policy.toString(),
//       provider: 'Vkontakte',
//       source: sync.settings.source,
//       editFlag: sync.settings.type,
//       type: !!sync.settings.type,
//       hashtags: sync.settings.hashtags ? sync.settings.hashtags.join('#') : ''
//     }
//   }
// )

export const selectIsUpdateInprogress = createSelector(
  selectProjects,
  (state) => state.sync.is_update_inprogress
)

export const selectCategories = createSelector(
  selectProjects,
  (state) => state.categories
)


export const selectActiveCategories = createSelector(
  selectCategories,
  (_, param) => param,
  (state, param) => sortBy(state[param] || [], 'id')
)

export const selectProjectCategories = createSelector(
  selectCategories,
  (_, param) => param,
  (state, param) => state[param] || []
)

export const selectActiveTopCategories = createSelector(
  selectCategories,
  (_, param) => param,
  (state, param) => {
    const categories = state[param] || []
    return categories.filter((category) => !category.parent)
  }
)

const filterByParent = (categories, categoryId) => {
  return categories.filter((category) => {
    if (!category.parent && !categoryId) {
      return true
    }
    return category.parent && category.parent.id === categoryId
  })
}

const searchCategories = (categories, search) => {
  const filtered = categories.filter(item => {
    if (!search) {
      return true
    }
    const name = item.name.toLowerCase()
    const searchLowered = search.toLowerCase()
    return name.indexOf(searchLowered) >= 0
  })
  return sortBy(filtered, 'groupBy')
}

export const selectActiveCategoriesByParent = createSelector(
  selectActiveCategories,
  (_, __, categoryId) => categoryId,
  (categories, categoryId) => filterByParent(categories, categoryId)
)

export const selectCategoriesBySearch = createSelector(
  selectActiveCategories,
  (_, __, categoryId) => categoryId,
  (_, __, ___, search) => search,
  (state, categoryId, search) => {
    if (search && search.length) {
      return searchCategories(state, search)
    } else {
      return filterByParent(state, categoryId)
    }
  }
)

export const selectAccess = createSelector(
  selectProjects,
  (state) => state.access
  )

export const selectBaseDomain = createSelector(
  selectAccess,
  (state) => state.base_domain
)

export const selectContacts = createSelector(
  selectProjects,
  (state) => {
    return JSON.parse(JSON.stringify(state.contacts))
    // const _contacts = state.contacts.asMutable()
    // if (_contacts.contact_list) {
    //   _contacts.contact_list = _contacts.contact_list.asMutable().map((contact) => contact.asMutable())
    // }
    // return _contacts
  }
)

export const selectProjectSearchLoading = createSelector(
  selectProjects,
  (state) => state.searchLoading
)

export const selectProjectGoodsOffsetCount = createSelector(
  selectProjects,
  (state) => state.goodsOffsetCount
)

export const selectProjectGoodsOffset = createSelector(
  selectProjects,
  (state) => state.goodsOffset
)

export const selectProjectGoodsTotalCount = createSelector(
  selectProjects,
  (state) => state.goods.length || 0
)

export const selectProjectGoodsSorting = createSelector(
  selectProjects,
  (state) => {
    const { goodsSortingTypes: options } = state
    return options.map((option) => {
      return ({
        name: option.value,
        value: option.key
      })
    })
  }
)



export const selectGoodsImagesByAlias = createSelector(
  selectProjects,
  selectActiveProject,
  (_, __, goodId) => goodId,
  (state, activeProject, goodId, id) => {
    return {}
  }
)

export const selectFinishProject = createSelector(
  selectProjects,
  (state) => state.finishProjectData
)

export const selectLoadMoreLoading = createSelector(
  selectProjects,
  (state) => state.loadMoreLoading
)

export const selectGoodsLoading = createSelector(
  selectProjects,
  (state) => state.goodsLoading
)

export const selectProjectCategoriesMap = createSelector(
  selectProjects,
  (state) => state.categoriesMap
)
