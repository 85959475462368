import { RootState } from "../rootTypes";
import { createSelector } from "reselect";
import nanoid from "nanoid";
import { ContentPageBlocks, TextBlockType } from "src/templates/utils/types/goodType";
import { flattenPages } from 'src/templates/default/layout/MobileMenu'
import { SelectPageContentReturnType } from "./pagesTypes";

const selectPagesState = (state: RootState) => state.pages;

export const selectPagesLoading = createSelector(
  selectPagesState,
  (state) => state.loading
);

export const selectPages = createSelector(
  selectPagesState,
  (_: RootState, id: string) => id,
  (state, id) => {
    return state.data[id]?.pages || []
  }
);

export const selectPage = createSelector(
    selectPagesState,
    (_: RootState, id: string) => id,
    (state, id) => {
        return state.data[id]?.content || null
    }
);

export const selectPagesLimits = createSelector(
  selectPagesState,
  (_: RootState, id: string) => id,
  (state, id) => {
    return state.data[id]?.limit || []
  }
);

export const selectMenuPages = createSelector(
  selectPagesState,
  (_: RootState, id: string) => id,
  (state, id) => {
    return state.data[id]?.menuPages || []
  }
)

export const selectMenuPagesMap = createSelector(
  selectMenuPages,
  (menuPages) => {
    return flattenPages({}, menuPages, "")
  }
)

export const selectMainPage = createSelector(
  selectPagesState,
  (_: RootState, id: string) => id,
  (state, id) => {
    const menuPages = state.data[id]?.menuPages || []
    if (menuPages.length > 0) {
      return menuPages[0]
    }
  }
)

export const selectPageContent = createSelector(
  selectPagesState,
  (_: RootState, projectId: string) => projectId,
  (_: RootState, __: string, alias: string) => alias,
  (state, projectId, alias): ContentPageBlocks => {
    const contents = state.data?.[projectId]?.content?.[alias]?.modules
    if (contents && contents.length > 0) {
      return contents.map((content) => {
        return { ...content, customId: content.id?.toString() || nanoid() }
      })
    }
    return []
  }
)

export const selectPageContentWithSeo = createSelector(
  selectPagesState,
  (_: RootState, projectId: string) => projectId,
  (_: RootState, __: string, alias: string) => alias,
  (state, projectId, alias): SelectPageContentReturnType => {
    const contents = state.data?.[projectId]?.content?.[alias]?.modules;

    const seoParameters =
      state.data?.[projectId]?.content?.[alias]?.seoParameters;
    let cleanSeo = true;
    seoParameters &&
      Object.values(seoParameters).forEach((value) => {
        if (value) {
          cleanSeo = false;
        }
      });

    if (contents && contents.length > 0) {
      const blocks = contents.map((content) => {
        return { ...content, customId: content.id?.toString() || nanoid() };
      });
      return { blocks, cleanSeo, seoParameters: seoParameters };
    }
    return { blocks: [], cleanSeo, seoParameters: seoParameters };
  }
);

export const selectPageContentInitialValues = createSelector(
  selectPageContentWithSeo,
  ({ blocks, cleanSeo, seoParameters }) => {
    if (blocks.length > 0) {
      const modules = blocks.map((block) => {
        if (block.moduleType === "GoodsSet") {
          return { ...block, categoryActive: !!block.payload.categoryId }
        }
        return block
      })
      return { modules, cleanSeo, seoParameters };
    }
    let emptyTextBlock: TextBlockType = {
      moduleType: "Text",
      customId: nanoid(),
      payload: {
        text: "",
      },
    };
    return {
      modules: [emptyTextBlock],
      cleanSeo,
      seoParameters
    };
  }
);