import React, { Suspense } from 'react'
import ProjectGoodShimmer from 'src/modules/projects/components/ProjectGoodShimmer'

const AddProperty = React.lazy(() => import('src/modules/projects/containers/ProjectAddProperty'))

const ProjectAddProperty = () => {
  return (
    <Suspense fallback={<ProjectGoodShimmer />}>
      <AddProperty />
    </Suspense>
  )
}

export default ProjectAddProperty
