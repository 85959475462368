import React, {memo, Suspense} from 'react'

const GoodDesign = React.lazy(() => import('src/modules/projects/containers/ProjectGoodDesign'))

const GoodDesignPage = () => {
  return (
    <Suspense fallback={<div></div>}>
      <GoodDesign />
    </Suspense>
  )
}

export default memo(GoodDesignPage)