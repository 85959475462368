import { handleActions, createAction } from 'redux-actions'
import Immutable from 'seamless-immutable'

// ---
// CONSTANTS
// ---

export const TOGGLE_PROFILE_LOADING = 'profile/TGGLE_LOADING'
export const GET_PROFILE_DATA = 'profile/GET_PROFILE_DATA'
export const SET_PROFILE_DATA = 'profile/SET_PROFILE_DATA'
export const CHANGE_PASSWORD = 'profile/CHANGE_PASSWORD'
export const CHANGE_EMAIL = 'profile/CHANGE_EMAIL'
export const BIND_VK = 'profile/BIND_VK'
export const GET_PROFILE_PAYMENT_DATA = 'profile/GET_PROFILE_PAYMENT_DATA'

export const GET_PROFILE_NOTIFICATIONS_DATA = 'profile/GET_PROFILE_NOTIFICATIONS_DATA'
export const SET_PROFILE_NOTIFICATIONS_DATA = "profile/SET_PROFILE_NOTIFICATIONS_DATA";
export const CHANGE_NOTIFICATION_TOPIC_STATUS = "profile/CHANGE_NOTIFICATION_TOPIC_STATUS";
export const CHANGE_NOTIFICATIONS_EMAIL = "profile/CHANGE_NOTIFICATIONS_EMAIL";
export const ENABLE_NOTIFICATIONS_CHANNEL = "profile/ENABLE_NOTIFICATIONS_CHANNEL";
export const DISABLE_NOTIFICATIONS_CHANNEL = "profile/DISABLE_NOTIFICATIONS_CHANNEL";

export const GET_PROFILE_WEBHOOK_DATA = "profile/GET_PROFILE_WEBHOOK_DATA";
export const SET_PROFILE_WEBHOOK_DATA = "profile/SET_PROFILE_WEBHOOK_DATA";
export const ADD_WEBHOOK = "profile/ADD_WEBHOOK";
export const CHANGE_WEBHOOK = "profile/CHANGE_WEBHOOK";
export const DELETE_WEBHOOK = "profile/DELETE_WEBHOOK";
export const DELETE_WEBHOOK_SUCCESS = "profile/DELETE_WEBHOOK_SUCCESS";


export const UNLINK_VK = 'profile/ULINK_VK'
export const UNLINK_VK_SUCCESS = 'profile/UNLINK_VK_SUCCESS'
export const ADD_EMAIL = 'ADD_EMAIL'
export const BIND_SOCIAL = 'BIND_SOCIAL'
export const UNLINK_SOCIAL = 'profile/UNLINK_SOCIAL'
export const DELETE_PROVIDER = 'profile/DELETE_PROVIDER'
export const DELAYED_INST_LOGIN = 'profile/DELAYED_INST_LOGIN'
export const CHANGE_LANGUAGE = "profile/CHANGE_LANGUAGE";

// ---
// ACTION CREATORS
// ---

export const toggleProfileLoading = createAction(TOGGLE_PROFILE_LOADING)
export const getProfileData = createAction(GET_PROFILE_DATA)
export const setProfileData = createAction(SET_PROFILE_DATA)
export const changePassword = createAction(CHANGE_PASSWORD)
export const changeEmail = createAction(CHANGE_EMAIL)
export const addEmail = createAction(ADD_EMAIL)
export const bindVk = createAction(BIND_VK)
export const getProfilePaymentData = createAction(GET_PROFILE_PAYMENT_DATA)
export const getProfileNotificationsData = createAction(GET_PROFILE_NOTIFICATIONS_DATA)
export const setProfileNotificationsData = createAction(SET_PROFILE_NOTIFICATIONS_DATA)
export const changeNotificationTopicStatus = createAction(CHANGE_NOTIFICATION_TOPIC_STATUS)
export const notificationsChangeEmail = createAction(CHANGE_NOTIFICATIONS_EMAIL)
export const enableNotificationsChannel = createAction(ENABLE_NOTIFICATIONS_CHANNEL)
export const disableNotificationsChannel = createAction(DISABLE_NOTIFICATIONS_CHANNEL)

export const getProfileWebhookData = createAction(GET_PROFILE_WEBHOOK_DATA);
export const setProfileWebhookData = createAction(SET_PROFILE_WEBHOOK_DATA);
export const addWebhook = createAction(ADD_WEBHOOK);
export const changeWebhook = createAction(CHANGE_WEBHOOK);
export const deleteWebhook = createAction(DELETE_WEBHOOK);
export const deleteWebhookSuccess = createAction(DELETE_WEBHOOK_SUCCESS);



export const unlinkVK = createAction(UNLINK_VK)
export const unlinkVKSuccess = createAction(UNLINK_VK_SUCCESS)
export const bindSocial = createAction(BIND_SOCIAL)
export const unlinkSocial = createAction(UNLINK_SOCIAL)
export const deleteProvider = createAction(DELETE_PROVIDER)
export const delayedInstLogin = createAction(DELAYED_INST_LOGIN)
export const changeLanguage = createAction(CHANGE_LANGUAGE);


// ---
// INITIAL STATE
// ---
const mockPayments = [
  {
    service_name: 'Название услуги',
    project_name: 'Название проекта',
    payment_date: '2019-09-14T12:45:06.150Z',
    period: 'month',
    price: 420,
    currency: 'USD',
    payment_method: 'Карта',
    ticket_url: ''
  },
  {
    service_name: 'Название услуги2',
    project_name: 'Название проекта2',
    payment_date: '2019-09-14T12:45:06.150Z',
    period: 'month',
    price: 420,
    currency: 'RUR',
    payment_method: 'Карта',
    ticket_url: ''
  }
]
const initialState = Immutable({
  loading: false,
  authSettings: {},
  linkedProjects: [],
  payments: mockPayments,
  notifications: [],
  language: "",
  available_languages: [],
  webhook: [],
});;

// ---
// REDUCER
// ---

export default handleActions(
  {
    [TOGGLE_PROFILE_LOADING]: (state, { payload }) =>
      Immutable.merge(state, { loading: payload }),
    [SET_PROFILE_DATA]: (state, { payload }) => Immutable.merge(state, payload),
    [SET_PROFILE_NOTIFICATIONS_DATA]: (state, { payload }) =>
      Immutable.merge(state, { notifications: payload }),
    [SET_PROFILE_WEBHOOK_DATA]: (state, { payload }) =>
      Immutable.merge(state, { webhook: payload }),
    [DELETE_WEBHOOK_SUCCESS]: (state, { payload }) =>
      Immutable.merge(state, {
        webhook: [...state.webhook].filter((item) => item.id !== payload.id),
      }),
    [UNLINK_VK_SUCCESS]: (state, { payload }) =>
      Immutable.merge(state, {
        authSettings: { ...state.authSettings, vkontakte_id: null },
      }),
    [DELETE_PROVIDER]: (state, { payload }) => {
      const providers = state.authSettings.providers.filter(
        (item) => item.provider !== payload
      );
      return state.setIn(["authSettings", "providers"], providers);
    },
    [CHANGE_LANGUAGE]: (state, { payload }) =>
      Immutable.merge(state, {
        language: payload.language,
      }),
  },
  initialState
);

