import { size } from "hopshopui";
import styled, { css } from "styled-components";

const EditorButton = styled.button<{
  active?: boolean;
}>`
  appearance: none;
  border: none;
  margin: 0 10px;
  background: none;
  cursor: pointer;
  &:active {
    transform: scale(1.05, 1.05);
  }
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  &:hover {
    /* TODO: move this to theme */
    background: rgba(0, 0, 0, 0.07);
  }
  @media screen and (max-width: ${size.mobile}) {
    &:hover {
      background: none;
    }
  } 
  ${(props) =>
    props.active &&
    css`
      background: rgba(0, 0, 0, 0.07) !important;
    `}

`;

export default EditorButton;
