import React, { PureComponent, Suspense } from 'react'
import styled from 'styled-components'
import { node } from 'prop-types'

class MainLayout extends PureComponent {
  render() {
    return (
      <Wrapper>
        <Suspense fallback={<div></div>}>
          {this.props.children}
        </Suspense>
      </Wrapper>
    )
  }
}

MainLayout.propTypes = {
  children: node
}

const Wrapper = styled.div`
  height: 100%;
`

export default MainLayout
