import { handleActions, createAction } from 'redux-actions'
import Immutable from 'seamless-immutable'
import findIndex from 'lodash/findIndex'
import { GOODS_OFFSET } from 'src/constants/Goods'
import { flattenCategories } from 'hopshopui'
import { sortCategories } from 'src/utils/categories'
import { finishProjectData } from 'src/utils/localStorage'

// ---
// CONSTANTS
// ---

// TODO: rename to GET_VK_ROJECTS_LIST AND SET_VK_PROJECS_LIST
export const GET_PROJECT_LIST = 'projects/GET_PROJECT_LIST'
export const SET_PROJECT_LIST = 'projects/SET_PROJECT_LIST'
export const TOGGLE_PROJECT_LOADING = 'projects/TOGGLE_PROJECT_LOADING'
export const TOGGLE_PROJECT_SEARCH_LOADING = 'projects/TOGGLE_PROJECT_SEARCH_LOADING'
export const GET_VK_GOODS = 'projects/GET_VK_GOODS'
export const SET_VK_GOODS = 'projects/SET_VK_GOODS'
export const IMPORT_VK_PROJECT = 'projects/IMPORT_VK_PROJECT'
export const CREATE_PROJECT = 'projects/CREATE_PROJECT'
export const GET_PROJECTS = 'projects/GET_PROJECTS'
export const SET_PROJECTS = 'projects/SET_PROJECTS'
export const RESET_PROJECTS = 'projects/RESET'
export const GET_PROJECT_SYNC_DATA = 'profile/GET_PROJECT_SYNC_DATA'
export const GET_INITIAL_PROJECT_SYNC_DATA = 'profile/GET_INITIAL_PROJECT_SYNC_DATA'
export const SET_PROJECT_SYNC_DATA = 'profile/SET_PROJECT_SYNC_DATA'
export const CHANGE_PROJECT_SYNC_DATA = 'profile/CHANGE_PROJECT_SYNC_DATA'
export const IMPORT_PROJECTS = 'profile/IMPORT_PROJECTS'
export const GET_SETTINGS_ABOUT = 'projects/GET_SETTINGS_ABOUT'
export const SET_SETTINGS_ABOUT = 'projects/SET_SETTINGS_ABOUT'
export const CHANGE_SETTINGS_ABOUT = 'projects/CHANGE_SETTINGS_ABOUT'
export const GET_PROJECT_DETAILS = 'projects/GET_PROJECT_DETAILS'
export const SET_PROJECT_DETAILS = 'projects/SET_PROJECT_DETAILS'
export const GET_PROJECT_GOODS = 'projects/GET_PROJECT_GOODS'
export const SET_PROJECT_GOODS = 'projects/SET_PROJECT_GOODS'
export const SET_PROJECT_GOODS_BOOKING = 'projects/SET_PROJECT_GOODS_BOOKING'
export const GET_PROJECT_CONTACTS = 'projects/GET_PROJECT_CONTACTS'
export const SET_PROJECT_CONTACTS = 'projects/SET_PROJECT_CONTACTS'
export const UPDATE_PROJECT_CONTACTS = 'projects/UPDATE_PROJECT_CONTACTS'
export const GET_CATEGORIES = 'projects/GET_CATEGORIES'
export const SET_CATEGORIES = 'projects/SET_CATEGORIES'
export const GET_PROJECT_ACCESS = 'projects/GET_PROJECT_ACCESS'
export const SET_PROJECT_ACCESS = 'projects/SET_PROJECT_ACCESS'
export const UPDATE_PROJECT_DOMAIN = 'projects/UPDATE_PROJECT_DOMAIN'
export const UPDATE_PROJECT_PUBLICITY = 'projects/UPDATE_PROJECT_PUBLICITY'
export const ADD_CATEGORY = 'projects/ADD_CATEGORY'
export const ADD_CATEGORY_SUCCESS = 'projects/ADD_CATEGORY_SUCCESS'
export const EDIT_CATEGORY = 'projects/EDIT_CATEGORY'
export const EDIT_CATEGORY_SUCCESS = 'projects/EDIT_CATEGORY_SUCCESS'
export const DELETE_CATEGORY = 'projects/DELETE_CATEGORY'
export const DELETE_CATEGORY_SUCESS = 'projects/DELETE_CATEGORY_SUCCESS'
export const INCREMENT_GOODS_COUNT = 'projects/INCREMENT_GOODS_COUNT'
export const RESET_GOODS_COUNT = 'projects/RESET_GOODS_COUNT'
export const SET_SORTING_TYPES = 'projects/SET_PROJECTS_TYPES'
export const ADD_PROJECT_GOOD = 'projects/ADD_PROJECT_GOOD'
export const GET_PROJECT_GOODS_BY_IDS = 'project/GET_PROJECT_GOODS_BY_IDS'
export const GET_PROJECT_GOOD_BY_ALIAS = 'project/GET_PROJECT_GOOD_BY_ALIAS'
export const SET_PROJECT_GOOD_EDIT = 'project/SET_PROJECT_GOOD_EDIT'
export const UPDATE_PROJECT_GOOD_BY_ALIAS = 'project/UPDATE_PROJECT_GOOD_BY_ALIAS'
export const FINISH_GET_PROJECTS = 'projects/FINISH_GET_PROJECTS'
export const SET_VK_PROJECTS = 'projects/SET_VK_PROJECTS'
export const LOAD_MORE_VK_GOODS = 'projects/LOAD_MORE_VK_GOODS'
export const SET_MORE_VK_GOODS = 'SET_MORE_VK_GOODS'
export const SET_VK_PROJECT_TYPE = 'projects/SET_VK_PROJECT_TYPE'
export const HANDLE_IMPORT_LOADING = 'projects/HANDLE_IMPORT_LOADING'
export const SET_FINISH_PROJECT_DATA = 'project/SET_FINISH_PROJECT_DATA'
export const LOAD_MORE_GOODS = 'project/LOAD_MORE_GOODS'
export const ADD_MORE_GOODS = 'project/ADD_MORE_GOODS'
export const TOGGLE_MORE_LOADING = 'project/TOGGLE_MORE_LOADING'
export const TOGGLE_GOODS_LOADING = 'project/TOGGLE_GOODS_LOADING'
export const UPDATE_CATEGORIES = 'project/UPDATE_CATEGORIES'
export const DELETE_CATEGORIES = 'project/DELETE_CATEGORIES'
export const RESET_PROJECT_GOODS = 'project/RESET_PROJECT_GOODS'
export const DELEGATE_DOMAIN = 'project/DELEAGE_DOMAIN'
export const GET_PROJECT_GOODS_WITH_SEARCH = 'project/GET_PROJECT_GOODS_WITH_SEARCH'
export const GET_DELAYED_PROJECT_GOODS_WITH_SEARCH = 'project/GET_DELAYED_PROJECT_GOODS_WITH_SEARCH'
export const SUBMIT_CHECKING_BOOKING_ORDER = 'orders/SUBMIT_CHECKING_BOOKING_ORDER'


// ---
// ACTION CREATORS
// ---

export const submitCheckingBookingOrder = createAction(SUBMIT_CHECKING_BOOKING_ORDER)
export const getProjectList = createAction(GET_PROJECT_LIST)
export const setProjectList = createAction(SET_PROJECT_LIST)
export const setVkProjectsList = createAction(SET_VK_PROJECTS)
export const setVkGoods = createAction(SET_VK_GOODS)
export const toggleProjectLoading = createAction(TOGGLE_PROJECT_LOADING)
export const finishGetProjects = createAction(FINISH_GET_PROJECTS)
export const getVkGoods = createAction(GET_VK_GOODS)
export const importVkProject = createAction(IMPORT_VK_PROJECT)
export const createProject = createAction(CREATE_PROJECT)
export const getProjects = createAction(GET_PROJECTS)
export const setProjects = createAction(SET_PROJECTS)
export const resetProject = createAction(RESET_PROJECTS)
export const getSettingsAbout = createAction(GET_SETTINGS_ABOUT)
export const setSettingsAbout = createAction(SET_SETTINGS_ABOUT)
export const changeSettingsAbout = createAction(CHANGE_SETTINGS_ABOUT)
export const getProjectDetails = createAction(GET_PROJECT_DETAILS)
export const setProjectDetails = createAction(SET_PROJECT_DETAILS)
export const getProjectGoods = createAction(GET_PROJECT_GOODS)
export const setProjectGoods = createAction(SET_PROJECT_GOODS)
export const setProjectGoodsBooking = createAction(SET_PROJECT_GOODS_BOOKING)
export const getProjectGoodsWithSearch = createAction(GET_PROJECT_GOODS_WITH_SEARCH)
export const getDelayedProjectGoodsWithSearch = createAction(GET_DELAYED_PROJECT_GOODS_WITH_SEARCH)
export const getProjectContacts = createAction(GET_PROJECT_CONTACTS)
export const setProjectContacts = createAction(SET_PROJECT_CONTACTS)
export const updateProjectContacts = createAction(UPDATE_PROJECT_CONTACTS)
export const getCategories = createAction(GET_CATEGORIES)
export const setCategories = createAction(SET_CATEGORIES)
export const getProjectAccess = createAction(GET_PROJECT_ACCESS)
export const setProjectAccess = createAction(SET_PROJECT_ACCESS)
export const updateProjectDomain = createAction(UPDATE_PROJECT_DOMAIN)
export const updateProjectPublicity = createAction(UPDATE_PROJECT_PUBLICITY)
export const addCategory = createAction(ADD_CATEGORY)
export const addCategorySuccess = createAction(ADD_CATEGORY_SUCCESS)
export const editCategory = createAction(EDIT_CATEGORY)
export const editCategorySuccess = createAction(EDIT_CATEGORY_SUCCESS)
export const deleteCategory = createAction(DELETE_CATEGORY)
export const deleteCategorySuccess = createAction(DELETE_CATEGORY_SUCESS)
export const toggleProjectSearchLoading = createAction(TOGGLE_PROJECT_SEARCH_LOADING)
export const incrementGoodsCount = createAction(INCREMENT_GOODS_COUNT)
export const resetGoodsCount = createAction(RESET_GOODS_COUNT)
export const setSortingTypes = createAction(SET_SORTING_TYPES)
export const addProjectGood = createAction(ADD_PROJECT_GOOD)
export const getProjectGoodsByIds = createAction(GET_PROJECT_GOODS_BY_IDS)
export const getProjectGoodByAlias = createAction(GET_PROJECT_GOOD_BY_ALIAS)
export const setProjectGoodEdit = createAction(SET_PROJECT_GOOD_EDIT)
export const updateProjectGoodByAlias = createAction(UPDATE_PROJECT_GOOD_BY_ALIAS)
export const loadMoreVKGoods = createAction(LOAD_MORE_VK_GOODS)
export const setVkProjectType = createAction(SET_VK_PROJECT_TYPE)
export const setMoreVkGoods = createAction(SET_MORE_VK_GOODS)
export const handleImportLoading = createAction(HANDLE_IMPORT_LOADING)
export const setFinishProjectData = createAction(SET_FINISH_PROJECT_DATA)
export const loadMoreGoods = createAction(LOAD_MORE_GOODS)
export const addMoreGoods = createAction(ADD_MORE_GOODS)
export const toggleMoreLoading = createAction(TOGGLE_MORE_LOADING)
export const toggleGoodsLoading = createAction(TOGGLE_GOODS_LOADING)
export const updateCategories = createAction(UPDATE_CATEGORIES)
export const deleteCategories = createAction(DELETE_CATEGORIES)
export const resetProjectGoods = createAction(RESET_PROJECT_GOODS)
export const delegateDomain = createAction(DELEGATE_DOMAIN)

// ---
// INITIAL STATE
// ---

// TODO: move all goods logic to it's separated reducer
const initialState = Immutable({
  loading: false,
  searchLoading: false,
  loadMoreLoading: false,
  goodsLoading: false,
  projects: [],
  vkProjects: [],
  vkGoods: [],
  vkProjectType: '',
  vkTotalCount: 0,
  goods: [],
  totalCount: 0,
  categoriesTotal: 0,
  goodsOffset: 0,
  goodsOffsetCount: GOODS_OFFSET,
  goodsSortingTypes: [],
  sync: {},
  settings: {
    about: {}
  },
  contacts: {},
  access: {},
  categories: {},
  categoriesMap: {},
  finishProjectData: {}
})

// ---
// REDUCER
// ---

export default handleActions(
  {
    [SET_PROJECT_LIST]: (state, action) =>
      Immutable.merge(state, { projects: action.payload }),
    [SET_VK_PROJECTS]: (state, action) => Immutable.merge(state, { vkProjects: action.payload }),
    [SET_VK_GOODS]: (state, { payload: { items, totalCount } }) =>
      Immutable.merge(state, { vkGoods: items, vkTotalCount: totalCount }),
    [TOGGLE_PROJECT_LOADING]: (state, action) =>
      Immutable.merge(state, { loading: action.payload }),
    [SET_PROJECTS]: (state, action) =>
      Immutable.merge(state, { projects: action.payload }),
    [SET_PROJECT_CONTACTS]: (state, action) =>
      Immutable.merge(state, { contacts: action.payload }),
    [RESET_PROJECTS]: (state, action) => Immutable(initialState),
    [SET_PROJECT_SYNC_DATA]: (state, { payload }) =>
      Immutable.merge(state, { sync: payload }),
    [SET_SETTINGS_ABOUT]: (state, action) =>
      Immutable.merge(state, { settings: { about: action.payload } }),
    [SET_PROJECT_ACCESS]: (state, action) =>
      Immutable.merge(state, { access: action.payload }),
    [SET_PROJECT_GOOD_EDIT]: (state, action) =>
      Immutable.merge(state, { editGood: action.payload }),
    [SET_PROJECT_DETAILS]: (state, { payload }) => {
      let projects = Immutable(state.projects)
      const index = findIndex(projects, ['id', payload.id])
      if (index >= 0) {
        const project = Immutable.merge(projects[index], payload)
        projects = projects.set(index, project)
      } else {
        projects = projects.concat(payload)
      }
      return Immutable.merge(state, { projects })
    },
    // TODO: check this one for changing count
    [SET_PROJECT_GOODS]: (state, { payload: { goods, total_count, totalCount } }) => {
      return Immutable.merge(state, {
        goods,
        categoriesTotal: total_count,
        totalCount
      })
    },
    [SET_PROJECT_GOODS_BOOKING]: (state, { payload: { available_bookings, unavailable_bookings}}) => {
      return Immutable.merge(state, {
        goodsBooking: {
          available_bookings,
          unavailable_bookings
        }
      })
    },
    [RESET_PROJECT_GOODS]: (state) => {
      return Immutable.merge(state, {
        goods: [],
        categoriesTotal: 0,
        totalCount: 0
      })
    },
    [ADD_MORE_GOODS]: (state, { payload }) => state.updateIn(['goods'], (goods) => [...goods, ...payload]),
    [RESET_PROJECTS]: (state, action) => Immutable(initialState),
    [SET_CATEGORIES]: (state, { payload: { projectId, data } }) => {
      let categories = Immutable(state.categories)
      const sortedCategories = sortCategories(data)
      categories = categories.set(projectId, sortedCategories)
      const categoriesMap = flattenCategories(data)
      return state.set('categories', categories).set('categoriesMap', categoriesMap)
    },
    [ADD_CATEGORY_SUCCESS]: (state, { payload: { projectId, category } }) => {
      const _categories = state.categories.set(projectId, [category, ...state.categories[projectId]])
      return state.set(`categories`, _categories)
    },
    [EDIT_CATEGORY_SUCCESS]: (state, { payload: { projectId, category } }) => {
      const index = state.categories[projectId].findIndex(_category => _category.id === category.id)
      return state.setIn(['categories', projectId, index], category)
    },
    [DELETE_CATEGORY_SUCESS]: (state, { payload: { projectId, categoryId } }) => {
      let _categories = state.getIn(['categories', projectId])
      const indexOf = findIndex(_categories, ['id', categoryId])
      _categories = [..._categories.slice(0, indexOf), ..._categories.slice(indexOf + 1)]
      return state.setIn(['categories', projectId], _categories)
    },
    [TOGGLE_PROJECT_SEARCH_LOADING]: (state, { payload }) => state.set('searchLoading', payload),
    [INCREMENT_GOODS_COUNT]: (state) => {
      let newOffset = state.goodsOffset + GOODS_OFFSET
      if (newOffset >= state.categoriesTotal) {
        newOffset = state.categoriesTotal
      }
      return Immutable.merge(state, {
        goodsOffset: newOffset
      })
    },
    [RESET_GOODS_COUNT]: (state) => state.set('goodsOffset', GOODS_OFFSET),
    [SET_SORTING_TYPES]: (state, { payload }) => state.set('goodsSortingTypes', payload),
    [SET_VK_PROJECT_TYPE]: (state, { payload }) => state.set('vkProjectType', payload),
    [SET_MORE_VK_GOODS]: (state, { payload }) => {
      return state.updateIn(['vkGoods'], (goods) => [...goods, ...payload])
    },
    [SET_FINISH_PROJECT_DATA]: (state, action) => {
      const data = action.payload
      // TODO: move that to sagas
      localStorage.setItem(finishProjectData, JSON.stringify(data))
      return Immutable.merge(state, { data  })
    },
    [TOGGLE_MORE_LOADING]: (state, { payload }) =>
      state.updateIn(['loadMoreLoading'], () => payload),
    [TOGGLE_GOODS_LOADING]: (state, { payload }) =>
      state.updateIn(['goodsLoading'], () => payload)
  },
  initialState
)

