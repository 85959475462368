import {
  call,
  put,
  takeLatest,
} from "redux-saga/effects";
import { deleteRequest, get, post, putRequest, } from "src/utils/requestSaga";
import { SagaIterator } from "redux-saga";
import { AxiosResponse } from "axios";
import { handleServerError, showNotification } from "../notification";
import { camelize, snakeize } from "casing";
import history from "src/utils/browserHistory";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import {
  triggerGetFeedbacksSettingByDomain,
  triggerGetFeedbacksSettingById,
  triggerUpdateFeedbacksSetting,
  FeedbacksSettingsResponseType,
  getFeedbacksSettingByDomain,
  getFeedbacksSettingById,
  updateFeedbacksSetting,
  triggerGetFeedbacksForModeration,
  getFeedbacksForModeration,
  triggerGetFeedbacksForGood,
  getFeedbacksForGood,
  FeedbackForGoodsType,
  FeedbacksForModerationResponseType,
  postNewFeedback,
  triggerPostNewFeedback,
  triggerUpdateFeedbackStatus,
  updateFeedbackStatus,
  triggerAddLike,
  triggerAddDislike,
  addComment,
  triggerAddComment,
  triggerUpdateComment,
  updateComment,
  deleteComment,
  triggerDeleteComment,
  addDislike,
  addLike,
  FeedbacksStatusesEnum
} from "./feedbacksTypes";
import { P } from "hopshopui";

const getFeedbacksSettingByDomainSaga = bindAsyncAction(getFeedbacksSettingByDomain)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<FeedbacksSettingsResponseType> = yield call(
      get,
      `projects/${payload.domain}/feedbacksSettings`
    );
    return { data: camelize(data) };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getFeedbacksSettingByIdSaga = bindAsyncAction(getFeedbacksSettingById)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<FeedbacksSettingsResponseType> = yield call(
      get,
      `projects/${payload.id}/feedbacksSettings`
    );
    return { data: camelize(data) };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const updateFeedbacksSettingSaga = bindAsyncAction(updateFeedbacksSetting)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<FeedbacksSettingsResponseType> = yield call(
      post,
      `/feedbacksSettings`,
      snakeize(payload)
    );
    history.push(`/project/${payload.projectId}/feedbacks/${payload.premoderation ? "New" : "Published"}`);
    yield put(showNotification({
      key: 'notifications.changesSaved'
    }))
    return { data: camelize(data) };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getFeedbacksForModerationSaga = bindAsyncAction(getFeedbacksForModeration)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<FeedbacksForModerationResponseType> = yield call(
      get,
      `projects/${payload.projectId}/feedbacks/moderation?status=${payload.status}&count=${payload.count}&offset=${payload.offset}`
    );
    return camelize(data);
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getFeedbacksForGoodSaga = bindAsyncAction(getFeedbacksForGood)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<FeedbackForGoodsType> = yield call(
      get,
      `projects/${payload.projectId}/feedbacks?goodsId=${payload.goodsId}&count=${payload.count}&offset=${payload.offset}`
    );
    return camelize(data);
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const postNewFeedbackSaga = bindAsyncAction(postNewFeedback)(function* ({
  payload,
}): SagaIterator {
  try {
    const res = yield call(post, `/projects/${payload.projectId}/feedbacks`, snakeize(payload.data));
    if (res.status === 200) {
      yield put(triggerGetFeedbacksForGood({
        count: 3,
        offset: 0,
        goodsId: payload.data.goodsId,
        loadMore: false,
        projectId: payload.projectId,
      }))
    }
    payload.callback && payload.callback()
  } catch (e) {
    if (e?.response?.data?.detail === "Email to which goods or services was ordered must be provided"){
      payload.callback && payload.callback("error")
    } else {
      yield put(handleServerError(e));
    }
  }
});

const updateFeedbacksStatusSaga = bindAsyncAction(updateFeedbackStatus)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(putRequest, `projects/${payload.projectId}/feedbacks/${payload.feedbackId}/status?newStatus=${payload.newStatus}`);

    if (payload.isModeration) {
      const prevStatus = payload.newStatus === FeedbacksStatusesEnum.Hidden ? FeedbacksStatusesEnum.Published : FeedbacksStatusesEnum.Hidden;
      const { data }: AxiosResponse<FeedbacksForModerationResponseType> = yield call(
        get,
        `projects/${payload.projectId}/feedbacks/moderation?status=${prevStatus}&count=0&offset=0`
      );
      return camelize(data)
    } else {
      const { data }: AxiosResponse<FeedbackForGoodsType> = yield call(
        get,
        `projects/${payload.projectId}/feedbacks?goodsId=${payload.goodsId}&count=${0}&offset=${0}`
      );
      return camelize(data);
    }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const addLikeSaga = bindAsyncAction(addLike)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(putRequest, `feedbacks/like?feedbackId=${payload.feedbackId}`);
    return {}
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const addDislikeSaga = bindAsyncAction(addDislike)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(putRequest, `feedbacks/dislike?feedbackId=${payload.feedbackId}`);
    return {}
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const addCommentSaga = bindAsyncAction(addComment)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data } = yield call(post, `projects/${payload.projectId}/feedbackComment`, snakeize(payload.data));
    return camelize(data)
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const updateCommentSaga = bindAsyncAction(updateComment)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(putRequest, `projects/${payload.projectId}/feedbackComment`, snakeize(payload.data));
    return { }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const deleteCommentSaga = bindAsyncAction(deleteComment)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(deleteRequest, `projects/${payload.projectId}/feedbackComment/${payload.commentId}`);
    return {}
  } catch (e) {
    yield put(handleServerError(e));
  }
});

export default function* () {
  yield takeLatest(triggerGetFeedbacksSettingByDomain.type, getFeedbacksSettingByDomainSaga);
  yield takeLatest(triggerGetFeedbacksSettingById.type, getFeedbacksSettingByIdSaga);
  yield takeLatest(triggerUpdateFeedbacksSetting.type, updateFeedbacksSettingSaga);
  yield takeLatest(triggerGetFeedbacksForModeration.type, getFeedbacksForModerationSaga);
  yield takeLatest(triggerGetFeedbacksForGood.type, getFeedbacksForGoodSaga);
  yield takeLatest(triggerPostNewFeedback.type, postNewFeedbackSaga);
  yield takeLatest(triggerUpdateFeedbackStatus.type, updateFeedbacksStatusSaga);
  yield takeLatest(triggerAddLike.type, addLikeSaga);
  yield takeLatest(triggerAddDislike.type, addDislikeSaga);
  yield takeLatest(triggerAddComment.type, addCommentSaga);
  yield takeLatest(triggerUpdateComment.type, updateCommentSaga);
  yield takeLatest(triggerDeleteComment.type, deleteCommentSaga);
}