import React, { Fragment } from 'react'
import styled from 'styled-components'
import { func, bool } from 'prop-types'

import { useT } from 'src/hooks/useT'
import Yup from 'src/utils/yup'
import { size } from 'src/utils/styles'

import { Input, P, PrimaryBlackButton, Divider, EnvelopIcon } from 'hopshopui'
import { BaseForm } from 'src/elements'
import { routerProps } from 'src/types/routerProps'
import history from 'src/utils/browserHistory'
import qs from "qs";
import { AddGoodText, DescriptionGoodText } from './AuthButtons'


const handleSubmit = (values, signup) => {
  const { email } = values
  const formattedEmail = email.replace(/\s+/g, '')
  let getParams = qs.parse(history.location.search, {ignoreQueryPrefix: true})
  localStorage.setItem('get-params', JSON.stringify(getParams));
  signup({
    email: formattedEmail,
    history
  })
}

const validationSchema = () => {
  return Yup.object().shape({
    email: Yup.string().email('email').required('required'),
  })
}

const redirectBack = () => {
  history.push('/redirect')
}

const AuthEmail = ({ signup, loading, login, ...props }) => {
  const { t } = useT()
  return (
    <BaseForm
      initialValues={{
        email: ''
      }}
      onSubmit={(data) => handleSubmit(data, signup)}
      validationSchema={validationSchema}
    >{({ handleSubmit, values, handleChange, handleBlur, errors, touched, isValid,  loading, ...props }) => (
      <Fragment>
        <Container>
          <IconWrapper>
            <EnvelopIcon/>
          </IconWrapper>
          <AddGoodText> {t("auth.enterEmail")} </AddGoodText>
          <DescriptionGoodText> {t("auth.enterEmailDescription")} </DescriptionGoodText>
        </Container>
        <Divider/>
        <InputContainer onSubmit={handleSubmit}>
          <InputStyled
            errors={errors}
            touched={touched}
            label={t('inputs.labels.email')}
            placeholder={t('auth.emailPlaceholder')}
            name="email"
            id="email"
            type="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            load={loading}
          />
          <Divider/>
          <ButtonStyled
            type="submit"
            disabled={!isValid}
            load={loading}
          >{t('auth.continue')}</ButtonStyled>
        </InputContainer>
      </Fragment>
    )}</BaseForm>
)}


AuthEmail.propTypes = {
  ...routerProps,
  signup: func,
  loading: bool
}

const Container = styled.div`
  padding: 20px 20px 0 20px;
`;

const IconWrapper = styled.div`
  width:150px;
  height: 150px;
  margin: 0 auto;
  @media screen and (max-width: ${size.mobile}) {
    height: 100px;
    svg{
      height: 120px;
    }
  }
`;

export const Heading = styled(P)`
  margin-left: 20px;
  color: ${props => props.theme.typo.textColor};
  @media screen and (max-width: ${size.mobile}) {
    margin-left: 0;
    margin-bottom: 40px;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
`

export const InputContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    background-color: ${props => props.passwordError && props.theme.colors.instPushed};
  }
  @media screen and (max-width: ${size.mobile}) {
    padding: 0;
  }
`
export const InputStyled = styled(Input)`
  input{
    border: unset;
    line-height: 140%;
    background-color: ${props => props.passwordError && props.theme.colors.instPushed};
  }
  label {
    color: rgba(0, 0, 0, 0.75);
    padding-top: 20px;
    padding-left: 20px;
  }
`;

export const ButtonStyled = styled(PrimaryBlackButton)`
  margin: 20px;
  width: 90%;
  @media screen and (max-width: ${size.mobile}) {
    margin: 20px 0 10px 0;
  }
`

export default AuthEmail
