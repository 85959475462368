export const stringify = (obj: Record<string | number, number | string>) => {
  if (!obj) {
    return "";
  }
  const pairArray = Object.keys(obj).map((key) => {
    if (obj[key] && parseInt(key, 10)) {
      return `${parseInt(key, 10)}|${obj[key]}`;
    }
    return undefined
  });
  const filteredArray = pairArray.filter((item) => item);
  const string = filteredArray.join(",");
  return string;
};

export const parse = (string: string) => {
  if (!string) {
    return {};
  }
  const pairStringArray = string.split(",");
  let obj: Record<string | number, string | number> = {};
  pairStringArray.forEach((pair) => {
    const pairArr = pair.split("|");
    if (pairArr[0] !== "" && parseInt(pairArr[1], 10)) {
      obj[pairArr[0]] = parseInt(pairArr[1], 10);
    }
  });
  return obj;
};
