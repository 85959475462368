import {takeLatest, call, put} from "redux-saga/effects";
import {SagaIterator} from "redux-saga";
import {AxiosResponse} from "axios";
import {bindAsyncAction} from "typescript-fsa-redux-saga";
import {handleServerError, showNotification} from "../notification";
import {
    getHtmlCode,
    GetHtmlCodeDonePayload,
    triggerGetHtmlCode,
    triggerUpdateHtmlCode,
    updateHtmlCode
} from "./htmlCodeTypes";
import {get, post} from "../../utils/requestSaga";


const getHtmlCodeSaga = bindAsyncAction(getHtmlCode)(function* ({payload}): SagaIterator {
    try {
        const {data}: AxiosResponse<GetHtmlCodeDonePayload> = yield call(
            get,
            `projects/${payload.projectId}/htmlCode`
        );
        return data;
    } catch (e) {
        yield put(handleServerError(e));
    }
})

const updateHtmlCodeSaga = bindAsyncAction(updateHtmlCode)(function* ({payload}) : SagaIterator {
    try {
        const { data }: AxiosResponse<GetHtmlCodeDonePayload> = yield call(post, `projects/${payload.projectId}/htmlCode`, {...payload.data});
        yield put(showNotification({
            key: 'notifications.changesSaved'
        }))
    }catch (e){
        yield put(handleServerError(e))
    }
})

export default function* () {
    yield takeLatest(triggerGetHtmlCode.type, getHtmlCodeSaga);
    yield takeLatest(triggerUpdateHtmlCode.type, updateHtmlCodeSaga);
}
