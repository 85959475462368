import { useEffect, useCallback, useRef } from 'react'
import history from 'src/utils/browserHistory'

const AutoscrollProvider = () => {

  const old = useRef()
  const onHistoryChange = useCallback((location) => {
    // example with multiple
    // const shouldIgnore = (/(project)|(orders)/).test(location.pathname)
    const shouldIgnore = (/(orders)/).test(location.pathname)
    if(!shouldIgnore && old.current !== location.pathname) {
      window.scroll(0, 0)
    }
    old.current = location.pathname
  }, [])

  useEffect(() => {
    old.current = history.location.pathname
    history.listen(onHistoryChange)
  }, [])
  return null
}

export default AutoscrollProvider