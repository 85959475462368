import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { SafeIcon, fullHeight, Divider } from 'hopshopui'

import Yup from 'src/utils/yup'
import AuthGlobalStyles from 'src/modules/auth/components/AuthGlobalStyles'
import { size } from 'src/utils/styles'
import { generatePass } from 'src/utils/password'
import { useT } from 'src/hooks/useT'
import { sanitize } from 'src/utils/token'
import { Formik } from 'formik'
import { CardComponentStyled, Container, Disclaimer } from '../auth/AuthPage'
import { DescriptonWrapper, IconWrapper } from 'src/modules/auth/components/AuthPassword'
import { AddGoodText } from 'src/modules/auth/components/AuthButtons'
import { ButtonStyled, InputContainer, InputStyled } from 'src/modules/auth/components/AuthEmail'

const validationSchema = () => Yup.object().shape({
  password: Yup.string()
  .required('required')
  .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@_$!#%*?/\\/.,’"~`&”-]{6,}$/, 'Password can only contain Latin letters.'),
  newPassword: Yup.string()
    .oneOf([Yup.ref('password'), 'Passwords do not match'])
    .required('required')
})
const initialValues = {
  password: '',
  newPassword: ''
}

const ResetPassword = ({ resetPassword, loading }) => {
  const { t } = useT()
  const [generated, setGenerated] = useState(false)
  const handleSubmit = useCallback(({ password }) => {
    let params = (new URL(document.location)).searchParams
    // TODO: Oo somehow changes + for spaces
    const restoreToken = sanitize(params.get('restoreToken'))
    const email = params.get('email')
    resetPassword({
      restoreToken,
      email,
      password
    })
  }, [resetPassword])

  const makeUp = useCallback((setFieldTouched, setFieldValue) => {
    const auto = generatePass(18)
    setGenerated(true)
    setFieldValue('password', auto)
    setFieldValue('newPassword', auto)
    setFieldTouched('password', true)
    setFieldTouched('newPassword', true)
  }, [setGenerated])

  const toggleGenerated = useCallback(() => {
    setGenerated(false)
  }, [setGenerated])


  return (
    <Page>
      <AuthGlobalStyles />
      <Container>
        <CardComponentStyled>
        <IconWrapper>
          <SafeIcon/>  
        </IconWrapper>

          <AddGoodText> {t('auth.newPassword')} </AddGoodText>
          <DescriptonWrapper> {t('auth.newPasswordText')} </DescriptonWrapper>

            <Formik
              enableReinitialize={true}
              // keepDirtyOnReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >{({ handleSubmit, errors, touched, values, handleChange, handleBlur, isValid, setFieldTouched, setFieldValue }) => {
              return <InputContainer onSubmit={handleSubmit}>
                <Divider/>
                  <InputStyled
                    errors={errors}
                    touched={touched}
                    label={t('inputs.labels.password')}
                    placeholder={t('inputs.placeholders.newPassword')}
                    name="password"
                    id="password"
                    type={generated ? 'text' : 'password'}
                    value={values.password}
                    onChange={(...props) => { handleChange(...props); toggleGenerated() }}
                    onBlur={handleBlur}
                    load={loading}
                    labelButton={<LabelButton>¯\_(ツ)_/¯  <span>{t('inputs.labels.makeUp')}</span></LabelButton>}
                    labelAction={() => makeUp(setFieldTouched, setFieldValue)}
                  />
                <Divider/>
                  <InputStyled
                    errors={errors}
                    touched={touched}
                    label={t('inputs.labels.repeatPassword')}
                    placeholder={t('inputs.placeholders.repeatNewPassword')}
                    name="newPassword"
                    id="newPassword"
                    type={generated ? 'text' : 'password'}
                    value={values.newPassword}
                    onChange={(...props) => { handleChange(...props); toggleGenerated() }}
                    onBlur={handleBlur}
                    load={loading}
                  />
                <Divider/>
                <ButtonStyled
                  type="submit"
                  load={loading}
                >{t('auth.continue')}
                </ButtonStyled>
              </InputContainer>
            }}</Formik>
        </CardComponentStyled>
        <DisclaimerWrapper shouldDisplay={true}>
          {t('auth.disclaimer')}
        </DisclaimerWrapper>
      </Container>
    </Page>
  )
}

// TODO: change that for element or styles
const Page = styled.div`
  background-color: ${props => props.theme.colors.black};
  ${fullHeight}
`

const DisclaimerWrapper = styled(Disclaimer)`
    @media screen and (max-width: ${size.mobile}) {
      width: 100%;
      margin: 20px 0;
      padding: 10px;
  }
`;

const LabelButton = styled.div`
  padding-right: 10px;
  font-weight: 400;
  color: black;
`

export default ResetPassword
