import React, { FC } from 'react'
import { useFavorite, UseFavoriteReturnType } from '../hooks/useFavorites'

export const FavoritesContext = React.createContext<Partial<UseFavoriteReturnType>>([{}])


export const FavoriteProvider = ({ children }: {children: React.ReactNode}) => {
  const [favortes, setFavorite, toggleFavorite, clearFavorites] = useFavorite()
  return (
    <FavoritesContext.Provider value={[favortes, setFavorite, toggleFavorite, clearFavorites]}>
      {children}
    </FavoritesContext.Provider>
  )
}

export default FavoriteProvider