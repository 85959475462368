import { connect } from 'react-redux'

import SettingsMainMarketplaces from './SettingsMarketplaces'

const mapStateToProps = () => ({})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMainMarketplaces)
