import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";

import TemplateProvider from "src/providers/TemplateProvider";
import { TemplateGlobalStyles } from "src/elements/styles/TemplateGlobal";
import { match } from "src/utils/propTypes";
import { selectActiveProject } from "src/store/projects";
import { func } from "prop-types";
import { CheckoutStatusContainer } from "src/elements";
import { getTemplateHeader } from "src/utils/getTemplate";
import { useQuery } from "src/hooks/query";

const DefaultTemplate = React.lazy(() =>
  import("../../../../templates/default/cart/CheckoutFailure")
);

const getTemplate = (template, props) => {
  switch (template) {
    default:
      return <DefaultTemplate {...props} />;
  }
};

const CheckoutFailure = ({
  match,
  getProjectDetails,
  coverSettingsDesign
}) => {
  const query = useQuery();
  const errorType = query.type;
  const { id } = match;
  const project = useSelector((state) => selectActiveProject(state, id));
  useEffect(() => {
    getProjectDetails(project.id);
  }, [project.id, getProjectDetails]);
  return (
    <TemplateProvider>
      <CheckoutStatusContainer>
        <Suspense fallback={""}>
          {getTemplateHeader("default", {
            project,
            shouldDisplayHeaderMobile: true,
            shouldDisplaySubHeader: false,
            noBottomMargin: true,
          })}
        </Suspense>
        <TemplateGlobalStyles />
        {/* TODO: add loading state */}
        <Suspense fallback={<div></div>}>
          {getTemplate("default", { project, type: errorType })}
        </Suspense>
      </CheckoutStatusContainer>
    </TemplateProvider>
  );
};

CheckoutFailure.propTypes = {
  match: match,
  getProjectDetails: func,
};

export default CheckoutFailure;
