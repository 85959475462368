import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getVkGoods, selectProjectsLoading, selectVkGoods, selectVkProjects, getProjectList, loadMoreVKGoods, selectVkProjectType, selectVkTotalCount, setFinishProjectData } from 'src/store/projects'
import { signOut } from 'src/store/auth'
import VkGoods from './VkGoods'

const mapStateToProps = (state) => ({
  projects: selectVkProjects(state),
  loading: selectProjectsLoading(state),
  goods: selectVkGoods(state),
  type: selectVkProjectType(state),
  totalCount: selectVkTotalCount(state)
})

const mapDispatchToProps = {
  getVkGoods,
  getProjectList,
  signout: signOut,
  loadMoreVKGoods,
  setFinishProjectData
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(VkGoods)
)
