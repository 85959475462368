import {
  BookingType, postConfirmBookingOrder, getBookingCalendarAvailableDayEnd,
   getLogs, getLog, getBookingCalendarAvailableDay, getUpdatedDisplayLog, 
   postLog, getServiceLog, getDisplayLog, getBookingDetails, resetLog, 
   resetDisplayLog, getEndDisplayLog, getStartDisplayLog, getDisplayLogID, postTransferringBooking, deleteLog
} from "./bookingTypes";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";

const INITIAL_STATE: BookingType = {
  loading: false,
  logDisplayID: 0,
  logs: {
    projectId: 0,
    bookingJournals: []
  },
  log: {
    item: {
      id: null,
      projectId: 0,
      title: '',
      description: '',
      image: null,
      workingDaysType: 'AnyDay',
      isWorkingHoursDependOnWeekDay: false,
      workingDaysInShift: {
        daysOffsAmount: 1,
        startDate: '',
        workingDaysAmount: 1,
      },
      workingDaysByWeekDay: {
        friday: true,
        monday: true,
        saturday: true,
        sunday: true,
        thursday: true,
        tuesday: true,
        wednesday: true,
      },
      workingHoursNotDependOnWeekDay: {
        endTime: "00:00",
        startTime: "00:00",
      },
      workingHoursDependOnWeekDay: {
        friday: {
          endTime: "00:00",
          startTime: "00:00",
        },
        monday: {
          endTime: "00:00",
          startTime: "00:00",
        },
        saturday: {
          endTime: "00:00",
          startTime: "00:00",
        },
        sunday: {
          endTime: "00:00",
          startTime: "00:00",
        },
        thursday: {
          endTime: "00:00",
          startTime: "00:00",
        },
        tuesday: {
          endTime: "00:00",
          startTime: "00:00",
        },
        wednesday: {
          endTime: "00:00",
          startTime: "00:00",
        },
      },
      crossBooking: true,
    },
    workingDaysTypes: [],
  },
  service: {
    bookingSetting: {
      confirmBooking: null,
      notificationText: null,
      remindClient: true,
      remindClientTime: "30",
      remindClientTimeType: "Minutes",
      remindMerchant: true,
      remindMerchantTime: "15",
      remindMerchantTimeType: "Minutes",
    }
  },
  displayLog: [],
  bookingCalendarAvailableDay: {
    availableDays: [],
  },
  activeBookingDetails:null,
};

export default reducerWithInitialState(INITIAL_STATE)
  .cases(
    [
      getLogs.started,
      getLog.started,
      getDisplayLog.started,
      postLog.started,
    ],
    (state) => ({
      ...state,
      loading: true,
    })
  )
  .case(getLogs.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.logs = action.result.logs
      }
      draft.loading = false;
      return draft;
    });
  })

  .case(getLog.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.log = action.result.log
      }
      draft.loading = false;
      return draft;
    });
  })

  .case(resetLog, (state) => {
    return produce(state, (draft) => {
      draft.log = INITIAL_STATE.log
    })
  })

  .case(getDisplayLog.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
          draft.displayLog = action.params.payload.lazyLoad ?  [...draft.displayLog, ...action.result.displayLog] :  action.result.displayLog;
      }
      draft.loading = false;
      return draft;
    });
  })

  .case(getUpdatedDisplayLog.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
          const index = draft.displayLog.findIndex(item => item.date === action.result.displayLog[0].date);
          draft.displayLog.splice(index, 1, action.result.displayLog[0]);
      }
      draft.loading = false;
      return draft;
    });
  })

  .case(getBookingDetails.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.activeBookingDetails = action.result.bookingDetails;
      }
      draft.loading = false;
      return draft;
    });
  })

  .case(postConfirmBookingOrder.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
            draft.activeBookingDetails = action.result.data;
            const index = draft.displayLog.findIndex(item => item.date === action.result.displayLog[0].date);
            draft.displayLog.splice(index, 1, action.result.displayLog[0]);
      }
      draft.loading = false;
      return draft;
    });
  })

  .case(resetDisplayLog, (state) => {
    return produce(state, (draft) => {
      draft.displayLog = INITIAL_STATE.displayLog
    })
  })

  .case(getStartDisplayLog.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.displayLog = action.params.payload.lazyLoad ? action.result.displayLog : [ ...action.result.displayLog, ...draft.displayLog] 
      }
      draft.loading = false;
      return draft;
    });
  })


  .case(getEndDisplayLog.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.displayLog.splice(-1, 0, ...draft.displayLog.splice(-1, 1, ...action.result.displayLog))
      }
      draft.loading = false;
      return draft;
    });
  })

  .case(getServiceLog.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.service = action.result.service
      }
      draft.loading = false;
      return draft;
    })
  })

  .case(getDisplayLogID.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.logDisplayID = action.result.logDisplayID
      }
      draft.loading = false;
      return draft;
    })
  })

  .case(getBookingCalendarAvailableDayEnd.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.bookingCalendarAvailableDay.availableDays.splice(-1, 0, ...draft.bookingCalendarAvailableDay.availableDays.splice(-1, 1, ...action.result.bookingCalendarAvailableDay.availableDays))
      }
      draft.loading = false;
      return draft;
    })
  })

  .case(getBookingCalendarAvailableDay.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.bookingCalendarAvailableDay.availableDays = action.result.bookingCalendarAvailableDay.availableDays
      }
      draft.loading = false;
      return draft;
    });
  }) 
  .case(postTransferringBooking.started, (state, action) => {
    return produce(state, (draft) => {
      draft.loading = true;
      return draft;
    });
  })
    .case(deleteLog.started, (state, action) => {
    return produce(state, (draft) => {
      draft.loading = true;
      return draft;
    });
  })
  .case(deleteLog.done, (state, action) => {
    return produce(state, (draft) => {
      draft.loading = false;
      return draft;
    });
  })
  .case(postTransferringBooking.done, (state, action) => {
    return produce(state, (draft) => {
      draft.displayLog = [];
      draft.loading = false;
      return draft;
    });
  });
