import React, { Fragment, useState, useCallback, useMemo } from 'react'
import { NotificationAction } from 'hopshopui'
import { useSelector } from 'react-redux'

import { func, string } from 'prop-types'
import history from 'src/utils/browserHistory'
import { selectProjectSettingsDesignSelection, selectProjectSettingsDesignModeSelected } from 'src/store/projectSettings'

const PaymentNotification = ({ path, text, delNotificationAction, pathname }) => {
  const [alreadyShown, setAlreadyShown] = useState(false)

  const onClose = useCallback((e) => {
    e.stopPropagation()
    setAlreadyShown(true)
    delNotificationAction()
  }, [delNotificationAction])

  const onClick = useCallback(() => {
    history.push(path)
    setAlreadyShown(true)
    delNotificationAction()
  }, [path, delNotificationAction])


  const onDesignPage = useMemo(() =>/\/project\/\d+\/design/.test(pathname), [pathname])
  const designSelection = useSelector(selectProjectSettingsDesignSelection)
  const designMode = useSelector(selectProjectSettingsDesignModeSelected)

  return(
    <Fragment>
      {!alreadyShown && text && !designSelection && !onDesignPage && !designMode && <NotificationAction onClick={onClick} onClose={onClose}>{text}</NotificationAction>}
    </Fragment>
  )
}


PaymentNotification.propTypes = {
  path: string,
  delNotificationAction: func,
  text: string
}

export default PaymentNotification