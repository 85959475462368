import actionCreatorFactory, { Action } from "typescript-fsa";

const actionCreator = actionCreatorFactory();

export type PromocodeType = {
  id?: number;
  projectId: number;
  promocode?: string;
  discountValue?: number;
  discountValueType?: string;
  minimalSum?: number;
  discountType?: string;
  endDate?: string;
  activationLimit?: number;
  isActive?: boolean;
};

export type DiscountType = {
  key: number;
  value: string;
};

export type DiscountValueType = {
  key: number;
  value: string;
};

export type GetPromocodesDonePayload = {
  items: PromocodeType[];
  discountTypes: DiscountType[];
  discountValueTypes: DiscountValueType[];
};

export const triggerGetPromocodes = actionCreator<string>(
  "project/TRIGGER_GET_PROMOCODES"
);
export const getPromocodes = actionCreator.async<
  Action<string>,
  GetPromocodesDonePayload
>("project/GET_PROMOCODES");

type ChangePromocodePayloadType = PromocodeType;
export const triggerChangePromocode = actionCreator<ChangePromocodePayloadType>(
  "project/TRIGGER_CHANGE_PROMOCODE"
);
export const changePromocode = actionCreator.async<
  Action<ChangePromocodePayloadType>,
  null
>("project/CHANGE_PROMOCODE");

type AddPromocodePayloadType = PromocodeType;
export const triggerAddPromocode = actionCreator<AddPromocodePayloadType>(
  "project/TRIGGER_ADD_PROMOCODE"
);
export const addPromocode = actionCreator.async<
  Action<AddPromocodePayloadType>,
  null
>("project/ADD_PROMOCODE");

type DeletePromocodePayloadType = {
  promocodeId: number;
  projectId: number;
};
export const triggerDeletePromocode = actionCreator<DeletePromocodePayloadType>(
  "project/TRIGGER_DELETE_PROMOCODE"
);
export const deletePromocode = actionCreator.async<
  Action<DeletePromocodePayloadType>,
  null
>("project/DELETE_PROMOCODE");

export type PromocodesStateType = {
  readonly loading: boolean;
  readonly promocodes: PromocodeType[];
  readonly discountTypes: DiscountType[];
  readonly discountValueTypes: DiscountValueType[];
};

export type PromocodesResponseType = {
  items: PromocodeType[];
  discountTypes: DiscountType[];
  discountValueTypes: DiscountValueType[];
};
