import {
  AnalyticsStateType,
  getAnalytics
} from "./analyticsTypes";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";
import { camelize } from "casing";

const INITIAL_STATE: AnalyticsStateType = {
  loading: false,
  analytics: {},
};

export default reducerWithInitialState(INITIAL_STATE)
  .cases(
    [
      getAnalytics.started,
    ],
    (state) => ({
      ...state,
      loading: true,
    })
  )
  .case(getAnalytics.done, (state, action) => {
    return produce(state, (draft) => {
      draft.loading = false;
      if (action.result) {
        action.result.forEach((analytic) => {
          draft.analytics[camelize(analytic.name.replace(/\./g, '').replace(/-/g, '_'))] = analytic
        })
      }
      return draft;
    });
  });