import { selectOrderToEdit } from './ordersSelector'

export type orderType = {
 id: string,
 project_id: string,
 currency_id: string,
 contact_name?: string,
 contact_phone: string
 contact_email: string
 commentary?: string,
 payment_method: {
   system_name: string
   friendly_name: string
 }
 order_status_id: number
 payment_status_id: 1 | 2 | 3 | 4
}


export type PaymentStates = {
  1: "CashPayment",
  2: "PayedOnline",
  3: "PendingOnline",
  4: "ErrorOnline"
}

export const selectOrderToEditWithTypes: (
  state: any,
  props: {
    active: string;
  }
) => {
  agree: boolean;
  comment: string;
  contact: string;
  deliveryId?: string;
  email: string;
  payment: string;
  phone: string;
  deliveryOptionString?: string;
} | null = selectOrderToEdit;