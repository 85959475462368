import {
  takeEvery,
  put,
  call,
  take,
  fork,
  takeLatest,
} from "redux-saga/effects";
import { get, post } from "src/utils/requestSaga";
import { SagaIterator } from "redux-saga";
import { AxiosResponse } from "axios";
import { handleServerError, showNotification } from "../notification";
import { camelize, snakeize } from "casing";
import { triggerGetLastPayment } from "../payment";
import { forEachLeadingCommentRange } from "typescript";
import {
  getMarketplaces, MarketplacesResponseType, setMarketplaces, getDocumentsLinks, triggerGetDocumentsLinks, triggerGetMarketplaces, triggerSetMarketplaces, triggerSetMarketplacesDisabled} from "./marketplacesTypes";
import { bindAsyncAction } from "typescript-fsa-redux-saga";

const getMarketplacesSaga = bindAsyncAction(getMarketplaces)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<MarketplacesResponseType> = yield call(
      get,
      `projects/${payload.projectId}/marketplaces`
    );
    return { marketplaces: camelize(data) };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getDocumentsLinksSaga = bindAsyncAction(getDocumentsLinks)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<MarketplacesResponseType> = yield call(
      get,
      `projects/${payload.domain}/marketplace/documentslinks`
    );
    return { documentsLinks: camelize(data) };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const setMarketplacesSaga = bindAsyncAction(setMarketplaces)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<MarketplacesResponseType> = yield call(
      post,
      `projects/${payload.projectId}/marketplaces/${payload.marketplaceId}/enable`,
    );
    yield put(triggerGetMarketplaces({projectId: payload.projectId}));
    yield put(showNotification({
      key: 'notifications.changesSaved'
    }))
    return { marketplaces: camelize(data) };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const setMarketplacesDisabledSaga = bindAsyncAction(setMarketplaces)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<MarketplacesResponseType> = yield call(
      post,
      `projects/${payload.projectId}/marketplaces/${payload.marketplaceId}/disable`,
    );
    yield put(triggerGetMarketplaces({projectId: payload.projectId}));
    yield put(showNotification({
      key: 'notifications.changesSaved'
    }))
    return { marketplaces: camelize(data) };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

export default function* () {
  yield takeLatest(triggerGetMarketplaces.type, getMarketplacesSaga);
  yield takeLatest(triggerGetDocumentsLinks.type, getDocumentsLinksSaga);
  yield takeLatest(triggerSetMarketplaces.type, setMarketplacesSaga);
  yield takeLatest(triggerSetMarketplacesDisabled.type, setMarketplacesDisabledSaga) 
}