import React, {
  memo,
  FC,
  useMemo,
  FormEvent,
  MouseEvent,
  useCallback,
  ChangeEvent,
} from "react";
import {
  Select,
  Divider,
  PillCard,
  DoneIcon,
  HeaderText,
  PillLineItem,
  ArrowLeftIcon,
  AddGoodButton,
  PillSelectLabel,
  PillMarginContainer,
  PillHorizontalContainer,
} from "hopshopui";
import { useT } from "src/hooks/useT";
import styled from "styled-components";
import { DesignFormContainer } from "./DesignHeaderForm";
import DesignColorPickerInput from "./DesignColorPickerInput";
import { DesignHeader } from "src/elements/layout/DesignHeader";
import { BackgroundTypes } from "src/templates/utils/types/projectSettings";
import { ConnectedDesignBackgroundForm } from "../containers/DesignBackgroundForm";

const DesignBackgroundForm: FC<ConnectedDesignBackgroundForm> = ({
  match,
  background,
  changeDesignBackground,
  checkAndDisableDesignType,
  triggerSetProjectDesignSettings,
}) => {
  const { t } = useT();
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      triggerSetProjectDesignSettings(match.params.id);
    },
    [match, triggerSetProjectDesignSettings]
  );

  const handleDecline = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    checkAndDisableDesignType()
  }, [checkAndDisableDesignType]);

  const handleBackgroundMonotoneChange = useCallback(
    (color: string) => {
      changeDesignBackground({
        type: "Monotone",
        payload: {
          color,
        },
      });
    },
    [changeDesignBackground]
  );

  return (
    <form onSubmit={handleSubmit}>
      <DesignHeader isForm={true}>
        <StyledBackButton onClick={handleDecline} >
          <ArrowLeftIcon fill="#000000" />
        </StyledBackButton>
        <HeaderTextStyled>{t("design.backgroundDesign")}</HeaderTextStyled>
        <StyledDoneButton>
          <DoneIcon stroke="#000000" />
        </StyledDoneButton>
      </DesignHeader>
      <DesignFormContainer>
        <PillCard white>
          <PillMarginContainer>
            <PillLineItem>
              <div>
                <PillSelectLabel>
                  {t("design.backgroundFillTypeLabel")}
                </PillSelectLabel>
                <Select
                  value={background.type}
                  pillStyle
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    console.log("e");
                  }}
                >
                  <option value="Monotone">
                    {t("design.backgroundFillTypeSolid")}
                  </option>
                </Select>
              </div>
            </PillLineItem>
            <Divider />
            <PillHorizontalContainer itemCount={1}>
              <DesignColorPickerInput
                value={
                  background.type === "Monotone"
                    ? background.payload.color || ""
                    : ""
                }
                onChange={handleBackgroundMonotoneChange}
                placeholder={t("design.placeholderDefault")}
                label={t("design.backgroundColorLabel")}
              />
            </PillHorizontalContainer>
          </PillMarginContainer>
        </PillCard>
      </DesignFormContainer>
    </form>
  );
};

const StyledBackButton = styled(AddGoodButton)`
  height: 40px;
  width: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  flex: none;
  background: #FFFFFF;
  border: none;
  &:hover{
    background: none;
  }
`;

const StyledDoneButton = styled(AddGoodButton)`
  display: block;
  flex: none;
  color: #000000;
  border: none;
  background: none;
  &:hover{
    background: none;
  }
`;

const HeaderTextStyled = styled(HeaderText)`
  font-weight: 500;
  color: #000000;
`

export default memo(DesignBackgroundForm);
