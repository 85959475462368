import React, { FC, useCallback, MouseEvent, memo } from "react";
import { useParams } from "react-router-dom";
import history from "src/utils/browserHistory";

type Props = {
  id: number;
  alias: string;
  disabled?: boolean
};

const ItemLink: FC<Props> = ({ id, alias, children, disabled }) => {
  const params = useParams<{id: string}>();

  const handleClick = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (!disabled) {
      history.push(`/project/${params.id}/goods/${alias}`)
    }
  }, [disabled, params.id, alias]);

  return <a href={`/project/${params.id}/goods/${alias}`} onClick={handleClick}>
    {children}
  </a>;
};


export default memo(ItemLink)