import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { selectProfileLoading } from 'src/store/profile'

import NotificationsChangeWebhook from './NotificationsChangeWebhook'

const mapStateToProps = (state) => ({
  loading: selectProfileLoading(state)
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(
    NotificationsChangeWebhook
  )
)
