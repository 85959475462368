import React, { Fragment, Suspense, useEffect, memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { func, bool } from 'prop-types'
import { CartContext } from 'src/templates/utils/providers/CartProvider'

import getImageUrl from 'src/utils/getImageUrl'
import { TemplateGlobalStyles } from 'src/elements/styles/TemplateGlobal'
import { match } from 'src/utils/propTypes'
import { selectActiveProject, selectFavoriteProjectGoods, selectProjectGoodsBooking } from 'src/store/projects'
import TemplateProvider from 'src/providers/TemplateProvider'
import { getTemplateHeader, getTemplateFooter } from 'src/utils/getTemplate'
import { useCartUrl } from 'src/hooks/cartUrl'
import history from "src/utils/browserHistory";

// TODO: change that
import CartShimmer from 'src/templates/default/cart/CartShimmer'

const DefaultTemplate = React.lazy(() => import('../../../templates/default/cart'))

const getTemplate = (template, props) => {
  switch (template) {
    default:
      return <DefaultTemplate {...props} />
  }
}

const ProjectCartPage = ({
  match,
  loading,
  getProjectGoodsByIds,
  submitCheckingBookingOrder,
}) => {
  const { id } = match.params
  const { cartItems, cartBookingItems } = useContext(CartContext)
  const { memoLoading } = useCartUrl(loading)
  const project = useSelector(state => selectActiveProject(state, id))
  const goods = useSelector(state => selectFavoriteProjectGoods(state, id, cartItems))
  const goodsBooking = useSelector(state => selectProjectGoodsBooking(state))

  useEffect(() => {
    const keys = Object.keys(cartItems)
    if (keys.length > 0) {
      getProjectGoodsByIds(keys)
    }
  }, [Object.keys(cartItems).length])

  const onItemClick = (alias) => {
    history.push(`/project/${id}/goods/${alias}`)
  }
  useEffect(() => {
    if (cartBookingItems) {
      submitCheckingBookingOrder({
        bookingOrder: cartBookingItems
      })
    }
  }, [cartBookingItems])

  return (
    <TemplateProvider currency={project.currency}>
      <Fragment>
        <TemplateGlobalStyles />
        <Suspense fallback={''}>
          {getTemplateHeader('default', {
            project,
            cartActive: true,
            isVisible: false
          })}
        </Suspense>
        <Suspense fallback={''}>
          {getTemplate('default', { project, getImageUrl, goods, loading: memoLoading, onItemClick, goodsBooking })}
        </Suspense>
        <Suspense fallback={''}>
          {getTemplateFooter('default', { title: project.title, isAdult: project.isAdult || project.hasAdultGoods, mobileHide: true })}
        </Suspense>
      </Fragment>
    </TemplateProvider>
  )
}

ProjectCartPage.propTypes = {
  match: match,
  getProjectGoodsByIds: func.isRequired,
  loading: bool
}

export default memo(ProjectCartPage)