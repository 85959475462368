import { connect, ConnectedProps } from "react-redux";
import { RootState } from "src/store/rootTypes";
import { DefinedRouterComponentProps } from "src/types";
import { withRouter } from "react-router-dom";
import { selectProjectSettingsHeaderDesign, updateProjectDesignHeader, toggleProjectDesignHeaderKey, changeHeaderTextColor, changeHeaderBackgroundColor, changeHeaderLogoRounding, triggerSetProjectDesignSettings, selectProjectSettingsLoading, checkAndDisableDesignType } from "src/store/projectSettings";
import DesignHeaderForm from '../components/DesignHeaderForm'

const mapStateToProps = (state: RootState, props: DefinedRouterComponentProps) => ({
  headerDesign: selectProjectSettingsHeaderDesign(state),
  loading: selectProjectSettingsLoading(state)
})

const mapDispatchToProps = {
  updateProjectDesignHeader,
  toggleProjectDesignHeaderKey,
  changeHeaderTextColor,
  changeHeaderBackgroundColor,
  changeHeaderLogoRounding,
  triggerSetProjectDesignSettings,
  checkAndDisableDesignType
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ConnectedDesignHeaderFormProps = ConnectedProps<typeof connector> & DefinedRouterComponentProps

export default withRouter(connector(DesignHeaderForm))