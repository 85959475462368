import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";
import {
  ProjectSettingsStateType,
  getCardViewSettings,
  updateCardView,
  toggleDesignMode,
  changeProjectDesignType,
  updateProjectDesignHeader,
  toggleProjectDesignHeaderKey,
  disableDesignType,
  changeHeaderTextColor,
  changeHeaderBackgroundColor,
  changeHeaderLogoRounding,
  getProjectDesignSettings,
  toggleProjectSettingsLoading,
  setProjectDesignSettings,
  checkAndDisableDesignType,
  toggleDesignConfimation,
  changeFooterTextColor,
  changeFooterBackgroundColor,
  toggleProjectDesignFooterKey,
  changeDesignBackground,
  setBlockDesignSettings,
  setInitialBlockDesignSettings,
  setModuleButtonDesign,
  updateBlockDesignSettings,
  setInitialModuleButtonDesign,
  updateButtonkDesignSettings,
  getCoverCustomizationSettings,
  toggleProjectDesignCoverKey,
  changeStringProjectDesignCoverKey,
  changeNumberProjectDesignCoverKey,
  loadNewCover,
  updateCoverCustomizationSettings,
  resetCover,
} from "./projectSettingsTypes";
import {
  HeaderSettings,
  FooterSettings,
  MonotoneBackground,
} from "src/templates/utils/types/projectSettings";
import isEqual from "lodash/isEqual";
import { CoverDefaultCustomization } from "src/templates/utils/defaultDesignValues";

export const DefaultHeaderSettings: HeaderSettings = {
  showContacts: true,
  showDescription: true,
  showLogo: true,
  showTitle: true,
  extendToFullScreen: true,
  logoRounding: 200,
};

export const DefaultFooterSettings: FooterSettings = {
  showPlatformName: true,
  showTitle: true,
  extendToFullScreen: true,
};

export const DefaultProjectBackground: MonotoneBackground = {
  type: "Monotone",
  payload: {
    color: "#eceff1",
  },
};

const INITIAL_STATE: ProjectSettingsStateType = {
  loading: false,
  designCancelConfirmation: false,
  cardView: {},
  designSelection: false,
  header: DefaultHeaderSettings,
  initialLayoutView: {
    header: DefaultHeaderSettings,
    footer: DefaultFooterSettings,
    background: DefaultProjectBackground,
    moduleButton: null,
    block: null,
    cover: null,
  },
  moduleButton: null,
  footer: DefaultFooterSettings,
  background: DefaultProjectBackground,
  block: null,
  cover: CoverDefaultCustomization,
};

export default reducerWithInitialState(INITIAL_STATE)
  .cases(
    [
      getCoverCustomizationSettings.started,
      updateCardView.started,
      getProjectDesignSettings.started,
      setProjectDesignSettings.started,
    ],
    (state) => {
      return { ...state, loading: true };
    }
  )
  .cases(
    [
      updateCoverCustomizationSettings.started,
    ],
    (state) => {
      return { ...state, loading: true };
    }
  )
  .cases(
    [
      getProjectDesignSettings.done, 
      setProjectDesignSettings.done, 
      getCoverCustomizationSettings.done,
      updateCoverCustomizationSettings.done,
    ],
    (state) => {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }
  )
  .case(toggleProjectSettingsLoading, (state) => {
    return produce(state, (draft) => {
      draft.loading = !draft.loading;
    });
  })
  .cases([getCardViewSettings.done, updateCardView.done], (state, payload) => {
    return produce(state, (draft) => {
      const { projectId, ...restData } = payload.result;
      draft.cardView[projectId] = restData;
      draft.loading = false;
    });
  })
  .cases(
    [getProjectDesignSettings.done, setProjectDesignSettings.done],
    (state, payload) => {
      return produce(state, (draft) => {
        if (payload.result) {
          draft.initialLayoutView = {
            ...payload.result.layoutView,
            cover: draft.cover,
            moduleButton: null,
            block: null,
          };
          draft.header = payload.result.layoutView.header;
          draft.footer = payload.result.layoutView.footer;
          draft.background = payload.result.layoutView.background;
          delete draft.designModeSelected;
        }
      });
    }
  )
  .cases(
    [getCoverCustomizationSettings.done],
    (state, payload) => {
      return produce(state, (draft) => {
        draft.cover = payload.result
        draft.initialLayoutView.cover = payload.result
      });
    }
  )
  .case(toggleProjectDesignCoverKey, (state, payload) => {
    return produce(state, (draft) => {
      if(draft.cover){
        draft.cover.coverSettings[payload] = !draft.cover.coverSettings[payload];
      }
    });
  })
  .case(changeStringProjectDesignCoverKey, (state, payload) => {
    return produce(state, (draft) => {
      if(draft.cover){
        draft.cover.coverSettings[payload.field] = payload.value;
      }
    });
  })
  .case(changeNumberProjectDesignCoverKey, (state, payload) => {
    return produce(state, (draft) => {  
      if(draft.cover){
        draft.cover.coverSettings[payload.field] = payload.value;
      }
    });
  })
  .case(loadNewCover, (state, payload) => {
    return produce(state, (draft) => {  
      if(draft.cover){
        draft.cover.newCoverImage = payload.imageUrl;
        draft.cover.coverSettings.image = payload.image;
      }
    });
  })
  .case(resetCover, (state, payload) => {
    return produce(state, (draft) => {  
      if(draft.cover){
        draft.cover.newCoverImage = null;
        draft.cover.coverSettings.image = null;
      }
    });
  })
  .case(toggleDesignMode, (state) => {
    return produce(state, (draft) => {
      draft.designSelection = !draft.designSelection;
    });
  })
  .case(changeProjectDesignType, (state, payload) => {
    return produce(state, (draft) => {
      draft.designModeSelected = payload;
      draft.designSelection = false;
    });
  })
  .case(updateProjectDesignHeader, (state, payload) => {
    return produce(state, (draft) => {
      draft.header = payload;
    });
  })
  .case(toggleProjectDesignHeaderKey, (state, payload) => {
    return produce(state, (draft) => {
      draft.header[payload] = !draft.header[payload];
    });
  })
  .case(disableDesignType, (state) => {
    return produce(state, (draft) => {
      delete draft.designModeSelected;
      draft.cover = draft.initialLayoutView.cover;
      draft.header = draft.initialLayoutView.header;
      draft.footer = draft.initialLayoutView.footer;
      draft.background = draft.initialLayoutView.background;
      draft.moduleButton = draft.initialLayoutView.moduleButton;
      draft.block = null;
    });
  })
  .case(checkAndDisableDesignType, (state) => {
    return produce(state, (draft) => {
      const currentLayoutViewSettings = {
        header: draft.header,
        footer: draft.footer,
        background: draft.background,
        block: draft.block,
        cover: draft.cover,
        moduleButton: draft.moduleButton,
      };
      const same = isEqual(draft.initialLayoutView, currentLayoutViewSettings);
      if (same) {
        delete draft.designModeSelected;
        draft.header = draft.initialLayoutView.header;
        draft.footer = draft.initialLayoutView.footer;
        draft.cover = draft.initialLayoutView.cover;
        draft.background = draft.initialLayoutView.background;
      } else {
        draft.designCancelConfirmation = true;
      }
    });
  })
  .case(toggleDesignConfimation, (state) => {
    return produce(state, (draft) => {
      draft.designCancelConfirmation = !draft.designCancelConfirmation;
    });
  })
  .case(changeHeaderTextColor, (state, payload) => {
    return produce(state, (draft) => {
      draft.header.textColor = payload;
    });
  })
  .case(changeHeaderBackgroundColor, (state, payload) => {
    return produce(state, (draft) => {
      draft.header.backgroundColor = payload;
    });
  })
  .case(changeHeaderLogoRounding, (state, payload) => {
    return produce(state, (draft) => {
      draft.header.logoRounding = Number(payload) || 0;
    });
  })
  .case(changeDesignBackground, (state, payload) => {
    return produce(state, (draft) => {
      draft.background = payload;
    });
  })
  .case(changeFooterTextColor, (state, payload) => {
    return produce(state, (draft) => {
      draft.footer.textColor = payload;
    });
  })
  .case(changeFooterBackgroundColor, (state, payload) => {
    return produce(state, (draft) => {
      draft.footer.backgroundColor = payload;
    });
  })
  .case(toggleProjectDesignFooterKey, (state, payload) => {
    return produce(state, (draft) => {
      draft.footer[payload] = !draft.footer[payload];
    });
  })
  .case(setBlockDesignSettings, (state, payload) => {
    return produce(state, (draft) => {
      draft.block = payload;
    });
  })
  .case(setInitialBlockDesignSettings, (state, payload) => {
    return produce(state, (draft) => {
      draft.initialLayoutView.block = payload;
    });
  })
  .case(setInitialModuleButtonDesign, (state, payload) => {
    return produce(state, (draft) => {
      draft.initialLayoutView.moduleButton = payload;
    });
  })
  .case(setModuleButtonDesign, (state, payload) => {
    return produce(state, (draft) => {
      draft.moduleButton = payload;
    });
  })
  .case(updateBlockDesignSettings.done, (state, payload) => {
    return produce(state, (draft) => {
      delete draft.designModeSelected;
      draft.block = null;
    });
  })
  .case(updateCoverCustomizationSettings.done, (state, payload) => {
    return produce(state, (draft) => {
      delete draft.designModeSelected;
      draft.moduleButton = null;
    });
  })
  .case(updateButtonkDesignSettings.done, (state, payload) => {
    return produce(state, (draft) => {
      delete draft.designModeSelected;
      draft.moduleButton = null;
    });
  });
  