import * as Yup from 'yup'

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    // eslint-disable-next-line no-template-curly-in-string
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path
    },
    test(value) {
      return value === this.resolve(ref)
    }
  })
}

Yup.addMethod(Yup.string, 'equalTo', equalTo)

function password(ref, msg) {
  return this.test('password', 'password', value => (
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@_$!#%*?.,’\-”"~`&])[A-Za-z\d@_$!#%*?.,’\-”"~`&]{10,}$/.test(value)
  ))
}

Yup.addMethod(Yup.string, 'password', password)

function whatsapp(ref, msg) {
  return this.test('whatsapp', 'whatsapp', value => (
    /wa.me/.test(value)
  ))
}
function telegram(ref, msg) {
  return this.test('telegram', 'telegram', value => (
    /t.me/.test(value)
  ))
}
function instagram(ref, msg) {
  return this.test('instagram', 'instagram', value => (
    /instagram/.test(value)
  ))
}
function vkontakte(ref, msg) {
  return this.test('vkontakte', 'vkontakte', value => (
    /vk.com/.test(value)
  ))
}

function ok(ref, msg) {
  return this.test('ok', 'ok', value => (
    /ok.ru/.test(value)
  ))
}

function YMCounter() {
  return this.test('YMCounter', 'YMCounter', value => (
    /<!-- Yandex.Metrika counter -->/.test(value)
  ))
}



Yup.addMethod(Yup.string, 'whatsapp', whatsapp)
Yup.addMethod(Yup.string, 'telegram', telegram)
Yup.addMethod(Yup.string, 'instagram', instagram)
Yup.addMethod(Yup.string, 'vkontakte', vkontakte)
Yup.addMethod(Yup.string, 'ok', ok)
Yup.addMethod(Yup.string, 'YMCounter', YMCounter)

const goodSchema = () => {
  return Yup.object().shape({
    price: Yup.number().max(9999999999).nullable().default(null),
    oldPrice: Yup.number().max(9999999999).nullable().default(null),
    quantity: Yup.number().max(9999999999).nullable().default(null),
    digitalGoodsContent: Yup.string()
      .test({
        name: "digital content",
        message: "Incorrect digital content",
        test: function (value) {
          let variantsImpactDigital = false;
          this.parent.variants.forEach((variant) => {
            if (variant.canImpactDigital) {
              variantsImpactDigital = true;
            }
          });
          if (this.parent.goodsTypeId === 2 && !variantsImpactDigital) {
            if (Number(this.parent.digitalGoodsTypeId) === 1) {
              return /^(https?:\/\/[^\s]+)$/.test(value);
            }
            if (Number(this.parent.digitalGoodsTypeId) === 2) {
              return /^(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))$/.test(
                value
              );
            }
            if (Number(this.parent.digitalGoodsTypeId) === 3) {
              return Boolean(value);
            }
            return true;
          }
          return true;
        },
      })
      .nullable(),
    variants: Yup.array().of(
      Yup.object()
        .shape({
          price: Yup.number().max(9999999999).nullable().default(null),
          oldPrice: Yup.number().max(9999999999).nullable().default(null),
          quantity: Yup.number().max(9999999999).nullable().default(null),
        })
        .test({
          name: "digital content1",
          message: "Incorrect digital content",
          test: function (value) {
            if (value.goodsTypeId === 2 && value.canImpactDigital) {
              if (Number(value.digitalGoodsTypeId) === 1) {
                return /^(https?:\/\/[^\s]+)$/g.test(value.digitalGoodsContent);
              }
              if (Number(value.digitalGoodsTypeId) === 2) {
                return /^(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))$/.test(
                  value.digitalGoodsContent
                );
              }
              if (Number(value.digitalGoodsTypeId) === 3) {
                return Boolean(value.digitalGoodsContent);
              }
              return true;
            }
            return true;
          },
        })
    ),
    serviceGoods: Yup.array().of(
      Yup.object().shape({
        bookingTimeType: Yup.string(),
        bookingDaysType: Yup.string(),
        bookAllDay: Yup.boolean(),

        bookingDaysPeriods: Yup.object().nullable().when("bookingDaysType", {
          is: "Periods",
          then: Yup.object().shape({
                periods: Yup.array().min(1),
              }),
        }),

        bookingTimeFree: Yup.object()
          .nullable()
          .when(
            [
              "bookingTimeType",
              "bookAllDay",
              "bookingDaysPeriods",
              "bookingDaysType",
            ],
            {
              is: (
                bookingTimeType,
                bookAllDay,
                bookingDaysPeriods,
                bookingDaysType
              ) => {
                return (
                  ((bookingDaysType === "Periods" &&
                    !bookingDaysPeriods.bookAllPeriod &&
                    !bookAllDay) ||
                    ((bookingDaysType === "Concrete" ||
                      bookingDaysType === "FromBookingJournal") &&
                      !bookAllDay)) &&
                  bookingTimeType === "Free"
                );
              },
              then: Yup.object().shape({
                duration: Yup.string().required(),
              }),
            }
          ),

        bookingTimeByTheDay: Yup.object()
          .nullable()
          .when(["bookingTimeType", "bookAllDay"], {
            is: (
              bookingTimeType,
              bookAllDay,
              bookingDaysPeriods,
              bookingDaysType
            ) => {
              return (
                ((bookingDaysType === "Periods" &&
                  !bookingDaysPeriods.bookAllPeriod &&
                  !bookAllDay) ||
                  ((bookingDaysType === "Concrete" ||
                    bookingDaysType === "FromBookingJournal") &&
                    !bookAllDay)) ||
                bookingTimeType === "ByTheDay"
              );
            },
            then: Yup.object().shape({
              checkOut: Yup.string().required(),
              checkIn: Yup.string().required(),
            }),
          }),
      })
    ),
      modules: Yup.array().of(
          Yup.object().shape({
              moduleType: Yup.string(),
              payload: Yup.object().nullable().when('moduleType', {
                  is: "ButtonSet",
                  then: Yup.object().shape({
                      addSecondButton: Yup.boolean(),
                      firstButton: Yup.object().shape({
                          text: Yup.string().required(),
                          url: Yup.string().required()
                      }),
                      secondButton: Yup.object().nullable().when('addSecondButton', {
                          is: true,
                          then: Yup.object().shape({
                              text: Yup.string().required(),
                              url: Yup.string().required()
                          })
                      })
                  })
              })
          })
      ),
  });
};

Yup.addMethod(Yup.object, 'goodSchema', goodSchema)

export default Yup
