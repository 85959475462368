import { ImageType } from "src/templates/utils/types/goodType";
import actionCreatorFactory, { Action } from "typescript-fsa";

const actionCreator = actionCreatorFactory();

export type FeedbacksType = {
  loading: boolean;
  feedbacksModerationCount: number;
  feedbacksGoodCount: number;
  statistics: {
    statistic: StatisticsType | null;
    hiddenFeedbacksCount: number | null,
  }
  states: string[];
  feedbacksSettings: FeedbacksSettingsResponseType | null;
  feedbacksForModeration: FeedbackForModerationType[] | null;
  feedbacks: FeedbackContent[] | null;
  likedFeedbacksIds:number[],
  dislikedFeedbacksIds: number[],
}

export type FeedbacksSettingsResponseType = {
  feedbacksSetting: FeedbacksSettingType;
  feedbacksSortTypes: {
    key: number;
    value: string;
  }[]
};

export type FeedbacksSettingType = {
  id: number | null;
  projectId: number | null;
  clientsFeedbacks: boolean;
  anonymousFeedbacks: boolean;
  premoderation: boolean;
  attachPhotos: boolean;
  helpfulnessAssessment: boolean;
  feedbacksSortType: string;
}

export type ComentType = {
  id: number;
  createdDate: string;
  text: string;
}

export type FeedbackContent = {
  id: number;
  authorName: string;
  createdDate: string;
  text: string;
  images: ImageType[],
  goodsRating: number;
  helpfulness: number;
  comment: ComentType;
  orderNumber: string;
}

export type FeedbackForModerationType = FeedbackContent & {
  goodsId: number;
  goodsTitle: string;
  goodsPrice: number;
  goodsImage: ImageType;
  normalizedTitle?: string;
}

export type RatingType = {
  rating: number;
  count: number;
}

export type StatisticsType = {
  totalCount: number;
  avarageRating: number;
  ratings: RatingType[];
}

export type FeedbacksForModerationResponseType = {
  feedbacks: FeedbackForModerationType[];
  statistics: StatisticsType;
  states: string[];
}

export type FeedbackForGoodsType = {
  feedbacks: FeedbackContent[];
  statistics: StatisticsType;
  hiddenFeedbacksCount: number;
}

export type NewFeedbackType = {
  goodsId: number;
  authorName: string;
  authorEmail: string;
  text: string;
  images: ImageType[];
  goodsRating: number;
}

export enum FeedbacksStatusesEnum {
  New = "New",
  Published = "Published",
  Hidden = "Hidden",
}

export type FeedbacksStatuses = FeedbacksStatusesEnum.Hidden | FeedbacksStatusesEnum.New | FeedbacksStatusesEnum.Published;

export type ComentPayloadType = {
  id: number | undefined;
  feedbackId: number;
  text: string
}

// get feedbacks settings by domain
export const triggerGetFeedbacksSettingByDomain = actionCreator<{ domain: string; }>(
  "feedback/TRIGGER_FEEDBACKS_SETTINGS_BY_DOMAIN"
);

export const getFeedbacksSettingByDomain = actionCreator.async<
  Action<{ domain: string; }>,
  { data: FeedbacksSettingsResponseType }
>("feedback/FEEDBACKS_SETTINGS_BY_DOMAIN");

// get feedbacks settings by id
export const triggerGetFeedbacksSettingById = actionCreator<{ id: string; }>(
  "feedback/TRIGGER_FEEDBACKS_SETTINGS_BY_ID"
);

export const getFeedbacksSettingById = actionCreator.async<
  Action<{ id: number; }>,
  { data: FeedbacksSettingsResponseType }
>("feedback/FEEDBACKS_SETTINGS_BY_ID");

// update feedbacks settings
export const triggerUpdateFeedbacksSetting = actionCreator<FeedbacksSettingType>(
  "feedback/TRIGGER_UPDATE_FEEDBACKS_SETTINGS"
);

export const updateFeedbacksSetting = actionCreator.async<
  Action<FeedbacksSettingType>,
  { data: FeedbacksSettingsResponseType }
>("feedback/UPDATE_FEEDBACKS_SETTINGS");


// get feedbacks for moderation
export const triggerGetFeedbacksForModeration = actionCreator<{ projectId: number; status: FeedbacksStatuses | undefined; count: number | undefined; offset: number | undefined; isNewStatus: boolean | undefined; }>(
  "feedback/TRIGGER_GET_FEEDBACKS_FOR_MODERATION"
);

export const getFeedbacksForModeration = actionCreator.async<
  Action<{ projectId: number; status: FeedbacksStatuses | undefined; count: number | undefined; offset: number | undefined; isNewStatus: boolean | undefined; }>,
  FeedbacksForModerationResponseType
>("feedback/GET_FEEDBACKS_FOR_MODERATION");

// get more feedbacks
export const triggerGetMoreFeedbacksForModeration = actionCreator<{ projectId: number; status: FeedbacksStatuses | undefined; count: number | undefined; offset: number | undefined; }>(
  "feedback/TRIGGER_GET_MORE_FEEDBACKS_FOR_MODERATION"
);

export const getMoreFeedbacksForModeration = actionCreator.async<
  Action<{ projectId: number; status: FeedbacksStatuses | undefined; count: number | undefined; offset: number | undefined; }>,
  FeedbacksForModerationResponseType
>("feedback/GET_MORE_FEEDBACKS_FOR_MODERATION");

// get feedbacks for good
export const triggerGetFeedbacksForGood = actionCreator<{ projectId: number; goodsId: number | undefined; count: number | undefined; offset: number | undefined; loadMore: boolean | undefined; }>(
  "feedback/TRIGGER_GET_FEEDBACKS_FOR_GOOD"
);

export const getFeedbacksForGood = actionCreator.async<
  Action<{ projectId: number; goodsId: number | undefined; count: number | undefined; offset: number | undefined; loadMore: boolean | undefined; }>,
  FeedbackForGoodsType
>("feedback/GET_FEEDBACKS_FOR_GOOD");


// get feedbacks for good
  export const triggerPostNewFeedback = actionCreator<{data: NewFeedbackType; projectId: number; goodTitle: string; callback:(status?: string)=>void }>(
  "feedback/TRIGGER_POST_NEW_FEEDBACK"
);

export const postNewFeedback = actionCreator.async<
  Action<{data: NewFeedbackType; projectId: number; goodTitle: string; callback:(status?: string)=>void }>,
  null
>("feedback/POST_NEW_FEEDBACK");

// update feedback status
export const triggerUpdateFeedbackStatus = actionCreator<{ projectId: number; feedbackId: number; newStatus: FeedbacksStatuses; goodsId: number | undefined; isModeration: boolean | undefined; }>(
  "feedback/TRIGGER_UPDATE_FEEDBACK_STATUS"
);

export const updateFeedbackStatus = actionCreator.async<
  Action<{ projectId: number; feedbackId: number; newStatus: FeedbacksStatuses; goodsId: number | undefined; isModeration: boolean | undefined;}>,
  FeedbacksForModerationResponseType | FeedbackForGoodsType
>("feedback/UPDATE_FEEDBACK_STATUS");

//  add like to feedback
export const triggerAddLike = actionCreator<{ feedbackId: number; }>(
  "feedback/TRIGGER_ADD_LIKE"
);

export const addLike = actionCreator.async<
  Action<{ feedbackId: number; }>,
  null
>("feedback/ADD_LIKE");

// add dislike to feedback
export const triggerAddDislike = actionCreator<{ feedbackId: number; }>(
  "feedback/TRIGGER_ADD_DISLIKE"
);

export const addDislike = actionCreator.async<
  Action<{ feedbackId: number; }>,
  null
>("feedback/ADD_DISLIKE");

// add new comment to feedback
export const triggerAddComment = actionCreator<{ projectId: number; data: ComentPayloadType; isModeration: boolean | undefined; }>(
  "feedback/TRIGGER_ADD_COMMENT"
);

export const addComment = actionCreator.async<
  Action<{ projectId: number; data: ComentPayloadType; isModeration: boolean | undefined; }>,
  ComentType
>("feedback/ADD_COMMENT");

// update feedback comment 
export const triggerUpdateComment = actionCreator<{ projectId: number; feedbackId: number;  data: ComentType; moderationMode: boolean | undefined }>(
  "feedback/TRIGGER_UPDATE_COMMENT"
);

export const updateComment = actionCreator.async<
  Action<{ projectId: number; feedbackId: number; data: ComentType; moderationMode: boolean | undefined }>,
  null
>("feedback/UPDATE_COMMENT");

// delete feedback comment
export const triggerDeleteComment = actionCreator<{ projectId: number; feedbackId: number; commentId: number; moderationMode: boolean | undefined }>(
  "feedback/TRIGGER_DELETE_COMMENT"
);

export const deleteComment = actionCreator.async<
  Action<{ projectId: number; feedbackId: number; commentId: number; moderationMode: boolean | undefined }>,
  null
>("feedback/DELETE_COMMENT");