import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";
import {
  ProjectSyncStateType,
  getProjectSync,
  SyncExternalProjectMappedType,
  updateProjectSync,
  updateProjectSyncWithoutImport,
  stopSyncInfinite,
} from "./syncTypes";

const INITIAL_STATE: ProjectSyncStateType = {
  loading: false,
  isUpdateInprogress: false,
  data: {},
};

export default reducerWithInitialState(INITIAL_STATE)
  .cases([getProjectSync.started, updateProjectSync.started], (state) => {
    return produce(state, (draft) => {
      draft.loading = true;
      draft.isUpdateInprogress = true;
      return draft;
    });
  })
  .cases(
    [getProjectSync.started, updateProjectSyncWithoutImport.started],
    (state) => {
      return produce(state, (draft) => {
        draft.loading = true;
        return draft;
      });
    }
  )
  .cases([getProjectSync.done, stopSyncInfinite], (state, actions) => {
    return produce(state, (draft) => {
      draft.loading = false;
      if (actions.result) {
        const { projectId, ...data } = actions.result;
        let externalProjects: SyncExternalProjectMappedType[] = [];
        data.externalProjects.forEach((projectEnum) => {
          projectEnum.projects.forEach((project) => {
            const mappedProject = {
              ...project,
              provider: projectEnum.provider,
            };
            externalProjects.push(mappedProject);
          });
        });
        if (externalProjects.length === 0) {
          externalProjects = draft?.data[projectId]?.externalProjects || [];
        }
        const mappedData = { ...data, externalProjects };
        draft.data[projectId] = mappedData;
        draft.isUpdateInprogress = mappedData.settings.isUpdateInprogress;
      }
      return draft;
    });
  })
  .case(updateProjectSync.done, (state, actions) => {
    return produce(state, (draft) => {
      draft.loading = false;
      if (actions.result) {
        const { projectId, ...data } = actions.result;
        draft.data[projectId].settings = data;
        draft.isUpdateInprogress = data.isUpdateInprogress;
      }
      return draft;
    });
  })
  .case(updateProjectSyncWithoutImport.done, (state, actions) => {
    return produce(state, (draft) => {
      draft.loading = false;
      if (actions.result) {
        const { projectId, ...data } = actions.result;
        draft.data[projectId].settings = data;
        draft.isUpdateInprogress = data.isUpdateInprogress;
      }
      return draft;
    });
  })
  .cases([updateProjectSync.failed], (state) => {
    return produce(state, (draft) => {
      draft.loading = false;
      draft.isUpdateInprogress = false;
      return draft;
    });
  })
  .cases([updateProjectSyncWithoutImport.failed], (state) => {
    return produce(state, (draft) => {
      draft.loading = false;
      draft.isUpdateInprogress = false;
      return draft;
    });
  });
