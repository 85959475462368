// TODO: figure out a proper way to handle Containers
import styled from 'styled-components'

const Container = styled.div`
  max-width: 960px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: row;
  font-family: ${props => props.theme.typo.primaryFont};
  /* TODO: add size */
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

export default Container