import { RootState } from "../rootTypes";
import { createSelector } from "reselect";

export const selectPaymentSystems = (state: RootState) => state.paymentSystems;

export const selectAvailablePSRaw = createSelector(
  selectPaymentSystems,
  (_: RootState, id: string) => id,
  (state, id) => {
    const paymentSystems = state.paymentSystems[id] || [];
    return paymentSystems.filter((ps) => {
      return ps.isEnabled && !ps.requireUserAction;
    });
  }
);

export const selectRequireUserAction = createSelector(
  selectPaymentSystems,
  (_: RootState, id: string) => id,
  (state, id) => {
    const paymentSystems = state.paymentSystems[id] || [];
    return paymentSystems.filter((ps) => ps.requireUserAction).length > 0;
  }
);

export const selectCheckoutPaymentSystems = createSelector(
  selectPaymentSystems,
  (_: RootState, id: string) => id,
  (state) => {
    return state.checkoutPaymentSystems;
  }
);

export const selectAllPaymentSystems = createSelector(
  selectPaymentSystems,
  (_: RootState, id: string) => id,
  (state, id) => {
    const paymentSystems = state.paymentSystems[id] || [];
    return paymentSystems;
  }
);
