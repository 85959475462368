export const providers: Record<string, ProviderType> = {
  vk: 'Vkontakte',
  inst: 'Instagram',
  ok: 'Ok'
}


export const CategoriesDepth = 3

export type ProviderType = 'Vkontakte' | 'Instagram' | 'Ok'

export const DomainServiceKey = 'DomainDelegation'

export const AnalyticsServiceKey = 'Analytics'

export const PromocodesServiceKey = 'PromoCodes'

export const DigitalGoodsServiceKey = 'DigitalGoods'

export const ContactsSocials = ['instagram', 'vkontakte', 'whatsapp', 'telegram', 'ok']

export const BlockTypes = [
  {
    key: 0,
    value: "Text",
  },
  {
    key: 1,
    value: "Image",
  },
  {
    key: 2,
    value: "Video",
  },
  {
    key: 3,
    value: "GoodsSet"
  },
  {
    key: 4,
    value: "Button"
  }
];
export const Pages1ServiceKey = 'Pages1'

export const Pages2ServiceKey = 'Pages2'

export const CustomCheckoutServiceKey = 'CheckoutCustom'

export const PackageAdvancedServiceKey = 'PackageAdvanced'

export const ScenariosServiceKey = 'Scenarios'

export const BookingServiceKey = 'Booking'