export const formatUrl = (data: string, shouldAdd = true): string => {
  let permamentData = data
  permamentData = permamentData.replace('https://', '')
  permamentData = permamentData.replace('http://', '')
  permamentData = permamentData.replace('www.', '')
  permamentData = permamentData.replace('http//:', '')
  permamentData = permamentData.replace('https//:', '')

  return shouldAdd ? 'https://' + permamentData : permamentData
};

export const cleanUrl = (url: string): string => {
  return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
}
