
import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";
import { 
  addComment, 
  addDislike, 
  addLike, 
  deleteComment, 
  FeedbacksType, 
  getFeedbacksForGood, 
  getFeedbacksForModeration, 
  getFeedbacksSettingByDomain, 
  getFeedbacksSettingById, 
  getMoreFeedbacksForModeration, 
  updateComment, 
  updateFeedbacksSetting, 
  updateFeedbackStatus 
} from "./feedbacksTypes";

const INITIAL_STATE: FeedbacksType = {
  loading: false,
  states: [],
  feedbacks: null,
  statistics: {
    statistic: null,
    hiddenFeedbacksCount: null,
  },
  feedbacksForModeration: null,
  feedbacksGoodCount: 3,
  feedbacksSettings: null,
  feedbacksModerationCount: 30,
  likedFeedbacksIds: [],
  dislikedFeedbacksIds: [],
};

export default reducerWithInitialState(INITIAL_STATE)
  .cases(
    [
      getFeedbacksSettingByDomain.started,
      getFeedbacksSettingById.started,
      getFeedbacksForModeration.started,
      getFeedbacksForGood.started,
    ],
    (state) => ({
      ...state,
      loading: true,
    })
  )
  .case(getFeedbacksSettingByDomain.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.feedbacksSettings = action.result.data
      }
      draft.loading = false;
      return draft;
    });
  })
  .case(getFeedbacksSettingById.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.feedbacksSettings = action.result.data
      }
      draft.loading = false;
      return draft;
    });
  })
  .case(updateFeedbacksSetting.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.feedbacksSettings = action.result.data
      }
      draft.loading = false;
      return draft;
    });
  })
  .case(getFeedbacksForModeration.done, (state, action) => {
    return produce(state, (draft) => {
      if (!action.params.payload.isNewStatus && draft.feedbacksForModeration?.length) {
        draft.feedbacksForModeration.push(...action.result.feedbacks);
      } else {
        draft.feedbacksForModeration = action.result.feedbacks;
        draft.states = action.result.states;
        draft.statistics = {
          statistic: action.result.statistics,
          hiddenFeedbacksCount: null,
        };
      }
      draft.loading = false;
      return draft;
    });
  })
  .case(getMoreFeedbacksForModeration.done, (state, action) => {
    return produce(state, (draft) => {
      if (draft.feedbacksForModeration?.length) {
        draft.feedbacksForModeration.push(...action.result.feedbacks);
      }
      draft.loading = false;
      return draft;
    });
  })
  .case(getFeedbacksForGood.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.params.payload.loadMore) {
        draft.feedbacks?.push(...action.result.feedbacks);
      } else {
        draft.feedbacks = action.result.feedbacks;
        draft.statistics = {
          statistic: action.result.statistics,
          hiddenFeedbacksCount: action.result.hiddenFeedbacksCount,
        };
      }
      draft.loading = false;
      return draft;
    });
  })
  .case(updateFeedbackStatus.done, (state, action) => {
    return produce(state, (draft) => {
      if(action.params.payload.isModeration && draft.feedbacksForModeration?.length){
        draft.statistics.statistic = action.result.statistics;
        draft.feedbacksForModeration = draft.feedbacksForModeration?.filter(item => item.id !== action.params.payload.feedbackId)
      } else if (draft.feedbacks?.length){
        draft.statistics.statistic = action.result.statistics;
        draft.feedbacks = draft.feedbacks?.filter(item => item.id !== action.params.payload.feedbackId)
        draft.statistics.hiddenFeedbacksCount = (draft.statistics.hiddenFeedbacksCount !== null) ? draft.statistics.hiddenFeedbacksCount + 1 : null;
      }
      draft.loading = false;
      return draft;
    });
  })
  .case(addDislike.done, (state, action) => {
    return produce(state, (draft) => {
      draft.dislikedFeedbacksIds.push(action.params.payload.feedbackId)
      if (draft.feedbacksForModeration?.length){
        const currentIndex = draft.feedbacksForModeration.findIndex(item => item.id === action.params.payload.feedbackId )
        if (currentIndex !== -1){
         draft.feedbacksForModeration[currentIndex].helpfulness -= 1
        }
       } else if ( draft.feedbacks?.length){
         const currentIndex = draft.feedbacks.findIndex(item => item.id === action.params.payload.feedbackId )
         if (currentIndex !== -1){
          draft.feedbacks[currentIndex].helpfulness -= 1
         }
       }
      draft.loading = false;
      return draft;
    })
  }) 
  .case(addLike.done, (state, action) => {
    return produce(state, (draft) => {
      draft.likedFeedbacksIds.push(action.params.payload.feedbackId)
      if (draft.feedbacksForModeration?.length){
        const currentIndex = draft.feedbacksForModeration.findIndex(item => item.id === action.params.payload.feedbackId )
        if (currentIndex !== -1){
         draft.feedbacksForModeration[currentIndex].helpfulness += 1
        }
       } else if ( draft.feedbacks?.length){
         const currentIndex = draft.feedbacks.findIndex(item => item.id === action.params.payload.feedbackId )
         if (currentIndex !== -1){
          draft.feedbacks[currentIndex].helpfulness += 1
         }
       }
      draft.loading = false;
      return draft;
    });
  })
  .case(updateComment.done, (state, action) => {
    return produce(state, (draft) => {
      const currentIndex = draft.feedbacks?.findIndex(item => item.id === action.params.payload.feedbackId)
      const currentModerationIndex = draft.feedbacksForModeration?.findIndex(item => item.id === action.params.payload.feedbackId)

      if (currentIndex !== undefined && currentIndex !== -1 && draft.feedbacks && !action.params.payload.moderationMode){
        draft.feedbacks[currentIndex].comment = action.params.payload.data
      } else if (currentModerationIndex !== undefined && currentModerationIndex !== -1 && draft.feedbacksForModeration && action.params.payload.moderationMode){
        draft.feedbacksForModeration[currentModerationIndex].comment = action.params.payload.data
      }
      draft.loading = false;
      return draft;
    });
  })
  .case(deleteComment.done, (state, action) => {
    return produce(state, (draft) => {
      const currentIndex = draft.feedbacks?.findIndex(item => item.id === action.params.payload.feedbackId)
      const currentModerationIndex = draft.feedbacksForModeration?.findIndex(item => item.id === action.params.payload.feedbackId)
      if (currentIndex !== undefined && currentIndex !== -1 && draft.feedbacks && !action.params.payload.moderationMode){
        draft.feedbacks[currentIndex].comment.text = ''
      }else if (currentModerationIndex !== undefined && currentModerationIndex !== -1 && draft.feedbacksForModeration && action.params.payload.moderationMode){
        draft.feedbacksForModeration[currentModerationIndex].comment.text = ''
      }
      draft.loading = false;
      return draft;
    });
  })
  .case(addComment.done, (state, action) => {
    return produce(state, (draft) => {
      if ( action.params.payload.isModeration && draft.feedbacksForModeration?.length){
         const currentIndex = draft.feedbacksForModeration.findIndex(item => item.id === action.params.payload.data.feedbackId )
         if (currentIndex !== -1){
          draft.feedbacksForModeration[currentIndex].comment = action.result
         }
        } else if ( draft.feedbacks?.length){
          const currentIndex = draft.feedbacks.findIndex(item => item.id === action.params.payload.data.feedbackId )
          if (currentIndex !== -1){
           draft.feedbacks[currentIndex].comment = action.result
          }
        }
      draft.loading = false;
      return draft;
    });
  });