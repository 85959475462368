import { RootState } from "../rootTypes";
import { createSelector } from "reselect";
import { GoodCardType } from "src/templates/utils/types/goodType";

const selectGoodsWidget = (state: RootState) => state.goodsWidget;

export const selectWidgetGoodsTotalCount = createSelector(
  selectGoodsWidget,
  (state) => state.goodsTotalCount
)

export const selectWidgetGoodsOffset = createSelector(
  selectGoodsWidget,
  (state) => state.offset
)

export const selectWidgetGoods = createSelector(
  selectGoodsWidget,
  (state): GoodCardType[] => state.goods || []
)

export const selectWidgetGoodsLoading = createSelector(
  selectGoodsWidget,
  (state): boolean => state.loading
)