import React, { useCallback } from "react";
import { string, object, array, func, bool } from "prop-types";
import { BaseForm } from "..";
import Yup from "src/utils/yup";

import PopupInputForm from "./PopupInputForm";

type Props = {
  validationSchema?: object;
  initialValues?: object;
  onSubmit: (value: string) => void;
  onClose: () => void;
  isInitialValid?: boolean;
  withDomain?: boolean;
  domain?: string;
  buttons: {
    name: string;
    shouldValidate?: boolean;
    shouldSubmit?: boolean;
  }[];
};

const PopupInput = ({
  validationSchema,
  initialValues,
  onSubmit,
  isInitialValid,
  ...props
}: Props) => {
  const handleSubmit = useCallback(
    (values) => {
      onSubmit(values.input);
    },
    [onSubmit]
  );
  return (
    <BaseForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      isInitialValid={isInitialValid}
      render={(formikProps: any) => (
        <PopupInputForm {...formikProps} {...props} />
      )}
    />
  );
};

PopupInput.propTypes = {
  title: string,
  placeholder: string,
  label: string,
  validationSchema: func,
  initialValues: object,
  type: string,
  buttons: array,
  handleButtons: func,
  onSubmit: func,
  isInitialValid: bool,
};

PopupInput.defaultProps = {
  type: "text",
  initialValues: {
    input: "",
  },
  validationSchema: () =>
    Yup.object({
      input: Yup.string().required(),
    }),
};

export default PopupInput;
