import React, {
  memo,
  FC,
  useCallback,
  ChangeEvent,
  FormEvent,
  useMemo,
  MouseEvent,
  useEffect,
} from "react";
import {
  PillCard,
  PillMarginContainer,
  PillLineItem,
  SmallTogleContainer,
  PillLineLabel,
  Toggle,
  PillHorizontalContainer,
  InputPill,
  Divider,
  Header,
  IconButton,
  HeaderText,
  DoneIcon,
  size, ArrowLeftIcon, AddGoodButton,
} from "hopshopui";
import styled from "styled-components";
import { ConnectedDesignFooterFormProps } from "../containers/DesignFooterForm";
import { useT } from "src/hooks/useT";
import DesignColorPickerInput from "./DesignColorPickerInput";

type DesignHeaderFormProps = {};

const DesignFooterForm: FC<ConnectedDesignFooterFormProps> = ({
  footerDesign,
  changeFooterTextColor,
  changeFooterBackgroundColor,
  toggleProjectDesignFooterKey,
  triggerSetProjectDesignSettings,
  match,
  loading,
  checkAndDisableDesignType,
}) => {
  const { t } = useT();

  const projectId = useMemo(() => {
    return match.params.id;
  }, [match]);

  // const handleFullScreenChange = useCallback(() => {
  //   updateProjectDesignHeader({
  //     ...headerDesign,
  //     extendToFullScreen: !headerDesign.extendToFullScreen,
  //   });
  // }, [headerDesign, updateProjectDesignHeader]);

  // const handleLogoChange = useCallback(() => {
  //   updateProjectDesignHeader({
  //     ...headerDesign,
  //     showLogo: !headerDesign.showLogo,
  //   });
  // }, [headerDesign, updateProjectDesignHeader]);

  // const handleTitleChange = useCallback(() => {}, ][])

  const updateFooterDesign = useCallback(
    (e: ChangeEvent) => {
      const key = e.target.getAttribute("name");
      if (key) {
        // TODO: fix types
        toggleProjectDesignFooterKey(key as any);
      }
    },
    [toggleProjectDesignFooterKey]
  );

  const handleTextColorChange = useCallback(
    (value: string) => {
      changeFooterTextColor(value);
    },
    [changeFooterTextColor]
  );

  const handleBackgroundChange = useCallback(
    (value: string) => {
      changeFooterBackgroundColor(value);
    },
    [changeFooterBackgroundColor]
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      triggerSetProjectDesignSettings(projectId);
    },
    [triggerSetProjectDesignSettings, projectId]
  );

  const handleDecline = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      checkAndDisableDesignType();
    },
    [checkAndDisableDesignType]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <StyledHeader isForm={true}>
        <StyledBackButton onClick={handleDecline} >
          <ArrowLeftIcon fill="#000000"/>
        </StyledBackButton>
        <HeaderTextStyled>{t("design.footer")}</HeaderTextStyled>
        <StyledDoneButton>
          <DoneIcon stroke="#000000" />
        </StyledDoneButton>
      </StyledHeader>
      <FormContainer>
        <PillCard white>
          <PillMarginContainer>
            <PillLineItem>
              <PillLineLabel>{t("design.headerFullScreen")}</PillLineLabel>
              <SmallTogleContainer>
                <Toggle
                  small
                  values={[]}
                  onChange={updateFooterDesign}
                  checked={footerDesign.extendToFullScreen}
                  toggleBackground
                  name="extendToFullScreen"
                  textHints={t("design.headerFullScreenHint")}
                ></Toggle>
              </SmallTogleContainer>
            </PillLineItem>

            <PillLineItem>
              <PillLineLabel>{t("design.title")}</PillLineLabel>
              <SmallTogleContainer>
                <Toggle
                  small
                  values={[]}
                  onChange={updateFooterDesign}
                  checked={footerDesign.showTitle}
                  toggleBackground
                  name="showTitle"
                  textHints={t("design.titleHint")}
                ></Toggle>
              </SmallTogleContainer>
            </PillLineItem>

            <PillLineItem>
              <PillLineLabel>{t("design.showPlatformName")}</PillLineLabel>
              <SmallTogleContainer>
                <Toggle
                  small
                  values={[]}
                  onChange={updateFooterDesign}
                  checked={footerDesign.showPlatformName}
                  toggleBackground
                  name="showPlatformName"
                  textHints={t("design.showPlatformNameHint")}
                ></Toggle>
              </SmallTogleContainer>
            </PillLineItem>
            <Divider />
            <PillHorizontalContainer itemCount={2}>
              <DesignColorPickerInput
                value={footerDesign.textColor || ""}
                onChange={handleTextColorChange}
                placeholder={t("design.placeholderDefault")}
                label={t("design.textColorLabel")}
              />
              <DesignColorPickerInput
                value={footerDesign.backgroundColor || ""}
                onChange={handleBackgroundChange}
                placeholder={t("design.placeholderDefault")}
                label={t("design.footerBackgroundLabel")}
              />
            </PillHorizontalContainer>
          </PillMarginContainer>
        </PillCard>
      </FormContainer>
    </form>
  );
};

const Form = styled.form``;

const FormContainer = styled.div`
  padding: 15px 15px 15px 15px;
  @media screen and (max-width: ${size.mobile}) {
    padding: 85px 15px 15px 15px;
  }
`;

const StyledHeader = styled(Header)`
  padding: 0 15px;
  @media screen and (max-width: ${size.mobile}) {
    top: 0;
    z-index: 6;
    position: fixed;
  }
`;

const StyledBackButton = styled(AddGoodButton)`
  height: 40px;
  width: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  flex: none;
  background: #FFFFFF;
  border: none;
  &:hover{
    background: none;
  }
`;

const StyledDoneButton = styled(AddGoodButton)`
  display: block;
  flex: none;
  color: #000000;
  border: none;
  background: none;
  &:hover{
    background: none;
  }
`;

const HeaderTextStyled = styled(HeaderText)`
  font-weight: 500;
  color: #000000;
`

export default memo(DesignFooterForm);
