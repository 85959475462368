import { useCallback, useEffect, useState } from 'react'
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '../localStorage'

export const localStorageFavorites = 'favorites'

export type FavoritesType = Record<string | number, boolean>
export type UseFavoriteReturnType = [FavoritesType, Function, Function, Function]

export const useFavorite = () => {
  const [favorites, setFavorites] = useState<FavoritesType>({})
  useEffect(() => {
    let _favorites = getLocalStorage(localStorageFavorites)
    if (_favorites) {
      const __favorites = JSON.parse(_favorites)
      setFavorites(__favorites)
    }
  }, [])

  const toggleFavorite = useCallback((id: number) => {  
    const favorite = !favorites[id]
    const _favorites = { ...favorites }
    if (favorite) {
      _favorites[id] = true
    } else {
      delete _favorites[id]
    }
    setFavorites(_favorites)
    setLocalStorage(localStorageFavorites, JSON.stringify(_favorites))
  }, [favorites, setFavorites])

  const clearFavorites = useCallback(() => {
    setFavorites({})
    removeLocalStorage(localStorageFavorites)
  }, [setFavorites])

  return [favorites, setFavorites, toggleFavorite, clearFavorites] as UseFavoriteReturnType

}