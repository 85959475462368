import { RootState } from "../rootTypes";
import { createSelector } from "reselect";
import { DomainServiceKey, AnalyticsServiceKey, Pages1ServiceKey, Pages2ServiceKey, CustomCheckoutServiceKey, PackageAdvancedServiceKey, PromocodesServiceKey, 
  ScenariosServiceKey, DigitalGoodsServiceKey, BookingServiceKey
} from "src/utils/variables";

import {
    MappedQuotation,
    MappedSubscriptionItemType, ServiceItemType,
} from "./subscriptionTypes";
import { getCurrency } from "src/templates/utils/getCurrency";
import { selectProfileProjects } from "src/store/profile";
import partition from "lodash/partition";
import produce from "immer";
import compact from "lodash/compact";
import { findActualSubscription } from "src/utils/subscriptionsFilter";
import {useMemo} from "react";


const positionSubscription: { [key: string]: number } = {
    'Scenarios': 5,
    'Pages1': 7,
    'DigitalGoods': 3,
    'PromoCodes': 1,
    'DomainDelegation': 9,
    'Analytics': 4,
    'CheckoutCustom': 6,
    'Booking': 2,
    'Pages2': 8,
}

const selectSubscriptions = (state: RootState) => state.subscriptions;

export const selectSubscriptionsLoading = createSelector(
  selectSubscriptions,
  (state) => state.loading
);

export const selectSubscriptionsServices = createSelector(
  selectSubscriptions,
  (state) => state.services
);

export const selectFilteredServices = createSelector(
    selectSubscriptionsServices,
    (_: RootState, id: string) => id,
    (state, id) => {
        const services = state[id] || [];
        const servicesListCondition = (el: ServiceItemType) => {
            return (el.systemName !== 'Тест' && el.systemName !== 'Test' && el.systemName !== 'Тесттттрррр' && el.systemName !== 'Testwwwww' && el.systemName !== 'PackageAdvanced')
        }

        const filteredServices = services.filter(serviceItem => servicesListCondition(serviceItem))
        const modifiedServices = filteredServices.map(service => {
            return {...service, position: positionSubscription[service.systemName]}
        })

        const sortedServices = modifiedServices.sort((a,b) => a.position - b.position)



        return sortedServices
    }
);

export const selectCustomDomainEnabled = createSelector(
  selectSubscriptions,
  (_: RootState, id: string) => id,
  (state, id) => {
    const subscriptions = state.subscriptions[id] || [];
    return (
      subscriptions.filter((subscription) => {
        return (
          subscription.systemName === DomainServiceKey && subscription.isActive
        );
      }).length > 0
    );
  }
);
export const selectAnalyticsEnabled = createSelector(
  selectSubscriptions,
  (_: RootState, id: string) => id,
  (state, id) => {
    const subscriptions = state.subscriptions[id] || [];
    return (
      subscriptions.filter((subscription) => {
        return (
          subscription.systemName === AnalyticsServiceKey && subscription.isActive
        );
      }).length > 0
    );
  }
);

export const select5PagesEnabled = createSelector(
  selectSubscriptions,
  (_: RootState, id: string) => id,
  (state, id) => {
    const subscriptions = state.subscriptions[id] || [];
    return (
      subscriptions.filter((subscription) => {
        return (
          subscription.systemName === Pages1ServiceKey && subscription.isActive
        );
      }).length > 0
    );
  }
);
export const select15PagesEnabled = createSelector(
  selectSubscriptions,
  (_: RootState, id: string) => id,
  (state, id) => {
    const subscriptions = state.subscriptions[id] || [];
    return (
      subscriptions.filter((subscription) => {
        return (
          subscription.systemName === Pages2ServiceKey && subscription.isActive
        );
      }).length > 0
    );
  }
);

export const selectPromocodesEnabled = createSelector(
  selectSubscriptions,
  (_: RootState, id: string) => id,
  (state, id) => {
    const subscriptions = state.subscriptions[id] || [];
    return (
      subscriptions.filter((subscription) => {
        return (
          subscription.systemName === PromocodesServiceKey && subscription.isActive
        );
      }).length > 0
    );
  }
);

export const selectDigitalGoodsEnabled = createSelector(
  selectSubscriptions,
  (_: RootState, id: string) => id,
  (state, id) => {
    const subscriptions = state.subscriptions[id] || [];
    return (
      subscriptions.filter((subscription) => {
        return (
          subscription.systemName === DigitalGoodsServiceKey && subscription.isActive
        );
      }).length > 0
    );
  }
);

export const selectServicesCurrencyCodes = createSelector(
  selectSubscriptions,
  (state) => state.currencyCodes || []
);

export const selectServicesCurrencySymbols = createSelector(
  selectServicesCurrencyCodes,
  (state) => {
    const mappedSymbols: Record<string, string> = {};
    state.forEach((currency) => {
      mappedSymbols[currency.key] = getCurrency(currency.value);
    });
    return mappedSymbols;
  }
);

const formatPricePerPeriod = ({
  price,
  period,
  codes,
  currencyId,
}: {
  price: number;
  period: number;
  codes: Record<string, string>;
  currencyId: number;
}) => {
  const pricePerPeriod = (price / period) * 30;
  const currencySymbol = codes[currencyId];
  return {
    pricePerPeriod,
    currencySymbol,
  };
};

export const selectServiceMappedQuotations = createSelector(
  selectSubscriptionsServices,
  (_: RootState, systemName: string) => systemName,
  (_: RootState, __: string, id: string) => id,
  selectServicesCurrencySymbols,
  (state, systemName, id, codes) => {
    let mappedQuotations: MappedQuotation[] = [];
    const activeService = state[id] && state[id].filter(
      (service) => service.systemName === systemName
    );
    if (activeService && activeService[0]) {
      mappedQuotations = activeService[0].quotations
        .map((quotation) => {
          const { pricePerPeriod, currencySymbol } = formatPricePerPeriod({
            price: quotation.price,
            period: quotation.durationInDays,
            codes,
            currencyId: quotation.currencyId,
          });

          return {
            ...quotation,
            pricePerPeriod,
            currencySymbol,
          };
        })
        .sort((a, b) => a.pricePerPeriod - b.pricePerPeriod);
    }

    return mappedQuotations;
  }
);

export const selectCheapeastQutation = createSelector(
  selectServiceMappedQuotations,
  (mappedQuotations) => {
    return mappedQuotations.sort(
      (a, b) => a.pricePerPeriod - b.pricePerPeriod
    )[0];
  }
);

export const selectServiceBySystemName = createSelector(
  selectSubscriptionsServices,
  (_: RootState, systemName: string) => systemName,
  (_: RootState, __: string, id: string) => id,
  (services, systemName, id) => {
    return (
      services[id] && services[id].filter((service) => service.systemName === systemName)[0] || {}
    );
  }
);

export const selectActiveServiceSubscription = createSelector(
  selectSubscriptions,
  (_: RootState, projectId: string) => projectId,
  (_: RootState, __: string, systemName: string) => systemName,
  selectServicesCurrencySymbols,
  (state, projectId, systemName, codes): MappedSubscriptionItemType | null => {
    let subs = state.subscriptions[projectId];
    if (subs && subs.length > 0) {
      subs = subs.filter((sub) => sub.systemName == systemName)
      const activeService = findActualSubscription(subs)
      if (activeService && !activeService.isExpired) {
        return {
          ...activeService,
          currencySymbol: codes[activeService.currencyId],
        };
      }
      return null;
    }
    return null;
  }
);

export const selectAllSubscriptions = createSelector(
  selectSubscriptions,
  (state) => state.subscriptions
);

type project = {
  id: number;
  is_active: boolean;
  project_name: string;
};

export const selectProjectsWithSubscriptions = createSelector(
  selectProfileProjects,
  selectAllSubscriptions,
  (projects, subscriptions) => {
    const _projects = projects.map((project: project) => {
      const partedByNameProjectSubscriptions = produce(
        subscriptions[project.id],
        (subs) => {
          if (!subs || subs.length === 0) {
            return [];
          }
          let partedSubs = [];
          let finished = false;
          while (!finished) {
            const partedSubscriptions = partition(subs, {
              systemName: subs[0].systemName,
            });
            partedSubs.push(partedSubscriptions[0]);
            subs = subs.filter((sub) => sub.systemName !== subs[0].systemName);
            if (partedSubscriptions[1].length === 0) {
              finished = true;
            }
          }
          return partedSubs;
        }
      );
      const finalSubscriptions = produce(
        partedByNameProjectSubscriptions,
        (partedSubscriptions) => {
          return partedSubscriptions.map((subs) => {
            return findActualSubscription(subs);
          });
        }
      );
      return { ...project, subscriptions: compact(finalSubscriptions).sort((a, b) => a?.subscriptionId - b?.subscriptionId) };
    });
    return _projects;
  }
);

export const selectCustomCheckoutEnabled = createSelector(
  selectSubscriptions,
  (_: RootState, id: string) => id,
  (state, id) => {
    const subscriptions = state.subscriptions[id] || [];
    // console.log(subscriptions, 'subscriptions')
    return (
      subscriptions.filter((subscription) => {
        return (
          subscription.systemName === CustomCheckoutServiceKey && subscription.isActive
        );
      }).length > 0
    );
  }
);

export const scenariosServiceKeyEnabled = createSelector(
  selectSubscriptions,
  (_: RootState, id: string) => id,
  (state, id) => {
    const subscriptions = state.subscriptions[id] || [];
    // console.log(subscriptions, 'subscriptions')
    return (
      subscriptions.filter((subscription) => {
        return (
          subscription.systemName === ScenariosServiceKey && subscription.isActive
        );
      }).length > 0
    );
  }
);

export const bookingServiceKeyEnabled = createSelector(
  selectSubscriptions,
  (_: RootState, id: string) => id,
  (state, id) => {
    const subscriptions = state.subscriptions[id] || [];
    // console.log(subscriptions, 'subscriptions')
    return (
      subscriptions.filter((subscription) => {
        return (
          subscription.systemName === BookingServiceKey && subscription.isActive
        );
      }).length > 0
    );
  }
);

export const selectPackageAdvancedEnabled = createSelector(
  selectSubscriptions,
  (_: RootState, id: string) => id,
  (state, id) => {
    const subscriptions = state.subscriptions[id] || [];
    // console.log(subscriptions, 'subscriptions')
    return (
      subscriptions.filter((subscription) => {
        return (
          subscription.systemName === PackageAdvancedServiceKey && subscription.isActive
        );
      }).length > 0
    );
  }
);
//CheckoutCustom
// export const selectServiceActiveSubscription = createSelector(
//   selectSubscriptionsServices,
//   (_: RootState, projectId: string) => projectId,
//   (__: RootState, p: string, systemName: string) => systemName,
//   () => {
//   }
// )

// export const selectDeliveryMethods = createSelector(
//   selectDelivery,
//   (_: RootState , id: string) => id,
//   (state, id) => {
//     const methods = state.data
//     if (methods[id]) {
//       return methods[id].asMutable({ deep: true })
//     }
//     return []
//   }
// )
