import { put, takeEvery, delay } from 'redux-saga/effects'

import { t } from 'src/locales/i18n'
import { setNotificaionText, delNotification, HANDLE_SERVER_ERROR, SHOW_NOTIFICATION, SHOW_NOTIFICATION_ACTION, setNotificaionActionText, setNotificaionActionRedirectPath, setOrderNotification, SHOW_ORDER_NOTIFICATION, hideOrderNotification } from './notificationReducer'

function* handleServerError({ payload }) {
  try {
    const text = payload.response.data ? t(`notifications.${payload.response.data.title}`) : t(`notifications.${payload.response.statusText}`)
    // TOOD: should I logout on fail status
    // console.log(payload.response.status, 'statys')
    // if (payload.response.status === 401) {
    //   yield put(signOut())
    // }
    // TODO: call logout here
    yield put(
      setNotificaionText(text)
    )
  } catch (e) {
    yield put(
      setNotificaionText(t('notifications.somethingWentWrong'))
    )
  }
  yield delay(5000)
  yield put(
    delNotification()
  )
}

function* showNotificationSaga({ payload }) {
  const text = payload.text || t(payload.key)
  yield put(setNotificaionText(text))
  yield delay(5000)
  yield put(delNotification())
}
function* showNotificationActionSaga({ payload }) {
  const text = payload.text || t(payload.key)
  const path = payload.path
  yield put(setNotificaionActionText(text))
  yield put(setNotificaionActionRedirectPath(path))
}

function* showOrderNotification({ payload }) {
  yield put(setOrderNotification(payload))
}


export default function* () {
  yield takeEvery(HANDLE_SERVER_ERROR, handleServerError)
  yield takeEvery(SHOW_NOTIFICATION, showNotificationSaga)
  yield takeEvery(SHOW_NOTIFICATION_ACTION, showNotificationActionSaga)
  yield takeEvery(SHOW_ORDER_NOTIFICATION, showOrderNotification)
}