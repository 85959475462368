import { PagesStateType, getPages, getMenuPages, getPageContent, updatePageContent, changePages } from "./pagesTypes";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";

const INITIAL_STATE: PagesStateType = {
  loading: false,
  data: {}
};

export default reducerWithInitialState(INITIAL_STATE)
  .cases([getPages.started, updatePageContent.started, getPageContent.started, changePages.started], (state) => ({
    ...state,
    loading: true,
  }))
  .cases([getPages.done, changePages.done], (state, action) => {
    return produce(state, (draft) => {
      draft.loading = false;
      if (action.result) {
        const projectData = draft.data[action.result.id] || {}
        projectData.limit = action.result.limits
        projectData.pages = action.result.pages
        draft.data[action.result.id] = projectData
      }
      return draft;
    });
  }).cases([getMenuPages.done], (state, action) => {
    return produce(state, (draft) => {
      draft.loading = false
      if (action.result) {
        const projectData = draft.data[action.result.projectId] || {}
        projectData.menuPages = action.result.data
        draft.data[action.result.projectId] = projectData
      }
      return draft
    })
  }).cases([getPageContent.done, updatePageContent.done], (state, action) => {
    return produce(state, (draft) => {
      draft.loading = false
      if (action.result) {
        const { result } = action
        const projectData = draft.data[result.projectId] || {}
        const content = projectData?.content || {}
        content[result.alias] = result?.data
        projectData.content = content
        console.log(projectData, 'projectData')
        draft.data[result.projectId] = projectData
      }
      return draft
    })
  })
