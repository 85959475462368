import { withRouter } from 'react-router-dom'
import {connect} from "react-redux";
import ProjectMenuPage from './ProjectMenuPage'
import {selectActiveProject} from "../../../store/projects";
import {matchParamsId} from "../../../utils/params";
import {selectSubscriptionsServices} from "../../../store/subscriptions";

const mapStateToProps = (state, props) => ({
    project: selectActiveProject(state, matchParamsId(props)),
    services: selectSubscriptionsServices(state)
})

const mapDispatchToProps = {

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectMenuPage))