import { connect, ConnectedProps } from "react-redux";
import { RootState } from "src/store/rootTypes";
import { DefinedRouterComponentProps } from "src/types";
import { withRouter } from "react-router-dom";
import {
  resetCover,
  loadNewCover,
  selectDesignCover,
  checkAndDisableDesignType,
  toggleProjectDesignCoverKey,
  changeStringProjectDesignCoverKey,
  changeNumberProjectDesignCoverKey,
  triggerGetCoverCustomizationSettings,
  triggerUpdateCoverCustomizationSettings,
} from "src/store/projectSettings";
import { selectImagesLoading, triggerUploadImageAsync } from "src/store/images";
import DesignCoverForm from "../components/DesignCoverForm";

const mapStateToProps = (state: RootState, props: DefinedRouterComponentProps) => ({
  imageLoading: selectImagesLoading(state),
  coverSettingsDesign: selectDesignCover(state),
})

const mapDispatchToProps = {
  resetCover,
  loadNewCover,
  triggerUploadImageAsync,
  checkAndDisableDesignType,
  toggleProjectDesignCoverKey,
  changeStringProjectDesignCoverKey,
  changeNumberProjectDesignCoverKey,
  triggerGetCoverCustomizationSettings,
  triggerUpdateCoverCustomizationSettings,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ConnectedDesignCoverFormProps = ConnectedProps<typeof connector> & DefinedRouterComponentProps

export default withRouter(connector(DesignCoverForm))