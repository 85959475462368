import {
  DeliveryStateType,
  SET_DELIVERY_METHODS,
  DeliveryPayloads,
  SetDeliveryPayload,
  TOGGLE_DELIVERY_LOADING,
} from "./deliveryTypes";
import { handleActions } from "redux-actions";
import Immutable, { ImmutableObject } from "seamless-immutable";

const initialState: DeliveryStateType = {
  loading: false,
  data: {},
};

export default handleActions<
  ImmutableObject<DeliveryStateType>,
  DeliveryPayloads
>(
  {
    [SET_DELIVERY_METHODS]: (state, action) => {
      const payload = action.payload as SetDeliveryPayload;
      return state.merge({
        data: {
          [payload.projectId]: payload.data,
        },
      });
    },
    [TOGGLE_DELIVERY_LOADING]: (state, action) => {
      console.log(state, action, 'toggleLoading')
      return state.set('loading', action.payload)
    },
  },
  Immutable(initialState)
);
