import React, { useEffect } from 'react'
import { parseSearch } from 'src/utils/search'

const SearchNotification = ({
  location,
  showNotification
}) => {
  useEffect(() => {
    const search = parseSearch(location.search)
    if (search.error) {
      showNotification({
        key: `notifications.${search.error}`
      })
    }
  }, [location.search, showNotification])
  return (
    <div></div>
  )
}


export default SearchNotification