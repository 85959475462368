import { ImageType } from "src/templates/utils/types/goodType";
import actionCreatorFactory, { Action } from "typescript-fsa";

const actionCreator = actionCreatorFactory();

export type MarketplacesType = {
  marketplaces: marketplaces;
  states: states;
};

export type MarketplaceType = {
  projectId: number;
  marketplaceId: number,
  state: string,
  logo: ImageType,
  systemName: string,
  link: string,
  title: string,
  description: string,
  rules: string,
  baseDomain: string
  fullDomain?: string,
  linkWithRelativePath?: string,
}

export type DocumetsLinksType = {
  privacyPolicyLink: string,
  userAgreementLink: string
}

export type marketplaces = [
  MarketplaceType
];

export type states = [
  {
    key: number,
    value: string
  }
];

// -----

export type GetMarketplacesDonePayload = {
  marketplaces: MarketplacesType;
  documentsLinks: DocumetsLinksType;
  loading: boolean;
};


export type MarketplacesStateType = {
  readonly loading: boolean;
  readonly documentsLinks: DocumetsLinksType;
  readonly marketplaces: MarketplacesType;
};

export type MarketplacesResponseType = {
  buttons: MarketplacesType;
  documentsLinks: DocumetsLinksType;
  loading: boolean;
};

export const triggerGetMarketplaces = actionCreator<{ projectId: string }>(
  "project/TRIGGER_GET_MARKETPLACES"
);

export const triggerGetDocumentsLinks = actionCreator<{ domain: string }>(
  "project/TRIGGER_GET_DOCUMENTS_LINKS"
);

export const getMarketplaces = actionCreator.async<
  Action<{ projectId: string }>,
  GetMarketplacesDonePayload
>("project/GET_MARKETPLACES");

export const getDocumentsLinks = actionCreator.async<
  Action<{ domain: string }>,
  GetMarketplacesDonePayload
>("project/GET_DOCUMENTS_LINKS");

export const triggerSetMarketplaces = actionCreator<{
  projectId: string;
  marketplaceId: number;
}>("project/TRIGGER_SET_MARKETPLACES");

export const setMarketplaces = actionCreator.async<Action<{
  projectId: string;
  marketplaceId: number;
}>, string>("project/SET_MARKETPLACES")

export const triggerSetMarketplacesDisabled = actionCreator<{
  projectId: string;
  marketplaceId: number;
}>("project/TRIGGER_SET_MARKETPLACES_DISABLED");

export const setMarketplacesDisabled = actionCreator.async<Action<{
  projectId: string;
  marketplaceId: number;
}>, string>("project/SET_MARKETPLACES_DISABLED")