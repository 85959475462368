import React, { memo, FC } from "react";
import { Popup } from "hopshopui";
import { useT } from "src/hooks/useT";

type PopupLeaveConfirmationProps = {
  handleButtons: (index: number) => void;
  handleClose: () => void;
};

const PoupLeaveConfirmation: FC<PopupLeaveConfirmationProps> = ({
  handleButtons,
  handleClose,
}) => {
  const { t } = useT();
  return (
    <Popup
      title={t("popup.verify")}
      text={t("project.addgood.draftFormStateVerifyGood")}
      handleButtons={handleButtons}
      buttons={[t("buttons.yes"), t("buttons.no")]}
      onClose={handleClose}
    />
  );
};

export default memo(PoupLeaveConfirmation);
