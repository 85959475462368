import { instLogin, okLogin } from "./localStorage"
import { providers } from "./variables"

export const getLSProvider = () => {
  const inst = !!localStorage.getItem(instLogin)
  const ok = !!localStorage.getItem(okLogin)
  let provider
  if (inst) {
    provider = providers.inst
  } else if (ok) {
    provider = providers.ok
  } else {
    provider = providers.vk
  }
  return provider
}