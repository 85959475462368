import actionCreatorFactory, { Action } from "typescript-fsa";

export type ImageItemType = {
  image: File;
  callback?: Function;
  id?: string | number;
  isAsync?: boolean;
};

export type ImagesStateType = {
  readonly loading: boolean;
  readonly duplicateImageLoading: boolean;
  readonly data: Array<ImageItemType>;
  readonly duplicateData: Array<string | number>;
};

const actionCreator = actionCreatorFactory();

export const toggleImagesLoading = actionCreator<boolean>(
  "images/TOGGLE_LOADING"
);

export const addImageToQueue = actionCreator<ImageItemType>(
  "images/ADD_IMAGE_TO_QUEUE"
);

export const clearImagesQueue = actionCreator(
  "images/CLEAR_IMAGES_QUEUE"
);

export const disableImagesLoading = actionCreator(
  "images/DISABLE_IMAGES_LOADING"
);

export const removeImageById = actionCreator<string | number>(
  "images/REMOVE_IMAGE_BY_ID"
);

export const triggerUploadImageAsync = actionCreator<ImageItemType>("images/TRIGGER_UPLOAD_IMAGE_ASYNC");

export const uploadImageAsync = actionCreator.async<Action<ImageItemType>, {}>(
  "images/UPLOAD_IMAGE_ASYNC"
);

export const triggerDuplicateImageAsync = actionCreator<{ callback?: Function; id?: string | number;}>("images/TRIGGER_DYPLICATE_IMAGE_ASYNC");

export const duplicateImageAsync = actionCreator.async<Action<{ callback?: Function; id: string | number; }>, {}>(
  "images/DYPLICATE_IMAGE_ASYNC"
);

export const clearDuplicateImagesQueue = actionCreator(
  "images/CLEAR_DUPLICATE_IMAGES_QUEUE"
);

export const addDuplicateImageToQueue = actionCreator<string | number>(
  "images/ADD_DUPLICATE_IMAGES_QUEUE"
);

export const removeDuplicateImageById = actionCreator<string | number>(
  "images/REMOVE_DUPLICATE_IMAGE_BY_ID"
);

export const toggleDuplicateImageLoading = actionCreator<boolean>(
  "images/TOOGLE_DUPLICATE_IMAGE_LOADING"
);