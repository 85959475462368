import { connect } from 'react-redux'

import FeedbacksSettingsPage from './FeedbacksSettingsPage'

const mapStateToProps = () => ({})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbacksSettingsPage)
