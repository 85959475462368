import React, { Suspense } from 'react'

const SettingsMainPromocodes = React.lazy(() => import('src/modules/projects/containers/SettingsMainPromocodes'))

const SettingsMainPromocodesPage = () => {
  return (
    // TODO: add loading shimmer
    <Suspense fallback={''}>
      <SettingsMainPromocodes />
    </Suspense>
  )
}

export default SettingsMainPromocodesPage