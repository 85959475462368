import React, {
  memo,
  FC,
  useCallback,
  ChangeEvent,
  FormEvent,
  useMemo,
  MouseEvent,
  Fragment,
} from "react";
import {
  PillCard,
  PillMarginContainer,
  PillLineItem,
  SmallTogleContainer,
  PillLineLabel,
  Toggle,
  PillHorizontalContainer,
  InputPill,
  Divider,
  Header,
  IconButton,
  HeaderText,
  DoneIcon,
  size,
  PillSelectLabel,
  Select,
} from "hopshopui";
import styled from "styled-components";
import { ConnectedDesignGoodsSetBlockFormProps } from "../containers/DesignGoodsSetBlockForm";
import { useT } from "src/hooks/useT";
import DesignColorPickerInput from "./DesignColorPickerInput";
import { DesignHeader } from "src/elements/layout/DesignHeader";

type DesignHeaderFormProps = {};

const DesignGoodsSetBlockForm: FC<ConnectedDesignGoodsSetBlockFormProps> = ({
  match,
  checkAndDisableDesignType,
  blockDesign,
  setBlockDesignSettings,
  triggerPushBlockDesignSettings,
}) => {
  const { t } = useT();

  const projectId = useMemo(() => {
    return match.params.id;
  }, [match]);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (blockDesign) {
        triggerPushBlockDesignSettings({ ...blockDesign, projectId: parseInt(projectId) });
      }
    },
    [triggerPushBlockDesignSettings, projectId, blockDesign]
  );

  const handleDecline = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      checkAndDisableDesignType();
    },
    [checkAndDisableDesignType]
  );

  const handleTextColorChange = useCallback(
    (value: string) => {
      blockDesign &&
        setBlockDesignSettings({
          ...blockDesign,
          customization: {
            ...blockDesign.customization,
            titleColor: value,
          },
        });
    },
    [setBlockDesignSettings, blockDesign]
  );

  const handleDescriptionColorChange = useCallback(
    (value: string) => {
      blockDesign &&
        setBlockDesignSettings({
          ...blockDesign,
          customization: {
            ...blockDesign.customization,
            descriptionColor: value,
          },
        });
    },
    [setBlockDesignSettings, blockDesign]
  );

  const handleBackgroundMonotoneChange = useCallback(
    (value: string) => {
      blockDesign &&
        setBlockDesignSettings({
          ...blockDesign,
          customization: {
            ...blockDesign.customization,
            backgroundPayload: {
              ...blockDesign.customization.backgroundPayload,
              color: value,
            },
          },
        });
    },
    [setBlockDesignSettings, blockDesign]
  );
  const handleIsUnfoldChange = useCallback(() => {
    blockDesign &&
      setBlockDesignSettings({
        ...blockDesign,
        customization: {
          ...blockDesign.customization,
          isUnfold: !blockDesign.customization.isUnfold,
        },
      });
  }, [setBlockDesignSettings, blockDesign]);
  const handlehasBackgroundChange = useCallback(() => {
    blockDesign &&
      setBlockDesignSettings({
        ...blockDesign,
        customization: {
          ...blockDesign.customization,
          hasBackground: !blockDesign.customization.hasBackground,
        },
      });
  }, [setBlockDesignSettings, blockDesign]);

  return (
    <form onSubmit={handleSubmit}>
      <DesignHeader>
        <IconButton iconType="arrow" square small onClick={handleDecline} />
        <HeaderText>{t("design.goodSet")}</HeaderText>
        <IconButton icon={<DoneIcon />} iconType="addGood" square small />
      </DesignHeader>
      <DesignFormContainer>
        <PillCard white>
          <PillMarginContainer>
            <PillHorizontalContainer itemCount={2}>
              <DesignColorPickerInput
                value={blockDesign?.customization.titleColor || ""}
                onChange={handleTextColorChange}
                placeholder={t("design.placeholderDefault")}
                label={t("design.headerColorLabel")}
              />
              <DesignColorPickerInput
                value={blockDesign?.customization.descriptionColor || ""}
                onChange={handleDescriptionColorChange}
                placeholder={t("design.placeholderDefault")}
                label={t("design.descriptionColorLabel")}
              />
            </PillHorizontalContainer>
            <MarginDivider />
            <PillLineItem>
              <PillLineLabel>{t("design.hasBackground")}</PillLineLabel>
              <SmallTogleContainer>
                <Toggle
                  small
                  values={[]}
                  onChange={handlehasBackgroundChange}
                  checked={blockDesign?.customization.hasBackground}
                  toggleBackground
                  name="hasBackground"
                  textHints={t("design.hasBackgroundHint")}
                ></Toggle>
              </SmallTogleContainer>
            </PillLineItem>
            <MarginDivider />
            {blockDesign?.customization.hasBackground && (
              <Fragment>
                <PillLineItem>
                  <div>
                    <PillSelectLabel>
                      {t("design.backgroundFillTypeLabel")}
                    </PillSelectLabel>
                    <Select
                      value={blockDesign?.customization.backgroundType}
                      pillStyle
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        console.log("e");
                      }}
                    >
                      <option value="Monotone">
                        {t("design.backgroundFillTypeSolid")}
                      </option>
                    </Select>
                  </div>
                </PillLineItem>
                <Divider />
                <PillHorizontalContainer itemCount={1}>
                  <DesignColorPickerInput
                    value={
                      blockDesign?.customization.backgroundType === "Monotone"
                        ? blockDesign?.customization.backgroundPayload.color ||
                          ""
                        : ""
                    }
                    onChange={handleBackgroundMonotoneChange}
                    placeholder={t("design.placeholderDefault")}
                    label={t("design.backgroundColorLabel")}
                  />
                </PillHorizontalContainer>

                <MarginDivider />
              </Fragment>
            )}
            <PillLineItem>
              <PillLineLabel>{t("design.isUnfold")}</PillLineLabel>
              <SmallTogleContainer>
                <Toggle
                  small
                  values={[]}
                  onChange={handleIsUnfoldChange}
                  checked={blockDesign?.customization.isUnfold}
                  toggleBackground
                  name="isUnfold"
                  textHints={t("design.isUnfoldHint")}
                ></Toggle>
              </SmallTogleContainer>
            </PillLineItem>
          </PillMarginContainer>
        </PillCard>
      </DesignFormContainer>
    </form>
  );
};

const Form = styled.form``;

const MarginDivider = styled(Divider)`
  margin-bottom: 10px;
`;

export const DesignFormContainer = styled.div`
  padding: 15px 15px 15px 15px;
  @media screen and (max-width: ${size.mobile}) {
    padding: 85px 15px 15px 15px;
  }
`;

export default memo(DesignGoodsSetBlockForm);
