import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ProjectPage from "./ProjectPage";
import {
  getProjects,
  addCategory,
  editCategory,
  loadMoreGoods,
  getCategories,
  deleteCategory,
  resetGoodsCount,
  getProjectGoods,
  getProjectDetails,
  selectGoodsLoading,
  incrementGoodsCount,
  selectLoadMoreLoading,
  selectProjectsSelector,
  selectProjectCategoriesMap,
} from "src/store/projects";

import {
  selectSyncInProgress,
  triggerGetProjectSync,
  selectActiveProjectSync,
} from "src/store/sync";
import { matchParamsId } from "src/utils/params";
import {
  toggleDesignMode,
  selectDesignCover,
  selectDesignBlocked,
  selectProjectSettingsDesignSelection,
  selectProjectSettingsDesignModeSelected,
} from "src/store/projectSettings";
import { triggerEditRange } from "src/store/goods";
import {
  triggerGetSubscriptions,
  bookingServiceKeyEnabled,
} from "src/store/subscriptions";
import { selectLogsForm } from "src/store/bookingLog";
import {triggerGetLogs} from "src/store/bookingLog/bookingTypes"
import { selectMainPage } from "src/store/pages";

const mapStateToProps = (state, props) => ({
  logs: selectLogsForm(state),
  loading: selectGoodsLoading(state),
  projects: selectProjectsSelector(state),
  designBlocked: selectDesignBlocked(state),
  loadMoreLoading: selectLoadMoreLoading(state),
  coverSettingsDesign: selectDesignCover(state),
  categoriesMap: selectProjectCategoriesMap(state),
  mainPage: selectMainPage(state, matchParamsId(props)),
  designMode: selectProjectSettingsDesignModeSelected(state),
  sync: selectActiveProjectSync(state, matchParamsId(props)),
  designSelection: selectProjectSettingsDesignSelection(state),
  isUpdateInprogress: selectSyncInProgress(state, matchParamsId(props)),
  bookingEnabled: bookingServiceKeyEnabled(state, matchParamsId(props)),
});

const mapDispatchToProps = {
  triggerGetSubscriptions,
  triggerGetProjectSync,
  incrementGoodsCount,
  getProjectDetails,
  toggleDesignMode,
  triggerEditRange,
  getProjectGoods,
  resetGoodsCount,
  deleteCategory,
  triggerGetLogs,
  getCategories,
  loadMoreGoods,
  editCategory,
  getProjects,
  addCategory,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectPage)
);
