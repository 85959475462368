import actionCreatorFactory, { Action } from "typescript-fsa";
import { UpdateGoodDetailPayload } from "../goods";

const actionCreator = actionCreatorFactory();


export type UploadFileType = {
  data: GetFileUploadLinkResponseType;
  file: File;
  index: string;
  callback: ( status: "Canceled" | "Error" | "Ok", id?: number, e?:any) => void;
}

export type GetFileUploadLinkType = {
  name: string;
  size: number;
  projectId: number;
  file:File;
  index: string;
  callback: ( status: "Canceled" |  "Error" | "Ok", id?: number, e?:any) => void;
}

export type GetFileUploadLinkResponseType = {
  fileLink: string;
  fileId: number;
  index: string;
}

export type DigitalGoodsSettingsStateType = {
  readonly loading: boolean;
  readonly loadFile: boolean;
  readonly loadNames: boolean;
  readonly digitalGoodsSettings: digitalGoodsSettingsType | null;
  readonly sendingCases: sendingCase[];
  readonly cloudInfo: StoreSpaceInfoType | null;
  readonly submitFilesInfo: GetFileUploadLinkResponseType[];
  readonly fileLink: string[];
  readonly fileQueue: number[];
  readonly digitalFilesNames: {id: number; name: string;}[] | [];
  readonly deletedFilesIds: {fileId: number; goodId: number; }[];
};

export type DigitalGoodsSettingsResponseType = {
  digitalGoodsSettings: digitalGoodsSettingsType;
  sendingCases: sendingCase[];
};


export type StoreSpaceInfoType = {
  freeStoreSpace:  number;
  uploadFileQuota: number;
  storeSpaceQuota: number;
}

export type digitalGoodsSettingsType = {
  id: number;
  projectId: number;
  sendingCase?: string;
  usePassword: boolean;
  limitInDays?: number | null;
  copyBan: boolean;
  notificationText?: string;
};

export type sendingCase = {
  key: number;
  value: string;
};

export type GetDigitalGoodsSettingsDonePayload = {
  digitalGoodsSettings: digitalGoodsSettingsType;
  sendingCases: sendingCase[];
};

export const triggerGetDigitalGoodsSettings = actionCreator<string>(
  "digitalGoods/TRIGGER_GET_DIGITAL_GOODS_SETTINGS"
);
export const getDigitalGoodsSettings = actionCreator.async<
  Action<string>,
  GetDigitalGoodsSettingsDonePayload
>("digitalGoods/GET_DIGITAL_GOODS_SETTINGS");

type ChangeDigitalGoodsSettingsPayloadType = digitalGoodsSettingsType;
export const triggerChangeDigitalGoodsSettings = actionCreator<ChangeDigitalGoodsSettingsPayloadType>(
  "digitalGoods/TRIGGER_CHANGE_DIGITAL_GOODS_SETTINGS"
);

export const changeDigitalGoodsSettings = actionCreator.async<
  Action<ChangeDigitalGoodsSettingsPayloadType>,
  null
>("digitalGoods/CHANGE_DIGITAL_GOODS_SETTINGS");

export const triggerGetFreeSpaceCloud = actionCreator<{projectId: string}>(
  "digitalGoods/TRIGGER_GET_FREE_SPACE_CLOUD"
);

export const getFreeSpaceCloud = actionCreator.async<
  Action<{projectId: string}>,
  StoreSpaceInfoType
>("digitalGoods/GET_FREE_SPACE_CLOUD");

export const triggerGetFileUploadLink = actionCreator<GetFileUploadLinkType>(
  "digitalGoods/TRIGGER_GET_UPLOAD_LINK"
);

export const getFileUploadLink = actionCreator.async<
  Action<GetFileUploadLinkType>,
  null
>("digitalGoods/GET_UPLOAD_LINK");

export const triggerUploadFile = actionCreator<UploadFileType>(
  "digitalGoods/TRIGGER_UPLOAD_FILE"
);

export const uploadFile = actionCreator.async<
  Action<UploadFileType>,
  { data: GetFileUploadLinkResponseType; canceled: boolean}
>("digitalGoods/UPLOAD_FILE");

export const triggerFinishUploadFile = actionCreator<{downloadFiles:{fileId: number}[]}>(
  "digitalGoods/TRIGGER_FINISH_UPLOAD_FILE"
);

export const finishUploadFile = actionCreator.async<
  Action<{storeFileId: number}[]>,
  null
>("digitalGoods/FINISH_UPLOAD_FILE");

export const deleteFile = actionCreator.async<
  Action<{index: string}>,
  null
>("digitalGoods/DELETE_FILE");

export const triggerClearFileByStoreName = actionCreator(
  "digitalGoods/TRIGGER_DELETE_FILE_BY_STORE_NAME"
);

export const clearFileByStoreName = actionCreator.async<
  Action<{}>,
  null
>("digitalGoods/DELETE_FILE_BY_STORE_NAME");

export const addFileToQueue = actionCreator<number>(
  "digitalGoods/ADD_FILE_TO_QUEUE"
);

export const removeFileFromQueue = actionCreator<number>(
  "digitalGoods/REMOVE_FILE_FROM_QUEUE"
);

export const toggleFileLoading = actionCreator<boolean>(
  "digitalGoods/TOGGLE_FILE_LOADING"
);

export const cancelUploadFile = actionCreator<{}>("digitalGoods/CANCEL_UPLOAD_FILE");

export const deleteDigitalFileConfirmation = actionCreator.async<
  Action<{payload: UpdateGoodDetailPayload}>,
  null
>("digitalGoods/DELETE_DIGITAL_FILE_CONFIRMATION");

export const triggerDeleteDigitalFileConfirmation = actionCreator<{payload: UpdateGoodDetailPayload}>(
  "digitalGoods/TRIGGER_DELETE_DIGITAL_FILE_CONFIRMATION"
);

export const triggerDeleteDigitalFile = actionCreator<{fileId: number; goodId: number}>(
  "digitalGoods/TRIGGER_DELETE_DIGITAL_FILE"
);

export const getDigitalFileName = actionCreator.async<
  Action<{id: number}>,
  {id: number; name: string;}
>("digitalGoods/GET_DIGITAL_FILE_NAME");

export const triggerGetDigitalFileName = actionCreator<{id: number}>(
  "digitalGoods/TRIGGER_GET_DIGITAL_FILE_NAME"
);

export const clearDigitalFilesNames = actionCreator(
  "digitalGoods/CLEAR_DIGITAL_FILE_NAME"
);

export const clearDeleteFilesIds = actionCreator(
  "digitalGoods/CLEAR_DELETE_FILE_IDS"
);

export const removeIdFromDeleteDigitalFileIdsList = actionCreator<{id: number;}>(
  "digitalGoods/REMOVE_ID_FROM_DELETE_FILE_IDS_LIST"
);

