import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { node } from 'prop-types'
import { useMobile } from 'hopshopui'
import { TemplateContext } from 'src/templates/utils/providers/TemplateProvider'
import { useRedirectTemplate } from 'src/hooks/redirectTemplate'
import { 
  selectActiveProjectPlatformTitle ,
  selectActiveProjectBaseDomain, 
  selectActiveProjectCurrency, 
  selectActiveProjectDomain, 
  selectContacts, 
} from 'src/store/projects'
import { 
  selectProjectSettingsDesignModeSelected, 
  selectProjectSettingsDesignSelection, 
  selectProjectSettingsHeaderDesign, 
  selectProjectSettingsFooterDesign, 
  setInitialBlockDesignSettings, 
  setInitialModuleButtonDesign, 
  selectModuleButtonDesign, 
  changeProjectDesignType, 
  setBlockDesignSettings, 
  selectDesignBackground,
  setModuleButtonDesign, 
  selectDesignBlocked, 
  selectDesignBlock, 
} from 'src/store/projectSettings'

import getImageUrl from 'src/utils/getImageUrl'
import { useParams, useLocation } from 'react-router-dom'
import { selectMenuPages } from 'src/store/pages'
import { getAliasFromPath } from 'src/templates/utils/alias'
import { templateT } from 'src/locales/i18n'
import { useProjectSettings } from 'src/hooks/useProjectSettings'
import { selectScenariosForm } from 'src/store/scenarios'
import { useDispatch } from 'react-redux'
import { selectGoodsLoading } from 'src/store/goods'
import { useT } from 'src/hooks/useT'

const TemplateProvider = ({ children, ...rest }) => {
  const { t: tA } = useT()
  const dispatch = useDispatch()
  const [isMobile] = useMobile()
  const params = useParams()
  const location = useLocation()

  const platformTitle = useSelector((state) => selectActiveProjectPlatformTitle(state, params.id))
  const baseDomain = useSelector((state) => selectActiveProjectBaseDomain(state, params.id))
  const headerSettings = useSelector((state) => selectProjectSettingsHeaderDesign(state))
  const footerSettings = useSelector((state) => selectProjectSettingsFooterDesign(state))
  const currency = useSelector((state) => selectActiveProjectCurrency(state, params.id))
  const domain = useSelector((state) => selectActiveProjectDomain(state, params.id))
  const backgroundSettings = useSelector((state) => selectDesignBackground(state))
  const buttonDesign = useSelector((state) => selectModuleButtonDesign(state))
  const blockDesignSettings = useSelector((state) => selectDesignBlock(state))
  const menuPages = useSelector((state) => selectMenuPages(state, params.id))
  const goodsDaysLoading = useSelector((state) => selectGoodsLoading(state))
  const designSelection = useSelector(selectProjectSettingsDesignSelection)
  const designBlocked = useSelector((state) => selectDesignBlocked(state))
  const designMode = useSelector(selectProjectSettingsDesignModeSelected)
  const contacts = useSelector(selectContacts)
  
  const setBlockCustomizationSettings = (value) => {
    dispatch(setBlockDesignSettings(value))
  }

  const changeDesignType = (value) => {
    dispatch(changeProjectDesignType(value))
  }

  const setInitialBlockCustomizationSettings = (value) => {
    dispatch(setInitialBlockDesignSettings(value))
  }

  const setInitialModuleButtonCustomizationSettings = (value) => {
    dispatch(setInitialModuleButtonDesign(value))
  }
  
  const setModuleButtonDesignSettings = (value) => {
    dispatch(setModuleButtonDesign(value))
  }

  const setInitialCoverCustomizationSettings = (value) => {
    dispatch(setInitialModuleButtonDesign(value))
  }
  
  const setCoverDesignSettings = (value) => {
    dispatch(setModuleButtonDesign(value))
  }

  const projectScenarios = useSelector((state) => selectScenariosForm(state))
  
  const pathName = useMemo(() => {
    return getAliasFromPath(location.pathname)
  }, [location.pathname])

  const projectSettingsMethods = useProjectSettings(params.id)

  const {
    redirectFav,
    redirectMenu,
    redirectItem,
    redirectCart,
    redirectGoods,
    redirectIndex,
    redirectSearch,
    redirectToGood,
    redirectCheckout,
    redirectProjectMain,
    redirectToEditCategories,
  } = useRedirectTemplate(menuPages)


  return (
    <TemplateContext.Provider value={{
      getImageUrl,
      redirectFav,
      redirectCart,
      onAdminHomeClick: redirectProjectMain,
      redirectToEditCategories,
      redirectCheckout,
      redirectSearch,
      redirectToGood,
      redirectGoods,
      redirectItem,
      isAdmin: true,
      currency,
      isMobile,
      contacts: contacts.contactList,
      menuPages,
      pathName: pathName,
      redirectMenu,
      redirectIndex,
      t: templateT,
      tA,
      headerSettings,
      backgroundSettings,
      footerSettings,
      projectScenarios,
      blockDesignSettings,
      designSelection,
      designMode,
      designBlocked,
      setBlockDesignSettings: setBlockCustomizationSettings,
      changeDesignType,
      setInitialBlockDesignSettings: setInitialBlockCustomizationSettings,
      setInitialModuleButtonDesign: setInitialModuleButtonCustomizationSettings,
      setModuleButtonDesign: setModuleButtonDesignSettings,
      setInitialCoverDesign: setInitialCoverCustomizationSettings,
      setCoverDesign: setCoverDesignSettings,
      buttonDesign: buttonDesign,
      goodsDaysLoading: goodsDaysLoading,
      domain,
      baseDomain,
      platformTitle,
      ...rest,
      ...projectSettingsMethods,
      baseUrl: '/api'
    }}>
      {children}
    </TemplateContext.Provider>
  )
}

TemplateProvider.propTypes = {
  children: node
}

export default memo(TemplateProvider)