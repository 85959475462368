import React, { Suspense } from 'react'
import FeedbacksMainShimer from 'src/modules/feedback/components/FeedbacksMainShimer';


const FeedbacksMain = React.lazy(() => import('src/modules/feedback/containers/FeedbacksMain'));

const FeedbacksModerationPage = () => {
  return (
    <Suspense fallback={<FeedbacksMainShimer />}>
      <FeedbacksMain/>
    </Suspense>
  )
}

export default FeedbacksModerationPage

