import React, { memo, Suspense } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { func, bool } from 'prop-types'
import { useSelector } from 'react-redux'
import { selectProjectsSelector } from 'src/store/projects'

const HaveNoProjectsRoute = ({ component: Component, layout: Layout, authroized, ...rest }) => {
  const projects = useSelector(selectProjectsSelector)
  if (!authroized) {
    return <Redirect to='/redirect' />
  } else if (projects.length > 0) {
    return <Redirect to={'/project/' + projects[0].id} />
  }
  return <Route {...rest} render={props => {
    return (
      <Suspense fallback={<div></div>}>
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      </Suspense>
    )
  }} />
}

HaveNoProjectsRoute.propTypes = {
  component: func,
  layout: func,
  authroized: bool,
}

export default memo(HaveNoProjectsRoute)
