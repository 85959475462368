import React, {
  memo,
  useState,
  useCallback,
  Suspense,
  SyntheticEvent,
  useRef,
  FC,
  useEffect,
} from "react";
import styled from "styled-components";
import {
  EditorState,
  CompositeDecorator,
  Editor as DraftEditor,
  DraftEditorCommand,
  DraftHandleValue,
  RichUtils,
  ContentBlock,
  ContentState,
  convertToRaw,
} from "draft-js";

import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import InlineControls from "./InlineControls";
import BlockControls from "./BlockControls";
import EditorLink from "./EditorLink";
import LinkControls from "./LinkControls";
import EditorButton from "src/elements/buttons/EditorButton";
import Check from "src/elements/icons/Check";
import { useBodyScrollHidden } from "hopshopui";
import NewEditor from "src/modules/editorNew/components/NewEditor";

const InserLink = React.lazy(() => import("src/assets/insertLink"));

function findLinkEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

type Props = {
  initialState?: string;
  onSubmit: (data: string) => void;
  hasHeader?: boolean;
};

const Editor: FC<Props> = ({ initialState, onSubmit, hasHeader }) => {
  const refEditor = useRef<DraftEditor>(null);
  const [linkEditorOpened, setLinkEditorOpened] = useState(false);

  useBodyScrollHidden();

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: EditorLink,
    },
  ]);

  const [editorState, setEditorState] = useState(() => {
    // TODO: remove this
    if (initialState) {
      const content = stateFromHTML(initialState);
      return EditorState.createWithContent(content, decorator);
    } else {
      return EditorState.createEmpty(decorator);
    }
  });

  useEffect(() => {
    setTimeout(() => {
      refEditor.current?.focus();
    }, 10);
  }, []);

  const handleKeyCommand = useCallback(
    (command: DraftEditorCommand, state: EditorState): DraftHandleValue => {
      const newState = RichUtils.handleKeyCommand(state, command);
      if (newState) {
        setEditorState(newState);
        return "handled";
      }
      // here goes custom logic
      return "not-handled";
    },
    [setEditorState]
  );

  const handleInlineToggle = useCallback(
    (inlineStyle: string) => {
      setEditorState((state) => {
        return RichUtils.toggleInlineStyle(state, inlineStyle);
      });
    },
    [setEditorState]
  );

  const handleBlockToggle = useCallback(
    (blockType: string) => {
      setEditorState((state) => {
        return RichUtils.toggleBlockType(state, blockType);
      });
    },
    [setEditorState]
  );

  const toggleLinkEditor = useCallback(
    (e: SyntheticEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setLinkEditorOpened((state) => !state);
    },
    [setLinkEditorOpened]
  );

  const handleLinkChange = useCallback(
    (state: EditorState) => {
      setEditorState(() => state);
      setTimeout(() => {
        refEditor.current?.focus();
      }, 10);
    },
    [setEditorState]
  );

  const handleSubmit = useCallback(
    (e: SyntheticEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const rawContent = convertToRaw(editorState.getCurrentContent())
      let html = ""

      if (Object.keys(rawContent.blocks).length > 0 && rawContent.blocks[0].text !== "") {
        html = stateToHTML(editorState.getCurrentContent());
      }
      onSubmit(html);
    },
    [editorState, onSubmit]
  );

  return (
  <>
    <FixedContainer>
      <OuterContainer>
        <Container>
          <InlineControls
            editorState={editorState}
            onToggle={handleInlineToggle}
          />
          {/* TODO: create better config */}
          {hasHeader && (
            <BlockControls
              editorState={editorState}
              onToggle={handleBlockToggle}
            />
          )}
          {/* TODO: think how to check if text is selected and what to do if not */}
          <EditorButton active={false} onClick={toggleLinkEditor}>
            <Suspense fallback={<div></div>}>
              <InserLink />
            </Suspense>
          </EditorButton>
          <EditorButton
            active={false}
            onClick={handleSubmit}
            data-testid="editor-submit"
          >
            <Check />
          </EditorButton>
          {linkEditorOpened && (
            <LinkControls
              editorState={editorState}
              onToggle={toggleLinkEditor}
              changeState={handleLinkChange}
            />
          )}
        </Container>
      </OuterContainer>
      <EditorContainer>
        <DraftEditor
          ref={refEditor}
          editorState={editorState}
          onChange={setEditorState}
          handleKeyCommand={handleKeyCommand}
          webDriverTestID="draft-editor"
        />
      </EditorContainer>
    </FixedContainer>
    </>
  );
};

Editor.defaultProps = {
  hasHeader: true,
};

const FixedContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.colors.mildBackground};
  z-index: 12;
  height: 100%;
  overflow: auto;
`;

const EditorContainer = styled.div`
  background: ${(props) => props.theme.colors.light};
  box-shadow: ${(props) => props.theme.colors.pillShadow};
  font-family: ${(props) => props.theme.typo.primaryFont};
  max-width: 500px;
  margin: 100px auto 20px auto;
  padding: 20px;
  border-radius: 10px;
`;

const OuterContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: ${(props) => props.theme.colors.pillShadow};
  background: ${(props) => props.theme.colors.light};
  padding: 15px 0;
`;

const Container = styled.div`
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  position: relative;
  justify-content: space-between;
`;

export default memo(Editor);
