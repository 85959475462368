import actionCreatorFactory, { Action } from "typescript-fsa"

const actionCreator = actionCreatorFactory()

export type HtmlCodeType = {
    head: string,
    body: string
}

export type HtmlCodeStateType = {
    readonly loading: boolean;
    readonly htmlCode: HtmlCodeType | null
}

export type GetHtmlCodeDonePayload = HtmlCodeType

export type HtmlCodeResponse = {
    projectId: string;
    data: HtmlCodeType
}

//Action get html code
export const triggerGetHtmlCode = actionCreator<{projectId: string}>(
    "html/TRIGGER_GET_HTML_CODE"
);

export const getHtmlCode = actionCreator.async<
        Action<{projectId: string}>,
        GetHtmlCodeDonePayload
    >("html/GET_HTML_CODE")

//Action update html code
export const triggerUpdateHtmlCode = actionCreator<HtmlCodeResponse>(
    "html/TRIGGER_UPDATE_HTML_CODE"
);

export const updateHtmlCode = actionCreator.async<
    Action<HtmlCodeResponse>,
    {data:HtmlCodeType}
    >("html/UPDATE_HTML_CODE");



