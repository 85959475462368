import { RootState } from "../rootTypes";
import { createSelector } from "reselect";
import { displayBookingCalendarAvailableDay } from "./bookingTypes";

const selectBooking = (state: RootState) => state.booking;

export const selectBookingFormLoading = createSelector(
  selectBooking,
  (state) => state.loading
);

export const selectLogsForm = createSelector(
  selectBooking,
  (state) => {
    return state.logs
  }
);

export const selectLogForm = createSelector(
  selectBooking,
  (state) => {
    return state.log
  }
);

export const selectServiceForm = createSelector(
  selectBooking,
  (state) => {
    return state.service
  }
);

export const selectDisplayLog = createSelector(
  selectBooking,
  (state) => {
    return state.displayLog
  }
);

export const selectBookingDetails = createSelector(
  selectBooking,
  (state) => {
    return state.activeBookingDetails
  }
);

export const selectDisplayLogID = createSelector(
  selectBooking,
  (state) => {
    return state.logDisplayID
  }
);

export const selectCalendarAvailableDay = createSelector(
  selectBooking,
  (state) => {
    const calendarAvailableDay: displayBookingCalendarAvailableDay = {availableDays: []}
    state.bookingCalendarAvailableDay.availableDays.map((item)=>{
      calendarAvailableDay.availableDays.push(new Date(item))
    })
    return calendarAvailableDay
  }
);
