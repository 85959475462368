import { RootState } from "../rootTypes";
import { createSelector } from "reselect";
import produce from "immer";

const selectScenarios = (state: RootState) => state.scenarios;

export const selectScenariosFormLoading = createSelector(
  selectScenarios,
  (state) => state.loading
);

export const selectScenariosForm = createSelector(
  selectScenarios,
  (state) => {
    return state.scenarios
  }
);
