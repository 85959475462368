import { connect } from 'react-redux'

import OrdersPage from './OrdersPage'

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage)
