import React, { Suspense } from 'react'
import ProfileShimmer from 'src/modules/profile/components/ProfileShimmer'

const Profile = React.lazy(() => import('src/modules/profile/containers/ProfilePassword'))

const ProfilePasswordPage = () => {
  return (
    <Suspense fallback={<ProfileShimmer />}>
      <Profile />
    </Suspense>
  )
}

export default ProfilePasswordPage