import React from 'react'
import styled from 'styled-components'
import { shimmerStyles } from 'hopshopui'

const VkShimmer = () => (
  <Container>
    <Shimmer></Shimmer>
    <Shimmer></Shimmer>
    <Shimmer></Shimmer>
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Shimmer = styled.div`
  ${shimmerStyles};
  height: 100px;
  width: 100%;
  max-width: 460px;
  border-radius: 10px;
  margin-bottom: 20px;
  align-self: center;
`

export default VkShimmer