import React from "react";

const EmptyContentFeather = (props: any) => {
  return (
    <svg
      width="212"
      height="211"
      viewBox="0 0 212 211"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0)">
        <path
          d="M69.7789 151.139L99.4219 147.86L155.155 78.3509C162.445 69.2587 161.004 55.9942 151.912 48.704C142.82 41.4137 129.559 42.8903 122.269 51.9823L66.5357 121.492L69.7789 151.139Z"
          fill="#A645CB"
        />
        <path
          d="M121.883 63.694C129.174 54.6017 142.491 53.1709 151.583 60.4611C155.304 63.4443 157.742 67.4509 158.83 71.7428C161.463 63.639 158.998 54.3857 151.956 48.7395C142.864 41.4493 129.559 42.8905 122.269 51.9825L66.5359 121.491L67.6171 131.374L121.883 63.694Z"
          fill="#960ECB"
        />
        <path
          d="M77.1125 141.993L73.8691 112.346L66.5359 121.491L69.779 151.139L77.1125 141.993Z"
          fill="#0491C7"
        />
        <path
          d="M73.8691 112.346L66.5359 121.491L67.617 131.374L74.9501 122.228L73.8691 112.346Z"
          fill="#2C4D77"
        />
        <path
          d="M112.314 98.0921L107.604 70.2744L95.8703 84.9078L99.1136 114.555L112.314 98.0921Z"
          fill="#B62F78"
        />
        <path
          d="M107.604 70.2744L95.8703 84.9078L96.9515 94.79L108.685 80.1566L107.604 70.2744Z"
          fill="#B60A66"
        />
        <path
          d="M136.196 102.216L106.533 105.479L116.8 92.6747L146.462 89.4116L136.196 102.216Z"
          fill="#0792C7"
        />
        <path
          d="M144.995 91.2408L116.8 92.6747L132.933 72.5536L159.299 69.6534L155.262 78.4364L144.995 91.2408Z"
          fill="#F60A53"
        />
        <path
          d="M159.255 69.6182L132.845 72.4834L126.978 79.8001L155.196 78.3838L159.255 69.6182Z"
          fill="#F6306D"
        />
        <path
          d="M132.857 102.543L106.446 105.409L100.58 112.725L128.797 111.309L132.857 102.543Z"
          fill="#960ECB"
        />
        <path
          d="M99.5285 147.946L71.3329 149.379L90.3994 125.6L118.595 124.166L109.795 135.141L99.5285 147.946Z"
          fill="#B60A66"
        />
        <path
          d="M118.49 124.08L90.3135 125.53L81.5135 136.505L109.69 135.056L118.49 124.08Z"
          fill="#B62F78"
        />
        <path
          d="M158.855 70.0297L132.845 72.4832L146.045 56.0208C146.045 56.0208 156.492 57.4676 158.855 70.0297Z"
          fill="#FE702D"
        />
        <path
          d="M145.38 57.0268C147.584 57.7962 149.696 58.9473 151.62 60.4905C155.341 63.4737 157.76 67.4654 158.848 71.7573C161.482 63.6537 159.041 54.4202 151.999 48.7743"
          fill="#FE5102"
        />
        <path
          d="M110.934 99.9912L107.671 70.3285L116.471 59.3534L119.734 89.0161L110.934 99.9912Z"
          fill="#FE702D"
        />
        <path
          d="M116.402 59.2992L107.602 70.2744L108.684 80.1566L117.484 69.1814L116.402 59.2992Z"
          fill="#FE5102"
        />
        <path
          d="M77.2006 142.062L73.9376 112.4L95.9375 84.9619L99.2005 114.625L77.2006 142.062Z"
          fill="#F6306D"
        />
        <path
          d="M95.8691 84.9078L73.8693 112.346L74.9502 122.228L96.9503 94.79L95.8691 84.9078Z"
          fill="#F60A53"
        />
        <path
          d="M90.479 113.604L87.1426 110.929L84.2764 114.504L87.6129 117.179L90.479 113.604Z"
          fill="white"
        />
        <path
          d="M94.938 138.32L91.6016 135.645L88.7354 139.219L92.0718 141.894L94.938 138.32Z"
          fill="white"
        />
        <path
          d="M137.485 54.9808L134.148 52.3056L131.282 55.8802L134.619 58.5553L137.485 54.9808Z"
          fill="white"
        />
        <path
          d="M157.062 79.8797C165.131 69.8168 163.464 55.0295 153.346 46.9167C143.228 38.804 128.432 40.3906 120.363 50.4538L64.0339 120.706L67.3722 150.235L57.606 162.415L61.1806 165.281L70.7567 153.338L100.733 150.132L157.062 79.8797ZM118.892 120.161L95.2756 122.759L107.356 107.693L130.972 105.095L118.892 120.161ZM153.488 77.0136L145.291 87.2361L121.675 89.8337L133.755 74.7679L156.245 72.2941C155.578 73.9553 154.663 75.5475 153.488 77.0136ZM112.565 93.8713L110.015 70.6845L114.761 64.7648L117.311 87.9516L112.565 93.8713ZM108.512 98.9263L100.832 108.505L98.2814 85.3179L105.962 75.7393L108.512 98.9263ZM94.2282 90.3729L96.7786 113.56L78.8319 135.942L76.2815 112.756L94.2282 90.3729ZM135.025 100.04L111.409 102.638L117.622 94.8892L141.238 92.2918L135.025 100.04ZM157.457 67.5512L137.808 69.7127L152.071 51.9248C156.262 56.1346 158.127 61.9449 157.457 67.5512ZM148.472 49.0891L121.365 82.8961L118.815 59.7093L123.938 53.3199C129.911 45.8698 140.475 44.2086 148.472 49.0891ZM68.8009 122.085L72.2283 117.811L74.7788 140.998L71.4136 145.194L68.8009 122.085ZM74.7981 148.298L91.2221 127.814L114.838 125.217L98.3508 145.779L74.7981 148.298Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="150"
            height="150"
            fill="white"
            transform="translate(94.832) rotate(38.723)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmptyContentFeather;
