import { useState, useCallback, FocusEvent } from "react";

export const useColorPicker = (scrollTop: number = 0, scrollLeft: number = 0) => {
  const [tooltipPosition, setTooltipPosition] = useState({
    top: 0,
    left: 0,
  });

  const [colorPickerVisible, setColorPickerVisible] = useState(false);

  const handleFocusCallback = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      let offsetLeft = event.target.offsetLeft + 20 + scrollLeft
      let offsetTop = event.target.offsetTop + event.target.clientHeight - scrollTop
      setTooltipPosition({
        top: offsetTop,
        left: offsetLeft,
      });
      setColorPickerVisible(true);
    },
    [setTooltipPosition, setColorPickerVisible, scrollTop, scrollLeft]
  );


  const toggleTooltipContainer = useCallback(() => {
    setColorPickerVisible(false);
  }, [setColorPickerVisible]);

  return {
    tooltipPosition,
    colorPickerVisible,
    setColorPickerVisible,
    handleFocusCallback,
    toggleTooltipContainer
  }
}