import React, {
  memo,
  FC,
  useCallback,
  ChangeEvent,
  FormEvent,
  useMemo,
  MouseEvent,
  useState,
  useEffect,
  useRef,
  Fragment,
} from "react";
import {
  PillCard,
  PillMarginContainer,
  PillLineItem,
  SmallTogleContainer,
  PillLineLabel,
  Toggle,
  PillHorizontalContainer,
  InputPill,
  Divider,
  HeaderText,
  DoneIcon,
  PillSelectLabel,
  Select, ArrowLeftIcon, AddGoodButton, Input, SecondaryRoundButton, EditIcon, DeleteIcon, size,
} from "hopshopui";
import styled from "styled-components";
import DesignColorPickerInput from "./DesignColorPickerInput";
import { DesignHeader } from "src/elements/layout/DesignHeader";
import { useT } from "src/hooks/useT";
import { DesignFormContainer } from "./DesignHeaderForm";
import { ConnectedDesignCoverFormProps } from "../containers/DesignCoverForm";
import { LogoInput } from "src/elements";
import { ImageType } from "src/templates/utils/types/goodType";

const DesignCoverForm: FC<ConnectedDesignCoverFormProps> = ({
  match,
  resetCover,
  imageLoading,
  loadNewCover,
  coverSettingsDesign,
  triggerUploadImageAsync,
  checkAndDisableDesignType,
  toggleProjectDesignCoverKey,
  changeStringProjectDesignCoverKey,
  changeNumberProjectDesignCoverKey,
  triggerGetCoverCustomizationSettings,
  triggerUpdateCoverCustomizationSettings,
}) => {
  const { t } = useT();
  const projectId = useMemo(() => {
    return match.params.id;
  }, [match]);
  const [localLoading, setLocalLoading] = useState(false);
  const loading = useMemo(()=> imageLoading || localLoading,[imageLoading, localLoading]);
  const formRef = useRef<HTMLDivElement | null>(null);
  const coverImageInputRef = useRef<{ inputElement: HTMLInputElement | null }>({ inputElement: null });
  const [scrollTop, setScrollTop] = useState(0);
  const scrollListener = (e: Event) => {
    setScrollTop((e.target as HTMLElement)?.scrollTop)
  }
  useEffect(() => {
    if (!formRef.current) return
    const el = formRef.current;
    el.addEventListener("scroll", (e) => scrollListener(e))
    return () => {
      el.removeEventListener("scroll", (e) => scrollListener(e))
    }
  }, [])

  const [settingsBarHeight, setSettingsBarHeight] = useState(500);

  useEffect(() => {
    setSettingsBarHeight(window.innerHeight - 70)
  }, []);

  const handleReaderListener = useCallback((nestedReader, file) => {
    triggerUploadImageAsync({
      image: file,
      callback: (data: ImageType) => {
        loadNewCover({
          image: data,
          imageUrl: nestedReader.result as string,
        })
        setLocalLoading(false)
      }
    });
  }, [triggerUploadImageAsync, loadNewCover]);

  const getLocalImgUrl = useCallback((file) => {
    if (file) {
      const reader =  new FileReader();
      reader.addEventListener('load', () => handleReaderListener(reader, file), false);
      reader.readAsDataURL(file);
    }
  }, [handleReaderListener]);

  const onCoverChange = useCallback((file) => {
    setLocalLoading(true)
    getLocalImgUrl(file)
  }, [getLocalImgUrl])

  const uploadImage = useCallback(() => {
    if (coverImageInputRef.current.inputElement) {
      coverImageInputRef.current.inputElement.click()
    }
  }, [])

  useEffect(() => {
      coverImageInputRef.current.inputElement = document.querySelector("input[ type=file]")
  }, [uploadImage, coverSettingsDesign])

  useEffect(()=>{
    if (!coverSettingsDesign?.coverSettings.image) {
      uploadImage();
    }
  }, [uploadImage])

  const handleButtonColorChange = useCallback(
    (value: string, fieldName: string) => {
      //Todo: fix types
      changeStringProjectDesignCoverKey({
        field: fieldName as any,
        value
      });
    },
    [changeStringProjectDesignCoverKey]
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!coverSettingsDesign || loading) return
      triggerUpdateCoverCustomizationSettings({
        projectId: parseInt(projectId),
        coverSettingsDesign: coverSettingsDesign.coverSettings
      });

    },
    [loading, coverSettingsDesign, projectId, triggerUpdateCoverCustomizationSettings]
  );

  const handleDecline = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (coverSettingsDesign) {
        checkAndDisableDesignType()
      }
    },
    [checkAndDisableDesignType, coverSettingsDesign]
  );

  const updateCoverDesign = useCallback(
    (e: ChangeEvent) => {
      const key = e.target.getAttribute("name");
      if (key) {
        //Todo: fix types
        toggleProjectDesignCoverKey(key as any);
      }
    },
    [toggleProjectDesignCoverKey]
  );

  const updateCoverDesignStringField = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const fieldName = e.target.getAttribute("name");
      const key = fieldName ? fieldName : "textAlignment";
      const value = e.target.value;
      if (key) {
        //Todo: fix types
        changeStringProjectDesignCoverKey({
          field: key as any,
          value
        });
      }
    },
    [changeStringProjectDesignCoverKey]
  );

  const updateCoverDesignNumberField = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const key = e.target.getAttribute("name");
      const value = e.target.valueAsNumber;
      if (key) {
        //Todo: fix types
        changeNumberProjectDesignCoverKey({
          field: key as any,
          value
        });
      }
    },
    [changeNumberProjectDesignCoverKey]
  );

  const [text, setText] = useState(coverSettingsDesign?.coverSettings.text || '')

  const handleChange = (e: any) => {
    const value = e.target.value;
    setText(value)
    const key = e.target.getAttribute("name");
    changeStringProjectDesignCoverKey({
      field: key as any,
      value: value,
    });
  }

  const onDeleteCover = useCallback(()=>{
    resetCover()
  }, [resetCover])

  return (
    <form onSubmit={handleSubmit}>
      <DesignHeader isForm={true}>
        <StyledBackButton disabled={loading} onClick={handleDecline} >
          <ArrowLeftIcon fill="#000000" />
        </StyledBackButton>
        <HeaderTextStyled>{t('goodDesign.cover')}</HeaderTextStyled>
        <StyledDoneButton>
          <DoneIcon stroke="#000000" />
        </StyledDoneButton>
      </DesignHeader>
      <DesignFormContainerWrapper height={settingsBarHeight} ref={formRef}>
        <PillCard white>
          <PillMarginContainer>
            <PillLineItem>
              <PillLineLabel>{t("design.headerFullScreen")}</PillLineLabel>
              <SmallTogleContainer>
                <Toggle
                  small
                  values={[]}
                  onChange={updateCoverDesign}
                  checked={coverSettingsDesign?.coverSettings.extendToFullScreen}
                  toggleBackground
                  name="extendToFullScreen"
                  textHints={t("design.headerFullScreenHint")}
                ></Toggle>
              </SmallTogleContainer>
            </PillLineItem>
            <InputPill
              label={t("design.height")}
              value={`${coverSettingsDesign?.coverSettings.heightPx}`}
              placeholder={t("design.placeholderDefault")}
              onChange={(e: ChangeEvent<HTMLInputElement>) => updateCoverDesignNumberField(e)}
              onBlur={() => { }}
              errors={{}}
              touched={{}}
              name="heightPx"
              disabled={false}
              type="number"
            />
            <StyledDivider />
            <PillLineItem>
              <PillLineLabel>{t("design.coverText")}</PillLineLabel>
              <SmallTogleContainer>
                <Toggle
                  small
                  values={[]}
                  onChange={updateCoverDesign}
                  checked={coverSettingsDesign?.coverSettings.showText}
                  toggleBackground
                  name="showText"
                  textHints={t("design.coverTextHint")}
                ></Toggle>
              </SmallTogleContainer>
            </PillLineItem>
            {coverSettingsDesign?.coverSettings.showText
              && <Fragment>
                <StyledInput
                  errors={{}}
                  touched={{}}
                  horizontal
                  textarea
                  placeholder={t('design.coverTextPlaceholder')}
                  label={t('project.addgood.blocks.text')}
                  name="text"
                  id="text"
                  type="text"
                  value={text}
                  onChange={handleChange}
                  load={false}
                  labelButton={<StyledSymbolsNumber><span>{240 - (coverSettingsDesign?.coverSettings.text?.length || 0)}</span></StyledSymbolsNumber> as any}
                  maxLength={240}
                />
                <PillHorizontalContainer itemCount={2}>
                  <PillLineItem>
                    <div>
                      <PillSelectLabel>
                        {t("design.alignments")}
                      </PillSelectLabel>
                      <Select
                        value={coverSettingsDesign?.coverSettings.textAlignment}
                        pillStyle
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => updateCoverDesignStringField(e)}
                      >
                        {coverSettingsDesign?.availableTextAlignments?.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {t(`design.availableTextAlignments.${item}`)}
                            </option>
                          )
                        })}
                      </Select>
                    </div>
                  </PillLineItem>
                  <InputPill
                    label={t("design.coverTextSize")}
                    value={`${coverSettingsDesign?.coverSettings.textSizePx}`}
                    placeholder={""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updateCoverDesignNumberField(e)}
                    onBlur={() => { }}
                    errors={{}}
                    touched={{}}
                    name="textSizePx"
                    disabled={false}
                    type="number"
                  />
                </PillHorizontalContainer>

                <PillHorizontalContainer itemCount={2}>
                  <DesignColorPickerInput
                    value={coverSettingsDesign?.coverSettings.textSize || ''}
                    onChange={(e) => handleButtonColorChange(e, "textSize")}
                    placeholder={t("design.placeholderDefault")}
                    label={t("goodDesign.textColor")}
                    scrollTop={scrollTop}
                  />
                </PillHorizontalContainer>
              </Fragment>
            }
            <StyledDivider />
            <PillLineItem>
              <PillLineLabel>{t("design.coverClickable")}</PillLineLabel>
              <SmallTogleContainer>
                <Toggle
                  small
                  values={[]}
                  onChange={updateCoverDesign}
                  checked={coverSettingsDesign?.coverSettings.moveOnClick}
                  toggleBackground
                  name="moveOnClick"
                  textHints={t("design.coverClickableHint")}
                ></Toggle>
              </SmallTogleContainer>
            </PillLineItem>
            {coverSettingsDesign?.coverSettings.moveOnClick
              && <InputPill
                label={t("project.addgood.digitalTypes.Link")}
                value={coverSettingsDesign?.coverSettings.link || ''}
                placeholder={t("design.linkPlaceholder")}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateCoverDesignStringField(e)}
                onBlur={() => { }}
                errors={{}}
                touched={{}}
                name="link"
                disabled={false}
                type="string"
              />
            }
          </PillMarginContainer>
        </PillCard>
        <ButtonsContainer>
          <StyledSecondaryRoundButton type={"button"} disabled={loading} load={loading} Icon={<EditIcon />} onClick={uploadImage}>
            {t("design.changeCover")}
          </StyledSecondaryRoundButton>
          <StyledSecondaryRoundButton type={"button"} disabled={loading} load={loading} Icon={<DeleteIcon />} onClick={onDeleteCover}>
            {t("buttons.delete")}
          </StyledSecondaryRoundButton>
        </ButtonsContainer>
        <StyledLogoInput
          small
          whiteIcon
          iconFill="#000000"
          onChange={onCoverChange}
        />
      </DesignFormContainerWrapper>
    </form>
  );
};

const StyledLogoInput = styled(LogoInput)`
  display: none;
`

const ButtonsContainer = styled.div`
  gap: 15px;
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: ${size.mobile}) {
    flex-direction: column;
  }
`

const StyledSecondaryRoundButton = styled(SecondaryRoundButton)`
  flex-grow: 0;
  path {
    fill: ${(props) => props.theme.colors.dark};
    opacity: 1;
  }
  div{
    font-size: 14px;
  }
  div:last-child{
    margin-left: 10px;
  }
`

const DesignFormContainerWrapper = styled(DesignFormContainer) <{ height: number }>`
  height: ${props => `${props.height}px`};
  overflow: scroll;

`
const StyledSymbolsNumber = styled.div`
  color: #000000;
  opacity: 0.25;
`

const StyledDivider = styled(Divider)`
  margin-bottom:20px;
`

const StyledInput = styled(Input)`
  flex-direction: column;
  width: 100%;
  padding: 20px;
  & div {
    width: 100%;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: unset;
  }
  & textarea{
    max-width: unset;
    border: unset;
    padding-left: 0;
  }
  & a {
    margin-left: unset;
  }
  & span {
    text-decoration: none;
  }
`

const StyledBackButton = styled(AddGoodButton)`
  height: 40px;
  width: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  flex: none;
  background: #FFFFFF;
  border: none;
  &:hover{
    background: none;
  }
`;

const StyledDoneButton = styled(AddGoodButton)`
  display: block;
  flex: none;
  color: #000000;
  border: none;
  background: none;
  &:hover{
    background: none;
  }
`;

const HeaderTextStyled = styled(HeaderText)`
  font-weight: 500;
  color: #000000;
`
export default memo(DesignCoverForm);
