import React, { useEffect } from 'react'
import { arrayOf, func } from 'prop-types'
import styled from 'styled-components'

import { projectShape } from 'src/templates/utils/types/projectShape'
import { history } from 'src/utils/propTypes'
import { vkLogin, okLogin } from 'src/utils/localStorage'

const ProjectListPage = ({ getProjects, projects, history, loading }) => {
  useEffect(() => {
    if (loading) {
      return 
    }
    if (projects.length === 1) {
      history.push('/project/' + projects[0].id)
    } else {
      const vk = localStorage.getItem(vkLogin) === 'true'
      const ok = localStorage.getItem(okLogin) === 'true'
      if (vk) {
        history.push('/import/vk')
      } else if (ok){
        history.push('/import/ok')
      } else {
        history.push('/createProject')
      }
    }
  }, [projects, loading])

  return (
    <Container></Container>
  )
}

const Container = styled.div``

ProjectListPage.propTypes = {
  getProjects: func.isRequired,
  projects: arrayOf(projectShape),
  history: history
}


export default ProjectListPage