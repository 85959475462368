import { createSelector } from 'reselect'

export const selectNotificationState = state => state.notification

export const selectNotification = createSelector(
  selectNotificationState,
  (notification) => notification.notification
)

export const selectNotificationAction = createSelector(
  selectNotificationState,
  (notification) => notification.notificationAction
)
export const selectNotificationActionPath = createSelector(
  selectNotificationState,
  (notification) => notification.notificationActionRedirectPath
)

export const selectOrderNotification = createSelector(
  selectNotificationState,
  (state) => {
    return {
      showNotificationOrder: state.showNotificationOrder,
      notificationOrder: state.notificationOrder
    }
  }
)