import { ImageType } from "src/templates/utils/types/goodType";
import { RootState } from "../rootTypes";
import {
  selectActiveProject,
  selectActiveProjectGoods,
  selectFavoriteProjectGoods,
  selectIsUpdateInprogress,
  selectActiveCategories
} from "./projectsSelector";

export type ProjectGood = {
  basePrice?: number
  changedDate?: string,
  defaultImage: ImageType
}

export const selectActiveProjectWithTypes: (
  state: any,
  id: string
) => {
  can_accept_cards: boolean;
  currency?: string;
  currency_id?: number;
  description?: string;
  domain?: string;
  hasAdultGoods: boolean;
  id: number;
  isAdult: boolean;
  is_adult: boolean; //хз откуда приходит и is_adult и isAdult
  is_payment_enabled: boolean;
  keywords?: Array<string>;
  logo?: Object;
  display_domain?: string;
  registration_date?: string;
  status: number;
  status_id?: number;
  template?: string;
  title?: string;
  timezone?: string;
  enabled_html_code?: boolean;
} = selectActiveProject;

export const selectFavoriteProjectGoodsWithTypes: (
  state: any,
  id: string,
  cartItems: Record<string, number>
) => Array<{
  base_price?: number;
  changed_date?: string;
  default_image: Object;
  id: number;
  is_adult: boolean;
  is_external: boolean;
  normalized_title?: string;
  old_price?: number;
  project_id: number;
  title?: string;
  timezone?: string;
}> = selectFavoriteProjectGoods;

export const selectActiveCategoriesWithState: (
  state: any,
  id: string,
) => any = selectActiveCategories

export const selectActiveProjectGoodsTyped: (state: RootState) => Array<any> = selectActiveProjectGoods;

export const selectIsUpdateInProgressTyped: (state: RootState) => boolean = selectIsUpdateInprogress