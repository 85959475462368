import { takeEvery, put, call } from "redux-saga/effects";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import {
  addPromocode,
  getPromocodes,
  changePromocode,
  deletePromocode,
  triggerAddPromocode,
  triggerGetPromocodes,
  PromocodesResponseType,
  triggerChangePromocode,
  triggerDeletePromocode,
} from "./promocodesTypes";
import { get, post, putRequest } from "src/utils/requestSaga";
import { SagaIterator } from "redux-saga";
import { AxiosResponse } from "axios";
import { handleServerError, showNotification } from "../notification";
import { camelize, snakeize } from "casing";

const getPromocodesSaga = bindAsyncAction(getPromocodes)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<PromocodesResponseType> = yield call(
      get,
      `/promocodes?projectId=${payload}`
    );
    return camelize(data);
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const changePromocodesSaga = bindAsyncAction(changePromocode)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(putRequest, `/promocodes`, snakeize(payload));
    yield put(triggerGetPromocodes(payload.projectId.toString()));
  } catch (e) {
    if (
      e?.response?.data?.errors?.Validations &&
      e?.response?.data?.errors?.Validations[0] &&
      e?.response?.data?.errors?.Validations[0].includes(
        "Unacceptable promocode"
      )
    ) {
      yield put(showNotification({ key: "promocodes.promocodeWrongSymbols" }));
    } else {
      yield put(handleServerError(e));
    }
  }
});

const addPromocodesSaga = bindAsyncAction(addPromocode)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(post, `/promocodes`, snakeize(payload));
    yield put(triggerGetPromocodes(payload.projectId.toString()));
  } catch (e) {
    if (
      e?.response?.data?.errors?.Validations &&
      e?.response?.data?.errors?.Validations[0] &&
      e?.response?.data?.errors?.Validations[0].includes(
        "Unacceptable promocode"
      )
    ) {
      yield put(showNotification({ key: "promocodes.promocodeWrongSymbols" }));
    } else {
      yield put(handleServerError(e));
    }
  }
});

const deletePromocodeSaga = bindAsyncAction(deletePromocode)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(post, `/promocodes/delete`, snakeize(payload));
    yield put(triggerGetPromocodes(payload.projectId.toString()));
  } catch (e) {
    yield put(handleServerError(e));
  }
});

export default function* () {
  yield takeEvery(triggerGetPromocodes.type, getPromocodesSaga);
  yield takeEvery(triggerChangePromocode.type, changePromocodesSaga);
  yield takeEvery(triggerAddPromocode.type, addPromocodesSaga);
  yield takeEvery(triggerDeletePromocode.type, deletePromocodeSaga);
}
