import { RootState } from "../rootTypes";
import { createSelector } from "reselect";
import { providers } from "src/utils/variables";
import { SyncFormValues } from "src/modules/projects/components/SettingsMainSync";

const selectProjectSync = (state: RootState) => state.sync;

export const selectActiveProjectSync = createSelector(
  selectProjectSync,
  (_: RootState, id: string) => id,
  (state, id) => state.data[id]
);

export const selelectProjectSyncLoading = createSelector(
  selectProjectSync,
  (state) => state.loading
);

export const selectProjectSyncData = createSelector(
  selectProjectSync,
  (_: RootState, id: string) => id,
  (sync, id) => {
    return sync.data[id]?.settings || {};
  }
);

export const selectProjectSyncInitialValues = createSelector(
  selectProjectSyncData,
  (sync) => {
    return {
      ...sync,
      edit: false,
      type: Boolean(sync.type),
      policy: String(sync.policy),
    };
  }
);

export const selectProjectSyncTypes = createSelector(
  selectActiveProjectSync,
  (sync) => {
    return sync?.types || [];
  }
);

export const selectProjectSyncPolicies = createSelector(
  selectActiveProjectSync,
  (sync) => sync?.policies ? [sync.policies[1], sync.policies[0], sync.policies[2]] : []
);

export const selectProjectFrequencies = createSelector(
  selectActiveProjectSync,
  (sync) => sync?.frequencies || []
);

export const selectSyncSourcesOptions = createSelector(
  selectActiveProjectSync,
  (sync) => {
    return (
      sync?.externalProjects.map((project) => {
        if (project.provider === providers.vk) {
          return {
            name: `VK: ${project.name}`,
            provider: project.provider,
            value: `https://vk.com/${project.screenName}`,
          };
        } else if (project.provider === providers.ok) {
          return {
            name: `OK: ${project.name}`,
            provider: project.provider,
            value: `https://ok.ru/${project.type}/${project.id}`,
          };
        }else {
          return {
            name: `Inst: @${project.name}`,
            provider: project.provider,
            value: `https://instagram.com/${project.screenName}`,
          };
        }
      }) || []
    );
  }
);

export const selectProjectFormData = createSelector(
  selectSyncSourcesOptions,
  selectActiveProjectSync,
  (options, sync): SyncFormValues => {
    const selectSourceValue = options.filter((option) => {
      return option.value === sync.settings.source;
    })[0]

    const sourceSelect = selectSourceValue?.value || "other";

    const otherSourceValue = selectSourceValue?.value ? '' : sync?.settings?.source

    const readOnlySourceValue = selectSourceValue?.name || otherSourceValue
      
    let hashtags = sync?.settings?.hashtags?.join('#')
    if (hashtags) {
      hashtags = '#' + hashtags
    } 
    return {
      ...sync?.settings,
      edit:!Boolean(sync?.settings.type),
      type: Boolean(sync?.settings.type),
      policy: String(sync?.settings.policy),
      frequency: Number(sync?.settings?.frequency),
      otherSource: otherSourceValue || "",
      sourceSelect,
      readOnlySourceValue,
      hashtags
    };
  }
);

export const selelectIsUpdateInprogress = createSelector(
  selectProjectSync,
  (state) => state.isUpdateInprogress
);

export const selectSyncInProgress = createSelector(
  selectActiveProjectSync,
  (sync) => sync?.settings?.isUpdateInprogress
)

export const selectSyncHasExternalProvider = createSelector(
  selectActiveProjectSync,
  (sync) => sync?.hasExternalProvider
)