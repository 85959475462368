import { connect, ConnectedProps } from "react-redux";
import { RootState } from "src/store/rootTypes";
import { DefinedRouterComponentProps } from "src/types";
import { withRouter } from "react-router-dom";
import { triggerPushButtonDesignSettings, selectProjectSettingsLoading, checkAndDisableDesignType, selectModuleButtonDesign, setModuleButtonDesign } from "src/store/projectSettings";
import DesignButtonForm from '../components/DesignButtonForm'
import { selectPageContent } from "src/store/pages";
import { matchParamsGoodMasterAlias, matchParamsId } from "src/utils/params";
import { getAliasFromPath } from "src/templates/utils/alias";
import { selectActiveGood } from "src/store/goods";

const mapStateToProps = (state: RootState, props: DefinedRouterComponentProps) => ({
  buttonDesign: selectModuleButtonDesign(state),
  loading: selectProjectSettingsLoading(state),
  content: selectPageContent(state, matchParamsId(props), getAliasFromPath(props.match.url)),
  item: selectActiveGood(state, matchParamsGoodMasterAlias(props)),
})

const mapDispatchToProps = {
  triggerPushButtonDesignSettings,
  checkAndDisableDesignType,
  setModuleButtonDesign,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ConnectedDesignButtonFormProps = ConnectedProps<typeof connector> & DefinedRouterComponentProps

export default withRouter(connector(DesignButtonForm))