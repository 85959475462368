import React, { Suspense, Fragment } from "react";

const Packages = React.lazy(
  () => import("src/modules/packages/containers/ProjectPackages")
);
const SubscriptionMain = React.lazy(
  () => import("src/modules/subscriptions/containers/SubscriptionMainContainer")
);

const ProjectPackages = () => {
  return (
    <Fragment>
      <Suspense fallback={<div />}>
        <Packages />
      </Suspense>
      <Suspense fallback={<div></div>}>
        <SubscriptionMain />
      </Suspense>
    </Fragment>
  );
};

export default ProjectPackages;
