import React, {FC, memo, Fragment, useCallback, useState, useContext, useEffect, useMemo} from 'react'
import {BellIcon, HouseIcon, ReviewsIcon, SettingsIcon, SubMenu, SettingsMenuIcon} from 'hopshopui'
import styled, {css} from 'styled-components'
import {useT} from 'src/hooks/useT'
import history from 'src/utils/browserHistory'
import MenuContext from "../../templates/utils/providers/MenuProvider"

interface Props {
  id: string;
  domain: string;
  bookingEnabled: boolean;
}

const MobileMenu: FC<Props> = ({id, domain, bookingEnabled}) => {
  const [activeLink, setActiveLink] = useState('home');
  const [currentSubMenu, setCurrentSubMenu] = useState<{title: string, counter?: number}[] | null>(null);
  const {isMenuVisibleWithScroll, changeIsMenu}: any = useContext(MenuContext);
  const { location } = history;

  const pathArray = useMemo(() => {
    return {
        orders: [`/project/${id}/orders`, `/project/${id}/booking/default/`],
        home: [`/project/${id}/goods`],
        options: [`/profile`, `/project/${id}/main`],
        feedbacks: [`/project/${id}/feedbacks/Published`]
    }
  }, [id])

  useEffect(() => {
    changeIsMenu(true)
  }, [])

  const {t} = useT()

  const closeMenu = () => {
    setActiveLink('')
    setCurrentSubMenu(null)
  }

  const menu = {
    options: [
      {title: t('menu.profile')},
      {title: t('menu.setting')},
    ],
    orders: [
      {title: t('menu.booking')},
      {title: t('menu.orders')},
    ]
  }

  const handleClick = useCallback((e: any) => {
    setActiveLink(e.currentTarget.name);
    switch (e.currentTarget.name){
      case 'options':
        setCurrentSubMenu(menu.options);
        break;
      case 'orders':
        setCurrentSubMenu(menu.orders);
        break;
      case 'feedbacks':
        closeMenu()
        history.push(`/project/${id}/feedbacks/Published`);
        break;
      default:
        history.push(`/`);
    }
  }, [menu.options, menu.orders, currentSubMenu]);


  const handleRedirect = (index: number) => {
    switch (activeLink) {
      case 'options':
        if(index === 0){
          history.push('/profile')
        }else{
          history.push(id ? `/project/${id}/main` : `/project`)
        }
        break;
      case 'orders':
        if(index === 0){
          history.push(`/project/${id}/booking/default/`)
        }else if (index === 1){
          history.push(id ? `/project/${id}/orders` : "/project")
        } else if (index === 2){
          history.push(id ? `/project/${id}/feedbacks/Published` : "/project")
        }
        break;
      default:
        history.push(`/`)
    }
    closeMenu();
  }

  return (
      <Fragment>
        {isMenuVisibleWithScroll &&  <TestContainer>
          <div style={{position: "relative", width: '100%', zIndex: 6}}>
            <SubMenuContainer items={currentSubMenu || []} position="bottom" open={Boolean(currentSubMenu)} handleClick={(index) => handleRedirect(index)}/>
            <MenuContainer>
              <MenuItems>
                <MenuItem data-testid='mobile-menu-orders-button' isActive={(activeLink === 'options' || pathArray.options.includes(location.pathname))} onClick={handleClick} name='options' >
                  <SettingsMenuIcon fill={(activeLink === 'options' || pathArray.options.includes(location.pathname)) && '#2DC6DB'}/>
                </MenuItem>
                <MenuItem isActive={(activeLink === 'home' && pathArray.home.includes(location.pathname))} onClick={handleClick} name='home'>
                  <HouseIcon fill={(activeLink === 'home' && pathArray.home.includes(location.pathname)) && '#5D5FEF'} />
                </MenuItem>
                <MenuItem  isActive={!currentSubMenu && (activeLink === 'feedbacks' || pathArray.feedbacks.includes(location.pathname))} onClick={handleClick} name="feedbacks" >
                  <ReviewsIcon fill={!currentSubMenu && (activeLink === 'feedbacks' || pathArray.feedbacks.includes(location.pathname)) && '#FFE289'} />
                </MenuItem>
                <MenuItem isActive={(activeLink === 'orders' || pathArray.orders.includes(location.pathname))} onClick={handleClick} name='orders'>
                  <BellIcon fill={(activeLink === 'orders' || pathArray.orders.includes(location.pathname)) && '#FF4081'}/>
                </MenuItem>
              </MenuItems>
            </MenuContainer>
          </div>
          {(activeLink && activeLink !== 'home') && <MenuBackground onClick={closeMenu}/>}
        </TestContainer>
        }
      </Fragment>
  )
}

const SubMenuContainer = styled(SubMenu)`
  padding-bottom: 75px;
  z-index: -1;
`

const handleSelectStyle = (name: string) => {
  switch (name) {
    case "options":
      return css`
              background: radial-gradient(52.5% 52.5% at 50% 82.5%, rgba(45, 198, 219, 0.5) 0%, rgba(45, 198, 219, 0) 100%);
              border: 1px solid rgba(255, 255, 255, 0.15);
            `;
    case "home":
      return css`
              background: radial-gradient(52.5% 52.5% at 50% 82.5%, rgba(93, 95, 239, 0.5) 0%, rgba(93, 95, 239, 0) 100%);
              border: 1px solid rgba(255, 255, 255, 0.15);
            `;
    case "feedbacks":
      return css`
        background: radial-gradient(52.5% 52.5% at 50% 82.5%, rgba(255, 226, 137, 0.5) 0%, rgba(255, 226, 137, 0) 100%);
        border: 1px solid rgba(255, 255, 255, 0.15);
      `;
    default:
      return css`
              background: radial-gradient(52.5% 52.5% at 50% 82.5%, rgba(255, 64, 129, 0.5) 0%, rgba(255, 64, 129, 0) 100%);
              border: 1px solid rgba(255, 255, 255, 0.15);
            `;
  }
};

const MenuItem = styled.a<{
  isActive?: boolean;
  name: string;
}>`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 13px;
  height: 40px;
  border-radius: 100px;
  cursor: pointer;
  ${props => props.isActive && handleSelectStyle(props.name) };
  flex-grow: 1;
`

const MenuItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TestContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 70px;
  z-index: 7;
`

const MenuContainer = styled.div`
  background: #000000;
  padding: 15px;
  border-radius: 40px 40px 0 0;
  z-index: 7;
`

const MenuBackground = styled.div`
  background: ${props => props.theme.colors.buttonPushed};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
`

export default memo(MobileMenu)