import {createSelector} from "reselect";
import {RootState} from "../rootTypes";

const selectHtmlCode = (state: RootState) => state.htmlCode;


export const selectHtmlCodeLoading = createSelector(
    selectHtmlCode,
    (state) => state.loading
);

export const selectHtmlCodeData = createSelector(
    selectHtmlCode,
    (state) => state.htmlCode
);