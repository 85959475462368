import {
  put,
  takeEvery,
  race,
  take,
  select,
  throttle,
  takeLatest,
  call,
} from "redux-saga/effects";

import { get, putRequest, post, deleteRequest } from "src/utils/requestSaga";
import {
  toggleProfileLoading,
  GET_PROFILE_DATA,
  setProfileData,
  CHANGE_PASSWORD,
  CHANGE_EMAIL,
  BIND_VK,
  GET_PROFILE_PAYMENT_DATA,
  GET_PROFILE_NOTIFICATIONS_DATA,
  CHANGE_NOTIFICATION_TOPIC_STATUS,
  CHANGE_NOTIFICATIONS_EMAIL,
  ENABLE_NOTIFICATIONS_CHANNEL,
  DISABLE_NOTIFICATIONS_CHANNEL,
  setProfileNotificationsData,
  GET_PROFILE_WEBHOOK_DATA,
  setProfileWebhookData,
  ADD_WEBHOOK,
  CHANGE_WEBHOOK,
  DELETE_WEBHOOK,
  deleteWebhookSuccess,
  CHANGE_LANGUAGE,
  UNLINK_VK,
  unlinkVKSuccess,
  ADD_EMAIL,
  BIND_SOCIAL,
  UNLINK_SOCIAL,
  deleteProvider,
  DELAYED_INST_LOGIN,
  getProfileData,
  SET_PROFILE_DATA,
  bindSocial,
} from "./profileReducer";
import i18n from "src/locales/i18n";
import jwtDecode from "jwt-decode";
import { handleServerError, showNotification } from "../notification";
import { providers } from "src/utils/variables";
import {
  selectProfileAuthProviders,
  selectProfileAuthSettings,
} from "./profileSelectors";
import { selectAccessToken } from "../auth";
import {
  instLogin,
  vkLogin,
  instDelayedLogin,
  okLogin,
} from "src/utils/localStorage";
import history from "src/utils/browserHistory";

export function* getProfileDataSaga() {
  yield put(toggleProfileLoading(true));
  try {
    const response = yield get('/profile')
    const {
      data: { auth_settings, linked_projects, language, available_languages },
    } = response;
    yield put(setProfileData({
      authSettings: auth_settings,
      linkedProjects: linked_projects,
      language: language,
      availableLanguages: available_languages
    }))
    i18n.changeLanguage(language.toLowerCase());
  } catch (e) {
    handleServerError(e);
  }
  yield put(toggleProfileLoading(false));
  
}

function* changePasswordSaga({ payload }) {
  yield put(toggleProfileLoading(true));
  try {
    const { currentPassword, newPassword } = payload;
    yield putRequest("/profile/security/password", {
      old_password: currentPassword,
      new_password: newPassword,
    });
    yield put(
      showNotification({
        key: "profile.passwordChangedSuccess",
      })
    );
    if (payload.redirect) {
      payload.redirect();
    }
  } catch (e) {
    yield put(handleServerError(e));
  }
  yield put(toggleProfileLoading(false));
}

function* changeEmailSaga({ payload }) {
  yield put(toggleProfileLoading(true));
  try {
    const { email } = payload;
    const resposne = yield putRequest("/profile/security/email", {
      email,
    });
    console.log(resposne, "response");
    yield put(showNotification({ key: "profile.emailChangedSuccess" }));
    if (payload.redirect) {
      payload.redirect();
    }
  } catch (e) {
    yield put(handleServerError(e));
  }
  yield put(toggleProfileLoading(false));
}

function* changeLanguageSaga({ payload }) {
  yield put(toggleProfileLoading(true));
  try {
    yield putRequest(`/profile/language`, payload)
    const language = payload.language;
    i18n.changeLanguage(language.toLowerCase());
  } catch (e) {
    yield put(handleServerError(e));
  }
  yield put(toggleProfileLoading(false));
  
}

function* addEmailSaga({ payload }) {
  yield put(toggleProfileLoading(true));
  try {
    const { email } = payload;
    yield post("/profile/security/link/" + email);
    payload.redirect();
  } catch (e) {
    yield put(handleServerError(e));
  }
  yield put(toggleProfileLoading(false));
}

function* bindVkSaga({ payload }) {
  yield put(toggleProfileLoading(true));
  const returnUrl = `/profile`;
  const url = `${process.env.REACT_APP_API_URL}/profile/security/link/external/${providers.vk}?returnUrl=${returnUrl}`;
  const decoded = jwtDecode(payload);
  try {
    const { data } = yield get("/profile/security/link/external/token");
    window.location.replace(
      `${url}&securityToken=${data}&userId=${decoded["urn:systemidentifier"]}`
    );
  } catch (e) {
    yield put(handleServerError(e));
  }
}

export function* bindSocialSaga({ payload: { provider, returnUrl, token } }) {
  const url = `${process.env.REACT_APP_API_URL}/profile/security/link/external/${provider}?returnUrl=${returnUrl}`;
  const decoded = jwtDecode(token);
  try {
    const { data } = yield get('/profile/security/link/external/token')
    window.location.replace(`${url}&securityToken=${data}&userId=${decoded['urn:systemidentifier']}`)
  } catch (e) {
    yield put(handleServerError(e));
  }
}

export function* delayedInstLoginSaga() {
  const response = yield call(getProfileDataSaga);
  const auth = yield select(selectProfileAuthSettings);
  const token = yield select(selectAccessToken);

  const instBinded = auth.providers.filter(
    (provider) => provider.provider === providers.inst
  );

  localStorage.removeItem(vkLogin);
  localStorage.removeItem(okLogin);

  if (instBinded.length === 0) {
    yield put(
      bindSocial({
        provider: providers.inst,
        token,
        returnUrl: "/auth/inst-delayed",
      })
    );
  } else {
    localStorage.removeItem(instDelayedLogin);
    localStorage.setItem(instLogin, true);
    history.push("/project");
  }
}

function* getProfilePaymentDataSaga() {
  yield put(toggleProfileLoading(true));
  try {
    const response = yield get("/users/current/payments");
    console.log("payments request", response);
  } catch (e) {
    handleServerError(e);
  }
  yield put(toggleProfileLoading(false));
}

function* getProfileNotificationsDataSaga() {
  yield put(toggleProfileLoading(true));
  try {
    const { data } = yield get("/notifications");
    yield put(setProfileNotificationsData(data));
  } catch (e) {
    handleServerError(e);
  }
  yield put(toggleProfileLoading(false));
}

function* changeNotificationTopicStatusSaga({ payload }) {
  yield put(toggleProfileLoading(true));
  const { channel, action, topicId } = payload;
  const url = `/notifications/${channel}/${action}/${topicId}`;
  try {
    const res = yield post(url);
    if (res) {
      yield getProfileNotificationsDataSaga();
    }
  } catch (e) {
    handleServerError(e);
  }
  yield put(toggleProfileLoading(false));
}

function* changeNotificationsEmailSaga({ payload }) {
  yield put(toggleProfileLoading(true));
  console.log(payload, "payload");
  try {
    const { email } = payload;
    const resposne = yield putRequest(
      "/notifications/email/change",
      JSON.stringify(email)
    );
    console.log(resposne, "response");
    yield put(showNotification({ key: "profile.emailChangedSuccess" }));
    if (payload.redirect) {
      payload.redirect();
    }
  } catch (e) {
    yield put(handleServerError(e));
  }
  yield put(toggleProfileLoading(false));
}

function* unlinkVkSaga() {
  yield put(toggleProfileLoading(true));
  try {
    yield deleteRequest(`/profile/security/unlink/${providers.vk}`);
    yield put(unlinkVKSuccess());
    yield put(showNotification({ key: "profile.unlinkVKSuccess" }));
  } catch (e) {
    handleServerError(e);
  }
  yield put(toggleProfileLoading(false));
}

function* unlinkSocialSaga({ payload }) {
  yield put(toggleProfileLoading(true));
  try {
    yield deleteRequest(`/profile/security/unlink/${payload}`);
    yield put(deleteProvider(payload));
  } catch (e) {
    yield put(handleServerError(e));
  }
  yield put(toggleProfileLoading(false));
}

function* enableNotificationChannel({ payload }) {
  yield put(toggleProfileLoading(true));
  const { channel, token } = payload;
  const url = `/notifications/${channel}/enable`;
  try {
    const res = yield post(url, JSON.stringify(token));
    if (payload.redirect) {
      payload.redirect();
    }
    if (res) {
      yield getProfileNotificationsDataSaga();
    }
  } catch (e) {
    handleServerError(e);
  }
  yield put(toggleProfileLoading(false));
}

function* disableNotificationChannel({ payload }) {
  yield put(toggleProfileLoading(true));
  const { channel } = payload;
  const url = `/notifications/${channel}/disable`;
  try {
    const res = yield post(url);
    if (res) {
      yield getProfileNotificationsDataSaga();
    }
  } catch (e) {
    handleServerError(e);
  }
  yield put(toggleProfileLoading(false));
}

function* getProfileWebhookDataSaga() {
  yield put(toggleProfileLoading(true));
  try {
    const { data } = yield get("/webhook/get_subscriptions?includeActiveSupscriptions=true");
    yield put(setProfileWebhookData(data));
  } catch (e) {
    handleServerError(e);
  }
  yield put(toggleProfileLoading(false));
}

function* addWebhookSaga({ payload }) {
  yield put(toggleProfileLoading(true));
  const { url } = payload;
  try {
    const response = yield post(
      `/webhook/add`,
      JSON.stringify({ webhook_url: url })
    );
    if (response) {
      yield getProfileWebhookDataSaga();
    }
    if (payload.redirect) {
      payload.redirect();
    }
  } catch (e) {
    handleServerError(e);
  }
  yield put(toggleProfileLoading(false));
}

function* changeWebhookSaga({ payload }) {
  yield put(toggleProfileLoading(true));
  try {
    const response = yield putRequest("/webhook/update", JSON.stringify(payload));
    if (response) {
      yield getProfileWebhookDataSaga();
    }
    if (payload.redirect) {
      payload.redirect();
    }
  } catch (e) {
    yield put(handleServerError(e));
  }
  yield put(toggleProfileLoading(false));
}

function* deleteWebhookSaga({ payload }) {
  yield put(toggleProfileLoading(true));
  try {
    const { id } = payload;
    yield get(`/webhook/delete?subscriptionId=${id}`);
    yield put(deleteWebhookSuccess(payload));
  } catch (e) {
    handleServerError(e);
  }
  yield put(toggleProfileLoading(false));
}

export default function* () {
  yield takeEvery(GET_PROFILE_DATA, getProfileDataSaga);
  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga);
  yield takeEvery(CHANGE_EMAIL, changeEmailSaga);
  yield takeEvery(CHANGE_LANGUAGE, changeLanguageSaga)
  yield takeEvery(BIND_VK, bindVkSaga);
  yield takeEvery(GET_PROFILE_PAYMENT_DATA, getProfilePaymentDataSaga);
  yield takeEvery(
    GET_PROFILE_NOTIFICATIONS_DATA,
    getProfileNotificationsDataSaga
  );
  yield takeEvery(
    CHANGE_NOTIFICATION_TOPIC_STATUS,
    changeNotificationTopicStatusSaga
  );
  yield takeEvery(CHANGE_NOTIFICATIONS_EMAIL, changeNotificationsEmailSaga);
  yield takeEvery(ENABLE_NOTIFICATIONS_CHANNEL, enableNotificationChannel);
  yield takeEvery(DISABLE_NOTIFICATIONS_CHANNEL, disableNotificationChannel);

  yield takeEvery(GET_PROFILE_WEBHOOK_DATA, getProfileWebhookDataSaga);
  yield takeEvery(ADD_WEBHOOK, addWebhookSaga);
  yield takeEvery(CHANGE_WEBHOOK, changeWebhookSaga);
  yield takeEvery(DELETE_WEBHOOK, deleteWebhookSaga);

  yield takeEvery(UNLINK_VK, unlinkVkSaga);
  yield takeEvery(ADD_EMAIL, addEmailSaga);
  yield throttle(1000, BIND_SOCIAL, bindSocialSaga);
  yield throttle(20000, DELAYED_INST_LOGIN, delayedInstLoginSaga);
  yield takeEvery(UNLINK_SOCIAL, unlinkSocialSaga);
}
