import styled from "styled-components";
import { Header, size } from "hopshopui";

export const DesignHeader = styled(Header)`
  padding: 0 15px;
  @media screen and (max-width: ${size.mobile}) {
    top: 0;
    z-index: 6;
    position: fixed;
  }
`;
