import { connect } from 'react-redux'

import { justTest } from 'src/store/auth'
import SecuredTest from './SecuredTest'

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  justTest
}

export default connect(mapStateToProps, mapDispatchToProps)(SecuredTest)
