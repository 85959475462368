import React, {useCallback, useMemo} from 'react'
import { useT } from 'src/hooks/useT';
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import copyToClipboard from 'src/utils/copyToClipboard'
import { showNotification } from 'src/store/notification'
import { H3, PreviewIcon, CopyIcon, textParagraph, size } from 'hopshopui'

type Props = {
  pageLink: string;
}

const ProjectContentPageLink = ({ pageLink }: Props) => {
  const { t } = useT()
  const dispatch = useDispatch()

  const aliasId = "pageAlias";
  const copyDomain = useCallback(() => {
    copyToClipboard(aliasId)
    dispatch(showNotification({
      key: 'notifications.pageAliasCopied'
    }))
  }, [])
  const httpsProtocol = useMemo(() => window.location.protocol, [])
  return (
    <Container>
      <PageLinkContainer>
        <StyledDomain onClick={copyDomain} id="header_domain">
          <span>
            {t("project.pages.EmptyPageLink")}
          </span>
        </StyledDomain>
        <StyledCopyIcon onClick={copyDomain} />
        <IconsDivider />
        <a
          href={'https://' + pageLink}
          target="_blank">
          <StyledPreviewIcon />
        </a>
        <InvisibleAlias id={aliasId}>{`${httpsProtocol}//${pageLink}`}</InvisibleAlias>
      </PageLinkContainer>
    </Container>
  )
}
const InvisibleAlias = styled.div`
  height: 0px;
  width: 0px;
  font-size: 0px;
`

const StyledDomain = styled(H3)`
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
  @media screen and (max-width: ${size.mobile}) {
    ${textParagraph}
    align-items: center;
  }
`

const StyledCopyIcon = styled(CopyIcon)`
  margin-left: 10px;
  cursor: pointer;
  path {
    fill: ${props => props.theme.colors.dark};
  }
`
const StyledPreviewIcon = styled(PreviewIcon)`
  margin-left: 10px;
  path {
    fill: ${props => props.theme.colors.dark};
  }
`

const IconsDivider = styled.div`
  opacity: 0.15;
  width: 1px;
  height: 20px;
  margin-left: 10px;
  background-color: ${props => props.theme.colors.dark};
`

const Container = styled.div`
  width: 100%; 
  display: flex;
  justify-content: center;
  margin: 45px 0 90px 0;
  a {
    display: flex;
  }

  @media screen and (max-width: ${size.mobile}) {
    padding-bottom: 70px;
  }
`
const PageLinkContainer = styled.div`
  background: ${props => props.theme.colors.button};
  border: 1px solid ${props => props.theme.colors.buttonBorder};
  border-radius: 50px;
  width: fit-content;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  max-height: 40px;
  max-width: 100%;
  color: ${props => props.theme.colors.dark};
`

export default ProjectContentPageLink