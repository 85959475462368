import React, { useEffect } from 'react'
import { func } from 'prop-types'

const SecuredTest = ({ justTest }) => {
  useEffect(() => {
    justTest()
  })
  return <div>Secured route</div>
}

SecuredTest.propTypes = {
  justTest: func
}

export default SecuredTest