import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getProjectDetails, getProjectGoodsByIds, selectProjectsLoading } from 'src/store/projects'
import ProjectFavorites from './ProjectFavoritesPage'

const mapStateToProps = (state) => ({
  loading: selectProjectsLoading(state),
})

const mapDispatchToProps = {
  getProjectDetails,
  getProjectGoodsByIds
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(ProjectFavorites)
)