import React from 'react'
import styled from 'styled-components'
import { newLoading, ProfileCard, size } from 'hopshopui'

const FeedbacksMainShimer = () => {
  return (
    <Container>
      <FeedbacksGrades>
        <InputShimmer />
        <TextShimmer />
        <TextShimmer />
        <TextShimmer />
        <TextShimmer />
        <TextShimmer />
      </FeedbacksGrades>
      <FeedbacksContainer>
        <GoodCardShimmer />
        <TextShimmer />
        <FeedbackTextShimmer />
      </FeedbacksContainer>
    </Container>
  )
}

const GoodCardShimmer = styled.div`
  width: 560px;
  height: 72px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
  ${newLoading};
  @media screen and (max-width: ${size.mobile}){
    width: 90%;
    margin-bottom: 0;
  }
`;

const FeedbackTextShimmer = styled(GoodCardShimmer)`
  height: 180px;
  margin-bottom: unset;
  @media screen and (max-width: ${size.mobile}){
    width: 90%;
  }
`;

const FeedbacksGrades = styled.div`
  width: 280px;
  height: 280px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 30px 0 0;
  @media screen and (max-width: ${size.mobile}){
    display: none;
  }
`

const Container = styled.div`
  max-width: 960px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding-left: 30px;
  @media screen and (max-width: ${size.mobile}){
    padding: 0;
  }
`
const TextShimmer = styled.div`
  margin: 20px 0;
  height: 20px;
  border-radius: 10px;
  width: 80%;
  ${newLoading}
  @media screen and (max-width: ${size.mobile}){
    width: 90%;
  }
`

const InputShimmer = styled.div`
  height: 40px;
  border-radius: 10px;
  display: block;
  ${newLoading};
`

const FeedbacksContainer = styled(ProfileCard)`
  width: 600px;
  padding: 20px;
  margin-top: 20px;
  @media screen and (max-width: ${size.mobile}){
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export default FeedbacksMainShimer