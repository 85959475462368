import { takeEvery, put, call } from "redux-saga/effects";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import {
  getCheckoutForm,
  triggerGetCheckoutForm,
  CheckoutFormResponseType,
  triggerChangeCheckoutForm,
  changeCheckoutForm,
} from "./checkoutTypes";
import { get, post } from "src/utils/requestSaga";
import { SagaIterator } from "redux-saga";
import { AxiosResponse } from "axios";
import { handleServerError, showNotification } from "../notification";
import { camelize, snakeize } from "casing";

const getCheckoutFormSaga = bindAsyncAction(getCheckoutForm)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<CheckoutFormResponseType> = yield call(
      get,
      `projects/${payload.id}/orderFormSettings${
        payload.showPromocode ? "?queriedFields=show_promocode" : ""
      }`
    );
    const fields = camelize(data.fields).map((field: any) => {
      if (field.type === "Options") {
        let optionsString = "";
        field.payload.options?.forEach((option: any) => {
          optionsString = optionsString + option.name + "\n";
        });

        const defaultOptions = field.payload.defaultOptions?.join(", ");
        return {
          ...field,
          payload: { ...field.payload, options: optionsString, defaultOptions },
        };
      }
      return field;
    });
    return { fields, showPromocode: data.show_promocode };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const changeCheckoutFormSaga = bindAsyncAction(changeCheckoutForm)(function* ({
  payload,
}): SagaIterator {
  try {
    const fields = payload.data.map((field, index) => {
      if (field.type === "Options") {
        const options = field.payload.options?.split(/\r\n|\r|\n/);
        const cleanOptions = options?.filter((option) => option);
        const mappedOptions = cleanOptions?.map((option, index) => {
          return { name: option, position: index };
        });
        const defaultOptions = field.payload.defaultOptions?.split(",");
        return {
          ...field,
          position: index + 1,
          payload: { ...field.payload, options: mappedOptions, defaultOptions },
        };
      }
      return { ...field, position: index + 1 };
    });
    const res = yield call(post, `projects/${payload.id}/orderFormSettings`, {
      fields: snakeize(fields),
    });
    if (res) {
      yield put(
        showNotification({
          key: "notifications.changesSaved",
        })
      );
    }
    yield put(triggerGetCheckoutForm({ id: payload.id }));
  } catch (e) {
    yield put(handleServerError(e));
  }
});

export default function* () {
  yield takeEvery(triggerGetCheckoutForm.type, getCheckoutFormSaga);
  yield takeEvery(triggerChangeCheckoutForm.type, changeCheckoutFormSaga);
}

// Error mapping types.
// Mapping types:
// FormDto -> FormModel↵HopShop.DTO.Common.FormDto -> HopShop.Forms.Models.FormModel
// Type Map configuration:↵FormDto -> FormModel
// HopShop.DTO.Common.FormDto -> HopShop.Forms.Models.FormModel
// Destination Member:
// Fields
