import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import LogRocket from "logrocket";

import rootSaga from "./saga";

import auth from './auth'
import notification from './notification'
import projects from './projects'
import profile from './profile'
import goods from './goods'
import orders from './orders'
import delivery from './delivery'
import payment from './payment'
import subscriptions from './subscriptions'
import paymentSystems from './paymentSystems'
import { getEnvProduction } from 'src/utils/env'
import sync from './sync'
import analytics from './analytics'
import customCheckout from './customCheckout'
import pages from './pages'
import images from './images'
import projectSettings from './projectSettings'
import goodsWidget from './goodsWidget'
import promocodes from './promocodes'
import scenarios from './scenarios'
import digitalGoods from "./digitalGoods";
import marketplaces from "./marketplaces";
import booking from "./bookingLog";
import feedbacks from './feedbacks';
import htmlCode from './htmlCode';

// ---
// REDUCERS
// ---

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware, LogRocket.reduxMiddleware()];

  // TODO: check to disable by default on prods
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
      })
    : compose;
  const enhancer = applyMiddleware(...middlewares);

  const rootReducer = combineReducers({
    auth,
    notification,
    projects,
    profile,
    goods,
    orders,
    delivery,
    payment,
    subscriptions,
    paymentSystems,
    sync,
    analytics,
    customCheckout,
    pages,
    images,
    projectSettings,
    goodsWidget,
    promocodes,
    scenarios,
    digitalGoods,
    marketplaces,
    booking,
    feedbacks,
    htmlCode
  })

  const store = createStore(rootReducer, composeEnhancers(enhancer));

  // to debug without proper extentions
  if (!getEnvProduction()) {
    window.store = store;
  }

  sagaMiddleware.run(rootSaga);

  return store;
}
