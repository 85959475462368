import { GoodsWidgetStateType, loadMoreGoodsWidget, resetLoadMore } from "./goodsWidgetType";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";
import { resetGoodsCount } from "../projects";

export const GoodsOffsetStep = 12

const INITIAL_STATE: GoodsWidgetStateType = {
  loading: false,
  goods: [],
  // overall goods count in project
  allGoodsCount: 0,
  goodsTotalCount: 0,
  offset: 0
}

export default reducerWithInitialState(INITIAL_STATE).cases(
  [loadMoreGoodsWidget.started], (state) => {
    return { ...state, loading: true }
  }).cases([loadMoreGoodsWidget.done], (state, { result }) => {
    if (result !== null) {
      return produce(state, (draft) => {
        draft.goods = [...draft.goods, ...result.goods]
        draft.offset = result.offset
        draft.goodsTotalCount = result.totalCount
        draft.loading = false
      })
    }
    return { ...state, loading: false }
  }).case(resetLoadMore, (state) => {
    return produce(state,  (draft) => {
      draft.allGoodsCount = 0
      draft.goodsTotalCount  = 0
      draft.offset = 0
      draft.goods = []
      draft.loading = true
    })
  })