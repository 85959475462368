import React, { useEffect } from 'react'
import { func } from 'prop-types'
import { history } from 'src/utils/propTypes'

const AuthOkPageStart = ({ authOk, history }) => {
  useEffect(() => {
    authOk(history)
  }, [authOk, history])
  return (
    <div></div>
  )
}

AuthOkPageStart.propTypes = {
  authOk: func,
  history: history
}

export default AuthOkPageStart