import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { loginSuccess } from 'src/store/auth'

import Authorize from './Authorize'


const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  loginSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(Authorize)
)
