import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AuthPage from './AuthPage'


const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(AuthPage)
)
