import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { selectProjectsSelector, getProjectDetails } from 'src/store/projects'
import CheckoutSuccessPage from './CheckoutSuccessPage'

const mapStateToProps = (state) => ({
  selectProjectsSelector,
})

const mapDispatchToProps = {
  getProjectDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(CheckoutSuccessPage)
)