import React from 'react'
import styled from 'styled-components'
import { ProfileCard, shimmerStyles, size } from 'hopshopui'

const ProjectGoodShimmer = () => {
  return (
    <Container>
      <ImagesContainer>
        <Image></Image>
      </ImagesContainer>
      <FormContainer>
        <InputShimmer />
        <InputShimmer />
        <InputShimmer />
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  max-width: 500px;
  margin: 60px auto 0 auto;
  @media screen and (max-width: ${size.mobile}){
    padding: 0 20px;
  }
`

const ImagesContainer = styled.div`
  /* width: 200px;
  height: 220px; */
`

const Image = styled.div`
  width: 220px;
  height: 220px;
  border-radius: 20px;
  ${shimmerStyles};
`

const InputShimmer = styled.div`
  height: 40px;
  border-radius: 10px;
  display: block;
  ${shimmerStyles};
  margin: 20px 0;
`

const FormContainer = styled(ProfileCard)`
  padding: 20px;
  margin-top: 20px;
`

export default ProjectGoodShimmer