import { camelize, snakeize } from "casing";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  CardViewFormTypes,
  ProjectSettingsCardView,
} from "src/templates/utils/types/projectSettings";
import { get, post, putRequest } from "src/utils/requestSaga";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import {
  handleServerError,
  showNotification,
} from "../notification";
import {
  updateCardView,
  getCardViewSettings,
  getProjectDesignSettings,
  setProjectDesignSettings,
  updateBlockDesignSettings,
  ProjectDesignResponseType,
  triggerGetCardViewSettings,
  updateButtonkDesignSettings,
  triggerUpdateCardViewSettings,
  getCoverCustomizationSettings,
  triggerPushBlockDesignSettings,
  triggerPushButtonDesignSettings,
  triggerGetProjectDesignSettings,
  triggerSetProjectDesignSettings,
  updateCoverCustomizationSettings,
  triggerGetCoverCustomizationSettings,
  triggerUpdateCoverCustomizationSettings,
} from "./projectSettingsTypes";
import history from "src/utils/browserHistory";
import { selectProjectLayoutSettings } from "./projectSettingsSelectors";
import { toggleProjectLoading } from "../projects";
import { triggerGetGoodDetail } from "src/store/goods";
import { getAliasFromPath } from "src/templates/utils/alias";
import { getSeparatedAliases } from "src/templates/utils/goodAlias";
import { triggerGetPageContent } from "src/store/pages";

const getCardViewSettingsSaga = bindAsyncAction(getCardViewSettings)(
  function* ({ payload }): SagaIterator {
    try {
      const response = yield call(get, `/projects/${payload}/cardViewSettings`);
      const { data } = response;
      const camelizeData: {
        cardView: ProjectSettingsCardView;
      } = camelize(data);
      return {
        ...camelizeData.cardView,
        projectId: payload,
      };
    } catch (e) {
      yield put(handleServerError(e));
    }
  }
);

const updateCardViewSettingsSaga = bindAsyncAction(updateCardView)(function* ({
  payload,
}): SagaIterator {
  try {
    const { rectangular, projectId, ...rest } = payload;
    const form: CardViewFormTypes = rectangular ? "Rectangular" : "Square";
    const data: ProjectSettingsCardView = { ...rest, form };
    const requestBody = snakeize(data);
    yield call(post, `/projects/${projectId}/cardViewSettings`, requestBody);
    yield put(
      showNotification({
        key: "notifications.cardViewSettingsSaved",
      })
    );
    history.push(`/project/${projectId}/goods`);
    return { ...data, projectId };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getProjectDesignSettingsSaga = bindAsyncAction(getProjectDesignSettings)(
  function* ({ payload }): SagaIterator {
    try {
      yield put(toggleProjectLoading());
      const response = yield call(
        get,
        `/projects/${payload}/layoutViewSettings`
      );
      const { data } = response;
      const camelizedData: ProjectDesignResponseType = camelize(data);
      return camelizedData;
    } catch (e) {
      yield put(handleServerError(e));
    }
  }
);

const setProjectDesignSettingsSaga = bindAsyncAction(setProjectDesignSettings)(
  function* ({ payload }): SagaIterator {
    try {
      yield put(toggleProjectLoading());
      const layoutRequest: ProjectDesignResponseType = yield select(
        selectProjectLayoutSettings
      );
      const request = snakeize(layoutRequest.layoutView);
      const response = yield call(
        post,
        `/projects/${payload}/layoutViewSettings`,
        request
      );
      yield put(
        showNotification({
          key: "design.settingsUpdatedNotification",
        })
      );
      const { data } = response;
      return camelize(data);
    } catch (e) {
      yield put(handleServerError(e));
    }
  }
);

const updateBlockDesignSettingsSaga = bindAsyncAction(
  updateBlockDesignSettings
)(function* ({ payload }): SagaIterator {
  try {
    const data = {
      ...payload,
      identifier:
        typeof payload.identifier === "string"
          ? payload.identifier
          : payload.identifier.toString(),
      moduleIdentifier:
        typeof payload.moduleIdentifier === "string"
          ? payload.moduleIdentifier
          : payload.moduleIdentifier.toString(),
    };
    const requestBody = snakeize(data);
    yield call(putRequest, `/modules/setCustomization`, requestBody);
    // yield put(
    //   triggerGetGoodsSetByIds({
    //     projectId: payload.projectId,
    //     identifier:
    //       typeof payload.identifier === "string"
    //         ? payload.identifier
    //         : payload.identifier.toString(),
    //     aliasType: payload.identifierType,
    //   })
    // );

    const alias = getSeparatedAliases(
      getAliasFromPath(history.location.pathname)
    )[0];
    if (payload.identifierType === "Goods") {
      yield put(
        triggerGetGoodDetail({ projectId: payload.projectId.toString(), alias })
      );
    }

    if (payload.identifierType === "Page") {
      yield put(
        triggerGetPageContent({
          projectId: payload.projectId.toString(),
          alias,
        })
      );
    }
    
    yield put(
      showNotification({
        key: "design.settingsUpdatedNotification",
      })
    );
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const updateButtonDesignSettingsSaga = bindAsyncAction(
  updateButtonkDesignSettings
)(function* ({ payload }): SagaIterator {
  try {
    const data = {
      ...payload,
      identifier:
        typeof payload.identifier === "string"
          ? payload.identifier
          : payload.identifier.toString(),
      moduleIdentifier:
        typeof payload.moduleIdentifier === "string"
          ? payload.moduleIdentifier
          : payload.moduleIdentifier.toString(),
    };
    const requestBody = snakeize(data);
    yield call(putRequest, `/modules/setCustomization`, requestBody);
   
    const alias = getSeparatedAliases(
      getAliasFromPath(history.location.pathname)
    )[0];
    if (payload.identifierType === "Goods") {
      yield put(
        triggerGetGoodDetail({ projectId: payload.projectId.toString(), alias })
      );
    }

    if (payload.identifierType === "Page") {
      yield put(
        triggerGetPageContent({
          projectId: payload.projectId.toString(),
          alias,
        })
      );
    }
    
    yield put(
      showNotification({
        key: "design.settingsUpdatedNotification",
      })
    );
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getCoverDesignSettingsSaga = bindAsyncAction(
  getCoverCustomizationSettings
)(function* ({ payload }): SagaIterator {
  try {
    
   const res =  yield call(get, `/projects/${payload.projectId}/cover`);

    return camelize(res.data)
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const updateCoverDesignSettingsSaga = bindAsyncAction(
  updateCoverCustomizationSettings
)(function* ({ payload }): SagaIterator {
  try {
    
    yield call(post, `/projects/${payload.projectId}/cover`, snakeize(payload.coverSettingsDesign));

    yield put(
      showNotification({
        key: "design.settingsUpdatedNotification",
      })
    );
  } catch (e) {
    yield put(handleServerError(e));
  }
});



export default function* () {
  yield takeLatest(triggerGetCardViewSettings.type, getCardViewSettingsSaga);
  yield takeLatest(
    triggerUpdateCardViewSettings.type,
    updateCardViewSettingsSaga
  );
  yield takeLatest(
    triggerGetProjectDesignSettings.type,
    getProjectDesignSettingsSaga
  );
  yield takeLatest(
    triggerSetProjectDesignSettings.type,
    setProjectDesignSettingsSaga
  );
  yield takeLatest(
    triggerPushBlockDesignSettings.type,
    updateBlockDesignSettingsSaga
  );
  yield takeLatest(
    triggerPushButtonDesignSettings.type,
    updateButtonDesignSettingsSaga
  );
  yield takeLatest(
    triggerGetCoverCustomizationSettings.type,
    getCoverDesignSettingsSaga
  );
  yield takeLatest(
    triggerUpdateCoverCustomizationSettings.type,
    updateCoverDesignSettingsSaga
  );
}
