import React, { useEffect } from 'react'
import { object, func } from 'prop-types'


const Authorize = ({ match, loginSuccess }) => {
  useEffect(() => {
    loginSuccess({ accessToken: match.params.token, refreshToken: match.params.refreshToken })
  }, [loginSuccess, match])
  return (
    <div></div>
  )
}

Authorize.propTypes = {
  match: object,
  loginSuccess: func.isRequired
}

export default Authorize
