import { call, put, take, race } from 'redux-saga/effects'

import { signOutSaga } from 'src/store/auth/authSaga'
import { exchangeTokens, EXCHANGE_TOKENS_SUCCESS, EXCHANGE_TOKENS_FAILURE } from 'src/store/auth'

import { request } from './request'
import history from './browserHistory'

export function* proceedRequest(func) {
  try {
    return yield func()
  } catch (e) {
    const status = e && e.request && e.request.status
    if (status === 401) {
      yield put(exchangeTokens())
      const { success  } = yield race({
        success: take(EXCHANGE_TOKENS_SUCCESS),
        reset: take(EXCHANGE_TOKENS_FAILURE)
      })
      if (success) {
        return yield func()
      } else {
        throw e
      }
    } else if (status === 422) {
      yield call(signOutSaga)
      history.push('/auth/signin-vkontakte/forbidden')
    } else {
      throw e
    }
  }
}

export function* get(url, data, config = {}) {
  const func = () => request.get(url, data, config)
  return yield proceedRequest(func)
}

export function* post(url, data, config = {}) {
  const func = () => request.post(url, data, config)
  return yield proceedRequest(func)
}

export function* putRequest(url, data, config = {}) {
  const func = () => request.put(url, data, config)
  return yield proceedRequest(func)
}

export function* deleteRequest(url, data, config = {}) {
  const func = () => request.delete(url, data, config)
  return yield proceedRequest(func)
}

export function* patch(url, data, config = {}) {
  const func = () => request.patch(url, data, config)
  return yield proceedRequest(func)
}