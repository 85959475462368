import React, { Suspense, useMemo } from 'react'
import styled from 'styled-components'
import { object } from 'prop-types'
import { Grid } from 'react-flexbox-grid'

import { P, fullHeight, CardComponent } from 'hopshopui'

import { size } from 'src/utils/styles'
import { useT } from 'src/hooks/useT'

import AuthShimmer from 'src/modules/auth/components/AuthShimmer'
import AuthGlobalStyles from 'src/modules/auth/components/AuthGlobalStyles'

const AuthButtons = React.lazy(() => import('src/modules/auth/containers/AuthButtons'))
const AuthEmail = React.lazy(() => import('src/modules/auth/containers/AuthEmail'))
const AuthPassword = React.lazy(() => import('src/modules/auth/containers/AuthPassword'))
const AuthReset = React.lazy(() => import('src/modules/auth/containers/AuthReset'))
const Phone = React.lazy(() => import('src/assets/phone'))

const getComponent = (params) => {
  if (Object.keys(params).length === 0) {
    return (
      <Suspense fallback={<AuthShimmer />}>
        <AuthButtons />
      </Suspense>
    )
  }
  if (params.type) {
    return (
      <Suspense fallback={<AuthShimmer />}>
        <AuthEmail />
      </Suspense>
    )
  }
  if (params.email) {
    return (
      <Suspense fallback={<AuthShimmer />}>
        <AuthPassword />
      </Suspense>
    )
  }
  if (params.reset) {
    return (
      <Suspense fallback={<AuthShimmer />}>
        <AuthReset />
      </Suspense>
    )
  }
}

const AuthPage = ({ match, history }) => {
  const displayText = useMemo(() => match.url === '/auth', [match])
  const { t } = useT()
  return (
    <Page>
      <AuthGlobalStyles />
      <Container>
      <CardComponentStyled>
        <Suspense fallback={<div></div>}>
          {getComponent(match.params)}
        </Suspense>
      </CardComponentStyled>
      <Disclaimer shouldDisplay={displayText}>{t('auth.disclaimer')}</Disclaimer>
      </Container>
    </Page>
  )
}

AuthPage.propTypes = {
  match: object
}

const Page = styled.div`
  background-color: ${props => props.theme.colors.black};
  overflow: hidden;
  && {
    ${fullHeight}
  }
`
export const CardComponentStyled = styled(CardComponent)`
  width: 360px;
  background-color: white;
  padding: 0;
  margin: 0 auto;
  align-items: unset;
  @media screen and (max-width: ${size.mobile}) {
    margin-top: 10px;
    max-width: 90%;
  }
`

export const Container = styled(Grid)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${size.mobile}) {
    && {
      margin: 0;
    }
  }
`

export const Disclaimer = styled(P)`
  width: 360px;
  font-size: 14px;
  opacity: 0.5;
  margin-top: 30px;
  color: ${props => props.theme.typo.textColor};
  @media screen and (max-width: ${size.mobile}) {
    margin: 20px 0;
    max-width: 90%;
  }
`

export default AuthPage