import React, {FC} from 'react';
import {useMobileMenu} from "../hooks/useMobileMenu";

export const MenuContext = React.createContext<Partial<ReturnType<typeof useMobileMenu>>>({
    isMenuVisibleWithScroll: true,
    isMobileMenu: true,
    isMenu: false,
    changeIsMenu: () => {},
    changeIsMenuVisible: () => {}
})

export const MenuProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const props = useMobileMenu()
    return (
        <MenuContext.Provider value={props}>
            {children}
        </MenuContext.Provider>
    )
}

export default MenuContext