import React, { useCallback, useMemo } from 'react'
import { Popup } from 'hopshopui'

import {useT} from 'src/hooks/useT'
import { func } from 'prop-types'

const SettingsMainPaymentsPopupEmail = ({ onClose, onSubmit }) => {
  const handleButtons = useCallback((index) => {
    if (index > 0) {
      onClose()
    } else {
      onSubmit()
    }
  }, [onSubmit, onClose])
  const { t } = useT()
  const buttons = useMemo(() => [t('popup.set'), t('popup.cancel')], [t])
  return (
    <Popup
      title={t('popup.make')}
      text={t('project.payments.emailDescription')}
      buttons={buttons}
      handleButtons={handleButtons}
    />
  )
}




SettingsMainPaymentsPopupEmail.propTypes = {
  onSubmit: func,
  onClose: func
}

export default SettingsMainPaymentsPopupEmail