import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import SettingsMainPayments from './SettingsMainPayments'

const mapStateToProps = (state, props) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(
    SettingsMainPayments
  )
)
