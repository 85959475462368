import React from 'react'
import styled from 'styled-components'

import { equalGrid, shimmerStyles } from 'hopshopui'

const VkGoodsShimmer = () => {
  return (
    <Container>
      <ShimmerItem></ShimmerItem>
      <ShimmerItem />
      <ShimmerItem />
    </Container>
  )
}

const Container = styled.div`
  ${equalGrid};
  max-width: 460px;
  height: auto;
  margin: 80px auto 0 auto;
`

const ShimmerItem = styled.div`
  ${shimmerStyles};
  display: flex; 
  flex-wrap: wrap;
  border-radius: 5px;
  width: calc(33.333333333333333% - 13.33px);
  &:before {
    content: "";
    float: left;
    padding-top: 100%;
  }
  &:nth-child(3n - 1) {
    margin-right: 20px;
    margin-left: 20px;
  }
`

export default VkGoodsShimmer