export const GoodSetDefaultCustomization = {
  titleColor: "#000000",
  descriptionColor: "#000000",
  hasBackground: true,
  backgroundType: "Monotone",
  backgroundPayload: {
    color: "#ffffff",
  },
  isUnfold: false,
};


export const ButtonSetDefaultCustomization = {
  useGradient: false,
  startColor:"#ffffff",
  endColor: "#ffffff",
  bgColor: "#ffffff",
  angle: 1,
  margin: 20,

  firstButtonCustomization: {
    color: "#000000",
    onHoverColor: "#2B2747",
    borderRadius: 10,
    textColor: "#ffffff",
    width: 960,
    padding: "12",
  },
  secondButtonCustomization:{
    color: "#000000",
    onHoverColor: "#2B2747",
    borderRadius: 10,
    textColor: "#ffffff",
    width: 960,
    padding: "12",
  },
}

export const CoverDefaultCustomization ={ 
  coverSettings: {
  image: null,
  extendToFullScreen: true,
  heightPx: 240,
  showText: false,
  text: null,
  textAlignment: "#000000",
  textSizePx: 36,
  textSize: "#000000",
  moveOnClick: false,
  link: null,
  },
  availableTextAlignments: null,
}