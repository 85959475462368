import { createSelector } from 'reselect'

export const selectAuth = state => state.auth

export const selectAuthLoading = createSelector(
  selectAuth,
  (auth) => auth.loading
)

export const selectPasswordError = createSelector(
  selectAuth,
  (auth) => auth.passwordError
)

export const selectAccessToken = createSelector(
  selectAuth,
  (auth) => auth.accessToken
)

export const selectAppLoading = createSelector(
  selectAuth,
  (auth) => auth.appInit
)

export const selectAuthrozied = createSelector(
  selectAuth,
  (auth) => auth && auth.authorized
)