import { takeEvery, put, call } from "redux-saga/effects";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import {
  getAnalytics,
  triggerGetAnalytics,
  AnalyticsResponseType,
  triggerChangeAnalytics,
  changeAnalytics,
} from "./analyticsTypes";
import { get, post } from "src/utils/requestSaga";
import { SagaIterator } from "redux-saga";
import { AxiosResponse } from "axios";
import { handleServerError, showNotification } from "../notification";
import { camelize } from "casing";

const getAnalyticsSaga = bindAsyncAction(getAnalytics)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<AnalyticsResponseType> = yield call(
      get,
      `projects/${payload}/analytics`
    );
    return camelize(data.items);
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const changeAnalyticsSaga = bindAsyncAction(changeAnalytics)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(post, `projects/${payload.id}/analytics`, {
      items: payload.data,
    });
    yield put(triggerGetAnalytics(payload.id));
    yield put(showNotification({
      key: 'notifications.analyticsSaved'
    }))
  } catch (e) {
    yield put(handleServerError(e));
  }
});

export default function* () {
  yield takeEvery(triggerGetAnalytics.type, getAnalyticsSaga);
  yield takeEvery(triggerChangeAnalytics.type, changeAnalyticsSaga);
}
