import { camelize, snakeize } from "casing";
import { SagaIterator } from "redux-saga";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  GoodType,
  ImageType,
  GoodCardType,
  GoodVariantType,
  PropertySetType,
  SeoParametersType,
  ContentPageBlocks,
  GoodImageEditType,
  ServiceGoodCalendar,

  PropertySetTypeNested,
} from "src/templates/utils/types/goodType";
import { filterByOld } from "src/utils/filterOldIds";
import { deleteRequest, get, post, putRequest } from "src/utils/requestSaga";

import { bindAsyncAction } from "typescript-fsa-redux-saga";
import { uploadAndFilterBlockTypeImages } from "../images/imagesSaga";
import { handleServerError } from "../notification";
import {
  selectActiveGood,
  selectGoodProperties,
  selectGoodVariantsMapped,
  selectEditGoodInitialValue,
} from "./goodsSelectors";
import {
  addGood,
  editRange,
  deleteGood,
  archiveGood,
  getGoodsSet,
  getGoodsByIds,
  getGoodDetail,
  deleteProperty,
  triggerAddGood,
  savePropertySet,
  triggerEditRange,
  getGoodsSetByIds,
  updateGoodDetail,
  generateVariants,
  triggerDeleteGood,
  triggerArchiveGood,
  triggerGetGoodsSet,
  GoodFormVariantType,
  triggerGetGoodsByIds,
  triggerGetGoodDetail,
  PropertySetTypeMapped,
  GoodVariantMappedType,
  GoodFormInitialValues,
  getServiceGoodCalendar,
  triggerDeleteAddedGood,
  triggerGetGoodsSetByIds,
  triggerGenerateVariants,
  triggerUpdateGoodDetail,
  deletePropertyAndVariants,
  triggerGetServiceGoodCalendar,
  getServiceGoodCalendarEndDate,
  triggerDeletePropertyAndVariants,
  triggerGetServiceGoodCalendarEndDate,
} from "./goodsTypes";
import history from "src/utils/browserHistory";
import produce from "immer";
import { AxiosResponse } from "axios";
import {
  clearDeleteFilesIds,
  clearDigitalFilesNames,
  triggerFinishUploadFile,
  triggerDeleteDigitalFileConfirmation,
} from "../digitalGoods";

const mapSEOParamsToArray = (
  seo?: SeoParametersType
): Omit<SeoParametersType, "keywords"> & {
  keywords: string[];
} => {
  let keywords: string[] = [];
  if (seo) {
    if (typeof seo.keywords === "string") {
      keywords = seo.keywords.split(", ");
    }
    return {
      ...seo,
      keywords,
    };
  }
  return {
    title: "",
    description: "",
    head: "",
    keywords,
  };
};

const mapSEOParamsToString = (
  seo?: SeoParametersType
): Omit<SeoParametersType, "keywords"> & {
  keywords: string;
} => {
  let keywords: string = "";
  if (seo) {
    if (typeof seo.keywords === "object") {
      keywords = seo.keywords.join(", ");
    }
    return {
      ...seo,
      keywords,
    };
  }
  return {
    title: "",
    description: "",
    head: "",
    keywords,
  };
};

const getGoodDetailSaga = bindAsyncAction(getGoodDetail)(function* ({
  payload,
}): SagaIterator {
  const response = yield call(
    get,
    `/projects/${payload.projectId}/goods/${payload.alias}${payload.geRawData ? "/raw" : ""
    }`
  );
  const data = camelize(response.data);
  const propertiesRes = yield call(
    get,
    `/goods/properties?goodsId=${data.item.id}&projectId=${payload.projectId}`
  );
  const properties: PropertySetType[] = camelize(propertiesRes.data);

  const mappedProps: PropertySetTypeMapped[] = properties.map((property) => {
    return {
      ...property,
      customSetId: property.setId?.toString() || "",
      properties: property.properties.map((nestedProp) => {
        return { ...nestedProp, customId: nestedProp.propertyId?.toString() };
      }),
    };
  });
  let variants: GoodVariantMappedType[] = [];
  if (mappedProps.length > 0) {
    const propertySets: Record<string, PropertySetTypeMapped> = {};
    mappedProps.forEach((prop) => {
      if (prop.customSetId) {
        propertySets[prop.customSetId] = prop;
      }
    });
    const customIdVariants = data.item.variants.map((variant: any) => {
      return {
        property1CustomId: variant.propertyId1,
        property2CustomId: variant.propertyId2,
        ...variant,
      };
    });
    variants = mapVariantProperties({ data: propertySets }, customIdVariants);
  }
  const goodSEO = mapSEOParamsToString(data.item.seoParameters);

  return {
    projectId: payload.projectId,
    alias: payload.alias,
    data: { ...data.item, variants, seoParameters: goodSEO, isForPublic: data.isForPublic },
    properties: mappedProps,
  };
});

const findAndDeleteImages = function* (
  oldImages: ImageType[],
  images: ImageType[]
) {
  const deleteIds = filterByOld(oldImages, images);
  yield all(
    deleteIds.map(function* (id) {
      return yield deleteRequest("/api-images/api/images/" + id);
    })
  );
};

const findAndDeleteVariantImages = function* (
  oldVariants: GoodVariantType[],
  variants: GoodVariantType[]
) {
  let oldImages: ImageType[] = [];
  let images: ImageType[] = [];
  oldVariants.forEach(
    (variant) => variant.defaultImage && oldImages.push(variant.defaultImage)
  );
  variants.forEach(
    (variant) => variant.defaultImage && images.push(variant.defaultImage)
  );
  const deleteIds = filterByOld(oldImages, images);
  yield all(
    deleteIds.map(function* (id) {
      return yield deleteRequest("/api-images/api/images/" + id);
    })
  );
};

const filterImages = function (
  images: (GoodImageEditType & { uploadedImage?: GoodImageEditType })[]
): GoodImageEditType[] {
  return images
    .filter((image) => !image.placeholder)
    .map((image) => {
      if (image.uploadedImage) {
        return image.uploadedImage;
      } else {
        return image;
      }
    });
};

const filterAndDeleteItemModules = function* ({
  projectId,
  oldModules,
  newModules,
  id,
}: {
  projectId: string;
  id: number;
  oldModules: ContentPageBlocks;
  newModules: ContentPageBlocks;
}) {
  try {
    const moduleIds = filterByOld(oldModules, newModules);
    if (moduleIds.length > 0) {
      const body = snakeize({
        projectId,
        moduleIds,
      });
      yield call(post, `/goods/${id}/deleteContent`, body);
    }
  } catch (e) { }
};

const uploadImagesAndFormatGood = function* (data: GoodFormInitialValues) {
  const images = yield call(filterImages, data.images);

  const finalModules: ContentPageBlocks = yield call(
    uploadAndFilterBlockTypeImages,
    data.modules
  );

  const canImpactQuantity =
    data.variants.length == 0 ||
    (data.variants.length > 0 && !data.variants[0].canImpactQuantity);
  const canImpactDescription =
    data.variants.length === 0 ||
    (data.variants.length > 0 && !data.variants[0].canImpactDescription);
  const canImpactDigital =
    data.variants.length === 0 ||
    (data.variants.length > 0 && !data.variants[0].canImpactDigital);

  const price = Number.isInteger(data.price as number) ? data.price : null;
  const oldPrice = Number.isInteger(data.oldPrice as number)
    ? data.oldPrice
    : null;
  const categoryId = data.categoryId || null;
  const quantity = canImpactQuantity
    ? Number.isInteger(data.quantity as number)
      ? data.quantity
      : null
    : null;
  const description = canImpactDescription ? data.description : null;
  const statusId = data.isAvailable ? 1 : 2;
  const goodsTypeId = canImpactDigital ? data.goodsTypeId : 1;
  const digitalGoodsTypeId = canImpactDigital ? data.digitalGoodsTypeId : null;
  const digitalGoodsContent = canImpactDigital
    ? data.digitalGoodsContent
    : null;

  const seoParameters = {
    ...data.seoParameters,
    keywords:
      typeof data.seoParameters.keywords === "string"
        ? data.seoParameters.keywords.split(", ")
        : [],
  };

  const requestGood: GoodType = {
    ...data,
    images: images,
    defaultImage: images[0],
    statusId,
    price,
    oldPrice,
    categoryId,
    quantity,
    description,
    modules: finalModules,
    seoParameters,
    goodsTypeId,
    digitalGoodsTypeId,
    digitalGoodsContent,
  };
  return requestGood;
};

const updateGoodDetailSaga = bindAsyncAction(updateGoodDetail)(function* ({
  payload,
}): SagaIterator {
  try {
    const oldGood: GoodType = yield select((state) =>
      selectActiveGood(state, payload.alias)
    );
    yield call(filterAndDeleteItemModules, {
      oldModules: oldGood.modules,
      newModules: payload.data.modules,
      projectId: payload.projectId,
      id: payload.goodId,
    });
    yield call(findAndDeleteImages, oldGood.images, payload.data.images);
    yield call(
      findAndDeleteVariantImages,
      oldGood.variants,
      payload.data.variants
    );

    const requestGood = yield call(uploadImagesAndFormatGood, payload.data);
    const updatedProperties: PropertySetTypeMapped[] = yield call(
      updateProperties,
      payload.projectId,
      oldGood.id.toString(),
      payload.alias
    );
    const mappedVariants = yield call(
      updateGoodVariants,
      payload.data,
      updatedProperties,
      payload.alias
    );
    const body = snakeize({
      ...requestGood,
      projectId: payload.projectId,
      variants: mappedVariants,
    });
    const response = yield call(putRequest, `/goods/${payload.goodId}`, body);
    const data = camelize(response.data);
    if (payload.finishUploadPayload && response.status === 200) {
      yield put(triggerFinishUploadFile({ downloadFiles: payload.finishUploadPayload }))
    }
    yield put(triggerDeleteDigitalFileConfirmation({ payload }))
    yield put(clearDigitalFilesNames())
    yield put(clearDeleteFilesIds())

    const variant  = data.item.variants.find((item: any) => item.isMain)
    history.push(
      `/project/${payload.projectId}/goods/${data.item.normalizedTitle}${variant ? `+${variant.normalizedTitle}` : ""}`
    );
    return {
      data: { ...data.item, variants: mappedVariants },
      projectId: payload.projectId,
      alias: payload.alias,
    };
  } catch (e) {
    console.log(e, "e");
    yield put(handleServerError(e));
  }
});

export const updateProperties = function* (
  projectId: string,
  goodsId: string,
  alias: string
) {
  const properties: ReturnType<typeof selectGoodProperties> = yield select(
    (state) => selectGoodProperties(state, alias)
  );
  const propertiesRes = yield call(
    get,
    `/goods/properties?goodsId=${goodsId}&projectId=${projectId}`
  );
  const oldProperties: PropertySetType[] = camelize(propertiesRes.data);

  const toDelete = oldProperties
    .filter((prop) => prop.setId && !properties.data[prop.setId])
    .map((prop) => prop.setId);

  if (toDelete.length > 0) {
    const deleteBody = snakeize({
      setIds: toDelete,
      projectId,
    });

    yield call(post, `/goods/properties/delete`, deleteBody);
  }

  if (Object.values(properties.data).length === 0) {
    return [];
  }
  const mappedProperties = Object.values(properties.data)
    .map((prop) => ({ ...prop, goodsId }))
    .sort((a, b) => a.position - b.position);

  const body = snakeize({
    projectId,
    goodsId,
    sets: mappedProperties,
  });

  // should delete right here
  const response = yield call(post, "/goods/properties", body);
  let responseProperties: PropertySetTypeMapped[] = camelize(
    response.data
  ).sort(
    (a: PropertySetTypeMapped, b: PropertySetTypeMapped) =>
      a.position - b.position
  );

  responseProperties = responseProperties.map((prop, index) => {
    const oldProperty = mappedProperties[index];
    const properties = prop.properties.map((nestedProp, nestedIndex) => {
      return {
        ...oldProperty.properties[nestedIndex],
        ...nestedProp,
      };
    });
    return {
      ...oldProperty,
      ...prop,
      properties,
    };
  });

  return responseProperties;
};

export const updateGoodVariants = function* (
  good: GoodFormInitialValues,
  properties: PropertySetTypeMapped[],
  alias: string
) {
  // TODO: IDK WHY CURRENT GOOD STATE IS HERE
  const currentGoodState: GoodFormInitialValues = yield select((state) =>
    selectEditGoodInitialValue(state, alias)
  );
  const propertiesRecord: Record<string, PropertySetTypeNested> = {};
  properties.forEach((prop) => {
    prop.properties.forEach((nestedProperty) => {
      const customId = nestedProperty.customId as string;
      propertiesRecord[customId] = nestedProperty;
    });
  });
  const mappedVariants = good.variants.map((variant) => {
    let propertyId1: number | undefined;
    let propertyId2: number | undefined;
    if (variant.property1CustomId) {
      propertyId1 = propertiesRecord[variant.property1CustomId]
        .propertyId as number;
    }
    if (variant.property2CustomId) {
      propertyId2 = propertiesRecord[variant.property2CustomId]
        .propertyId as number;
    }
    const price = variant.canImpactPrice ? variant.price : good.price;
    const oldPrice = variant.canImpactPrice ? variant.oldPrice : good.oldPrice;
    const description = variant.canImpactDescription
      ? variant.description
      : null;
    const statusId = variant.isAvailable ? 1 : 2;
    const quantity = variant.canImpactQuantity
      ? Number.isInteger(variant.quantity as number)
        ? variant.quantity
        : null
      : null;
    const seoParameters = mapSEOParamsToArray(variant.seoParameters);
    const goodsTypeId = variant.canImpactDigital
      ? variant.goodsTypeId
      : good.goodsTypeId;
    const digitalGoodsTypeId = variant.canImpactDigital
      ? variant.digitalGoodsTypeId
      : good.digitalGoodsTypeId;
    const digitalGoodsContent = variant.canImpactDigital
      ? variant.digitalGoodsContent
      : good.digitalGoodsContent;

    return {
      ...variant,
      quantity,
      propertyId1,
      propertyId2,
      price,
      oldPrice,
      description,
      statusId,
      seoParameters,
      goodsTypeId,
      digitalGoodsTypeId,
      digitalGoodsContent,
    };
  });

  return mappedVariants;
};

const addGoodSaga = bindAsyncAction(addGood)(function* ({
  payload,
}): SagaIterator {
  try {
    const requestGood = yield call(uploadImagesAndFormatGood, payload);
    const mappedVariants = requestGood.variants.map((variant: any) => {
      return {
        ...variant,
        goods_type_id: 1,
        seoParameters: mapSEOParamsToArray(variant.seoParameters),
      };
    });
    const body = snakeize({
      ...payload,
      ...requestGood,
      variants: mappedVariants,
    });
    const response = yield call(post, `/goods`, body);
    let data = camelize(response.data);
    const updatedProperties: PropertySetTypeMapped[] = yield call(
      updateProperties,
      payload.projectId,
      data.id,
      "addgood"
    );
    let variants = [];
    if (updatedProperties.length > 0) {
      variants = yield call(
        updateGoodVariants,
        { ...data, variants: payload.variants },
        updatedProperties,
        "addgood"
      );
      const updatedBody = snakeize({
        ...data,
        variants,
        projectId: payload.projectId,
      });
      const updatedResponse = yield call(
        putRequest,
        `/goods/${data.id}`,
        updatedBody
      );
      data = camelize(updatedResponse.data.item);
    }
    if (payload.finishUploadPayload && response.status === 200) {
      yield put(triggerFinishUploadFile({ downloadFiles: payload.finishUploadPayload }))
    }
    yield put(triggerDeleteAddedGood())
    const variant  = data.variants.find((item: any) => item.isMain)

    history.push(`/project/${payload.projectId}/goods/${data.normalizedTitle}${variant ? `+${variant.normalizedTitle}` : ""}`);

    return {
      alias: data.normalizedTitle,
      data,
    };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const deleteGoodSaga = bindAsyncAction(deleteGood)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(
      deleteRequest,
      `/projects/${payload.projectId}/goods/${payload.goodId}`
    );
    if (payload.callback) {
      payload.callback();
    }
    return payload.alias;
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const archiveGoodSaga = bindAsyncAction(archiveGood)(function* ({
  payload,
}): SagaIterator {
  try {
    yield call(deleteRequest, `/goods/${payload.goodId}`);
    if (payload.callback) {
      payload.callback();
    }
    return payload.alias;
  } catch (e) {
    yield put(handleServerError(e));
  }
});

export const prepareGoodProperties = (
  data: Record<string, PropertySetTypeMapped>,
  goodsId?: string
): PropertySetTypeMapped[] => {
  const mappedPropertySets: PropertySetTypeMapped[] = Object.keys(data).map(
    (key, index) => {
      const set = data[key];
      const mappedProperties = set.properties.map((property, propertyIndex) => {
        return {
          ...property,
          position: propertyIndex,
        };
      });
      return {
        ...set,
        goodsId,
        properties: mappedProperties as PropertySetTypeMapped["properties"],
        position: index,
      };
    }
  );
  return mappedPropertySets;
};

export const mapVariantProperties = (
  properties: ReturnType<typeof selectGoodProperties>,
  currentVariants: GoodVariantMappedType[] = []
): GoodVariantMappedType[] => {
  let returnValues: GoodVariantMappedType[];
  const sortedProperties = Object.values(properties.data)
    .sort((a, b) => a.position - b.position)
    .map((prop, index) => {
      const properties = prop.properties.map((nestedProp) => ({
        ...nestedProp,
        setPosition: index,
      }));
      return {
        ...prop,
        properties,
      };
    });

  const maxedProperties = Object.values(sortedProperties)
    .sort((a, b) => b.properties.length - a.properties.length)
    .map((property) =>
      property.properties.map((p) => {
        return {
          ...p,
          canImpactPrice: property.canImpactPrice,
          canImpactQuantity: property.canImpactQuantity,
          canImpactDescription: property.canImpactDescription,
          canImpactDigital: property.canImpactDigital,
        };
      })
    );

  const [first, ...rest] = maxedProperties;

  let generatedVariants: GoodVariantMappedType[] = [];

  if (rest.length >= 1) {
    rest.forEach((property) => {
      property.forEach((nestedProperty) => {
        first.forEach((firstProperty) => {
          let name = "";
          if (firstProperty.setPosition > nestedProperty.setPosition) {
            name = nestedProperty.name + " " + firstProperty.name;
          } else {
            name = firstProperty.name + " " + nestedProperty.name;
          }

          const generatedVariant: GoodVariantMappedType = {
            property1CustomId: firstProperty.customId,
            property2CustomId: nestedProperty.customId,
            // TODO: property mapping to variant
            isAvailable: true,
            title: name,
          };
          generatedVariants.push(generatedVariant);
        });
      });
    });
    const allProperties = produce(properties, (draft) => {
      return Object.values(draft.data)
        .sort((a, b) => a.position - b.position)
        .map((prop) =>
          [...prop.properties].sort((a, b) => a.position - b.position)
        );
    });

    const unwrappedArray = produce(allProperties, (draft) => {
      const [firstProp, secondProp] = draft;
      return firstProp.concat(secondProp).map((props, index) => {
        return { ...props, customizedPosition: index };
      });
    });
    // to find names
    const unwrappedMap: Record<string, typeof unwrappedArray[0]> = {};
    unwrappedArray.forEach((prop) => {
      unwrappedMap[prop.customId as string] = prop;
    });

    const [firstIds, secondIds] = allProperties.map((prop) =>
      prop.map((v) => v.customId)
    );

    returnValues = generatedVariants.sort((a, b) => {
      const aIndexInFirstSet =
        firstIds.indexOf(a.property1CustomId) >= 0
          ? firstIds.indexOf(a.property1CustomId)
          : firstIds.indexOf(a.property2CustomId);
      const bIndexInFirstSet =
        firstIds.indexOf(b.property1CustomId) >= 0
          ? firstIds.indexOf(b.property1CustomId)
          : firstIds.indexOf(b.property2CustomId);
      if (aIndexInFirstSet > bIndexInFirstSet) {
        return 1;
      } else if (aIndexInFirstSet == bIndexInFirstSet) {
        const aIndexInSecondSet =
          secondIds.indexOf(a.property1CustomId) >= 0
            ? secondIds.indexOf(a.property1CustomId)
            : secondIds.indexOf(a.property2CustomId);
        const bIndexInSecondSet =
          secondIds.indexOf(b.property1CustomId) >= 0
            ? secondIds.indexOf(b.property1CustomId)
            : secondIds.indexOf(b.property2CustomId);
        if (aIndexInSecondSet > bIndexInSecondSet) {
          return 1;
        } else if (aIndexInSecondSet == bIndexInSecondSet) {
          return 0;
        } else {
          return -1;
        }
      }
      return -1;
    });
  } else {
    returnValues = first
      .sort((a, b) => a.position - b.position)
      .map((property, index) => ({
        property1CustomId: property.customId,
        canImpactDescription: property.canImpactDescription,
        canImpactPrice: property.canImpactPrice,
        canImpactDigital: property.canImpactDigital,
        // TODO: property mapping to variant
        canImpactQuantity: property.canImpactQuantity,
        isAvailable: true,
        isMain: index === 0,
        title: property.name,
      }));
  }
  const oldGoodsMap: Record<string, GoodVariantMappedType> = {};
  if (currentVariants) {
    currentVariants.forEach((variant) => {
      const defaultIds = [];
      if (variant.property1CustomId) {
        defaultIds.push(variant.property1CustomId);
      }
      if (variant.property2CustomId) {
        defaultIds.push(variant.property2CustomId);
      }

      if (defaultIds.length > 0) {
        const key = defaultIds.sort().toString();
        oldGoodsMap[key] = variant;
      }
    });
  }
  // Merging with old variants by name
  let hasMain = false;
  returnValues = returnValues.map((variant) => {
    let isAvailable = true;
    let isMain = false;
    let seoParameters: SeoParametersType | undefined;
    let goodsTypeId: number | undefined;
    // TODO: merge images as well
    const customIds = [];
    if (variant.property1CustomId) {
      customIds.push(variant.property1CustomId);
    }
    if (variant.property2CustomId) {
      customIds.push(variant.property2CustomId);
    }

    if (customIds.length > 0) {
      const key = customIds.sort().toString();
      const oldVariant = oldGoodsMap[key] || {};
      if (oldVariant) {
        // TODO: move that to selector phase
        isAvailable = oldVariant.statusId
          ? oldVariant.statusId > 1
            ? false
            : true
          : true;
        isMain = oldVariant.isMain || false;
        seoParameters = mapSEOParamsToString(oldVariant.seoParameters);
        goodsTypeId = oldVariant.goodsTypeId || 1;
        if (isMain) {
          hasMain = isMain;
        }
        return {
          ...variant,
          ...oldVariant,
          isAvailable,
          isMain,
          seoParameters,
          goodsTypeId,
        };
      }
    }
    // no mapping happened
    return variant;
  });

  if (!hasMain) {
    returnValues[0].isMain = true;
  }

  return returnValues;
};

export const generateVariantsSaga = bindAsyncAction(generateVariants)(
  function* ({ payload }): SagaIterator {
    // TODO: move to a separate function
    const mappedProperties = payload.data.properties.map((prop, index) => ({
      ...prop,
      position: index,
    }));
    const data = { ...payload.data, properties: mappedProperties };
    yield put(savePropertySet({ alias: payload.alias, data }));

    const alias = payload.alias || "addgood";
    const properties: ReturnType<typeof selectGoodProperties> = yield select(
      (state) => selectGoodProperties(state, alias)
    );
    const currentGoodVariants: GoodFormVariantType[] = yield select((state) =>
      selectGoodVariantsMapped(state, alias)
    );
    const variants = mapVariantProperties(properties, currentGoodVariants);
    return {
      alias,
      variants,
    };
  }
);

export const deletePropertyAndVariantsSaga = bindAsyncAction(
  deletePropertyAndVariants
)(function* ({ payload }): SagaIterator {
  yield put(deleteProperty(payload));
  const properties: ReturnType<typeof selectGoodProperties> = yield select(
    (state) => selectGoodProperties(state, payload.alias)
  );
  let variants: GoodVariantMappedType[] = [];
  if (Object.values(properties.data).length > 0) {
    const currentGoodVariants: GoodFormVariantType[] = yield select((state) =>
      selectGoodVariantsMapped(state, payload.alias)
    );
    variants = mapVariantProperties(properties, currentGoodVariants);
  }
  return {
    alias: payload.alias,
    variants,
  };
});

const getGoodsByIdsSaga = bindAsyncAction(getGoodsByIds)(function* ({
  payload,
}): SagaIterator {
  try {
    const response: AxiosResponse<{
      totalCount: number;
      goods: GoodCardType[];
    }> = yield call(post, `/goods/getByIds`, snakeize({ goodsIds: payload }));
    const data: typeof response.data = camelize(response.data);
    return data.goods;
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getGoodsSetSaga = bindAsyncAction(getGoodsSet)(function* ({
  payload,
}): SagaIterator {
  try {
    const body = snakeize(payload);
    const response: AxiosResponse<{
      moduleId: string;
      goods: GoodCardType[];
    }> = yield call(post, `/modules/getGoodsSetByDomain`, body);
    const data: typeof response.data = camelize(response.data);
    return data;
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getGoodsSetByIdsSaga = bindAsyncAction(getGoodsSetByIds)(function* ({
  payload,
}): SagaIterator {
  try {
    const body = snakeize(payload);
    const response: AxiosResponse<{
      moduleId: string;
      goods: GoodCardType[];
    }> = yield call(post, `/modules/getGoodsSetById`, body);
    const data: typeof response.data = camelize(response.data);
    return data;
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const editRangeSaga = bindAsyncAction(editRange)(function* ({
  payload,
}): SagaIterator {
  try {
    const exceptGoodsIds = payload.allSelected ? payload.goodsIds : []
    const ids = payload.allSelected ? [] : payload.goodsIds
    let body = {}
    switch (payload.rangeEditType) {
      case "status":
        body = {
          query: {
            goodsIds: ids,
            categoryId: payload.activeCategory,
            exceptGoodsIds: exceptGoodsIds
          },
          projectId: payload.projectId,
          statusId: payload.statusId,
        };
        yield call(putRequest, "/goods/editRange", snakeize(body));
        if (payload.callback) {
          payload.callback();
        }
        break;
      case "delete":
        body = {
          query: {
            goodsIds: ids,
            categoryId: payload.activeCategory,
            exceptGoodsIds: exceptGoodsIds
          },
          projectId: payload.projectId,
        };
        yield call(putRequest, "/goods/deleteRange", snakeize(body));
        if (payload.callback) {
          payload.callback();
        }
        break;
      default:
        break;
    }
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getServiceGoodCalendarSaga = bindAsyncAction(getServiceGoodCalendar)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<ServiceGoodCalendar> = yield call(
      get,
      `bookingOption/${payload.bookingOptionId}/calendar?startDate=${payload.startDate}&size=${payload.size}`
    );
    return camelize(data)
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const getServiceGoodCalendarSagaEndDate = bindAsyncAction(getServiceGoodCalendarEndDate)(function* ({
  payload,
}): SagaIterator {
  try {
    const { data }: AxiosResponse<ServiceGoodCalendar> = yield call(
      get,
      `bookingOption/${payload.bookingOptionId}/calendar?startDate=${payload.startDate}&size=${payload.size}`
    );
    return camelize(data)
  } catch (e) {
    yield put(handleServerError(e));
  }
});



export default function* () {
  yield takeLatest(triggerGetGoodDetail.type, getGoodDetailSaga);
  yield takeLatest(triggerUpdateGoodDetail.type, updateGoodDetailSaga);
  yield takeLatest(triggerAddGood.type, addGoodSaga);
  yield takeLatest(triggerDeleteGood.type, deleteGoodSaga);
  yield takeLatest(triggerArchiveGood.type, archiveGoodSaga);
  yield takeLatest(triggerGenerateVariants.type, generateVariantsSaga);
  yield takeLatest(
    triggerDeletePropertyAndVariants,
    deletePropertyAndVariantsSaga
  );
  yield takeLatest(triggerGetGoodsByIds, getGoodsByIdsSaga);
  yield takeLatest(triggerGetGoodsSet, getGoodsSetSaga);
  yield takeLatest(triggerEditRange, editRangeSaga);
  yield takeLatest(triggerGetGoodsSetByIds, getGoodsSetByIdsSaga);
  yield takeLatest(triggerGetServiceGoodCalendar, getServiceGoodCalendarSaga);
  yield takeLatest(triggerGetServiceGoodCalendarEndDate, getServiceGoodCalendarSagaEndDate);

}
