import produce from "immer";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  getServiceGoodCalendarEndDate,
  triggerDuplicateVariantsImage,
  triggerDuplicateMainImage,
  deletePropertyAndVariants,
  triggerDuplicateModule,
  triggerDeleteAddedGood,
  getServiceGoodCalendar,
  PropertySetTypeMapped,
  updateGoodDetail,
  generateVariants,
  getGoodsSetByIds,
  savePropertySet,
  deleteGoodDraft,
  deleteProperty,
  getGoodDetail,
  GoodStateType,
  getGoodsByIds,
  saveGoodDraft,
  archiveGood,
  deleteGood,
  getGoodsSet,
  addGood,
} from "./goodsTypes";

// // ---
// // CONSTANTS
// // ---

// export const TOGGLE_GOODS_LOADING = 'goods/TOGGLE_GOOD_LOADING'
// export const GET_GOOD_BY_ALIAS = 'goods/GET_GOOD_BY_ALIAS'
// export const SET_GOOD_BY_ALIAS = 'goods/SET_GOOD_BY_ALIAS'

// // ---
// // ACTION CREATORS
// // ---

// export const getGoodByAlias = createAction(GET_GOOD_BY_ALIAS)
// export const setGoodByAlias = createAction(SET_GOOD_BY_ALIAS)
// export const toggleGoodsLoading = createAction(TOGGLE_GOODS_LOADING)

// // ---
// // INITIAL STATE
// // ---

// const initialState = Immutable({
//   loading: false,
//   data: {},
//   blockTypes: {}
// })

// // ---
// // REDUCER
// // ---

// export default handleActions(
//   {
//     [SET_GOOD_BY_ALIAS]: (state, { payload: { item, block_types } }) => {
//       const data = state.data.set(item.normalizedTitle, item)
//       return Immutable.merge(state, { data })
//     },
//     [TOGGLE_GOODS_LOADING]: (state, { payload }) => {
//       return state.set('loading', payload)
//     }
//   },
//   initialState
// )

const INITIAL_STATE: GoodStateType = {
  loading: false,
  data: {},
  properties: {},
  listData: {},
  listIds: [],
  goodsSetDataMap: {},
  serviceDayCalendar:{
    availableDays: [
    ],
  },
};

export default reducerWithInitialState(INITIAL_STATE)
  .cases(
    [
      getGoodDetail.started,
      updateGoodDetail.started,
      addGood.started,
      archiveGood.started,
    ],
    (state) => ({
      ...state,
      loading: true,
    })
  )
  .cases(
    [deleteGood.started], (state) => ({
      ...state,
      loading: true,
    })
  )
  .cases(
    [updateGoodDetail.done, addGood.done],
    (state, action) => {
      return produce(state, (draft) => {
        if (action.result) {
          draft.data[action.result.alias] = action.result.data;
        }
        draft.loading = false;
        return draft;
      });
    }
  )
  .cases(
    [getGoodDetail.done],
    (state, action) => {
      return produce(state, (draft) => {
        if (action.result) {
          const { alias } = action.result
          if (draft.data[alias]?.hasUnsavedChanges) {
            draft.data[alias] = { ...action.result.data, ...draft.data[alias] };
          } else {
            draft.data[action.result.alias] = { ...action.result.data };
          }
          if (action.result.properties && action.result.properties.length > 0 && !state.properties[action.result.alias]?.hasUnsavedData) {
            const mappedProperties: Record<number, PropertySetTypeMapped> = {};
            action.result.properties.forEach((property) => {
              const nestedProperties = property.properties.map((nestedProp) => {
                const customId = nestedProp.propertyId?.toString()
                return { ...nestedProp, customId }
              })
              mappedProperties[property.customSetId as number] = { ...property, properties: nestedProperties };
            });
            draft.properties[action.result.alias] = { data: mappedProperties }
          }
        }
        draft.loading = false;
        return draft;
      });
    }
  )

  .cases([getServiceGoodCalendar.started], (state) => {
    return produce(state, (draft) => {
      draft.loading = true;
      return draft;
    })
  })

  .cases([getServiceGoodCalendar.done], (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.serviceDayCalendar = action.result
      }
      draft.loading = false;
      return draft;
    })
  })

  .cases([archiveGood.done, deleteGood.done], (state, action) => {
    return produce(state, (draft) => {
      draft.loading = false;
      if (action.result) {
        delete draft.data[action.result];
      }
      return draft;
    });
  })
  .case(deleteProperty, (state, action) => {
    return produce(state, (draft) => {
      delete draft.properties[action.alias].data[action.id]
      draft.properties[action.alias].hasUnsavedData = true
      return draft
    })
  })
  .cases(
    [savePropertySet],
    (state, action) => {
      return produce(state, (draft) => {
        const key = action.alias || 'addgood';
        // TODO: map positions right here and fix types
        if (draft.properties[key]) {
          draft.properties[key].data[action.data.customSetId] = { ...action.data };
          draft.properties[key].hasUnsavedData = true
        } else {
          draft.properties[key] = { data: { [action.data.customSetId as any]: action.data }, hasUnsavedData: true }
        }
        return draft;
      });
    }
  )
  .cases([generateVariants.done, deletePropertyAndVariants.done], (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        const { alias, variants } = action.result
        // TODO: figure out do I need default good here
        const good = draft.data[alias] || {
          images: [],
          modules: [],
        }
        good.variants = variants
        draft.data[alias] = good
        return draft
      }
    })
  })
  .case(saveGoodDraft, (state, action) => {
    return produce(state, draft => {
      draft.data[action.alias] = { ...action.good, hasUnsavedChanges: true }
      return draft
    })
  })
  .case(deleteGoodDraft, (state, action) => {
    return produce(state, draft => {
      delete draft.data[action.alias]
      delete draft.properties[action.alias]
      return draft
    })
  })
  .cases([getGoodsByIds.done], (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        const obj = action.result.reduce(
          (commonObject, good) => ({ ...commonObject, [good.id]: good }), draft.listData
        )
        draft.listData = obj
      }
    })
  })
  
  .cases([getGoodsSet.done, getGoodsSetByIds.done], (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        action.result.forEach((module) => {
          draft.goodsSetDataMap[module.moduleId] = module.goods
        })
      }
    })
  })

  .case(getServiceGoodCalendarEndDate.done, (state, action) => {
    return produce(state, (draft) => {
      if (draft.serviceDayCalendar.availableDays ) {
       draft.serviceDayCalendar.availableDays.splice(-1, 0, ...draft.serviceDayCalendar.availableDays.splice(-1, 1, ...action.result.availableDays!))
      }
      draft.loading = false;
      return draft;
    });
  })
  .case(triggerDuplicateMainImage, (state, action) => {
    return produce(state, (draft) => {
      if(draft.data[action.id || 'addgood'] &&  draft.data[action.id || 'addgood'].images){
        draft.data[action.id || 'addgood'].images.splice(action.index, 0, action.newImage);
      } else {
        draft.data[action.id || 'addgood'] = {
          ...draft.data[action.id || 'addgood'],
          images: [action.newImage]
        }
      }
      return draft;
    });
  })
  .case(triggerDuplicateVariantsImage, (state, action) => {
    return produce(state, (draft) => {
      draft.data[action.id || 'addgood'].variants[action.index].defaultImage = action.newImage;
      return draft;
    });
  })
  .case(triggerDeleteAddedGood, (state, action) => {
    return produce(state, (draft) => {
      if (draft.data["addgood"]) {
        delete draft.data["addgood"];
        delete draft.properties["addgood"];
      }
      return draft;
    });
  })
  .case(triggerDuplicateModule, (state, action) => {
    return produce(state, (draft) => {
      if(draft.data[action.id || 'addgood']  &&  draft.data[action.id || 'addgood'].modules){
        draft.data[action.id || 'addgood'].modules.splice(action.index, 0, action.contentBlock);
      } else {
        draft.data[action.id || 'addgood'] = {
          ...draft.data[action.id || 'addgood'],
          modules: [action.contentBlock]
        }
      }
      return draft;
    });
  });
  