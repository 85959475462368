import React from 'react'
import styled, { keyframes } from 'styled-components'
import { bool, string } from 'prop-types'

const AppLoader = () => {
  return (
    <Container>
      <Svg viewBox="0 0 200 200" preserveAspectRatio="xMidYMid meet">
        <Jumper className="jumper" strokeLinecap="round" strokeLinejoin="round" d="M47.5,94.3c0-23.5,19.9-42.5,44.5-42.5s44.5,19,44.5,42.5"/>
        <g stroke="#000000" strokeWidth="1">
          <CircleL className="circleL" fill="none" strokeMiterlimit="10" cx="47.2" cy="95.6" rx="10.7" ry="2.7" />
          <CircleR className="circleR" fill="none" strokeMiterlimit="10" cx="136.2" cy="95.6" rx="10.7" ry="2.7" />
        </g>
        <JumperClone className="jumper clone" strokeLinecap="round" strokeLinejoin="round" d="M47.5,94.3c0-23.5,19.9-42.5,44.5-42.5s44.5,19,44.5,42.5"/>
      </Svg>
    </Container>
  )
}

AppLoader.propTypes = {
  rotated: bool,
  className: string
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(108.07deg, #3F51B5 -0.42%, #7A3FB5 100%);
`
const len = 136.71884155273438
const time = 1.3

const animJumper = keyframes`
  8% {
    stroke-dasharray: ${len*0.15}px, ${len}px;
  }
  32% {
    stroke-dasharray: ${len*0.3}px, ${len}px;
    stroke-dashoffset: ${len*-0.35}px;
  }
  56% {
    stroke-dasharray: ${len*0.15}px, ${len}px;
    stroke-dashoffset: ${len*-0.85};
  }
  64% {
    stroke-dasharray: 0, ${len}px;
    stroke-dashoffset: -${len}px;
  }
}`

const animCircle = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    transform: scale(3);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}`

const Svg = styled.svg`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40rem;
  height: 40rem;
  margin-left: -20rem;
  margin-top: -20rem;
  overflow: visible;
`

const Jumper = styled.path`
  fill: none;
  stroke: rgb(255,255,255);
  stroke-width: 10px;
  stroke-dashoffset: 0;
  stroke-dasharray: 0, ${len}px;
  animation: ${animJumper} ${time}s linear infinite;
  
`

const JumperClone = styled(Jumper)`
  opacity: 0.05;
  // transform-origin for svg bugged in ff, so i'm using translateY hack
  transform: translateY(200px) scaleY(-1);
`

const CircleL = styled.ellipse`
  opacity: 0;
  transform-origin: 47.2px 95.6px;
  animation: ${animCircle} ${time}s infinite;
  stroke: #ffffff;
`
const CircleR = styled.ellipse`
  opacity: 0;
  transform-origin: 136.2px 95.6px;
  animation: ${animCircle} ${time}s ${time*0.56}s infinite;
  stroke: #ffffff;
`

export default AppLoader