import React, { memo, Fragment, useCallback } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { gradientBackground, H2, P, Button } from 'hopshopui'
import {  useHistory } from 'react-router-dom'

import {useT} from 'src/hooks/useT'

const ErrorStyles = createGlobalStyle`
  html, body {
    height: 100%;
  }
  body {
    ${gradientBackground};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`


const ErrorForbidden = () => {
  const history = useHistory()
  const buttonClick = useCallback((href) => {
    history.push(href)
  }, [history])
  const { t } = useT()
  return (
    <Fragment>
      <ErrorStyles />
      <Center>
        <Row>
          <Col md={6}>
            <Face>
              ಠ_ಠ
            </Face>
          </Col>
          <Col md={6}>
            <H2>{t('errors.talkToSupport')}</H2>
            <P>{t('errors.accountSuspended')}</P>
            <Button onClick={() => buttonClick('mailto:team@hopshop.ru')}>team@hopshop.ru</Button>
            <Button onClick={() => buttonClick('/')}>{t('errors.toIndex')}</Button>
          </Col>
        </Row>
      </Center>
    </Fragment>
  )
}

const Center = styled(Grid)`
  /* margin-top: 50%; */
  /* transform: translateY(-50%); */
  h2, P, button {
    margin-bottom: 10px;
  }
`

const Face = styled(P)`
  font-size: 180px;
`

export default memo(ErrorForbidden)