import React, { Suspense } from 'react'
import ProjectGoodShimmer from 'src/modules/projects/components/ProjectGoodShimmer'


const BookingLogSettings = React.lazy(() => import('src/modules/bookingLog/containers/BookingLogSettings'))

const BookingLogSettingsPage = () => {
  return (
    <Suspense fallback={<ProjectGoodShimmer />}>
      <BookingLogSettings/>
    </Suspense>
  )
}

export default BookingLogSettingsPage
