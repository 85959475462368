import { useLocation } from "react-router-dom"
import qs from 'qs'
// A custom hook that builds on useLocation to parse

// the query string for you.
export function useQuery() {
  const { search } = useLocation()
  return qs.parse(search, {
    ignoreQueryPrefix: true
  })
}