import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { selectProjectsLoading } from 'src/store/projects'

import SettingsMainSync from './SettingsMainSync'

const mapStateToProps = (state) => ({
  loading: selectProjectsLoading(state)
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(
    SettingsMainSync
  )
)
