import React, { Fragment } from 'react'
import styled from 'styled-components'
import { func, bool } from 'prop-types'
import { SecondaryRoundButton, Divider, EnterPasswordIcon } from 'hopshopui'

import Yup from 'src/utils/yup'
import { routerProps } from 'src/types/routerProps'
import { BaseForm } from 'src/elements'
import { InputContainer, ButtonStyled, InputStyled } from 'src/modules/auth/components/AuthEmail'
import { useT } from 'src/hooks/useT'
import { size } from 'src/utils/styles'
import { AddGoodText, DescriptionGoodText } from './AuthButtons'

const getEmail = (match) => {
  return match.params.email || ''
}

const handleSubmit = ({ password, email }, history, login) => {
  login({ password, email, history })
}

const redirectBack = (history) => {
  history.push('/auth/type')
}

const getLabel = (authorized, t) => {
  if (authorized) {
    return t('inputs.labels.password')
  } else {
    return t('inputs.labels.passwordFromMail')
  }
}

const reset = ({ email }, history, restorePassword) => {
  restorePassword({
    email,
    history
  })
}

const renderForm = ({
  match,
  handleSubmit,
  errors,
  touched,
  handleChange,
  handleBlur,
  values,
  isValid,
  history,
  loading,
  restorePassword,
  passwordError,
  setPasswordError,
  t
}) => {
  const authorized = !!match.params.auth
  const resetPassword = (e) => {
    e.stopPropagation()
    e.preventDefault()
    reset(match.params, history, restorePassword)
  }

  return (
    <Fragment>
      <Divider/>
      <InputContainer passwordError={passwordError} onSubmit={handleSubmit}>
        <InputStyled
          passwordError={passwordError}
          load={loading}
          errors={errors}
          touched={touched}
          label={getLabel(authorized, t)}
          // labelButton={authorized && <LinkContainer>(ಠ_ಠ)<span>{t('auth.remember')}</span></LinkContainer>}
          labelAction={() => reset(match.params, history, restorePassword)}
          placeholder={t('inputs.placeholders.password')}
          name="password"
          id="password"
          type="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={()=>setPasswordError(false)}
        />
      <Divider/>
        <StyledButtonWrapper
          load={loading}
          disabled={!isValid || loading}
          type="submit"
        > 
        {t('buttons.login')}
        </StyledButtonWrapper>
        <SecondaryRoundButtonWrapper onClick={resetPassword}>
          {t('auth.passwordReminder')}
        </SecondaryRoundButtonWrapper>
      </InputContainer>
    </Fragment>
  )
}

export const IconWrapper = styled.div`
  margin-top: 30px;
  text-align: center;
  svg .keyIconColor {
    fill: ${props => props.passwordError && props.theme.colors.instPushed};
  }
`;

renderForm.propTypes = {
  ...routerProps
}


const validationSchema = () => {
  return Yup.object().shape({
    password: Yup.string().required('required'),
  })
}

const AuthPassword = ({ match, history, login, loading, authorized, restorePassword, setPasswordError, passwordError, }) => {
  const email = getEmail(match)
  const { t } = useT()
  return (
    <Container>
      <IconWrapper passwordError={passwordError}>
        <EnterPasswordIcon/>
      </IconWrapper>
      <TitleWrapper> {t("auth.enterPassword")} </TitleWrapper>
      <DescriptonWrapper> {t("auth.enterPasswordDescription")} </DescriptonWrapper>
          
      <BaseForm
        initialValues={{
          password: '',
          email
        }}
        onSubmit={(values) => handleSubmit(values, history, login)}
        render={(formikProps) => renderForm({ ...formikProps, match, history, loading, authorized, restorePassword, t, passwordError, setPasswordError })}
        validationSchema={validationSchema}
      />
    </Container>
  )
}

AuthPassword.propTypes = {
  ...routerProps,
  login: func.isRequired,
  restorePassword: func.isRequired,
  loading: bool
}

const TitleWrapper = styled(AddGoodText)`
  padding: 0;
`;

export const DescriptonWrapper = styled(DescriptionGoodText)`
  margin: 10px 20px 20px 20px;
  @media screen and (max-width: ${size.mobile}) {
    margin: 10px 20px 20px 20px;
  }
`;

const StyledButtonWrapper = styled(ButtonStyled)`
  margin-bottom:10px;

`

const SecondaryRoundButtonWrapper = styled(SecondaryRoundButton)`
  margin: 0 auto;
  width: 90%;
  flex-grow: 0;
`;

const Container = styled.div`
  padding-bottom: 20px;
  @media screen and (max-width: ${size.mobile}) {
    padding-bottom: 10px;
  }
`



export default AuthPassword