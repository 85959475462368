import { post } from "src/utils/requestSaga";
import { SagaIterator } from "redux-saga";
import {
  ContentPageBlocks,
} from "src/templates/utils/types/goodType";
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import { bindAsyncAction } from "typescript-fsa-redux-saga";

import {
  triggerUploadImageAsync,
  uploadImageAsync,
  addImageToQueue,
  removeImageById,
  disableImagesLoading,
  triggerDuplicateImageAsync,
  duplicateImageAsync,
  addDuplicateImageToQueue,
  removeDuplicateImageById,
  toggleDuplicateImageLoading,
} from "./imagesTypes";

import { selectImages } from "./imagesSelectors";

import { ImagesStateType, toggleImagesLoading } from "./imagesTypes";
import nanoid from "nanoid";
import produce from "immer";

export const uploadAndFilterBlockTypeImages = function (
  payload: ContentPageBlocks
): ContentPageBlocks {
  let mappedWithPositions = payload
    .map((block) => {
      if (block.moduleType === "GoodsSet") {
        return produce(block, (draft) => {
          if (draft.categoryActive) {
            draft.payload.goods = []
          } else {
            if (draft.payload.goods) {
              draft.payload.goods = draft.payload.goods.map((goodId) => Number(goodId))
              draft.payload.categoryId = null
            }
          }
        })
      }
      return block
    })
    .map((block, index) => {
      return {
        ...block,
        position: index,
      };
    })
    .filter((block) => {
      if (block.moduleType === "Text") {
        return block.payload.text && block.payload.text.length > 0;
      } else if (block.moduleType === "Image") {
        return block.id || block.payload.imageFile || Object.keys(block.payload).length > 0;
      } else if (block.moduleType === "Video") {
        return block.payload.url;
      } else if (block.moduleType === "GoodsSet") {
        return block.payload.categoryId || block.payload.description || block.payload.goods.length >= 1 || block.payload.name
      } else if (block.moduleType === "ButtonSet") {
        return true
      } else if (block.moduleType === "HtmlBlock") {
        return block.payload.html && block.payload.html.length > 0;
      }
    });

  const oldImagesBlocks = mappedWithPositions.filter(
    (block) => block.moduleType === "Image" && !block.payload.imageFile
  );
  const oldImageBlocksWithoutCustomIds = oldImagesBlocks.map((block) => {
    const _block = { ...block };
    !_block.id && delete _block.id;
    return _block;
  });

  const filteredByImagesBlock = mappedWithPositions.filter(
    (content) => content.moduleType !== "Image"
  );
  const finalModules = [
    ...filteredByImagesBlock,
    ...oldImageBlocksWithoutCustomIds,
  ].sort((a, b) => a.position - b.position);

  return finalModules;
};

export const uploadImageAsyncSaga = bindAsyncAction(uploadImageAsync)(
  function* ({ payload }): SagaIterator {
    const id = payload.id || nanoid();
    const isAsyncString = payload.isAsync !== undefined && payload.isAsync !== null ? `/?isAsync=${payload.isAsync}` : ''

    yield put(addImageToQueue({ ...payload, id }));
    yield put(toggleImagesLoading(true));

    const formData = new FormData();
    formData.append("file", payload.image);

    const res = yield call(
      post,
      `/api-images/api/images/add${isAsyncString}`,
      formData
    );

    if (res.status === 200) {
      payload.callback && payload.callback(res.data);
    }
    yield put(removeImageById(id));

    const imagesState: ImagesStateType = yield select(selectImages);
    if (imagesState.data.length === 0) {
      yield put(disableImagesLoading());
    }

    //yield call(startImagesUploadSaga);

    return {};
  }
);

export const duplicateImageAsyncSaga = bindAsyncAction(duplicateImageAsync)(
  function* ({ payload }): SagaIterator {
    const customId = nanoid();

    yield put(addDuplicateImageToQueue( payload.id ));
    yield put(toggleDuplicateImageLoading(true));

    const res = yield call(
      post,
      `/api-images/api/images/${payload.id}/duplicate`, false
    );

    if (res.status === 200) {
      payload.callback && payload.callback(res.data, customId, payload.id);
    }
    yield put(removeDuplicateImageById(payload.id));

    const imagesState: ImagesStateType = yield select(selectImages);
    if (imagesState.duplicateData.length === 0) {
      yield put(toggleDuplicateImageLoading(false));
    }

    return {};
  }
);


/* export const startImagesUploadSaga = function* () {
  const imagesState: ImagesStateType = yield select(selectImages);
  const { data } = imagesState;
  if (!imagesState.loading && data.length > 0) {
    let flag = true;

    yield put(toggleImagesLoading(true));

    while (flag) {
      const _imagesState: ImagesStateType = yield select(selectImages);
      if (_imagesState.data.length === 0) {
        flag = false;
      }
      const _data = _imagesState.data;

      yield put(clearImagesQueue());

      yield all(
        _data.map(function* (imageBlock) {
          const formData = new FormData();
          formData.append("file", imageBlock.image);
          const res = yield post(
            `/api-images/api/images/add?isAsync=false`,
            formData
          );

          if (res.status === 200) {
            imageBlock.callback && imageBlock.callback(res.data);
          }
        })
      );
    }

    yield put(toggleImagesLoading(false));
  }
};*/

export default function* () {
  yield takeEvery(triggerUploadImageAsync.type, uploadImageAsyncSaga);
  yield takeEvery(triggerDuplicateImageAsync.type, duplicateImageAsyncSaga);
}
