import { SagaIterator } from "redux-saga";
import {
  takeEvery,
  call,
  put,
  fork,
  race,
  take,
  cancel,
  delay,
  throttle
} from "redux-saga/effects";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import { Action } from "typescript-fsa";
import {
  getProjectSync,
  triggerGetProjectSync,
  SyncRequestType,
  triggerUpdateProjectSync,
  updateProjectSync,
  SyncSettingsType,
  importProjectSync,
  triggerImportProjectSync,
  startSyncInfinite,
  stopSyncInfinite,
  triggerUpdateSyncCache,
  updateSyncCache,
  updateProjectSyncWithoutImport,
  triggerUpdateProjectSyncWithoutImport
} from "./syncTypes";
import { get, post, putRequest } from "src/utils/requestSaga";
import { AxiosResponse } from "axios";
import { camelize, snakeize } from "casing";
import { handleServerError } from "../notification";
import qs from "qs";
import {
  getProjectGoodsWithSearch,
  getDelayedProjectGoodsWithSearch,
} from "../projects";
import { getProviderFromString } from "src/utils/getProviderFromString";
import { formatUrl } from "src/utils/url";

export const getProjectSyncSaga = bindAsyncAction(getProjectSync)(function* ({
  payload,
}): SagaIterator<
  | (SyncRequestType & {
    projectId: string;
  })
  | void
> {
  try {

    const searchString = payload.externalProjects
      ? {
        queriedFields: "external_projects",
      }
      : {};
    const search = qs.stringify(searchString);
    const response: AxiosResponse<SyncRequestType> = yield call(
      get,
      `/projects/${payload.projectId}/sync?${search}`
    );
    const data: SyncRequestType = camelize(response.data);
    if (data.settings.isUpdateInprogress) {
      yield put(startSyncInfinite(payload.projectId));
    } else {
      yield put(
        getDelayedProjectGoodsWithSearch({ projectId: payload.projectId })
      );
    }
    if (payload.externalProjects) {
      yield put(triggerUpdateSyncCache(payload.projectId))
    }
    return {
      ...data,
      projectId: payload.projectId,
    };
  } catch (e) {
    yield put(handleServerError(e));
  }
});

const updateSyncCacheSaga = bindAsyncAction(updateSyncCache)(
  function* ({ payload }): SagaIterator {
    yield call(putRequest, `/projects/${payload}/sync`)
  }
)

export function* startSyncInfiniteSaga({ payload }: Action<string>) {
  const infinite = yield fork(syncInfinite, payload);
  // in case we need to cancel from somewhere else
  yield race({
    stop: take(stopSyncInfinite.type),
  });
  yield cancel(infinite);
}

export function* syncInfinite(payload: string) {
  try {
    while (true) {
      const response: AxiosResponse<SyncRequestType> = yield call(
        get,
        `/projects/${payload}/sync`
      );
      const data: SyncRequestType = camelize(response.data);
      if (!data.settings.isUpdateInprogress) {
        // stop bg sync
        yield put(
          stopSyncInfinite({
            result: {
              ...data,
              projectId: payload,
            },
          })
        );
      }
      yield delay(3000);
    }
  } finally {
    yield put(
      getProjectGoodsWithSearch({
        projectId: payload,
      })
    );
  }
}

export const updateProjectSyncSaga = bindAsyncAction(updateProjectSync)(
  function* ({ payload }): SagaIterator<SyncSettingsType | void> {
    const hashtags = payload?.hashtags?.split("#") || null;
    const policy = Number(payload.policy);
    const type = Number(payload.type);
    const source =
      payload.sourceSelect && payload.sourceSelect !== "other"
        ? payload.sourceSelect
        : formatUrl(payload.otherSource);

    const provider = getProviderFromString(source)

    const body = {
      ...payload,
      hashtags,
      policy,
      source,
      type,
      provider
    };

    try {
      yield call(post, `/projects/${payload.projectId}/sync`, snakeize(body));
      if (!type) {
        yield put(triggerImportProjectSync(payload.projectId))
      }
      return body;
    } catch (e) {
      yield put(handleServerError(e));
    }
  }
);

export const updateProjectSyncWithoutImportSaga = bindAsyncAction(updateProjectSyncWithoutImport)(
  function* ({ payload }): SagaIterator<SyncSettingsType | void> {
    const hashtags = payload?.hashtags?.split("#") || null;
    const policy = Number(payload.policy);
    const type = Number(payload.type);
    const source =
      payload.sourceSelect && payload.sourceSelect !== "other"
        ? payload.sourceSelect
        : formatUrl(payload.otherSource);

    const provider = getProviderFromString(source)

    const body = {
      ...payload,
      hashtags,
      policy,
      source,
      type,
      provider
    };

    try {
      yield call(post, `/projects/${payload.projectId}/sync`, snakeize(body));
      return body;
    } catch (e) {
      yield put(handleServerError(e));
    }
  }
);

export const importProjectSyncSaga = bindAsyncAction(importProjectSync)(
  function* ({ payload }): SagaIterator {
    try {
      yield call(post, `/projects/${payload}/sync/import`);
      yield put(
        triggerGetProjectSync({
          projectId: payload,
        })
      );
      return {};
    } catch (e) {
      yield put(handleServerError(e));
    }
  }
);

export default function* () {
  yield takeEvery(triggerGetProjectSync.type, getProjectSyncSaga);
  yield throttle(3000, triggerUpdateProjectSync.type, updateProjectSyncSaga);
  yield throttle(3000, triggerUpdateProjectSyncWithoutImport.type, updateProjectSyncWithoutImportSaga);
  yield throttle(3000, triggerImportProjectSync.type, importProjectSyncSaga);
  yield takeEvery(startSyncInfinite.type, startSyncInfiniteSaga);
  yield takeEvery(triggerUpdateSyncCache, updateSyncCacheSaga)
}
