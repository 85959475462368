import React, { Suspense, memo } from 'react'
import ProjectGoodShimmer from 'src/modules/projects/components/ProjectGoodShimmer'

const EditGood = React.lazy(() => import('src/modules/projects/containers/ProjectEditGood'))

const ProjectEditGood = () => {
  return (
    <Suspense fallback={<ProjectGoodShimmer />}>
      <EditGood />
    </Suspense>
  )
}

export default memo(ProjectEditGood)
