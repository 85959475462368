import axios from 'axios'

export const request = axios.create()

request.defaults.baseURL = `/api`
request.defaults.headers['Content-Type'] = 'application/json; charset=UTF-8'
request.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'

export const setupToken = token => {
  request.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const resetToken = () => {
  request.defaults.headers.common.Authorization = undefined
}

request.interceptors.request.use(
  request => {
    if (request.url.includes('https://s3')){
      request.headers['Content-Type'] = "multipart/form-data"
      request.headers.put['Content-Type'] = "multipart/form-data"
      delete request.headers.common.Authorization
      delete request.headers.common.Accept

    }
    return request
  },
  error => {
    console.log(error)
  }
)

request.interceptors.response.use(
  response => response,
  error => {
    // if (error.response.status === 401) {
    //   // TODO Navigate to Login
    // }
    return Promise.reject(error)
  }
)
