import React, { Fragment } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { newLoading } from 'hopshopui'

const CategoriesShimmer = () => {
  return (
    <Fragment>
      <ShimmerGlobalStyles />
      <Container>
        <ShimmerItem />
        <ShimmerItem />
        <ShimmerItem />
      </Container>
    </Fragment>
  )
}

const ShimmerGlobalStyles = createGlobalStyle`
  body {
    background: ${props => props.theme.colors.mildBackground};
    min-height: auto;
    height: 100%;
  }
`

const Container = styled.div`
  margin-top: 50px;
`

const ShimmerItem = styled.div`
  height: 60px;
  max-width: 500px;
  margin: 20px auto;
  ${newLoading};
  border-radius: 10px;
`



export default CategoriesShimmer