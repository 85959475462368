import React, { Suspense } from 'react'
import ProjectGoodShimmer from 'src/modules/projects/components/ProjectGoodShimmer'

const EditProperty = React.lazy(() => import('src/modules/projects/containers/ProjectEditProperty'))

const ProjectEditProperty = () => {
  return (
    <Suspense fallback={<ProjectGoodShimmer />}>
      <EditProperty />
    </Suspense>
  )
}

export default ProjectEditProperty
