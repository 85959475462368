import React from 'react'
import { string, func, object, array } from 'prop-types'
import { PopupCategories as PopupCategoriesElement } from 'src/elements'

const PopupCategories = ({
  active,
  categories,
  onClose,
  onAccept,
  seeAllLabel,
  onAdminClick,
}) => {
  return (
    <PopupCategoriesElement 
      categories={categories}
      active={active}
      onSetActive={onAccept}
      onDeny={onClose}
      onAdminClick={onAdminClick}
      seeAllLabel={seeAllLabel}
    />
  )
}

PopupCategories.propTypes = {
  addCategory: func,
  editCategory: func,
  deleteCategory: func,
  onClose: func.isRequired,
  onAccept: func.isRequired,
  active: string,
  activeCategoryShape: object,
  projectId: string.isRequired,
  categories: array,
  seeAllLabel: string,
}

export default PopupCategories