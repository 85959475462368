import React, { Fragment, Suspense, useEffect } from 'react'
import { match } from 'src/utils/propTypes'
import { useSelector } from 'react-redux'
import { func, bool } from 'prop-types'

import getImageUrl from 'src/utils/getImageUrl'
import { selectActiveProject, selectFavoriteProjectGoods } from 'src/store/projects'
import { useFavorite } from 'src/templates/utils/hooks/useFavorites'
import { TemplateGlobalStyles } from 'src/elements/styles/TemplateGlobal'
import TemplateProvider from 'src/providers/TemplateProvider'
import { getTemplateFooter, getTemplateHeader } from 'src/utils/getTemplate'
import ItemLink from 'src/elements/template/ItemLink'

const DefaultTemplate = React.lazy(() => import('../../../templates/default/favorites'))

const getTemplate = (template, props) => {
  switch (template) {
    default:
      return <DefaultTemplate {...props} />
  }
}

const ProjectFavoritesPage = ({
  match,
  loading,
  getProjectGoodsByIds,
}) => {
  const { id } = match.params
  const project = useSelector(state => selectActiveProject(state, id))
  const [favorites] = useFavorite()
  const goods = useSelector(state => selectFavoriteProjectGoods(state, id, favorites))

  useEffect(() => {
    const keys = Object.keys(favorites)
    if (keys.length > 0) {
      getProjectGoodsByIds(keys)
    }
  }, [favorites])
  return (
    <TemplateProvider>
      <Fragment>
        <TemplateGlobalStyles />
        {/* TODO: add loading */}
        <Suspense fallback={''}>
          {getTemplateHeader('template', { project, favActive: true })}
        </Suspense>
        <Suspense fallback={''}>
          {getTemplate('default', { project, getImageUrl, goods, loading, link: ItemLink })}
        </Suspense>
        <Suspense fallback={''}>
          {getTemplateFooter('default', { title: project.title, isAdult: project.isAdult || project.hasAdultGoods, mobileHide: true })}
        </Suspense>
      </Fragment>
    </TemplateProvider>
  )
}

ProjectFavoritesPage.propTypes = {
  match: match,
  getProjectGoodsByIds: func.isRequired,
  loading: bool
}


export default ProjectFavoritesPage