import {
  GoodType,
  GoodVariantType,
  PropertySetType,
  PropertySetTypeNested,
  GoodCardType,
  ServiceGood,
  ServiceGoodCalendar,
  GoodImageEditType,
  VideoBlockType,
  ImageBlockType,
  TextBlockType,
  GoodBlockType,
  ButtonBlockType,
  HTMLBlockType,
} from "src/templates/utils/types/goodType";
import actionCreatorFactory, { Action } from "typescript-fsa";

export type ContentPageBlock = (
  | VideoBlockType
  | ImageBlockType
  | TextBlockType
  | GoodBlockType
  | HTMLBlockType
  | ButtonBlockType
  )

export type GoodMappedType = Omit<GoodType, "variants"> & {
  variants: GoodVariantMappedType[];
  serviceGoods: Array<ServiceGood>;
  hasUnsavedChanges?: boolean;
  isAvailable: boolean;
};

export type GoodStateType = {
  readonly loading: boolean;
  readonly data: Record<string, GoodMappedType>;
  readonly properties: Record<
    string,
    { hasUnsavedData?: boolean; data: Record<string, PropertySetTypeMapped> }
  >;
  readonly listData: Record<number, GoodCardType>;
  readonly listIds: number[];
  readonly goodsSetDataMap: Record<string, GoodCardType[]>;
  readonly serviceDayCalendar: ServiceGoodCalendar;
  
};

export type GoodVariantMappedOption = {
  property1CustomId?: string;
  property2CustomId?: string;
};

export type GoodVariantMappedType = GoodVariantType & GoodVariantMappedOption;

export type PropertySetNestedMapped = PropertySetTypeNested & {
  canImpactPrice: boolean;
  canImpactDescription: boolean;
  canImpactQuantity: boolean;
  canImpactDigital: boolean;
  setCustomId: string | number;
};

export type GoodFormVariantType = GoodVariantMappedType & {
  canImpactPrice: boolean;
  canImpactDescription: boolean;
  canImpactQuantity: boolean;
  canImpactDigital: boolean;
};

export type GoodFormInitialValues = Omit<GoodMappedType, "variants"> & {
  variants: GoodFormVariantType[];
  serviceGoods: Array<ServiceGood>;
  splitBookingOptions?: boolean;
  discount?: string;
  cleanSeo?: boolean;
};

export type PropertySetTypeMapped = PropertySetType & {
  customSetId: string | number;
};

const actionCreator = actionCreatorFactory();

export const triggerGetGoodDetail = actionCreator<{
  projectId: string;
  alias: string;
  geRawData?: boolean;
}>("goods/TRIGGER_GET_GOOD_DETAIL");

export const getGoodDetail = actionCreator.async<
  Action<{
    projectId: string;
    alias: string;
    geRawData?: boolean;
  }>,
  {
    alias: string;
    data: GoodMappedType;
    isForPublic: boolean;
    properties: PropertySetTypeMapped[];
  }
>("goods/GET_GOOD_DETAIL");

export type UpdateGoodDetailPayload = {
  projectId: string;
  alias: string;
  goodId: number;
  data: GoodFormInitialValues;
  finishUploadPayload?: {
    fileId: number;
}[]; 
};

export const triggerUpdateGoodDetail = actionCreator<UpdateGoodDetailPayload>(
  "goods/TRIGGER_UPDATE_GOOD_DETAIL_PAYLOAD"
);
export const updateGoodDetail = actionCreator.async<
  Action<UpdateGoodDetailPayload>,
  {
    alias: string;
    data: GoodFormInitialValues;
  }
>("goods/UPDATE_GOOD_DETAIL_PAYLOAD");

type TriggerAddGoodPayload = GoodFormInitialValues & {
  projectId: string;
  finishUploadPayload?: {
    fileId: number;
}[]; 
};

export const triggerAddGood = actionCreator<TriggerAddGoodPayload>(
  "goods/TRIGGER_ADD_GOOD"
);
export const addGood = actionCreator.async<
  Action<TriggerAddGoodPayload>,
  {
    alias: string;
    data: GoodMappedType;
  }
>("goods/ADD_GOOD");

export type DeleteGoodPayload = {
  projectId: string;
  goodId: number;
  alias: string;
  callback?: () => void;
};

export const triggerDeleteGood = actionCreator<DeleteGoodPayload>(
  "goods/TRIGGER_DELETE_GOOD_PAYLOAD"
);
export const deleteGood = actionCreator.async<
  Action<DeleteGoodPayload>,
  string
>("DELETE_GOOD_PAYLOAD");

export const triggerArchiveGood = actionCreator<DeleteGoodPayload>(
  "goods/TRIGGER_ARCHIVE_GOOD"
);
export const archiveGood = actionCreator.async<
  Action<DeleteGoodPayload>,
  string
>("goods/ARCHIVE_GOOD");

type SetPropertyPayload = { alias?: string; data: PropertySetTypeMapped };

export const savePropertySet = actionCreator<SetPropertyPayload>(
  "goods/SAVE_PROPERTY_SET"
);

export const triggerGenerateVariants = actionCreator<SetPropertyPayload>(
  "goods/TRIGGER_GENERATE_VARIANTS"
);

export const deleteProperty = actionCreator<{
  alias: string;
  id: string;
}>("goods/DELETE_PROPERTY");

export const triggerDeletePropertyAndVariants = actionCreator<{
  alias: string;
  id: string;
}>("goods/TRIGGER_DELETE_PROPERTY_AND_VARIANTS");

export const deletePropertyAndVariants = actionCreator.async<
  Action<{
    alias: string;
    id: string;
  }>,
  {
    alias: string;
    variants: GoodVariantMappedType[];
  }
>("goods/DELETE_PROPERTY_AND_VARIANTS");

export const generateVariants = actionCreator.async<
  Action<SetPropertyPayload>,
  {
    alias: string;
    variants: GoodVariantMappedType[];
  }
>("goods/GENERATE_VARIANTS");

export const saveGoodDraft = actionCreator<{
  alias: string;
  good: GoodFormInitialValues;
}>("goods/SAVE_GOOD_DRAFT");

export const deleteGoodDraft = actionCreator<{ alias: string }>(
  "goods/DELETE_GOOD_DRAFT"
);

export const triggerGetGoodsByIds = actionCreator<number[]>(
  "goods/TRIGGER_GET_GOODS_BY_IDS"
);
export const getGoodsByIds = actionCreator.async<
  Action<number[]>,
  GoodCardType[]
>("goods/GET_GOODS_BY_IDS");

type GetGoodsSetPayload = {
  domain: string;
  aliasType: "Goods" | "Page";
  alias: string;
};
export const triggerGetGoodsSet = actionCreator<GetGoodsSetPayload>(
  "goods/TRIGGET_GET_GOODS_SET"
);
export const getGoodsSet = actionCreator.async<
  Action<GetGoodsSetPayload>,
  {
    moduleId: string;
    goods: GoodCardType[];
  }[]
>("goods/GET_GOODS_SET");

type GetGoodsSetByIdsPayload = {
  projectId: string | number;
  aliasType: "Goods" | "Page";
  identifier: string;
};
export const triggerGetGoodsSetByIds = actionCreator<GetGoodsSetByIdsPayload>(
  "goods/TRIGGET_GET_GOODS_SET_BY_IDS"
);
export const getGoodsSetByIds = actionCreator.async<
  Action<GetGoodsSetByIdsPayload>,
  {
    moduleId: string;
    goods: GoodCardType[];
  }[]
>("goods/GET_GOODS_SET_BY_IDS");

export type GoodEditRangePayload = {
  goodsIds: number[];
  exceptGoodsIds: number[];
  projectId: string;
  statusId: number;
  callback?: () => void;
  rangeEditType: "status" | "delete";
  activeCategory?: number;
  allSelected: boolean; 
};


export const triggerEditRange = actionCreator<GoodEditRangePayload>(
  "goods/TRIGGER_GOODS_RANGE_EDIT"
);
export const editRange = actionCreator.async<
  Action<GoodEditRangePayload>,
  void
>("goods/GOODS_RANGE_EDIT");

export const triggerGetServiceGoodCalendar = actionCreator<{bookingOptionId: string; startDate: string; size: number;}>("goods/TRIGGER_GET_SERVICE_GOOD_CALENDAR");

export const getServiceGoodCalendar = actionCreator.async<
  Action<{bookingOptionId: string; startDate: string; size: number;}>,
  ServiceGoodCalendar
>("goods/GET_SERVICE_GOOD_CALENDAR");

export const triggerGetServiceGoodCalendarEndDate = actionCreator<{bookingOptionId: string; startDate: string; size: number;}>("goods/TRIGGER_GET_SERVICE_GOOD_CALENDAR_END_DATE");

export const getServiceGoodCalendarEndDate  = actionCreator.async<
  Action<{bookingOptionId: string; startDate: string; size: number;}>,
  ServiceGoodCalendar
>("goods/GET_SERVICE_GOOD_CALENDAR_END_DATE");

export const triggerDuplicateMainImage = actionCreator<{newImage: GoodImageEditType; index: number; id?: string; }>(
  "goods/TRIGGER_DUPLICATE_MAIN_IMAGE"
);

export const triggerDuplicateVariantsImage = actionCreator<{newImage: GoodImageEditType; index: number; id?: string; }>(
  "goods/TRIGGER_DUPLICATE_VARIANTS_IMAGE"
);

export const triggerDuplicateModule = actionCreator<{contentBlock: ContentPageBlock; index: number; id?: string;}>(
  "goods/TRIGGER_DUPLICATE_MODULE"
);

export const triggerDeleteAddedGood = actionCreator(
  "goods/TRIGGER_DELLETE_ADD_GOOD"
);