const currencies = {
  'RUR': '₽',
  'USD': '$',
  'EUR': '€',
  "BTC": "₿",
  "AMD": "֏",
  "ILS": "₪",
  "KZT": "₸",
  "KGS": "с",
  "UZS": "сўм",
  "BYN": "Br",
  "AZN": "₼",
  "TJS": "с.",
  "UAH": "₴",
  "GEL": "₾",
  "USDT": "₮",
}

export const getCurrency = (currency) => {
  if (currency) {
    return currencies[currency]
  } else {
    return currencies['RUR']
  }
}