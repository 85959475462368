import React, { useEffect, useRef, MutableRefObject, useCallback } from "react";
import { getEnvWSUrl } from "src/utils/env";
import { accessToken } from "src/utils/localStorage";
import { camelize, snakeize } from "casing";
import { useDispatch } from "react-redux";
import { showOrderNotification } from "src/store/notification";
import { triggerGetPS } from 'src/store/paymentSystems'

type SocketMessage<T> = {
  messageId: string;
  message: T;
};

export type OrderMessage = {
  actionId: "NewOrder";
  projectId: string;
  orderIds: string[];
};

export type CapustaMessage = {
  actionId: "CapustaToken",
  projectId: string
}

export const SocketProvider = () => {
  const wsRef: MutableRefObject<WebSocket | undefined> = useRef();
  const reconnectDelayRef: MutableRefObject<number> = useRef(5000);
  const dispatch = useDispatch()
  const sendMessage = useCallback((messageId: string) => {
    const deliveredMessage = {
      messageId,
      ok: true
    };
    wsRef.current?.send(JSON.stringify(snakeize(deliveredMessage)));
  }, [wsRef])
  const handleOnMessage = useCallback((event: MessageEvent) => {
    const message: SocketMessage<any> = camelize(JSON.parse(event.data));
    const messageData = camelize(JSON.parse(message.message))
    switch (messageData.actionId) {
      case 'NewOrder':
        dispatch(showOrderNotification(messageData as OrderMessage))
        break
      case 'CapustaToken':
        const capustaMessage = messageData as CapustaMessage
        dispatch(triggerGetPS(capustaMessage.projectId))
        break
    }
    sendMessage(message.messageId)
  }, [sendMessage, dispatch]);
  const connectSocket = useCallback(() => {
    const url = getEnvWSUrl();
    const authToken = localStorage.getItem(accessToken);
    if (url && authToken) {
      wsRef.current = new WebSocket(url, [authToken]);
      if (wsRef.current) {
        wsRef.current.onopen = (event) => {
          reconnectDelayRef.current = 5000;
        };
        wsRef.current.onerror = (event) => {
          console.log("error", event);
        };
        wsRef.current.onclose = () => {
          setTimeout(() => {
            connectSocket();
          }, reconnectDelayRef.current);
          reconnectDelayRef.current = reconnectDelayRef.current + 5000;
        };
        wsRef.current.onmessage = handleOnMessage;
      }
    }
  }, [wsRef, reconnectDelayRef, handleOnMessage]);

  useEffect(() => {
    connectSocket();
  }, [connectSocket]);

  return <div></div>;
};
