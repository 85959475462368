import React, { Fragment, Suspense, useCallback } from 'react'
import styled from 'styled-components'

import { A, fullHeight, P2, size } from 'hopshopui'
import history from 'src/utils/browserHistory'

import VkShimmer from 'src/modules/projects/components/VkShimmer'
import { VkHeader, VkStyledArrow } from 'src/elements'
import AuthGlobalStyles from 'src/modules/auth/components/AuthGlobalStyles'
import { paymentAgree, finishProjectData } from 'src/utils/localStorage'
import { func, object, bool } from 'prop-types'
import { useT } from 'src/hooks/useT'
import { CardComponentStyled, Container } from '../auth/AuthPage'

const AuthPayment = React.lazy(() => import('src/modules/projects/components/AuthPayment'))

const Payments = ({
  importVkProject,
  createProject,
  // finishProjectData,
  loading
}) => {
  const { t } = useT()

  const handleBack = useCallback(() => {
    history.goBack()
  }, [])

  const finishProject = useCallback((isAgree) => {
    let projectData
    try {
      const data = localStorage.getItem(finishProjectData)
      if (data) {
        projectData = JSON.parse(data)
      } else {
        history.push('/')
      }
    } catch (e) {
      history.push('/')
    }

    if (projectData.social) {
      importVkProject({ ...projectData.data, AgreeToAcceptCards: isAgree })
    } else {
      createProject({ ...projectData.data, AgreeToAcceptCards: isAgree, history })
    }
  }, [createProject, importVkProject])

  const onYesClick = useCallback(() => {
    localStorage.setItem(paymentAgree, 'yes')
    finishProject(true)
  }, [finishProject])

  const onNoClick = useCallback(() => {
    finishProject(false)
  }, [finishProject])
  return (
    <Fragment>
      <AuthGlobalStyles />
      <Background>
        <StyledContainer>
           
          <HeaderWrapper>
              <VkStyledArrow onClick={handleBack} />
            <VkHeader>
              {t('auth.payment.cardHeader')}
            </VkHeader>
          </HeaderWrapper>
          <Suspense fallback={<VkShimmer />}>
            <CardComponentStyled>
              <AuthPayment onYesClick={onYesClick} onNoClick={onNoClick} loading={loading} />
            </CardComponentStyled>
          </Suspense>
          <TermsContainer>
            <TermsText>
              {t('auth.payment.terms') + ' '}<TermsLink href="https://docs.capusta.space/Agreement.pdf" target="_blank">{t('auth.payment.termsLink') + '.'}</TermsLink>
            </TermsText>
          </TermsContainer>
        </StyledContainer>
      </Background>
    </Fragment>
  )
}

Payments.propTypes = {
  importVkProject: func,
  createProject: func,
  finishProjectData: object,
  loading: bool
}

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 500px;
    @media screen and (max-width: ${size.mobile}) {
      width: unset;
      button{
        display: none;
      }
    }
`;

const Background = styled.div`
  background-color: ${props => props.theme.colors.black};
  ${fullHeight};
  color: white;
  position: relative;
  overflow: hidden;
`

const TermsContainer = styled.div`
  max-width: 360px;
  width: 100%;
  padding-top: 20px;
  margin: 0 auto;
  @media screen and (max-width: ${size.mobile}){
    padding: 10px 0 0 10px;
    width: 300px;

  }
`

const TermsText = styled(P2)`
  opacity: 0.5;
  max-width: 360px;
`

const TermsLink = styled(A)`
  color: inherit;
`

const StyledContainer = styled(Container)`
  justify-content: unset;
`;

export default Payments