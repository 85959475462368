import React, { useEffect, useState, Suspense, useCallback, Fragment } from 'react'
import styled from 'styled-components'
import { func, bool, arrayOf } from 'prop-types'
import { fullHeight, useHasScroll } from 'hopshopui'
import find from 'lodash/find'
import { Grid } from 'react-flexbox-grid'

import {useT} from 'src/hooks/useT'
import AuthGlobalStyles from 'src/modules/auth/components/AuthGlobalStyles'
import { VkStyledArrow, VkHeader } from 'src/elements'
import { VkProjecType, history } from 'src/utils/propTypes'
import VkShimmer from 'src/modules/projects/components/VkShimmer'
import { instLogin, okLogin } from 'src/utils/localStorage'
import { providers } from 'src/utils/variables'
import { useSignout } from 'src/hooks/signout'
import { HeaderWrapper } from '../payment/Payment'
import { size } from 'hopshopui'

const VkProjectList = React.lazy(() => import('src/modules/projects/components/VkProjectsList'))


const ImportProject = ({ getProjectList, projects, loading, history,  importVkProject, setFinishProjectData }) => {
  const [active, setActive] = useState(null)
  const [activeProject, setActiveProject] = useState(null)
  const signout = useSignout()
  const { t } = useT()

  const handleSelect = useCallback((id) => {
    setActive(id)
    const project = find(projects, ['screen_name', id])
    setActiveProject(project)
    return project
  }, [projects, setActiveProject])

  console.log(activeProject, 'activeProject')

  const handleImport = useCallback((activeProjectProps) => {
    if (activeProjectProps.any_goods !== false) {
      history.push('/import/goods/' + activeProjectProps.id)
    } else {
      // importVkProject({ goods: [], projectId: activeProjectProps.screen_name, history })
      setFinishProjectData({ social: true, data: { goods: [], projectId: activeProjectProps.screen_name, history } })
      history.push('/payment')
    }
  }, [importVkProject, history, setFinishProjectData])

  useEffect(() => {
    const inst = !!localStorage.getItem(instLogin)
    const ok = !!localStorage.getItem(okLogin)
    let provider
    if (inst) {
      provider = providers.inst
    }
    if(ok) {
      provider = providers.ok
    }
    getProjectList(provider)
  }, [getProjectList])

  useEffect(() => {
    if (projects && projects.length === 1) {
      const newActiveProject = handleSelect(projects[0].screen_name)
      handleImport(newActiveProject)
    }
  }, [projects, handleSelect, handleImport])
  const [hasScroll] = useHasScroll(projects)

  const handleImportClick = () => {
    handleImport(activeProject)
  }

  const skipProjectChoosing = () =>{
    history.push('/createProject')
  }

  return (
    <Fragment>
      <AuthGlobalStyles />
      <Page>
      <PageContainer>
        <StyledContainer hasScroll={hasScroll}>
          <StyledHeaderWrapper>
            <VkStyledArrow onClick={() => signout({ history })} />
            <VkHeader>{t('project.whichProjectStart')}</VkHeader>
          </StyledHeaderWrapper>
          <Suspense fallback={<VkShimmer />}>
            <VkProjectList
              active={active}
              projects={projects}
              loading={loading}
              onSelect={handleSelect}
              handleImport={handleImportClick}
              skipProjectChoosing={skipProjectChoosing}
            />
          </Suspense>
        </StyledContainer>
        </PageContainer>
      </Page>
    </Fragment>
  )
}

ImportProject.propTypes = {
  history: history.isRequired,
  loading: bool.isRequired,
  getProjectList: func.isRequired,
  projects: arrayOf(VkProjecType).isRequired,
  importVkProject: func.isRequired,
  signout: func.isRequired,
  setFinishProjectData: func.isRequired
}

const Page = styled.div`
  background-color: ${props => props.theme.colors.black};
  ${fullHeight};
  align-items: center;
`
const PageContainer = styled.div`
  width: 600px;
  @media screen and (max-width: ${size.mobile}) {
    width: unset;
  }
`;

const StyledContainer = styled(Grid)`
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
  && {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
    width: 100%;
  }
  @media screen and (max-width: ${size.mobile}) {
  && {
    padding: 0;
  } 
  }
`

const StyledHeaderWrapper = styled(HeaderWrapper)`
  width: unset;
  justify-content: center;
`;

export default ImportProject