import React, { Suspense } from 'react'
import ProjectGoodShimmer from 'src/modules/projects/components/ProjectGoodShimmer'

const AddGood = React.lazy(() => import('src/modules/projects/containers/ProjectAddGood'))

const ProjectAddGood = () => {
  return (
    <Suspense fallback={<ProjectGoodShimmer />}>
      <AddGood />
    </Suspense>
  )
}

export default ProjectAddGood
