import React, {
  memo,
  FC,
  useCallback,
  ChangeEvent,
  FormEvent,
  useMemo,
  MouseEvent,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  PillCard,
  PillMarginContainer,
  PillLineItem,
  SmallTogleContainer,
  PillLineLabel,
  Toggle,
  PillHorizontalContainer,
  InputPill,
  Divider,
  HeaderText,
  DoneIcon,
  PillSelectLabel,
  Select, ArrowLeftIcon, AddGoodButton,
} from "hopshopui";
import styled from "styled-components";
import DesignColorPickerInput from "./DesignColorPickerInput";
import { DesignHeader } from "src/elements/layout/DesignHeader";
import { useT } from "src/hooks/useT";
import { ConnectedDesignButtonFormProps } from "../containers/DesignButtonForm";
import { DesignFormContainer } from "./DesignHeaderForm";

const DesignButtonForm: FC<ConnectedDesignButtonFormProps> = ({
  buttonDesign,
  content,
  triggerPushButtonDesignSettings,
  match,
  item,
  loading,
  setModuleButtonDesign,
  checkAndDisableDesignType,
}) => {
  const { t } = useT();
  const projectId = useMemo(() => {
    return match.params.id;
  }, [match]);
  const formRef = useRef<HTMLDivElement | null>(null);
  const [scrollTop, setScrollTop] = useState(0); 
  const scrollListener = (e: Event) =>{
    setScrollTop((e.target as HTMLElement)?.scrollTop)
  }
  useEffect(() => {
    if(!formRef.current) return 
    const el = formRef.current;
    el.addEventListener("scroll", (e) => scrollListener(e))
    return () =>{
      el.removeEventListener("scroll", (e) => scrollListener(e))
    }
  }, [])

  const isSecondButton = useMemo(() => buttonDesign?.addSecondButton, [buttonDesign]);

  const [settingsBarHeight, setSettingsBarHeight] = useState(500);

  useEffect(() => {
    setSettingsBarHeight(window.innerHeight - 70 )
  }, []);

  const toggleIsBackground = useCallback(
    (e:  React.ChangeEvent<HTMLInputElement>) => {
    
    if (buttonDesign){  
      if (e.currentTarget.checked){
        setModuleButtonDesign({
          ...buttonDesign,
          customization: {
            ...buttonDesign.customization,
            bgColor: "#ffff"
          }
        })
      } else if (!e.currentTarget.checked) {
        setModuleButtonDesign({
          ...buttonDesign,
          customization: {
            ...buttonDesign.customization,
            bgColor: 'transparent',
            useGradient: false,
          }
        })
      }
     
    }
    }, [buttonDesign, setModuleButtonDesign]);

  const handleButtonColorChange = useCallback(
    (value: string, property: string, field?: 0 | 1) => {
      if (buttonDesign) {
        if (Object.is(field, 0)) {
          setModuleButtonDesign({
            ...buttonDesign,
            customization: {
              ...buttonDesign.customization,
              firstButtonCustomization: {
                ...buttonDesign.customization.firstButtonCustomization,
                [property]: value,
              }
            }
          })
        } else if (Object.is(field, 1)) {
          setModuleButtonDesign({
            ...buttonDesign,
            customization: {
              ...buttonDesign.customization,
              secondButtonCustomization: {
                ...buttonDesign.customization.secondButtonCustomization,
                [property]: value,
              }
            }
          })
        } else {
          setModuleButtonDesign({
            ...buttonDesign,
            customization: {
              ...buttonDesign.customization,
              [property]: value,
            }
          })
        }
      }
    },
    [buttonDesign, setModuleButtonDesign]
  );

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, property: string, field?: 0 | 1 | 2) => {
      const value = Number(e.target.value);
        if (!buttonDesign) return
      if (Object.is(field, 0)) {
        setModuleButtonDesign({
          ...buttonDesign,
          customization: {
            ...buttonDesign.customization,
            firstButtonCustomization: {
              ...buttonDesign.customization.firstButtonCustomization,
              [property]: value,
            }
          }
        })
      } else if (Object.is(field, 1)) {
        setModuleButtonDesign({
          ...buttonDesign,
          customization: {
            ...buttonDesign.customization,
            secondButtonCustomization: {
              ...buttonDesign.customization.secondButtonCustomization,
              [property]: value,
            }
          }
        })
      } else if (Object.is(field,2)) {
        setModuleButtonDesign({
          ...buttonDesign,
          customization: {
            ...buttonDesign.customization,
            secondButtonCustomization: {
              ...buttonDesign.customization.secondButtonCustomization,
              [property]: value,
            },
            firstButtonCustomization: {
              ...buttonDesign.customization.firstButtonCustomization,
              [property]: value,
            }
          }
        })
      } else {
        setModuleButtonDesign({
          ...buttonDesign,
          customization: {
            ...buttonDesign.customization,
            [property]: value
          }
        })
      }
    }, [setModuleButtonDesign, buttonDesign]);

  const handleMarginChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, property: string, field: 0 | 1) => {
      const value = e.target.value;
      const nuberArray = value.trim().split(" ")
      let isVailid = true;
      nuberArray.forEach(i => {
        if (isNaN(Number(i))){
          isVailid = false
        }
      })
      if (!buttonDesign || !isVailid || (nuberArray.length > 4)) return
      setModuleButtonDesign({
        ...buttonDesign,
        customization: {
          ...buttonDesign.customization,
          firstButtonCustomization: {
            ...buttonDesign.customization.firstButtonCustomization,
            [property]: value,
          },
          secondButtonCustomization: {
            ...buttonDesign.customization.secondButtonCustomization,
            [property]: value,
          }
        }
      })
    }, [setModuleButtonDesign, buttonDesign]);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!buttonDesign)return
      triggerPushButtonDesignSettings({ ...buttonDesign, projectId: parseInt(projectId) });

    },
    [triggerPushButtonDesignSettings, projectId, buttonDesign]
  );
  
const toggleField = useCallback(() =>{
  if (buttonDesign) {
    setModuleButtonDesign({
      ...buttonDesign,
      applyToAll: !buttonDesign.applyToAll, 
    });
  }
}, [setModuleButtonDesign, buttonDesign]);

  const handleDecline = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (buttonDesign) {
        checkAndDisableDesignType()
      }
    },
    [checkAndDisableDesignType, buttonDesign]
  );

  const customization = useMemo(() => {
    return buttonDesign?.customization
  }, [buttonDesign]);

  return (
    <form onSubmit={handleSubmit}>
      <DesignHeader  isForm={true}>
        <StyledBackButton onClick={handleDecline} >
          <ArrowLeftIcon fill="#000000"/>
        </StyledBackButton>
        <HeaderTextStyled>{t('project.addgood.blocks.button')}</HeaderTextStyled>
        <StyledDoneButton>
          <DoneIcon stroke="#000000" />
        </StyledDoneButton>
      </DesignHeader>
      <DesignFormContainerWrapper height={settingsBarHeight} ref={formRef}>
        <PillCard white>
          <PillMarginContainer>
            {isSecondButton
              && <PillLineItem>
                <Title>{t("project.addgood.blocks.button")} 1</Title>
              </PillLineItem>
            }
            <PillHorizontalContainer itemCount={2}>
              <DesignColorPickerInput
                value={customization?.firstButtonCustomization.color || ""}
                onChange={(e) => handleButtonColorChange(e, `color`, 0)}
                placeholder={t("design.placeholderDefault")}
                label={t("goodDesign.buttonColor")}
                scrollTop={scrollTop}
              />
              <DesignColorPickerInput
                value={customization?.firstButtonCustomization.onHoverColor || ""}
                onChange={(e) => handleButtonColorChange(e, `onHoverColor`, 0)}
                placeholder={t("design.placeholderDefault")}
                label={t("goodDesign.colorHover")}
                scrollTop={scrollTop}
              />
            </PillHorizontalContainer>
            <Divider />
            <PillHorizontalContainer itemCount={2}>
              <DesignColorPickerInput
                value={customization?.firstButtonCustomization.textColor || ""}
                onChange={(e) => handleButtonColorChange(e, `textColor`, 0)}
                placeholder={t("design.placeholderDefault")}
                label={t("goodDesign.textColor")}
                scrollTop={scrollTop}
              />
              <InputPill
                label={t("goodDesign.roundingLabel")}
                value={String(customization?.firstButtonCustomization.borderRadius)}
                placeholder={String(customization?.firstButtonCustomization.borderRadius)}
                onChange={(e) => handleInputChange(e, 'borderRadius', 0)}
                onBlur={() => { }}
                errors={{}}
                touched={{}}
                name="logorounding"
                disabled={false}
                type="number"
              />
            </PillHorizontalContainer>
            <Divider />
            {!isSecondButton
              && <PillHorizontalContainer itemCount={2}>
                <InputPill
                  label={t("goodDesign.maxWidth")}
                  value={String(customization?.firstButtonCustomization.width) || ""}
                  placeholder={String(customization?.firstButtonCustomization.width) || ""}
                  onChange={(e) => handleInputChange(e, 'width', 2)}
                  onBlur={() => { }}
                  errors={{}}
                  touched={{}}
                  name="logorounding"
                  disabled={false}
                  type="number"
                />
                <InputPill
                  label={t("goodDesign.marginInButton")}
                  value={String(customization?.firstButtonCustomization.padding) || ""}
                  placeholder={String(customization?.firstButtonCustomization.padding) || ""}
                  onChange={(e) => handleMarginChange(e, 'padding', 0)}
                  onBlur={() => { }}
                  errors={{}}
                  touched={{}}
                  name="logorounding"
                  disabled={false}
                  type="string"
                />
              </PillHorizontalContainer>
            }
            <MarginDivider />
            {isSecondButton && <>
              <PillLineItem>
                <Title>{t("project.addgood.blocks.button")} 2</Title>
              </PillLineItem>
              <PillHorizontalContainer itemCount={2}>
                <DesignColorPickerInput
                  value={customization?.secondButtonCustomization.color || ""}
                  onChange={(e) => handleButtonColorChange(e, `color`, 1)}
                  placeholder={t("design.placeholderDefault")}
                  label={t("goodDesign.buttonColor")}
                  scrollTop={scrollTop}
                />
                <DesignColorPickerInput
                  value={customization?.secondButtonCustomization.onHoverColor || ""}
                  onChange={(e) => handleButtonColorChange(e, `onHoverColor`, 1)}
                  placeholder={t("design.placeholderDefault")}
                  label={t("goodDesign.colorHover")}
                  scrollTop={scrollTop}
                />
              </PillHorizontalContainer>
              <Divider />
              <PillHorizontalContainer itemCount={2}>
                <DesignColorPickerInput
                  value={customization?.secondButtonCustomization.textColor || ""}
                  onChange={(e) => handleButtonColorChange(e, `textColor`, 1)}
                  placeholder={t("design.placeholderDefault")}
                  label={t("goodDesign.textColor")}
                  scrollTop={scrollTop}
                />
                <InputPill
                  label={t("goodDesign.roundingLabel")}
                  value={String(customization?.secondButtonCustomization.borderRadius)}
                  placeholder={String(customization?.secondButtonCustomization.borderRadius)}
                  onChange={(e) => handleInputChange(e, 'borderRadius', 1)}
                  onBlur={() => { }}
                  errors={{}}
                  touched={{}}
                  name="logorounding"
                  disabled={false}
                  type="number"
                />
              </PillHorizontalContainer>
              <MarginDivider />
              <InputPill
                label={t("goodDesign.distanceButton")}
                value={String(customization?.margin)}
                placeholder={String(customization?.margin)}
                onChange={(e) => handleInputChange(e, 'margin')}
                onBlur={() => { }}
                errors={{}}
                touched={{}}
                name="logorounding"
                disabled={false}
                type="number"
              />
              <Divider />
              <PillHorizontalContainer itemCount={2}>
                <InputPill
                  label={t("goodDesign.maxWidth")}
                  value={String(customization?.firstButtonCustomization.width) || ""}
                  placeholder={String(customization?.firstButtonCustomization.width) || ""}
                  onChange={(e) => handleInputChange(e, 'width', 2)}
                  onBlur={() => { }}
                  errors={{}}
                  touched={{}}
                  name="logorounding"
                  disabled={false}
                  type="number"
                />
                <InputPill
                  label={t("goodDesign.marginInButton")}
                  value={String(customization?.firstButtonCustomization.padding) || ""}
                  placeholder={String(customization?.firstButtonCustomization.padding) || ""}
                  onChange={(e) => handleMarginChange(e, 'padding', 0)}
                  onBlur={() => { }}
                  errors={{}}
                  touched={{}}
                  name="logorounding"
                  disabled={false}
                  type="string"
                />
              </PillHorizontalContainer>
              <MarginDivider />
            </>
            }
            <PillLineItem>
              <PillLineLabel>{t("goodDesign.withBackground")}</PillLineLabel>
              <SmallTogleContainer>
                <Toggle
                  small
                  values={[]}
                  onChange={toggleIsBackground}
                  checked={Boolean(customization!.useGradient || (customization!.bgColor !== "transparent"))}
                  toggleBackground
                  name="withBackground"
                  textHints={t("goodDesign.withBackgroundHint")}
                ></Toggle>
              </SmallTogleContainer>
            </PillLineItem>

            <Divider />
            {Boolean(customization!.useGradient || (customization!.bgColor !== "transparent"))
              && (
                <>
                  <PillLineItem>
                    <SelectLine>
                      <PillSelectLabel>
                        {t("goodDesign.backgroundType")}
                      </PillSelectLabel>
                      <Select
                        value={"background.type"}
                        pillStyle
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          console.log("e");
                        }}
                      >
                        <option value="Monotone">
                          {t("design.backgroundFillTypeSolid")}
                        </option>
                      </Select>
                    </SelectLine>
                  </PillLineItem>
                  <Divider />
                  <>
                    <DesignColorPickerInput
                      value={customization?.bgColor || ""}
                      onChange={(e) => handleButtonColorChange(e, `bgColor`)}
                      placeholder={t("design.placeholderDefault")}
                      label={t("design.backgroundColorLabel")}
                      scrollTop={scrollTop + 210}
                      scrollLeft={100}
                    />
                  </>
                </>
              )}
            <MarginDivider />
            <PillLineItem>
              <PillLineLabel>{t("goodDesign.applyToAllButtons")}</PillLineLabel>
              <SmallTogleContainer>
                <Toggle
                  small
                  values={[]}
                  onChange={toggleField}
                  checked={buttonDesign?.applyToAll || false}
                  toggleBackground
                  name="forAllButtons"
                  textHints={t("goodDesign.applyToAllButtonsHint")}
                ></Toggle>
              </SmallTogleContainer>
            </PillLineItem>
          </PillMarginContainer>
        </PillCard>
      </DesignFormContainerWrapper>
    </form>
  );
};

const DesignFormContainerWrapper = styled(DesignFormContainer) <{ height: number }>`
    height: ${props => `${props.height}px`};
    /* overflow: auto; */
    overflow: scroll;

`

const MarginDivider = styled.div`
height: 12px;
background: #000000;
opacity: 0.1;
margin-bottom:10px;
`

const Title = styled.div`
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 100%;
color: #000000;
`

const SelectLine = styled.div`
margin: 20px 0 10px;
`

const StyledBackButton = styled(AddGoodButton)`
  height: 40px;
  width: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  flex: none;
  background: #FFFFFF;
  border: none;
  &:hover{
    background: none;
  }
`;

const StyledDoneButton = styled(AddGoodButton)`
  display: block;
  flex: none;
  color: #000000;
  border: none;
  background: none;
  &:hover{
    background: none;
  }
`;

const HeaderTextStyled = styled(HeaderText)`
  font-weight: 500;
  color: #000000;
`
export default memo(DesignButtonForm);
