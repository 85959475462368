import actionCreatorFactory, { ActionCreator, Action } from "typescript-fsa";
import { MenuPageType } from "src/templates/utils/types/menuPage";
import { ContentPageBlocks } from "src/templates/utils/types/goodType";

const actionCreator = actionCreatorFactory();

export type PageType = {
  id: number | null;
  parentId: number | null;
  typeName: string;
  name: string;
  alias?: string;
  positionIndex?: number;
  isMain: boolean;
  isHidden: boolean;
  hasContent: boolean;
  editable: boolean;
  createdDate?: string;
  children?: [PageType] | [];
  replicatedId?: number;
};

export type SeoParametersType = {
  title: string;
  description: string;
  keywords: string | string[];
  head: string;
};

export type ContentPageType = {
  pageAlias: string;
  pageName?: string;
  isForPublic: boolean;
  modules: ContentPageBlocks;
  seoParameters: SeoParametersType;
};

export type LimitType = {
  type: string;
  freeLimit: number;
  payedLimit: number;
};

export type GetPagesDonePayload = {
  pages: PageType[];
  limits: LimitType[];
  id: string;
};

export const triggerGetPages = actionCreator<string>("pages/TRIGGER_GET_PAGES");
export const getPages = actionCreator.async<
  Action<string>,
  GetPagesDonePayload
>("pages/GET_PAGES");

type ChangePagesPayloadType = {
  data: PageType[];
  id: string;
  deleteData: PageType[];
  callback?: (pages: PageType[]) => void;
};
export const triggerChangePages = actionCreator<ChangePagesPayloadType>(
  "pages/TRIGGER_CHANGE_PAGES"
);
export const changePages = actionCreator.async<
  Action<ChangePagesPayloadType>,
  GetPagesDonePayload
>("pages/CHANGE_PAGES");

export const triggerGetMenuPages = actionCreator<string>(
  "pages/TRIGGER_GET_MENU_PAGES"
);
export const getMenuPages = actionCreator.async<
  Action<string>,
  {
    projectId: string;
    data: MenuPageType[];
  }
>("pages/GET_MENU_PAGES");

export const triggerGetIndexPage = actionCreator<string>(
  "pages/TRIGGER_GET_INDEX_PAGE"
);
export const getIndexPage = actionCreator.async<Action<string>, null>(
  "pages/GET_INDEX_PAGE"
);

export type PageContentPayload = {
  projectId: string;
  alias: string;
};

export type PageContentType = {};

export type PageContentResponseType = {
  projectId: string;
  alias: string;
  data: ContentPageType;
};

export const triggerGetPageContent = actionCreator<PageContentPayload>(
  "pages/TRIGGER_PAGE_CONTENT"
);
export const getPageContent = actionCreator.async<
  Action<PageContentPayload>,
  PageContentResponseType
>("pages/GET_PAGE_CONTENT");

export type UpdatePageContentPayload = {
  projectId: string;
  alias: string;
  modules: ContentPageBlocks;
  seoParameters: SeoParametersType;
  cleanSeo: boolean;
};
export const triggerUpdatePageContent = actionCreator<UpdatePageContentPayload>(
  "pages/TRIGGER_UPDATE_PAGE_CONTENT"
);
export const updatePageContent = actionCreator.async<
  Action<UpdatePageContentPayload>,
  PageContentResponseType
>("pages/UPDATE_PAGE_CONTENT");

export type PagesStateType = {
  readonly loading: boolean;
  readonly data: Record<
    string,
    {
      pages: PageType[];
      limit: LimitType[];
      menuPages: MenuPageType[];
      content: Record<string, ContentPageType>;
    }
  >;
};

export type PagesResponseType = GetPagesDonePayload;

export type SelectPageContentReturnType = {
  blocks: ContentPageBlocks;
  cleanSeo: boolean;
  seoParameters: SeoParametersType;
};
