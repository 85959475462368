import { RootState } from "../rootTypes";
import { createSelector } from "reselect";

const selectDigitalGoodsState = (state: RootState) => state.digitalGoods;

export const selectDigitalGoodsLoading = createSelector(
  selectDigitalGoodsState,
  (state) => state.loading
);

export const selectDigitalFilesNamesLoading = createSelector(
  selectDigitalGoodsState,
  (state) => state.loadNames
);

export const selectDigitalFilesSizeQvota = createSelector(
  selectDigitalGoodsState,
  (state) => state.cloudInfo?.uploadFileQuota
);

export const selectDigitalFilesNames = createSelector(
  selectDigitalGoodsState,
  (state) => state.digitalFilesNames
);

export const selectDeletedFilesIds = createSelector(
  selectDigitalGoodsState,
  (state) => state.deletedFilesIds
);

export const selectFileLoading = createSelector(
  selectDigitalGoodsState,
  (state) => state.loadFile
);
export const selectFileQueue = createSelector(
  selectDigitalGoodsState,
  (state) => state.fileQueue
);

export const selectDigitalGoodsSettings = createSelector(
  selectDigitalGoodsState,
  (state) => {
    return state.digitalGoodsSettings
  }
);

export const selectDigitalGoodsSendingCases = createSelector(
  selectDigitalGoodsState,
  (state) => {
    return state.sendingCases
  }
);

export const selectCloudFreeSpace = createSelector(
  selectDigitalGoodsState,
  (state) => {
    return state.cloudInfo
  }
);

export const selectSubmitFileStoreNames = createSelector(
  selectDigitalGoodsState,
  (state) => {
    return state.submitFilesInfo
  }
);