import React, { memo } from "react";
import styled from "styled-components";

const Check = () => {
  return <CheckIcon />;
};

const CheckIcon = styled.i`
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(1.4, 1.4);
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  &:after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    left: 3px;
    top: -1px;
    width: 6px;
    height: 10px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    transform-origin: bottom left;
    transform: rotate(45deg);
  }
`;

export default memo(Check);
