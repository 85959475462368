import { SubscriptionitemType } from "src/store/subscriptions/subscriptionTypes";
import { produce } from "immer";

export const findActualSubscription = (
  subscriptions: Array<SubscriptionitemType>,
): SubscriptionitemType & {
  isExpired?: boolean
} | null => {
  return produce(subscriptions, (draft) => {
    if (!draft || draft.length === 0) {
      return null;
    }
    const activeSubs = draft.filter((subscription) => subscription.isActive);
    if (activeSubs.length > 0) {
      return activeSubs[0];
    } else {
      const sortedSubs = draft.slice().sort(
        (subscriptionA, subscriptionB) =>
          new Date(subscriptionB.endDate).getTime() -
          new Date(subscriptionA.endDate).getTime()
      );
      const activeSub = sortedSubs[0]
      const isExpired = new Date(activeSub.endDate).getTime() - new Date().getTime() < 0
      return {...activeSub , isExpired };
    }
  });
};
