import {getHtmlCode, HtmlCodeStateType} from "./htmlCodeTypes";
import {reducerWithInitialState} from "typescript-fsa-reducers";
import produce from "immer";


const INITIAL_STATE: HtmlCodeStateType = {
    loading: false,
    htmlCode: null
}

export default reducerWithInitialState(INITIAL_STATE)
    .cases(
        [getHtmlCode.started],
        state => ({
            ...state,
            loading: true
        })
    )
    .case(getHtmlCode.done, (state, action) => {
        return produce(state, (draft) => {
            if (action.result) {
                draft.htmlCode = action.result
            }
            draft.loading = false;
            return draft;
        });
    })