import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { MenuItem, Divider, size, WhiteSelect } from "hopshopui";
import styled from "styled-components";
import { func, object } from "prop-types";
import ProfileLanguagePopup, { TypePopup } from "./ProfileLanguagePopup";

import { useT } from "src/hooks/useT";
import { history } from "src/utils/propTypes";

const ProfileMenu = ({
  match,
  signOut,
  location,
  about,
  getSettingsAbout,
  language,
  changeLanguage,
  availableLanguages,
  changeSettingsAbout,
  projects,
  ...props
}) => {
  const id = projects[0].id;

  useEffect(() => {
    getSettingsAbout({ projectID: id });
  }, [id, getSettingsAbout]);

  const clientLanguage = about.language;
  const active = location.pathname;
  const { t } = useT();

  const [changeLanguagePopup, setChangeLanguagePopup] = useState({
    state: false,
    lang: "",
  });
  const [options, setOptions] = useState({});

  const handleChange = (value) => {
    if (value !== clientLanguage) {
      setChangeLanguagePopup({ state: true, lang: value });
    } else {
      changeLanguage({ language: value });
    }
  };

  const handlePopupClose = () => {
    changeLanguage({ language: changeLanguagePopup.lang });
    setChangeLanguagePopup({ state: false, lang: "" });
  };

  const handlePopupSubmit = () => {
    changeSettingsAbout({
      projectID: id,
      data: { ...about, language: changeLanguagePopup.lang },
    });
    handlePopupClose();
  };

  useEffect(() => {
    if (availableLanguages) {
      const options = availableLanguages.map((item) => {
        return {
          label: t(`profile.language.${item}`),
          id: item,
        };
      });
      setOptions(options);
    }
  }, [availableLanguages, t]);

  return (
    <Container {...props}>
      <Link exact={true} to="/profile/auth">
        <MenuItem active={active.indexOf("/profile/auth") >= 0}>
          {t("profile.dataForAuth")}
        </MenuItem>
      </Link>
      <Link exact={true} to="/profile/services">
        <MenuItem active={active.indexOf("/profile/services") >= 0}>
          {t("profile.projectServices")}
        </MenuItem>
      </Link>
      <Link to="/profile/payments" activeClassName="active">
        <MenuItem active={active === "/profile/payments"}>
          {t("profile.paymentsHistory")}
        </MenuItem>
      </Link>
      <Link to="/profile/notifications">
        <MenuItem active={active.indexOf("/profile/notifications") >= 0}>
          {t("profile.notifications")}
        </MenuItem>
      </Link>
      <DividerStyled />
      <SelectContainer>
        <WhiteSelect
          options={options}
          title={t("profile.language.popup.title")}
          selected={language && t(`profile.language.${language}`)}
          labels
          onItemClick={handleChange}
        />
      </SelectContainer>
      <DividerStyled />
      <Logout onClick={signOut}>{t("profile.logout")}</Logout>
      {changeLanguagePopup.state && (
        <ProfileLanguagePopup
          onClose={handlePopupClose}
          onSubmit={handlePopupSubmit}
          currentLanguage={clientLanguage}
          selectLanguage={changeLanguagePopup.lang}
          type={TypePopup.admin}
        />
      )}
    </Container>
  );
};

ProfileMenu.propTypes = {
  signOut: func.isRequired,
  history: history.isRequired,
  location: object,
  about: object,
};

const SelectContainer = styled.div`
  padding: 10px;
`;
const DividerStyled = styled(Divider)`
  margin-top: 20px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  @media screen and (max-width: ${size.mobile}) {
    display: none;
  }
`;

const Container = styled.div``;

const Link = styled(NavLink)``;
const Logout = styled(MenuItem)`
  color: ${(props) => props.theme.colors.error};
`;

export default ProfileMenu;
