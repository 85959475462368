import React, { FC, memo, useCallback } from "react";
import styled from "styled-components";
import { ConnectedDesignModeWrapperProps } from "../containers/DesignModeWrapper";
import history from 'src/utils/browserHistory'

const DesignModeWrapper: FC<ConnectedDesignModeWrapperProps> = ({
  children,
  designSelection,
  designMode,
  changeProjectDesignType,
  type,
  match,
  toggleDesignMode,
  zIndex = 1
}) => {
  const handleWrapperClick = useCallback(() => {
    if (designSelection) {
      if (type === "Goods") {
        history.push(`/project/${match.params.id}/design`)
        toggleDesignMode()
      } else {
        changeProjectDesignType(type)
      }
    }
  }, [
    designSelection,
    changeProjectDesignType,
    type,
    match,
    toggleDesignMode
  ]);
  return (
    <Wrapper active={designSelection} modeActive={!!designMode} zIndex={zIndex} onClick={handleWrapperClick}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  active: boolean;
  modeActive: boolean
  zIndex: number
}>`
  position: ${props => (props.active || props.modeActive) ? 'relative': 'static'};
  z-index: ${props => props.modeActive ? props.zIndex : props.zIndex + 4};
  &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: ${(props) => (props.active ? "block" : "none")};
    opacity: 0.5;
    z-index: 4;
  }
  &:hover {
    &:after {
      background: ${props => props.theme.colors.dark};
    }
  }
`;

export default memo(DesignModeWrapper);
