import React, { Suspense } from 'react'

const SettingsMainDelivery = React.lazy(() => import('src/modules/projects/containers/ProjectMainDelivery'))

const SettingsMainDeliveryPage = () => {
  return (
    // TODO: add loading shimmer
    <Suspense fallback={''}>
      <SettingsMainDelivery />
    </Suspense>
  )
}

export default SettingsMainDeliveryPage