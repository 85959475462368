import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { getEnvProduction } from './env'

const key = process.env.REACT_APP_YM

export const init = () => {
  if (getEnvProduction()) {
    console.log('production', process.env.REACT_APP_NODE_ENV, "key")
    if (key) {
      LogRocket.init(key)
      setupLogRocketReact(LogRocket)
    }
  }
}

export const identify = (id, props = {}) => {
  if (getEnvProduction() && key)  {
    LogRocket.identify(id, props)
  }
}