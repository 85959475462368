import React, { memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'src/hooks/query'
import history from 'src/utils/browserHistory'
import { func } from 'prop-types'
import { instLogin, vkLogin, okLogin } from 'src/utils/localStorage'

const AuthInstPage = ({ loginSuccess, showNotification }) => {
  const query = useQuery()
  useEffect(() => {
    const accessToken = query.accessToken
    const refreshToken = query.refreshToken
    if (accessToken && refreshToken) {
      localStorage.setItem(instLogin, true)
      localStorage.removeItem(vkLogin)
      localStorage.removeItem(okLogin)
      loginSuccess({ accessToken, refreshToken })
      history.push('/project')
    } else if (query.error) {
      showNotification({
        key: `notifications.${query.error}`
      })
      history.replace('/redirect')
    }
  }, [query, loginSuccess, showNotification])
  return (
    <div></div>
  )
}

AuthInstPage.propTypes = {
  loginSuccess: func,
  showNotification: func
}

export default memo(AuthInstPage)