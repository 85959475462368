import {
    ScenariosType,
    getScenarios,
    ScenariosStateType
  } from "./scenariosTypes";
  import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";
import { camelize } from "casing";
import { boolean } from "yup";

  const INITIAL_STATE: ScenariosStateType = {
    loading: false,
    scenarios: {
      search: {
        show: false,
      },
      favorites: {
        show: false,
      },
      basket: {
        show: false,
      },
      goodsCardBuy: {
        show: false,
        name: '',
        action: '',
        link: '',
      },
      catalogueBuy: {
        show: false,
        name: '',
        action: '',
        link: '',
      },
      bookingBuy:{
        name:'',
      },
      bookingServiceCheckinName:'',
    },
  };

  export default reducerWithInitialState(INITIAL_STATE)
  .cases(
    [
      getScenarios.started,
    ],
    (state) => ({
      ...state,
      loading: true,
    })
  )
  .case(getScenarios.done, (state, action) => {
    return produce(state, (draft) => {
      if (action.result) {
        draft.scenarios = action.result.scenarios;
        draft.scenarios.bookingBuy = {
          name: action.result.scenarios.bookingServiceCheckinName
        };
      }
      draft.loading = false;
      return draft;
    });
  });
