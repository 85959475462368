import actionCreatorFactory, { Action } from "typescript-fsa";
import { BackEndEnum } from "src/types/BackendTypes";
import { providers, ProviderType } from "src/utils/variables";
import { SyncFormValues } from "src/modules/projects/components/SettingsMainSync";

const actionCreator = actionCreatorFactory();

export type ProjectSyncPayload = {
  projectId: string;
  externalProjects?: boolean;
};

export type SyncExternalProjectMappedType = {
  screenName: string;
  name: string;
  provider: ProviderType;
  type?: string;
  id?: string;
};
type DataType = Omit<ProjectSyncDataType, "externalProjects"> & {
  externalProjects: SyncExternalProjectMappedType[];
};

export type ProjectSyncStateType = {
  loading: boolean;
  data: Record<string, DataType>;
  isUpdateInprogress: boolean;
};

export type SyncSettingsType = {
  frequency: number | null;
  hashtags: string[] | null;
  importHashtagsOnly: boolean;
  isUpdateInprogress: boolean;
  lastUpdate: string;
  policy: number;
  provider: ProviderType;
  source: string;
  type: number;
};

export type SyncRequestType = {
  frequencies: BackEndEnum[];
  policies: BackEndEnum[];
  types: BackEndEnum[];
  externalProjects: {
    provider: ProviderType;
    projects: {
      screenName: string;
      name: string;
    }[];
  }[];
  settings: SyncSettingsType;
  hasExternalProvider: boolean
};

export type ProjectSyncDataType = SyncRequestType & {};

export const triggerGetProjectSync = actionCreator<ProjectSyncPayload>(
  "sync/TRIGGER_GET_PROJECT_SYNC"
);

export const getProjectSync = actionCreator.async<
  Action<ProjectSyncPayload>,
  SyncRequestType & {
    projectId: string;
  }
>("sync/GET_PROJECT_SYNC");

export const triggerUpdateProjectSync = actionCreator<
  SyncFormValues & {
    projectId: string;
  }
>("sync/TRIGGER_UPDATE_PROJECT_SYNC_DATA");

export const updateProjectSync = actionCreator.async<
  Action<
    SyncFormValues & {
      projectId: string;
    }
  >,
  SyncSettingsType & {
    projectId: string;
  }
>("sync/UPDATE_PROJECT_SYNC_DATA");

export const triggerUpdateProjectSyncWithoutImport = actionCreator<
  SyncFormValues & {
    projectId: string;
  }
>("sync/TRIGGER_UPDATE_PROJECT_SYNC_DATA_WITHOUT_IMPORT");

export const updateProjectSyncWithoutImport = actionCreator.async<
  Action<
    SyncFormValues & {
      projectId: string;
    }
  >,
  SyncSettingsType & {
    projectId: string;
  }
>("sync/UPDATE_PROJECT_SYNC_DATA_WITHOUT_IMPORT");

export const triggerImportProjectSync = actionCreator<string>(
  "sync/TRIGGER_IMPORT_PROJECT_SYNC"
);

export const importProjectSync = actionCreator.async<Action<string>, {}>(
  "sync/_IMPORT_PROJECT_SYNC"
);


export const triggerUpdateSyncCache = actionCreator<string>("sync/TRIGGER_SYNC_CACHE_BUST")
export const updateSyncCache = actionCreator.async<Action<string>, null>("sync/TRIGGER_SYNC_CACHE_BUST")

export const startSyncInfinite = actionCreator<string>(
  "sync/START_SYNC_INFINITE"
);
export const stopSyncInfinite = actionCreator<{
  result: SyncRequestType & {
    projectId: string;
  };
}>("sync/STOP_SYNC_INFINITE");
