import React, { memo, FC, Fragment, useMemo } from "react";
import { EditorState } from "draft-js";

import InlineControl from "./InlineControl";

type Props = {
  editorState: EditorState;
  onToggle: (props: string) => void;
};

export type InlineStyle = "BOLD" | "ITALIC" | "UNDERLINE" | "STRIKETHROUGH";

const BUTTONS_CONFIG: {
  style: InlineStyle;
  label: string;
}[] = [
  {
    label: "formatBold",
    style: "BOLD",
  },
  {
    label: "formatItalic",
    style: "ITALIC",
  },
  {
    label: "formatUnderline",
    style: "UNDERLINE",
  },
  {
    label: "formatStrikeThrough",
    style: "STRIKETHROUGH",
  },
];

const InlineControls: FC<Props> = ({ onToggle, editorState }) => {
  const inlineStyle = useMemo(() => editorState.getCurrentInlineStyle(), [
    editorState,
  ]);
  return (
    <Fragment>
      {BUTTONS_CONFIG.map((config) => (
        <InlineControl
          style={config.style}
          onToggle={onToggle}
          active={inlineStyle.has(config.style)}
          key={config.style}
        />
      ))}
    </Fragment>
  );
};

export default memo(InlineControls);
