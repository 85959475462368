export const getMasterGoodAlias = (alias: string): string => {
  const separatorIndex = alias.indexOf('+')
  if (separatorIndex >= 0) {
    return alias.substr(0, separatorIndex)
  }
  return alias
}

export const getSeparatedAliases = (alias: string): string[] => {
  const aliases: string[] = []
  const masterAlias = getMasterGoodAlias(alias)
  aliases.push(masterAlias)
  if (alias === masterAlias) {
    return aliases
  }
  const secondAliasCutted = alias.replace(`${masterAlias}+`, '')
  const secondAlias = getMasterGoodAlias(secondAliasCutted)
  aliases.push(secondAlias)
  if (alias === `${masterAlias}+${secondAlias}`) {
    return aliases
  }
  const thirdAliasCutted = alias.replace(`${masterAlias}+${secondAlias}+`, '')
  const thirdAlias = getMasterGoodAlias(thirdAliasCutted)
  aliases.push(thirdAlias)
  return aliases
}
