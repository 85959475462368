import React from 'react'
import { ContactType } from '../types/contactType'
import { GoodImageEditType } from '../types/goodType'
import { MenuPageType } from '../types/menuPage'
import { ProjectScenarios } from '../types/projectScenarios'
import { CardViewFormTypes, ProjectSettingsCardView, HeaderSettings, FooterSettings, MonotoneBackground, GradientBackground } from '../types/projectSettings'
import { BlockCustomizationSettings, ButtonBlockCustomizationSettings } from '../types/goodType'

export const TemplateContext = React.createContext<{
  redirectFav: () => void,
  redirectCart: () => void,
  redirectCheckout: () => void,
  redirectSearch: (search?: string) => void,
  redirectToGood: (goodTitle: string) => void,
  redirectGoods: () => void,
  redirectIndex: () => void,
  redirectItem: (title: string) => void,
  redirectToEditCategories: () => void,
  onAdminHomeClick: () => void,
  getImageUrl: (image?: GoodImageEditType) => string,
  redirectMenu: (payload: { alias: string, typeName: string }, asPath?: string) => void,
  contacts: ContactType[],
  menuPages: MenuPageType[],
  headerSettings: HeaderSettings
  backgroundSettings: GradientBackground | MonotoneBackground,
  footerSettings: FooterSettings,
  loading: boolean,
  baseUrl: string,
  // TODO: currency string
  currency: string,
  template: string,
  contactsOpened: boolean,
  isAdmin: boolean,
  pathName: string
  isMobile: boolean,
  t: (key: string) => string,
  tA:(key: string) => string,
  isUserAdult: string,
  setCartAdultId: (param: string | number) => void,
  // hopshopfront only types to dynamically update design values
  setRounding?: (rounding: number) => void,
  setButtonColorHover?: (color: string) => void
  setButtonColor?: (color: string) => void
  setShowGoodsTitle?: (value: boolean) => void,
  setShowGoodsPrice?: (value: boolean) => void,
  setForm?: (value: CardViewFormTypes) => void,

  projectScenarios: ProjectScenarios,
  blockDesignSettings: BlockCustomizationSettings | null,
  designSelection: boolean,
  designMode: string,
  setBlockDesignSettings: (value: BlockCustomizationSettings) => void,
  changeDesignType: (value: string) => void,
  setInitialBlockDesignSettings: (value: BlockCustomizationSettings) => void,
  setInitialModuleButtonDesign: (value: ButtonBlockCustomizationSettings) => void,
  setModuleButtonDesign: (value:ButtonBlockCustomizationSettings) => void,
  buttonDesign: ButtonBlockCustomizationSettings | null,
  designBlocked: boolean,
  goodsDaysLoading: boolean;
  domain:string;
  
} & ProjectSettingsCardView>({
  baseUrl: '/api',
  headerSettings: {
    showContacts: true,
    showDescription: true,
    showLogo: true,
    showTitle: true,
    extendToFullScreen: false,
    logoRounding: 200 
  },
  backgroundSettings: {
    type: "Monotone",
    payload: {}
  },
  footerSettings: {
    extendToFullScreen :true,
    showTitle :true,
    showPlatformName :true
  },
  rounding: 10,
  buttonColorHover: '',
  buttonColor: '',
  form: 'Rectangular',
  showGoodsPrice: true,
  showGoodsTitle: true,
  contacts: [],
  isUserAdult: '',
  setCartAdultId: () => { },
  t: () => '',
  tA: () => '',
  redirectFav: () => { },
  redirectCart: () => { },
  redirectCheckout: () => { },
  redirectSearch: () => { },
  redirectToGood: (goodTitle: string) => {},
  redirectGoods: () => { },
  redirectItem: () => { },
  redirectToEditCategories: () => { },
  redirectMenu: () => { },
  onAdminHomeClick: () => { },
  getImageUrl: () => '',
  redirectIndex: () => { },
  menuPages: [],
  loading: false,
  currency: 'RUR',
  template: 'default',
  contactsOpened: false,
  pathName: '',
  isAdmin: false,
  isMobile: false,

  projectScenarios: {
    search: {
      show:true
    },
    favorites: {
      show:true
    },
    basket: {
      show:true
    },
    goodsCardBuy: {
      show: false,
      name: '',
      action: 'PutInBusket',
      link: '',
    },
    catalogueBuy: {
      show: false,
      name: '',
      action: 'PutInBusket',
      link: '',
    },
    bookingBuy:{
      name:'',
    },
    bookingServiceCheckinName:'',
  },
  blockDesignSettings: null,
  designSelection: false,
  designMode: '',
  setBlockDesignSettings: () => {},
  changeDesignType: () => {},
  setInitialBlockDesignSettings: () => {},
  setInitialModuleButtonDesign: () => {},
  setModuleButtonDesign: () => {},
  buttonDesign: null,
  designBlocked: false,
  goodsDaysLoading: false,
  domain: '',
})