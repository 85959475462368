import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { loginSuccess } from 'src/store/auth'
import { showNotification } from 'src/store/notification'
import AuthOk from './AuthOk'


const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  loginSuccess,
  showNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthOk))
