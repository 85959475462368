export const getLocalStorage = (key) => {
  if (typeof localStorage != undefined) {
    return localStorage && localStorage.getItem(key)
  }
}

export const setLocalStorage = (key, value) => {
  return localStorage && localStorage.setItem(key, value)
}

export const removeLocalStorage = (key) => {
  return localStorage && localStorage.removeItem(key)
}