import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getProjectDetails } from 'src/store/projects'
import CheckoutFailerPage from './CheckoutFailurePage'

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  getProjectDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(CheckoutFailerPage)
)