import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AuthVkPageStart from './AuthVkStartPage'
import { authVk } from 'src/store/auth'


const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  authVk
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthVkPageStart))
