import { RootState } from "../rootTypes";
import { createSelector } from "reselect";
import produce from "immer";

const selectPromocodesState = (state: RootState) => state.promocodes;

export const selectPromocodesLoading = createSelector(
  selectPromocodesState,
  (state) => state.loading
);

export const selectPromocodes = createSelector(
  selectPromocodesState,
  (state) => {
    return state.promocodes
  }
);

export const selectPromocodesDiscountTypes = createSelector(
  selectPromocodesState,
  (state) => {
    return state.discountTypes
  }
);

export const selectPromocodesDiscountValueTypes = createSelector(
  selectPromocodesState,
  (state) => {
    return state.discountValueTypes
  }
);