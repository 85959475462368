import actionCreatorFactory, { ActionCreator, Action } from "typescript-fsa";

const actionCreator = actionCreatorFactory();

export type SimplePayload = {
  placeholder?: string;
};

export type PhonePayload = SimplePayload & {
  checkInternationalFormat?: boolean;
};

export type CheckboxPayload = {
  description?: string;
  checkedByDefault?: boolean;
  showDocument: boolean;
  documentName?: string;
  documentLink?: string;
};

export type OptionsPayload = {
  options?: string;
  defaultOptions?: string;
  allowMultiple?: boolean;
};

export type CheckoutFieldType = {
  type: string;
  name: string;
  position: number;
  isRequired: boolean;
  payload: SimplePayload & PhonePayload & CheckboxPayload & OptionsPayload;
};
export type GetCheckoutFormDonePayload = {
  fields: CheckoutFieldType[],
  showPromocode: boolean;
};

type getCheckoutFormPayload = {
  id: string;
  showPromocode?: boolean;
}

export const triggerGetCheckoutForm = actionCreator<getCheckoutFormPayload>(
  "checkout/TRIGGER_GET_CHECKOUT_FORM"
);
export const getCheckoutForm = actionCreator.async<
  Action<getCheckoutFormPayload>,
  GetCheckoutFormDonePayload
>("checkout/GET_CHECKOUT_FORM");

type ChangeCheckoutFormPayloadType = {
  data: CheckoutFieldType[];
  id: string;
};
export const triggerChangeCheckoutForm = actionCreator<ChangeCheckoutFormPayloadType>(
  "checkout/TRIGGER_CHANGE_CHECKOUT_FORM"
);
export const changeCheckoutForm = actionCreator.async<
  Action<ChangeCheckoutFormPayloadType>,
  null
>("checkout/CHANGE_CHECKOUT_FORM");

export type CheckoutFormStateType = {
  readonly loading: boolean;
  readonly checkoutFields: CheckoutFieldType[];
  readonly showPromocode: boolean;
};

export type CheckoutFormResponseType = {
  fields: GetCheckoutFormDonePayload;
  show_promocode: boolean;
};

/*{
  "fields": [
    {
      "type": "string",
      "position": 0,
      "name": "string",
      "is_required": true
    }
  ],
  "available_types": [
    "string"
  ]
}*/
