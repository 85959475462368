import { connect } from 'react-redux'
import history from 'src/utils/browserHistory'

import NotificationAction from 'src/modules/notification/components/NotificationAction'
import { selectNotificationAction, delNotificationAction, selectNotificationActionPath } from 'src/store/notification'

const mapStateToProps = (state) => ({
  text: selectNotificationAction(state),
  path: selectNotificationActionPath(state),
  pathname: history.location.pathname
})

const mapDispatchToProps = {
  delNotificationAction
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationAction)
